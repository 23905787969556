import { ConsolidatedProbesHistory } from '@/modules/stats/availability/domain/consolidated-probes-history.js';

export class FindConsolidatedProbesEvents {
  _probeEventsRepository;

  /**
   * @param {ProbeEventsRepository} probeEventsRepository
   */
  constructor(probeEventsRepository) {
    this._probeEventsRepository = probeEventsRepository;
  }

  async execute(period) {
    const probeEvents = await this._probeEventsRepository.findAll(period);

    return ConsolidatedProbesHistory.fromProbeEvents(probeEvents);
  }
}
