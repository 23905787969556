<template>
  <SkyCard class="px-0 py-0 w-full min-w-0 shadow-none">
    <div v-if="probes.isEmpty()">
      <AvailabilityNoProbesFoundAlert class="rounded-md" />
    </div>

    <div v-else>
      <div>
        <AvailabilityMaintenanceAlert
          v-if="hasOnlyMaintenanceEvent"
          class="rounded-md"
        />
        <AvailabilityNotAllProbesOnLineAlert
          v-else-if="hasUnresolvedEvent"
          class="rounded-md"
        />
        <AvailabilityAllProbesOnlineAlert
          v-else
          class="rounded-md"
        />

        <div
          v-if="hasWorkInProgress"
          class="flex flex-row items-center justify-center bg-primary text-white gap-2 text-xs p-2 rounded-md mb-2"
        >
          <FontAwesomeIcon :icon="faBolt" />
          <span>{{ $t('components.stats.workInProgress') }}</span>
        </div>

        <SkyLazy>
          <AvailabilityConsolidatedMonitorsWidget :history="history" />
        </SkyLazy>
      </div>
      <span class="h-[1px] block w-full mx-auto bg-[#D0DAFD] my-4"></span>
      <SkyLazy :min-height="50">
        <AvailabilitySummariesWidget :history="history" />
      </SkyLazy>
    </div>
  </SkyCard>
</template>

<script setup>
import AvailabilityConsolidatedMonitorsWidget from '@/modules/stats/availability/infrastructure/presenters/organisms/widget/AvailabilityConsolidatedMonitorsWidget.vue';
import AvailabilitySummariesWidget from '@/modules/stats/availability/infrastructure/presenters/organisms/widget/AvailabilitySummariesWidget.vue';
import { ConsolidatedProbesHistory } from '@/modules/stats/availability/domain/consolidated-probes-history.js';
import { DateProvider } from '@/modules/stats/availability/domain/dates/date-provider.js';
import { Probes } from '@/modules/stats/availability/domain/probe/probes.js';
import { onMounted, provide, ref } from 'vue';
import AvailabilityNotAllProbesOnLineAlert from '@/modules/stats/availability/infrastructure/presenters/molecules/AvailabilityNotAllProbesOnLineAlert.vue';
import AvailabilityNoProbesFoundAlert from '@/modules/stats/availability/infrastructure/presenters/molecules/AvailabilityNoProbesFoundAlert.vue';
import AvailabilityAllProbesOnlineAlert from '@/modules/stats/availability/infrastructure/presenters/molecules/AvailabilityAllProbesOnlineAlert.vue';
import AvailabilityMaintenanceAlert from '@/modules/stats/availability/infrastructure/presenters/molecules/AvailabilityMaintenanceAlert.vue';
import { availabilityInjectors } from '@/modules/stats/availability/infrastructure/presenters/availability-injectors.js';
import { ProbeEvents } from '@/modules/stats/availability/domain/probe-event/collection/probe-events.js';
import some from 'lodash/some';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
  history: {
    type: ConsolidatedProbesHistory,
    required: true,
  },
  dateProvider: {
    type: DateProvider,
    required: true,
  },
  probes: {
    type: Probes,
    required: true,
  },
  monthsToDisplay: {
    type: Number,
    default: ProbeEvents.MAXIMUM_MONTHS,
  },
});

const allEvents = ref([]);
const hasUnresolvedEvent = ref(false);
const hasWorkInProgress = ref(false);
const hasOnlyMaintenanceEvent = ref(false);

provide(availabilityInjectors.dateProvider, props.dateProvider);
provide(availabilityInjectors.probes, props.probes);
provide(availabilityInjectors.monthsToDisplay, props.monthsToDisplay);

function getHasUnresolvedEvent() {
  return !!allEvents.value.find((event) => !event.lifeTime.resolvedAt);
}

function getHasWorkInProgress() {
  return some(allEvents.value, (event) => !!event.lifeTime.acknowledgedAt && !event.lifeTime.resolvedAt);
}

function getHasOnlyMaintenanceEvent() {
  return allEvents.value.length > 0 && allEvents.value.reduce((acc, event) => (!event.lifeTime.resolvedAt && event.type === 'MAINTENANCE' ? acc : false), true);
}

onMounted(() => {
  allEvents.value = props.history.eventsFromIds();
  hasUnresolvedEvent.value = getHasUnresolvedEvent();
  hasWorkInProgress.value = getHasWorkInProgress();
  hasOnlyMaintenanceEvent.value = getHasOnlyMaintenanceEvent();
});
</script>
