import get from 'lodash/get.js';
import set from 'lodash/set.js';
import has from 'lodash/has.js';
import find from 'lodash/find.js';
import filter from 'lodash/filter.js';
import config from '@/config/index.js';
import forEach from 'lodash/forEach.js';
import findIndex from 'lodash/findIndex.js';
import { i18n } from '@/plugins/i18n/index.js';
import { getServers, getServer, postServerAction, getProviderServerTypesFromZone, postCreateServer, getKubernetes, getKubernetesById } from '@/modules/clouds/clouds.api.js';

const stateData = {
  serversSocket: undefined,
  kubernetesSocket: undefined,
  hasMoreServers: false,
  hasMoreKubernetes: false,
  servers: [],
  kubernetes: [],
  serverTypes: {},
};

const getters = {
  getServers: (state) => state.servers,
  getKubernetes: (state) => state.kubernetes,
  getServer:
    (state) =>
    ({ serverId }) =>
      find(state.servers, { _id: serverId }),
  getKubernetesById:
    (state) =>
    ({ kubernetesId }) =>
      find(state.kubernetes, { _id: kubernetesId }),
  getProviderZoneServerTypes:
    (state) =>
    ({ provider, zone }) =>
      get(state.serverTypes, [provider, zone], []),
  getServerType:
    (state, localGetters) =>
    ({ commercialType, provider, zone }) =>
      find(localGetters.getProviderZoneServerTypes({ provider, zone }), { commercialType }),
};

const mutations = {
  setServers(state, { servers, hasMore }) {
    state.hasMoreServers = hasMore;
    state.servers = servers;
  },
  setKubernetes(state, { kubernetes, hasMore }) {
    state.hasMoreKubernetes = hasMore;
    state.kubernetes = kubernetes;
  },
  setServerSocket(state, { serversSocket }) {
    state.serversSocket = serversSocket;
  },
  setKuberneteSocket(state, { kubernetesSocket }) {
    state.kubernetesSocket = kubernetesSocket;
  },
  addServer(state, { server }) {
    state.servers = [server, ...filter(state.servers, ({ _id }) => _id !== server._id)];
  },
  addKubernetes(state, { kubernetes }) {
    state.kubernetes = [kubernetes, ...filter(state.kubernetes, ({ _id }) => _id !== kubernetes._id)];
  },
  updateServer(state, { server }) {
    const serverIndex = findIndex(state.servers, { _id: server._id });
    if (serverIndex === -1) {
      return;
    }

    forEach(server, (fieldValue, fieldKey) => {
      set(state.servers, [serverIndex, ...fieldKey.split('.')], fieldValue);
    });
  },
  updateKubernetes(state, { kubernetes }) {
    const kubernetesIndex = findIndex(state.kubernetes, { _id: kubernetes._id });
    if (kubernetesIndex === -1) {
      return;
    }

    forEach(kubernetes, (fieldValue, fieldKey) => {
      set(state.kubernetes, [kubernetesIndex, ...fieldKey.split('.')], fieldValue);
    });
  },
  setProviderZoneServerTypes(state, { provider, zone, serverTypes }) {
    set(state.serverTypes, [provider, zone], serverTypes);
  },
};

const actions = {
  async fetchServers({ commit }) {
    try {
      const { servers, hasMore } = await getServers();

      commit('setServers', { servers, hasMore });
    } catch (err) {
      throw new Error(i18n.global.t('clouds.store.fetchServers.requestError'));
    }
  },
  async fetchKubernetes({ commit }) {
    try {
      const { kubernetes, hasMore } = await getKubernetes();

      commit('setKubernetes', { kubernetes, hasMore });
    } catch (err) {
      throw new Error(i18n.global.t('clouds.store.fetchKubernetes.requestError'));
    }
  },
  async fetchKubernetesById({ commit }, { kubernetesId }) {
    try {
      const kubernetes = await getKubernetesById({ kubernetesId });

      commit('addKubernetes', { kubernetes });
    } catch (err) {
      throw new Error(i18n.global.t('clouds.store.fetchKubernetesById.requestError'));
    }
  },
  async fetchServer({ commit }, { serverId }) {
    try {
      const server = await getServer({ serverId });

      commit('addServer', { server });
    } catch (err) {
      throw new Error(i18n.global.t('clouds.store.fetchServer.requestError'));
    }
  },
  async serverAction(store, { serverId, action }) {
    try {
      await postServerAction({ serverId, action });
    } catch (err) {
      throw new Error(i18n.global.t('clouds.store.serverAction.requestError'));
    }
  },
  createSocket({ rootGetters }) {
    const token = rootGetters['auth/token'];

    const { host, protocol } = new URL(config.endpoints.clouds);
    const socketProtocol = protocol === 'http:' ? 'ws:' : 'wss:';

    return new WebSocket(`${socketProtocol}//${host}?token=${token}`);
  },
  async watchServersOpen({ commit, state, dispatch }) {
    if (state.serversSocket) return;

    const serversSocket = await dispatch('createSocket');

    serversSocket.onmessage = ({ data }) => {
      try {
        const { type, payload } = JSON.parse(data);

        if (!payload.document) {
          return;
        }

        commit(type, { server: payload.document });
      } catch (err) {
        console.error(err);
      }
    };

    serversSocket.onopen = () => {
      console.debug('socket:clouds:servers#open');
    };

    serversSocket.onclose = () => {
      commit('setServerSocket', { serversSocket: undefined });
      console.debug('socket:clouds:servers#closed');
    };

    serversSocket.onerror = () => {
      console.debug('socket:clouds:servers#error');
      setTimeout(() => dispatch('watchServersOpen'), 5000);
    };

    commit('setServerSocket', { serversSocket });
  },
  watchServersClose({ commit, state }) {
    if (!state.serversSocket) return;
    state.serversSocket.close();
    commit('setServerSocket', { serversSocket: undefined });
  },
  async watchKubernetesOpen({ commit, state, dispatch }) {
    if (state.kubernetesSocket) return;

    const kubernetesSocket = await dispatch('createSocket');

    kubernetesSocket.onmessage = ({ data }) => {
      try {
        const { type, payload } = JSON.parse(data);

        if (!payload.document) {
          return;
        }

        commit(type, { kubernetes: payload.document });
      } catch (err) {
        console.error(err);
      }
    };

    kubernetesSocket.onopen = () => {
      console.debug('socket:clouds:kubernetes#open');
    };

    kubernetesSocket.onclose = () => {
      commit('setKuberneteSocket', { kubernetesSocket: undefined });
      console.debug('socket:clouds:kubernetes#closed');
    };

    kubernetesSocket.onerror = () => {
      console.debug('socket:clouds:kubernetes#error');
      setTimeout(() => dispatch('watchKubernetesOpen'), 5000);
    };

    commit('setKuberneteSocket', { kubernetesSocket });
  },
  watchKubernetesClose({ commit, state }) {
    if (!state.kubernetesSocket) return;
    state.kubernetesSocket.close();
    commit('setKuberneteSocket', { kubernetesSocket: undefined });
  },
  async fetchTypes({ state, commit }, { provider, zone }) {
    if (has(state.serverTypes, [provider, zone])) {
      return;
    }
    try {
      const { hasMore, serverTypes } = await getProviderServerTypesFromZone({ provider, zone });

      commit('setProviderZoneServerTypes', {
        provider,
        zone,
        hasMore,
        serverTypes,
      });
    } catch (err) {
      throw new Error(i18n.global.t('clouds.store.fetchTypes.requestError'));
    }
  },
  async createServer({ commit }, { provider, zone, image, commercialType }) {
    try {
      const server = await postCreateServer({
        provider,
        zone,
        image,
        commercialType,
      });

      commit('addServer', { server });
      return server;
    } catch (err) {
      throw new Error(i18n.global.t('clouds.store.createServer.requestError'));
    }
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
