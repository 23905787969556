<template>
  <Notifications />
  <router-view />
</template>

<style lang="scss">
.notify {
  z-index: 9999;
  transition: height 2s ease;
  max-width: 80%;
  @apply top-16 right-8 fixed flex flex-col-reverse items-end;

  &__item {
    @apply py-4 px-6 cursor-pointer rounded shadow text-white overflow-hidden bg-gray-500;

    &:not(:last-child) {
      @apply mt-2;
    }

    &.error {
      @apply bg-red-400;
    }
    &.warning {
      @apply bg-orange-400;
    }
    &.success {
      @apply bg-green-500;
    }
    &.info {
      @apply bg-blue-500;
    }
  }
}
</style>
