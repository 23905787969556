import { capitalize } from '@/utils/capitalize.js';
import { ValueObject } from '@/utils/value-objects/value-object.js';
import { InvalidDateValueObject } from '@/utils/value-objects/date/invalid-date-value-object.js';
import { required } from '@/utils/validate/required.js';
import { ImmutableCollection } from '@/utils/collections/immutable-collection.js';

const SECOND_MILLISECONDS = 1000;
const MINUTE_MILLISECONDS = SECOND_MILLISECONDS * 60;
const HOUR_MILLISECONDS = MINUTE_MILLISECONDS * 60;
const DAY_MILLISECONDS = HOUR_MILLISECONDS * 24;

export class DateValueObject extends ValueObject {
  /**
   * @param {DateValueObject | Date | string | number} value
   */
  static fromNative(value) {
    if (value instanceof DateValueObject) {
      return new this(value._value);
    }

    return new this(new Date(value));
  }

  /**
   * @protected
   */
  validate() {
    required(this._value instanceof Date, InvalidDateValueObject.fromValue(this._value));
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  isEqual(date) {
    return this.hasSameDay(date) && this.hasSameWeek(date) && this.hasSameMonth(date) && this.hasSameYear(date);
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  isBefore(date) {
    return this._value < date._value;
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  isBeforeOrEqual(date) {
    return this._value <= date._value;
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  isAfter(date) {
    return this._value > date._value;
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  isAfterOrEqual(date) {
    return this._value >= date._value;
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  isAfterCurrentMonth(date) {
    return this.isAfter(date) && !this.hasSameMonth(date);
  }

  /**
   * @param {DateValueObject} toDate
   * @return {ImmutableCollection<DateValueObject>}
   */
  getAllDaysBetween(toDate) {
    const firstDate = this.isBeforeOrEqual(toDate) ? this : toDate;
    const lastDate = this.isBeforeOrEqual(toDate) ? toDate : this;

    const days = [];
    for (let date = firstDate; date.isBeforeOrEqual(lastDate); date = date.addDays(1)) {
      days.push(date.withoutTime());
    }

    return new ImmutableCollection(...days);
  }

  /**
   * @return {ImmutableCollection<DateValueObject>}
   */
  getAllDaysUntilEndOfMonth() {
    const numberOfDaysUntilEndOfMonth = this.getDaysInMonth() - this._value.getDate();

    return this.getAllDaysBetween(this.addDays(numberOfDaysUntilEndOfMonth));
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  hasSameDay(date) {
    return this._value.getDate() === date._value.getDate() && this.hasSameMonth(date);
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  hasSameWeek(date) {
    return this.getWeekNumber() === date.getWeekNumber() && this.hasSameMonth(date);
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  hasSameISO8601Week(date) {
    return this.getISO8601WeekNumber() === date.getISO8601WeekNumber();
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  hasSameISO8601WeekAndMonth(date) {
    return this.hasSameISO8601Week(date) && this.hasSameMonth(date);
  }

  /**
   * @param {DateValueObject} date
   * @returns {boolean}
   */
  hasSameMonth(date) {
    return this._value.getMonth() === date._value.getMonth() && this.hasSameYear(date);
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  hasSameYear(date) {
    return this._value.getFullYear() === date._value.getFullYear();
  }

  toLocaleString() {
    return this._value.toLocaleString();
  }

  isFirstWeek() {
    return this.isIso8601WeekNumberMaximum() && this.getWeekNumber() === 1;
  }

  getMaximumWeekNumber() {
    return 52;
  }

  isIso8601WeekNumberMaximum() {
    return this.getISO8601WeekNumber() === this.getMaximumWeekNumber();
  }

  /**
   * @returns {number}
   */
  getISO8601WeekNumber() {
    const targetDate = new Date(this._value);
    const dayOfWeek = (this._value.getDay() + 6) % 7;
    targetDate.setDate(targetDate.getDate() - dayOfWeek + 3);
    const firstThursday = targetDate.valueOf();
    targetDate.setMonth(0, 1);
    if (targetDate.getDay() !== 4) {
      targetDate.setMonth(0, 1 + ((4 - targetDate.getDay() + 7) % 7));
    }

    return 1 + Math.ceil((firstThursday - targetDate) / 604800000);
  }

  /**
   * @returns {number}
   */
  getWeekNumber() {
    const oneJanuary = new Date(this._value.getFullYear(), 0, 1);

    return Math.ceil(((this._value.getTime() - oneJanuary.getTime()) / 86400000 + oneJanuary.getDay() + 1) / 7);
  }

  getDaysInMonth() {
    return new Date(this._value.getFullYear(), this._value.getMonth() + 1, 0).getDate();
  }

  isDayNumber(dayNumber) {
    return this._value.getDate() === dayNumber;
  }

  /**
   * @param {DateValueObject} date
   * @return {boolean}
   */
  isSameDay(date) {
    return this._value.getDate() === date._value.getDate() && this.hasSameMonth(date);
  }

  getDayNumber() {
    return this._value.getDate();
  }

  get currentMonthLabel() {
    return capitalize(this._value.toLocaleString(navigator.language, { month: 'long', year: 'numeric' }));
  }

  getRelativeTime() {
    const relativeTimeFormat = new Intl.RelativeTimeFormat(navigator.language, {
      numeric: 'auto',
    });
    const daysDifference = Math.round((this._value.getTime() - new Date().getTime()) / DAY_MILLISECONDS);
    const hoursDifference = Math.round((this._value.getTime() - new Date().getTime()) / HOUR_MILLISECONDS);

    return daysDifference === 0 ? relativeTimeFormat.format(hoursDifference, 'hour') : relativeTimeFormat.format(daysDifference, 'day');
  }

  /**
   * @param {number} dayNumber
   * @returns {Date}
   */
  createDateWithSameYearAndMonth(dayNumber) {
    return new Date(new Date(this._value.getFullYear(), this._value.getMonth(), dayNumber));
  }

  /**
   * @param {number} dayNumber
   * @return {number}
   */
  getTimeFromDayNumber(dayNumber) {
    return this.createDateWithSameYearAndMonth(dayNumber).getTime();
  }

  /**
   * @returns {number}
   */
  getTimeOfTheFirstDayOfMonth() {
    return this.getTimeFromDayNumber(1);
  }

  /**
   * @param {number} number
   */
  addDays(number) {
    return new this.constructor(
      new Date(
        this._value.getFullYear(),
        this._value.getMonth(),
        this._value.getDate() + number,
        this._value.getHours(),
        this._value.getMinutes(),
        this._value.getSeconds(),
        this._value.getMilliseconds(),
      ),
    );
  }

  /**
   * @param {number} number
   * @return {DateValueObject}
   */
  addMonths(number) {
    return new this.constructor(
      new Date(
        this._value.getFullYear(),
        this._value.getMonth() + number,
        this._value.getDate(),
        this._value.getHours(),
        this._value.getMinutes(),
        this._value.getSeconds(),
        this._value.getMilliseconds(),
      ),
    );
  }

  /**
   * @param {number} number
   * @return {DateValueObject}
   */
  addHours(number) {
    return new this.constructor(
      new Date(
        this._value.getFullYear(),
        this._value.getMonth(),
        this._value.getDate(),
        this._value.getHours() + number,
        this._value.getMinutes(),
        this._value.getSeconds(),
        this._value.getMilliseconds(),
      ),
    );
  }

  /**
   * @param {number} number
   * @return {DateValueObject}
   */
  removeMonths(number) {
    return this.addMonths(-number);
  }

  withoutTime() {
    return new this.constructor(new Date(this._value.getFullYear(), this._value.getMonth(), this._value.getDate()));
  }

  withEndOfDayTime() {
    return new this.constructor(new Date(this._value.getFullYear(), this._value.getMonth(), this._value.getDate(), 23, 59, 59, 999));
  }

  /**
   * @param {DateValueObject} date
   * @return {0|1|-1}
   */
  compareTo(date) {
    if (this._value > date._value) {
      return 1;
    }

    return this._value < date._value ? -1 : 0;
  }

  /**
   * @param {DateValueObject} date1
   * @param {DateValueObject} date2
   * @return {boolean}
   */
  isBetweenTwoDates(date1, date2) {
    return this.compareTo(date1) >= 0 && this.compareTo(date2) <= 0;
  }

  getTimeEllapsed(date) {
    return this._value.getTime() - date._value.getTime();
  }
}
