import get from 'lodash/get';

export function formatKPIUrl(baseUrl, params) {
  const from = get(params, 'from');
  const to = get(params, 'to');

  if (to && from) {
    return `${baseUrl}?to=${to}&from=${from}`;
  }

  if (to) {
    return `${baseUrl}?to=${to}`;
  }

  if (from) {
    return `${baseUrl}?from=${from}`;
  }

  return baseUrl;
}

export function getAvailabilityReportTotalTime(events) {
  return events.reduce((acc, event) => {
    if (!event.lifeTime.resolvedAt) return acc;
    return acc + event.lifeTime.resolvedAt.getTimeEllapsed(event.lifeTime.startedAt);
  }, 0);
}
