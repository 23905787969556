<template>
  <SkyModal
    :open="open"
    transparent
    hide-close-button
    @close="emit('close')"
  >
    <div class="max-w-xl mx-auto overflow-hidden bg-white rounded-lg lg:max-w-3xl">
      <SkyForm
        class="p-4"
        :class="{ 'border-b': notEmptySearch }"
      >
        <SkyInputText
          v-model="form.search"
          autofocus
          :placeholder="$t('components.projects.taskSearchModal.placeholder')"
          :icon="faMagnifyingGlass"
          @input="handleSearch"
          @keydown.up.prevent="hightlightPreviousResult"
          @keydown.down.prevent="hightlightNextResult"
          @keydown.enter="openFocusedTask"
        />
        <span class="text-xs text-gray-400">{{ $t('components.projects.taskSearchModal.helperText') }}</span>
      </SkyForm>
      <div
        v-if="notEmptySearch"
        class="flex flex-col p-2"
      >
        <p
          v-if="loading"
          class="text-xs text-gray-400"
        >
          {{ $t('components.projects.taskSearchModal.searching') }}
        </p>
        <p
          v-else-if="tasks.length === 0"
          class="text-xs text-gray-400"
        >
          {{ $t('components.projects.taskSearchModal.noResults') }}
        </p>
        <div
          v-else
          class="p-2"
        >
          <span class="p-2 text-sm font-medium">{{ $t('components.projects.taskSearchModal.resultsTitle') }} :</span>
          <div
            v-for="(task, index) in bestResults"
            :key="task.taskId"
            class="search-results"
            tabindex="0"
            :class="{ 'bg-gray-100': isCurrentResult(index) }"
            @click="() => openTask(task)"
            @keydown.enter="() => openTask(task)"
            @mouseover="highlightedResultIndex = index"
            @focus="highlightedResultIndex = index"
          >
            <div class="p-2 overflow-hidden whitespace-nowrap overflow-ellipsis">
              <span
                v-if="task.deletedAt"
                class="p-1 px-2 mr-1 text-xs text-white bg-yellow-600 border border-yellow-600 rounded"
              >
                {{ $t('components.projects.taskSearchModal.archived') }}
              </span>
              <span
                class="p-1 px-2 mr-3 text-xs text-white rounded"
                :class="`search-${task.listId}`"
              >
                <FontAwesomeIcon
                  :icon="get(statusListsDetails, `${task.listId}.icon`)"
                  class="mr-0.5"
                />
                {{ get(statusListsDetails, `${task.listId}.name`) }}
              </span>
              <span class="font-bold text-gray-300">{{ task.taskId }}</span>
              -
              <span>{{ task.name }}</span>
            </div>
            <span class="open-tag">{{ $t('components.projects.taskSearchModal.openTask') }}</span>
          </div>

          <span
            v-if="tasks.length > bestResults.length"
            class="p-2 text-xs text-gray-400 underline cursor-pointer"
            @click="emit('close')"
          >
            {{ $t('components.projects.taskSearchModal.seeAll') }} : {{ numberOfResultsLeft }}
            {{ numberOfResultsLeft > 1 ? $t('components.projects.taskSearchModal.moreResults') : $t('components.projects.taskSearchModal.moreResult') }}
          </span>
        </div>
      </div>
    </div>
  </SkyModal>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import get from 'lodash/get';
import slice from 'lodash/slice';
import { faBolt, faCheckDouble, faInboxIn, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { useI18n } from 'vue-i18n';

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'search'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();

    const state = reactive({
      form: {
        search: undefined,
      },
      loading: false,
      currentSearch: computed(() => store.getters['projects/getCurrentSearch']),
      notEmptySearch: computed(() => state.currentSearch?.length > 0),
      tasks: [],
      timer: undefined,
      highlightedResultIndex: -1,
      bestResults: computed(() => slice(state.tasks, 0, 5)),
      numberOfResultsLeft: computed(() => state.tasks.length - state.bestResults.length),
      statusListsDetails: {
        todo: {
          name: t('components.projects.taskSearchModal.taskList.todo'),
          icon: faInboxIn,
        },
        in_progress: {
          name: t('components.projects.taskSearchModal.taskList.in_progress'),
          icon: faBolt,
        },
        done: {
          name: t('components.projects.taskSearchModal.taskList.done'),
          icon: faCheckDouble,
        },
      },
    });

    function handleAfterSearch(tasks) {
      state.tasks = tasks;
      state.loading = false;
    }

    function handleSearch() {
      clearTimeout(state.timer);
      state.timer = setTimeout(async () => {
        state.loading = true;
        store.commit('projects/setCurrentSearch', { search: state.form.search });
        emit('search', handleAfterSearch);
      }, 500);
    }

    function openTask({ taskId, appId }) {
      emit('close', { taskSlug: taskId, appId });
    }

    function hightlightPreviousResult() {
      if (state.highlightedResultIndex > 0) {
        state.highlightedResultIndex -= 1;
      }
    }

    function hightlightNextResult() {
      if (state.highlightedResultIndex < state.bestResults.length - 1) {
        state.highlightedResultIndex += 1;
      }
    }

    function isCurrentResult(index) {
      return state.highlightedResultIndex === index;
    }

    function openFocusedTask() {
      const focusedTask = get(state.bestResults, `[${state.highlightedResultIndex}]`);

      if (!focusedTask) {
        return;
      }
      openTask(focusedTask);
    }

    watch(
      () => store.getters['projects/getCurrentSearch'],
      () => {
        state.form = {
          search: store.getters['projects/getCurrentSearch'],
        };
      },
    );

    onMounted(() => {
      state.tasks = store.getters['projects/getFilteredTasks'];
    });

    return {
      ...toRefs(state),
      handleSearch,
      emit,
      openTask,
      get,
      hightlightPreviousResult,
      hightlightNextResult,
      isCurrentResult,
      openFocusedTask,
      faMagnifyingGlass,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-results {
  @apply rounded mt-3 gap-5 hover:bg-gray-100 p-2 transition-colors text-sm flex flex-row justify-between items-center cursor-pointer;

  .open-tag {
    @apply text-gray-400 text-xs invisible;
  }

  &:hover .open-tag {
    @apply visible;
  }

  .search-todo {
    @apply bg-error;
  }

  .search-in_progress {
    @apply bg-warning;
  }

  .search-done {
    @apply bg-success;
  }
}
</style>
