<template>
  <div
    v-if="needPagination"
    class="bg-[#EBEEFF] p-3 flex flex-row justify-between items-center w-full rounded"
  >
    <div class="flex flex-row gap-2 items-center">
      <FontAwesomeIcon
        :icon="faSquareCaretLeft"
        class="text-[#9faee3] text-xl"
        :class="{
          'cursor-pointer': canGoPreviousPage,
          'cursor-not-allowed opacity-50': !canGoPreviousPage,
        }"
        @click="canGoPreviousPage && goToPage(-internalPageSize)"
      />
      <FontAwesomeIcon
        :icon="faSquareCaretRight"
        class="text-[#9faee3] text-xl"
        :class="{
          'cursor-pointer': canGoNextPage,
          'cursor-not-allowed opacity-50': !canGoNextPage,
        }"
        @click="canGoNextPage && goToPage(+internalPageSize)"
      />
    </div>
    <span class="text-xs text-[#9faee3]">{{ start / internalPageSize + 1 }} / {{ Math.ceil(list.length / internalPageSize) }}</span>
    <div class="relative group">
      <span class="text-xs text-[#9faee3] border border-dark-blue-100 rounded px-3 py-2 cursor-pointer hover:bg-[#dadef2] font-medium">
        {{ internalPageSize }} / {{ list.length }} {{ type }}
      </span>
      <div class="hidden absolute flex-col bg-[#EBEEFF] text-[#9faee3] text-xs border border-dark-blue-100 rounded w-full bottom-full group-hover:flex">
        <button
          type="button"
          class="whitespace-nowrap px-2 py-1 hover:bg-[#dadef2] font-medium"
          @click="() => setPageSize(pageSize)"
        >
          {{ pageSize }} {{ type }}
        </button>
        <button
          type="button"
          class="whitespace-nowrap px-2 py-1 hover:bg-[#dadef2] font-medium"
          @click="() => setPageSize(Math.ceil(list.length / 2))"
        >
          {{ $t('components.ui.skyPagination.halfItems') }}
        </button>
        <button
          type="button"
          class="whitespace-nowrap px-2 py-1 hover:bg-[#dadef2] font-medium"
          @click="() => setPageSize(list.length)"
        >
          {{ $t('components.ui.skyPagination.allItems') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { faSquareCaretLeft, faSquareCaretRight } from '@fortawesome/pro-duotone-svg-icons';
import { computed, onMounted, ref, watch } from 'vue';

const emit = defineEmits(['update:model-value']);
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  list: {
    type: Array,
    default: () => [],
  },
  pageSize: {
    type: Number,
    default: 5,
  },
  type: {
    type: String,
    default: 'items',
  },
});

const start = ref(0);
const internalPageSize = ref(props.pageSize);
const needPagination = computed(() => props.list.length > internalPageSize.value || internalPageSize.value !== props.pageSize);
const canGoNextPage = computed(() => start.value + internalPageSize.value < props.list.length);
const canGoPreviousPage = computed(() => start.value > 0);
const paginatedList = computed(() => (needPagination.value ? props.list.slice(start.value, start.value + internalPageSize.value) : props.list));

function setPageSize(size) {
  internalPageSize.value = size;
  start.value = 0;
  emit('update:model-value', paginatedList.value);
}

function goToPage(page) {
  start.value += page;
  emit('update:model-value', paginatedList.value);
}

watch(
  () => props.list,
  () => emit('update:model-value', paginatedList.value),
  { deep: true },
);

onMounted(() => {
  emit('update:model-value', paginatedList.value);
});
</script>
