/**
 * @interface
 */
export class ProbeEventsRepository {
  /**
   * @return {Promise<ProbeEvents>}
   */
  async findAll() {
    throw new Error('Not implemented');
  }
}
