<template>
  <div class="relative state-filters z-20">
    <div
      class="relative text-xs transition-colors rounded hover:opacity-75"
      :class="{
        'text-primary hover:bg-gray-100': !open && countActiveStateFilters === 0,
        'text-white bg-primary': open,
        'text-white bg-green-400': !open && countActiveStateFilters > 0,
      }"
    >
      <button
        type="button"
        class="px-3 py-2 outline-none appearance-none focus:outline-none"
        @click="open = !open"
      >
        <FontAwesomeIcon :icon="faFilter" />
        {{ countActiveStateFilters > 0 ? `${countActiveStateFilters} filtre${countActiveStateFilters > 1 ? 's' : ''}` : '' }}
      </button>
      <button
        v-show="countActiveStateFilters > 0"
        type="button"
        class="px-2 py-2 bg-white outline-none appearance-none focus:outline-none bg-opacity-30 hover:bg-opacity-10"
        @click="resetAndClose"
      >
        <FontAwesomeIcon :icon="faTimes" />
      </button>
    </div>
    <SkyPopup
      :open="open"
      class="flex flex-col items-start p-5 sky-popup-positionning"
      :class="{
        'active-label-filters': activeLabelFilters.length > 0,
      }"
    >
      <SkyTitle class="mb-4 text-2xl">{{ $t('components.projects.stateFiltersSelect.title') }}</SkyTitle>
      <div class="grid gap-4 sky-popup-content-container">
        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium text-primary">{{ $t('components.projects.stateFiltersSelect.kind.label') }}</span>
          <label
            v-for="(filterLabel, filterValue) in taskKinds"
            :key="filterValue"
            :for="`state-filter-kind-${filterValue}`"
            class="block px-2 py-2 text-sm font-light rounded cursor-pointer select-none whitespace-nowrap hover:opacity-75"
            :class="{
              'bg-gray-100': !kindFilters.includes(filterValue),
              'bg-primary text-white': kindFilters.includes(filterValue),
            }"
          >
            <input
              :id="`state-filter-kind-${filterValue}`"
              v-model="kindFilters"
              type="checkbox"
              name="state-filter-kind"
              class="hidden"
              :value="filterValue"
            />
            <div class="flex flex-row items-center justify-start gap-2">
              <span
                class="flex items-center justify-center w-4 h-4 overflow-hidden border rounded"
                :class="{
                  'text-transparent border-gray-300': !kindFilters.includes(filterValue),
                  'bg-white border-white text-primary': kindFilters.includes(filterValue),
                }"
              >
                <FontAwesomeIcon
                  :icon="faCheck"
                  style="font-size: 0.6rem"
                />
              </span>
              {{ filterLabel }}
            </div>
          </label>
          <SkyForm
            no-validate
            class="flex flex-col gap-2"
          >
            <SkySelect
              v-model="isPendingFilter"
              :label="$t('components.projects.stateFiltersSelect.pending.label')"
            >
              <option :value="undefined">{{ $t('components.projects.stateFiltersSelect.pending.all') }}</option>
              <option :value="true">{{ $t('components.projects.stateFiltersSelect.pending.yes') }}</option>
              <option :value="false">{{ $t('components.projects.stateFiltersSelect.pending.no') }}</option>
            </SkySelect>
          </SkyForm>
        </div>

        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium text-primary">{{ $t('components.projects.stateFiltersSelect.priority.label') }}</span>
          <label
            v-for="{ code } in taskPriorities"
            :key="code"
            :for="`state-filter-priority-${code}`"
            class="block px-2 py-2 text-sm font-light rounded cursor-pointer select-none whitespace-nowrap bg-gray-100 hover:opacity-75"
          >
            <input
              :id="`state-filter-priority-${code}`"
              v-model="prioritiesFilters"
              type="checkbox"
              name="state-filter-priority"
              class="hidden"
              :value="code"
            />
            <div class="flex flex-row items-center justify-start gap-2">
              <span
                class="flex items-center justify-center w-4 h-4 overflow-hidden border rounded"
                :class="{
                  'text-transparent border-gray-300': !prioritiesFilters.includes(code),
                  'bg-primary border-primary text-white': prioritiesFilters.includes(code),
                }"
              >
                <FontAwesomeIcon
                  :icon="faCheck"
                  style="font-size: 0.6rem"
                />
              </span>
              <TaskPriorityIcon :priority="code" />
            </div>
          </label>
        </div>

        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium text-primary">{{ $t('components.projects.stateFiltersSelect.estimation.label') }}</span>
          <label
            v-for="(filterLabel, filterValue) in taskEstimationStates"
            :key="filterValue"
            :for="`state-filter-estimation-${filterValue}`"
            class="block px-2 py-2 text-sm font-light rounded cursor-pointer select-none whitespace-nowrap hover:opacity-75"
            :class="{
              'bg-gray-100': !estimationFilters.includes(filterValue),
              'bg-primary text-white': estimationFilters.includes(filterValue),
            }"
          >
            <input
              :id="`state-filter-estimation-${filterValue}`"
              v-model="estimationFilters"
              type="checkbox"
              name="state-filter-estimation"
              class="hidden"
              :value="filterValue"
            />
            <div class="flex flex-row items-center justify-start gap-2">
              <span
                class="flex items-center justify-center w-4 h-4 overflow-hidden border rounded"
                :class="{
                  'text-transparent border-gray-300': !estimationFilters.includes(filterValue),
                  'bg-white border-white text-primary': estimationFilters.includes(filterValue),
                }"
              >
                <FontAwesomeIcon
                  :icon="faCheck"
                  style="font-size: 0.6rem"
                />
              </span>
              {{ filterLabel }}
            </div>
          </label>

          <span class="text-sm font-medium text-primary mt-2">{{ $t('components.projects.stateFiltersSelect.milestone.label') }}</span>
          <SkyFilterList
            v-model="filteredMilestones"
            :elements="taskMilestones"
            search-on="name"
            @search="handleSearchNewMilestones"
          >
            <label
              v-for="milestone in filteredMilestones"
              :key="milestone._id"
              :for="`state-filter-estimation-${milestone._id}`"
              class="block px-2 py-2 text-sm font-light rounded cursor-pointer select-none whitespace-nowrap hover:opacity-75"
              :class="{
                'bg-gray-100': !milestonesFilters.includes(milestone._id),
                'bg-primary text-white': milestonesFilters.includes(milestone._id),
              }"
            >
              <input
                :id="`state-filter-estimation-${milestone._id}`"
                v-model="milestonesFilters"
                type="checkbox"
                name="state-filter-estimation"
                class="hidden"
                :value="milestone._id"
              />
              <div class="flex flex-row items-center justify-start gap-2">
                <span
                  class="flex items-center justify-center w-4 h-4 overflow-hidden border rounded"
                  :class="{
                    'text-transparent border-gray-300': !milestonesFilters.includes(milestone._id),
                    'bg-white border-white text-primary': milestonesFilters.includes(milestone._id),
                  }"
                >
                  <FontAwesomeIcon
                    :icon="faCheck"
                    style="font-size: 0.6rem"
                  />
                </span>
                <FontAwesomeIcon
                  :icon="faFlag"
                  class="text-[#98A2D2]"
                />
                {{ milestone.name }}
              </div>
            </label>
          </SkyFilterList>

          <div v-if="selectedMilestones.length > 0">
            <span class="text-xs text-gray-500">Other selected elements</span>
            <label
              v-for="milestone in selectedMilestones"
              :key="milestone._id"
              :for="`state-filter-estimation-${milestone._id}`"
              class="block px-2 py-2 text-sm font-light rounded cursor-pointer select-none whitespace-nowrap hover:opacity-75"
              :class="{
                'bg-gray-100': !milestonesFilters.includes(milestone._id),
                'bg-primary text-white': milestonesFilters.includes(milestone._id),
              }"
            >
              <input
                :id="`state-filter-estimation-${milestone._id}`"
                v-model="milestonesFilters"
                type="checkbox"
                name="state-filter-estimation"
                class="hidden"
                :value="milestone._id"
              />
              <div class="flex flex-row items-center justify-start gap-2">
                <span
                  class="flex items-center justify-center w-4 h-4 overflow-hidden border rounded"
                  :class="{
                    'text-transparent border-gray-300': !milestonesFilters.includes(milestone._id),
                    'bg-white border-white text-primary': milestonesFilters.includes(milestone._id),
                  }"
                >
                  <FontAwesomeIcon
                    :icon="faCheck"
                    style="font-size: 0.6rem"
                  />
                </span>
                <FontAwesomeIcon
                  :icon="faFlag"
                  class="text-[#98A2D2]"
                />
                {{ milestone.name }}
              </div>
            </label>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <SkyForm
            v-if="enableMembers"
            no-validate
            class="flex flex-col gap-2"
          >
            <UsersSelect
              v-model="membersFilters"
              :label="$t('components.projects.stateFiltersSelect.assignedUser.label')"
            />
          </SkyForm>
          <span class="text-sm font-medium text-primary whitespace-nowrap">{{ $t('components.projects.stateFiltersSelect.delivery.label') }}</span>
          <SkyForm>
            <SkyDatePicker
              v-model="deliveryDateFilter"
              :icon="faCalendarAlt"
            />
            <div class="flex flex-col justify-center gap-4 p-2 mt-3 border-2 border-gray-100 rounded">
              <SkyInputRadioSwitch
                v-model="deliveryComparatorFilter"
                :helper-text="$t('components.projects.stateFiltersSelect.delivery.helperText')"
                name="state-filter-delivery-date-comparator"
                :choices="deliveryComparators"
                required
              />
              <SkyButton
                size="small"
                variant="plain"
                @click="clearDateFilter()"
              >
                {{ $t('components.projects.stateFiltersSelect.delivery.removeFilter') }}
              </SkyButton>
            </div>
          </SkyForm>
        </div>
      </div>
    </SkyPopup>
  </div>
</template>

<script>
import { faCalendarAlt, faFilter, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { computed, reactive, toRefs, onMounted, onUnmounted } from 'vue';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import get from 'lodash/get';
import map from 'lodash/map';
import UsersSelect from '@/modules/users/components/UsersSelect.vue';
import SkyInputRadioSwitch from '@/components/ui/SkyInputRadioSwitch.vue';
import { taskKinds, taskPrioritiesLabels, taskEstimationStates, taskDateComparators } from '@/modules/projects/projects.constants.js';
import { useNotify } from '@/plugins/notify/index.js';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import TaskPriorityIcon from './TaskPriorityIcon.vue';

export default {
  components: { SkyInputRadioSwitch, TaskPriorityIcon, UsersSelect },
  props: {
    enableMembers: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { locale } = useI18n();
    const notify = useNotify();

    const state = reactive({
      open: false,
      currentUser: computed(() => store.getters['auth/currentUser']),
      isAdminSkyloud: computed(() => store.getters['auth/isAdminSkyloud']),
      taskKinds: computed(() => taskKinds[locale.value]),
      taskMilestones: computed(() => store.getters['projects/getMilestones'].list),
      filteredMilestones: [],
      taskPriorities: computed(() => taskPrioritiesLabels[locale.value]),
      taskEstimationStates: computed(() => get(taskEstimationStates, locale.value)),
      taskDateComparators: computed(() => get(taskDateComparators, locale.value)),
      kindFilters: computed({
        get() {
          return store.getters['projects/getKindFilters'];
        },
        set(value) {
          try {
            store.commit('projects/setKindFilters', { kindFilters: value });
            store.dispatch('projects/fetchTasks');
            if (state.isAdminSkyloud) {
              store.dispatch('projects/fetchTasksStats');
            }
          } catch (err) {
            notify.error(err.message);
          }
        },
      }),
      membersFilters: computed({
        get() {
          return store.getters['projects/getMembersFilters'];
        },
        set(value) {
          try {
            store.commit('projects/setMembersFilters', { membersFilters: value });
            store.dispatch('projects/fetchTasks');
            if (state.isAdminSkyloud) {
              store.dispatch('projects/fetchTasksStats');
            }
          } catch (err) {
            notify.error(err.message);
          }
        },
      }),
      milestonesFilters: computed({
        get() {
          return store.getters['projects/getMilestonesFilters'];
        },
        set(value) {
          try {
            store.commit('projects/setMilestonesFilters', { milestonesFilters: value });
            store.dispatch('projects/fetchTasks');
            if (state.isAdminSkyloud) {
              store.dispatch('projects/fetchTasksStats');
            }
          } catch (err) {
            notify.error(err.message);
          }
        },
      }),
      prioritiesFilters: computed({
        get() {
          return store.getters['projects/getPrioritiesFilters'];
        },
        set(value) {
          try {
            store.commit('projects/setPrioritiesFilters', { prioritiesFilters: value });
            store.dispatch('projects/fetchTasks');
            if (state.isAdminSkyloud) {
              store.dispatch('projects/fetchTasksStats');
            }
          } catch (err) {
            notify.error(err.message);
          }
        },
      }),
      estimationFilters: computed({
        get() {
          return store.getters['projects/getEstimationFilters'];
        },
        set(value) {
          try {
            store.commit('projects/setEstimationFilters', { estimationFilters: value });
            store.dispatch('projects/fetchTasks');
            if (state.isAdminSkyloud) {
              store.dispatch('projects/fetchTasksStats');
            }
          } catch (err) {
            notify.error(err.message);
          }
        },
      }),
      deliveryDateFilter: computed({
        get() {
          return store.getters['projects/getDeliveryDateFilter'];
        },
        set(value) {
          try {
            store.commit('projects/setDeliveryDateFilter', { deliveryDateFilter: value });
            store.dispatch('projects/fetchTasks');
            if (state.isAdminSkyloud) {
              store.dispatch('projects/fetchTasksStats');
            }
          } catch (err) {
            notify.error(err.message);
          }
        },
      }),
      isPendingFilter: computed({
        get() {
          return store.getters['projects/getIsPendingFilter'];
        },
        set(value) {
          try {
            store.commit('projects/setIsPendingFilter', { isPendingFilter: value });
            store.dispatch('projects/fetchTasks');
            if (state.isAdminSkyloud) {
              store.dispatch('projects/fetchTasksStats');
            }
          } catch (err) {
            notify.error(err.message);
          }
        },
      }),
      deliveryComparatorFilter: computed({
        get() {
          return store.getters['projects/getDeliveryComparatorFilter'];
        },
        set(value) {
          try {
            store.commit('projects/setDeliveryComparatorFilter', { deliveryComparatorFilter: value });
            store.dispatch('projects/fetchTasks');
            if (state.isAdminSkyloud) {
              store.dispatch('projects/fetchTasksStats');
            }
          } catch (err) {
            notify.error(err.message);
          }
        },
      }),
      countActiveStateFilters: computed(() => store.getters['projects/countActiveStateFilters']),
      deliveryComparators: computed(() =>
        map(state.taskDateComparators, (label, value) => ({
          label,
          value,
        })),
      ),
      activeLabelFilters: computed(() => store.getters['projects/getLabelFilters']),
      selectedMilestones: computed(() =>
        state.taskMilestones.filter((milestone) => state.milestonesFilters.includes(milestone._id) && !state.filteredMilestones.some((mil) => mil._id === milestone._id)),
      ),
    });

    function handleClickOutside(event) {
      if (state.open === false) {
        return;
      }

      const { target } = event;
      const isOutsideClick = !target.closest('.state-filters');

      if (!isOutsideClick) {
        return;
      }

      state.open = false;
    }

    function resetAndClose() {
      state.open = false;
      store.dispatch('projects/resetTaskStateFilters', { enableMembers: props.enableMembers });
    }

    function clearDateFilter() {
      store.commit('projects/setDeliveryDateFilter', { deliveryDateFilter: undefined });
    }

    async function handleSearchNewMilestones(search) {
      try {
        await store.dispatch('projects/fetchMilestones', { search });
      } catch (err) {
        notify.error(err.message);
      }
    }

    onMounted(() => {
      document.body.addEventListener('click', handleClickOutside, {
        passive: true,
      });
    });

    onUnmounted(() => {
      document.body.removeEventListener('click', handleClickOutside);
    });

    return {
      ...toRefs(state),
      resetAndClose,
      clearDateFilter,
      handleSearchNewMilestones,
      faCalendarAlt,
      faFilter,
      faTimes,
      faCheck,
      faFlag,
    };
  },
};
</script>

<style lang="scss" scoped>
.sky-popup-positionning {
  @apply max-w-screen-full transition-transform;

  @media screen and (min-width: 500px) {
    @apply left-auto right-0;

    width: 29rem;
    --tw-translate-x: calc(69.5%);

    .sky-popup-content-container {
      @apply grid-cols-2 gap-y-5;
    }
  }

  @screen md {
    --tw-translate-x: calc(50% - 18px);
  }

  @media screen and (min-width: 1440px) {
    width: 60rem;
    --tw-translate-x: calc(50% - 18px);

    &.active-label-filters {
      --tw-translate-x: calc(50% - 18px + 80px);
    }

    .sky-popup-content-container {
      @apply grid-cols-4;
    }
  }
}
</style>
