import { computed, inject, nextTick, onBeforeMount, onBeforeUnmount, reactive, watch } from 'vue';
import some from 'lodash/some';
import first from 'lodash/first';
import isNaN from 'lodash/isNaN';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import differenceWith from 'lodash/differenceWith';

function transformModelValueByType({ modelValue, type = 'text' }) {
  if (type === 'text') {
    return modelValue;
  }

  if (type === 'number') {
    return parseFloat(modelValue);
  }

  if (type === 'date') {
    const timestamp = Date.parse(modelValue);
    if (isNaN(timestamp)) {
      return undefined;
    }
    const date = new Date(timestamp);
    const year = `${date.getFullYear()}`;
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    return `${year}-${month}-${day}`;
  }

  return modelValue;
}

export default function useValidatable({ props, emit, ...extendedState }) {
  const { register, unregister } = inject('form');

  const state = reactive({
    errorBucket: [],
    hasError: computed(() => state.errorBucket.length > 0),
    lazyValue: transformModelValueByType({
      modelValue: props.modelValue,
      type: props.type,
    }),
    internalValue: computed({
      get() {
        return state.lazyValue;
      },
      set(value) {
        state.lazyValue = value;

        if (!isEmpty(value) || isNumber(value)) {
          emit('update:modelValue', value);
          return;
        }

        if (!isEmpty(value) || isBoolean(value)) {
          emit('update:modelValue', value);
          return;
        }

        if (isArray(value)) {
          emit('update:modelValue', []);
          return;
        }

        emit('update:modelValue', undefined);
      },
    }),
    isResetting: false,
    hasInput: false,
    isFocused: false,
    hasFocused: false,
    valid: false,
    isRequired: computed(() => some(props.rules, (rule) => rule())),
    shouldValidate: computed(() => {
      if (state.isResetting) return false;

      return props.validateOnBlur ? state.hasFocused && !state.isFocused : state.hasInput || state.hasFocused;
    }),
    validationTarget: computed(() => {
      if (state.shouldValidate) return state.errorBucket;
      return [];
    }),
    hasErrorMessages: computed(() => state.validationTarget.length > 0),
    errorMessages: computed(() => state.validationTarget),
    firstErrorMessage: computed(() => first(state.errorMessages)),
    ...extendedState,
  });

  watch(
    () => props.modelValue,
    (value) => {
      state.lazyValue = transformModelValueByType({ modelValue: value, type: props.type });
    },
  );

  function validate(forceValidation = false) {
    if (!props.rules && !forceValidation) return undefined;
    const errorBucket = [];
    const value = state.internalValue;

    if (forceValidation) {
      state.hasInput = true;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const rule of props.rules) {
      const result = typeof rule === 'function' ? rule(value) : rule;

      if (typeof result === 'string') {
        errorBucket.push(result);
      } else if (typeof result !== 'boolean') {
        console.error(`Rules should return a string or boolean, received '${typeof result}' instead`);
      }
    }

    state.errorBucket = errorBucket;
    state.valid = errorBucket.length === 0;

    return state.valid;
  }

  watch(
    () => props.rules,
    (value, oldValue) => {
      if (differenceWith(value, oldValue, isEqual)) return;
      validate();
    },
  );

  watch(
    () => state.internalValue,
    () => {
      state.hasInput = true;

      // eslint-disable-next-line no-unused-expressions
      props.validateOnBlur || nextTick(validate);
    },
  );

  const context = {
    state,
    validate,
  };

  register(context);

  onBeforeUnmount(() => {
    unregister(context);
  });

  onBeforeMount(() => {
    validate();
  });

  return context;
}
