import SkyButton from '@/components/ui/buttons/SkyButton.vue';
import SkyForm from '@/components/ui/SkyForm.vue';
import SkyModal from '@/components/ui/SkyModal.vue';
import SkyInputText from '@/components/ui/SkyInputText.vue';
import SkyCalendar from '@/components/ui/SkyCalendar.vue';
import SkyLoading from '@/components/ui/SkyLoading.vue';
import SkyCollapse from '@/components/ui/SkyCollapse.vue';
import SkyButtonLink from '@/components/ui/SkyButtonLink.vue';
import SkySelectIcon from '@/components/ui/SkySelectIcon.vue';
import SkyDatePicker from '@/components/ui/SkyDatePicker.vue';
import SkyDateRangePicker from '@/components/ui/SkyDateRangePicker.vue';
import SkySelect from '@/components/ui/SkySelect.vue';
import SkyAsyncSelect from '@/components/ui/SkyAsyncSelect.vue';
import SkyTextarea from '@/components/ui/SkyTextarea.vue';
import SkyInputChips from '@/components/ui/SkyInputChips.vue';
import SkyCheckbox from '@/components/ui/SkyCheckbox.vue';
import SkySwitch from '@/components/ui/SkySwitch.vue';
import SkyButtonConfirm from '@/components/ui/SkyButtonConfirm.vue';
import SkyWizard from '@/components/ui/SkyWizard.vue';
import SkyGauge from '@/components/ui/SkyGauge.vue';
import SkyCard from '@/components/ui/SkyCard.vue';
import SkyDebug from '@/components/ui/SkyDebug.vue';
import SkyPopup from '@/components/ui/SkyPopup.vue';
import SkyTitle from '@/components/ui/SkyTitle.vue';
import SkyCalendarDay from '@/components/ui/SkyCalendarDay.vue';
import SkyCalendarDayEvent from '@/components/ui/SkyCalendarDayEvent.vue';
import SkyTooltip from '@/components/ui/tooltips/SkyTooltip.vue';
import SkyPagination from '@/components/ui/SkyPagination.vue';
import SkyTabs from '@/components/ui/SkyTabs.vue';
import SkyLazy from '@/components/ui/SkyLazy.vue';
import SkyFilterList from '@/components/ui/SkyFilterList.vue';
import SkyTable from '@/components/ui/tables/index.js';
import SkyList from '@/components/ui/lists/index.js';

export default {
  install: (app) => {
    app.component('SkyButton', SkyButton);
    app.component('SkyForm', SkyForm);
    app.component('SkyModal', SkyModal);
    app.component('SkyInputText', SkyInputText);
    app.component('SkyCalendar', SkyCalendar);
    app.component('SkyLoading', SkyLoading);
    app.component('SkyCollapse', SkyCollapse);
    app.component('SkyButtonLink', SkyButtonLink);
    app.component('SkySelectIcon', SkySelectIcon);
    app.component('SkyDatePicker', SkyDatePicker);
    app.component('SkyDateRangePicker', SkyDateRangePicker);
    app.component('SkySelect', SkySelect);
    app.component('SkyAsyncSelect', SkyAsyncSelect);
    app.component('SkyTextarea', SkyTextarea);
    app.component('SkyInputChips', SkyInputChips);
    app.component('SkyCheckbox', SkyCheckbox);
    app.component('SkySwitch', SkySwitch);
    app.component('SkyButtonConfirm', SkyButtonConfirm);
    app.component('SkyWizard', SkyWizard);
    app.component('SkyGauge', SkyGauge);
    app.component('SkyCard', SkyCard);
    app.component('SkyDebug', SkyDebug);
    app.component('SkyPopup', SkyPopup);
    app.component('SkyTitle', SkyTitle);
    app.component('SkyTabs', SkyTabs);
    app.component('SkyCalendarDayEvent', SkyCalendarDayEvent);
    app.component('SkyCalendarDay', SkyCalendarDay);
    app.component('SkyPagination', SkyPagination);
    app.component('SkyTooltip', SkyTooltip);
    app.component('SkyLazy', SkyLazy);
    app.component('SkyFilterList', SkyFilterList);
    app.use(SkyTable);
    app.use(SkyList);
  },
};
