import { ref } from 'vue';
import { useElementBounding, useElementHover, useElementVisibility, watchThrottled } from '@vueuse/core';
import { or } from '@vueuse/math';

export const useTooltip = () => {
  /** @type {import('vue').Ref<HTMLDivElement>} */
  const hostElementRef = ref();
  /** @type {import('vue').Ref<HTMLDivElement>} */
  const tooltipElementRef = ref();
  const isHovered = or(useElementHover(hostElementRef), useElementHover(tooltipElementRef));
  const hostOffset = useElementBounding(hostElementRef);
  const elementIsInView = useElementVisibility(hostElementRef);
  const positionTooltipElement = () => {
    if (!tooltipElementRef.value) {
      return;
    }
    const windowScrollLeft = (window.pageXOffset || document.documentElement.scrollLeft) - (document.documentElement.clientLeft || 0);
    const windowScrollTop = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
    tooltipElementRef.value.style.left = `${windowScrollLeft + hostOffset.left.value + hostOffset.width.value / 2}px`;
    tooltipElementRef.value.style.top = `${windowScrollTop + hostOffset.top.value}px`;
  };

  watchThrottled([hostOffset.left, hostOffset.top, hostOffset.width, isHovered, elementIsInView], positionTooltipElement, { throttle: 150 });

  return {
    hostElementRef,
    tooltipElementRef,
    isHovered,
    elementIsInView,
    positionTooltipElement,
  };
};
