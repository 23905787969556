import { ProbeEventStatus } from '@/modules/stats/availability/domain/probe-event/status/probe-event-status.js';

export class UnknownStatus extends ProbeEventStatus {
  getTranslateTextKey() {
    return 'stats.availability.status.unknown';
  }

  getBackgroundColor() {
    return 'bg-dark-blue-50';
  }

  getTextColor() {
    return 'text-dark-blue-50';
  }

  getOutlineColor() {
    return 'outline-dark-blue-50';
  }
}
