import { ProbesRepository } from '@/modules/stats/availability/domain/repositories/probes-repository.js';
import { ApiFindAllProbesResponse } from '@/modules/stats/availability/infrastructure/repositories/probes/api-find-all-probes-response.js';

/**
 * @implements {ProbesRepository}
 */
export class ApiProbesRepository extends ProbesRepository {
  constructor(httpClient) {
    super();
    this._httpClient = httpClient;
  }

  async findAll() {
    const response = await this._httpClient.get('/admin/probes');

    return new ApiFindAllProbesResponse(response.data).toProbes();
  }
}
