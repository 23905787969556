import flow from 'lodash/flow';
import fpNth from 'lodash/fp/nth';
import fpSplit from 'lodash/fp/split';

// eslint-disable-next-line import/prefer-default-export
export function readAccessTokenData({ accessToken }) {
  try {
    if (!accessToken) {
      return undefined;
    }
    const tokenData = flow(fpSplit('.'), fpNth(1))(accessToken);
    return JSON.parse(atob(tokenData));
  } catch (err) {
    return undefined;
  }
}
