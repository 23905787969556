<template>
  <div class="overflow-hidden bg-white border rounded-lg border-dark-blue-100">
    <div class="flex items-center justify-between px-6 py-2">
      <div>
        <span class="text-lg font-bold text-gray-800">
          {{ currentMonth }}
        </span>
        <span class="ml-1 text-lg font-normal text-gray-600">
          {{ year }}
        </span>
      </div>
      <div
        class="px-1 border rounded-lg"
        style="padding-top: 2px"
      >
        <button
          type="button"
          class="inline-flex items-center p-1 leading-none transition duration-100 ease-in-out rounded-lg cursor-pointer hover:bg-gray-200"
          @click="previousMonth"
        >
          <svg
            class="inline-flex w-6 h-6 leading-none text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <div class="inline-flex h-6 border-r" />
        <button
          type="button"
          class="inline-flex items-center p-1 leading-none transition duration-100 ease-in-out rounded-lg cursor-pointer hover:bg-gray-200"
          @click="nextMonth"
        >
          <svg
            class="inline-flex w-6 h-6 leading-none text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="-mx-1 -mb-1">
      <div class="flex flex-wrap">
        <div
          v-for="(day, index) of days"
          :key="index"
          style="width: 14.26%"
          class="px-2 py-2"
        >
          <div class="text-sm font-bold tracking-wide text-center text-gray-600 uppercase">
            {{ day }}
          </div>
        </div>
      </div>

      <div class="flex flex-wrap border-t border-l">
        <div
          v-for="(blankDay, blankIndex) in blankDays"
          :key="blankIndex"
          style="width: 14.28%; height: 120px"
          class="px-4 pt-2 text-center border-b border-r"
        />
        <div
          v-for="(date, dateIndex) in nbOfDays"
          :key="dateIndex"
          style="width: 14.28%; height: 120px"
          class="relative px-4 pt-2 border-b border-r"
        >
          <div
            class="inline-flex items-center justify-center w-6 h-6 leading-none text-center transition duration-100 ease-in-out rounded-full cursor-pointer"
            :class="{
              'bg-blue-500 text-white': isToday(date) == true,
              'hover:bg-blue-200 hover:text-white': isToday(date) == false,
            }"
            @click="$emit('addEvent', { date })"
          >
            {{ date.getDate() }}
          </div>
          <div
            style="height: 80px"
            class="mt-1 overflow-y-auto"
          >
            <!-- <div
              class="absolute top-0 right-0 inline-flex items-center justify-center w-6 h-6 mt-2 mr-2 text-sm leading-none text-white bg-gray-700 rounded-full"
              x-show="events.filter(e => e.event_date === new Date(year, month, date).toDateString()).length"
              x-text="events.filter(e => e.event_date === new Date(year, month, date).toDateString()).length"></div> -->
            <slot
              v-for="event of events.filter((e) => new Date(e.date).toDateString() === date.toDateString())"
              :key="event._id"
              name="day"
              :event="event"
            >
              <div
                class="px-2 py-1 mt-1 overflow-hidden border rounded-lg cursor-pointer"
                :class="{
                  'border-blue-200 text-blue-800 bg-blue-100': event.theme === 'blue',
                  'border-red-200 text-red-800 bg-red-100': event.theme === 'red',
                  'border-yellow-200 text-yellow-800 bg-yellow-100': event.theme === 'yellow',
                  'border-green-200 text-green-800 bg-green-100': event.theme === 'green',
                  'border-purple-200 text-purple-800 bg-purple-100': event.theme === 'purple',
                }"
                @click="$emit('openEvent', { event })"
              >
                <p class="text-sm leading-tight truncate">
                  {{ event.title }}
                </p>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useI18n } from 'vue-i18n';
import split from 'lodash/split';

dayjs.extend(isoWeek);

export default {
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['openEvent', 'addEvent', 'rangeChange'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const today = new Date();
    const state = reactive({
      blankDays: computed(() => {
        const dayOfWeek = new Date(Date.UTC(state.year, state.month)).getDay();
        const withfirstDayOffset = dayOfWeek < 1 ? 6 : dayOfWeek - 1;
        return [...Array(withfirstDayOffset)].map((_, i) => i);
      }),
      nbOfDays: computed(() => {
        const daysInMonth = new Date(Date.UTC(state.year, state.month + 1, 0)).getDate();
        return [...Array(daysInMonth)].map((_, day) => new Date(Date.UTC(state.year, state.month, day + 1)));
      }),
      month: today.getMonth(),
      year: today.getFullYear(),
      currentMonth: computed(() => state.months[state.month]),
      days: split(t('components.ui.skyCalendar.daysArray'), ','),
      months: split(t('components.ui.skyCalendar.monthsArray'), ','),
    });

    function isToday(date) {
      return today.toDateString() === date.toDateString();
    }

    function emitRangeChanged() {
      const start = new Date(Date.UTC(state.year, state.month));
      emit('rangeChange', {
        start: dayjs.utc(start).toDate(),
        end: dayjs.utc(start).add(1, 'month').toDate(),
      });
    }

    function previousMonth() {
      if (state.month === 0) {
        state.month = 11;
        state.year -= 1;
        emitRangeChanged();
        return;
      }
      state.month -= 1;
      emitRangeChanged();
    }

    function nextMonth() {
      if (state.month === 11) {
        state.month = 0;
        state.year += 1;
        emitRangeChanged();
        return;
      }
      state.month += 1;
      emitRangeChanged();
    }

    return {
      ...toRefs(state),
      isToday,
      previousMonth,
      nextMonth,
    };
  },
};
</script>
