import { i18n } from '@/plugins/i18n/index.js';
import { postHtmlToPdf } from '@/modules/converters/converters.api.js';

const stateData = {};

const getters = {
  getState: (state) => state,
};

const mutations = {
  resetState(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async htmlToPdf(_, { html }) {
    try {
      return await postHtmlToPdf({ html });
    } catch (err) {
      throw new Error(i18n.global.t('converters.store.htmlToPdf.requestError'));
    }
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
