<template>
  <label class="block">
    <div
      v-if="label"
      class="block mb-2 text-sm font-medium text-primary"
      :class="{ required: state.isRequired }"
    >
      {{ label }}
    </div>
    <div class="relative">
      <div
        v-if="icon"
        class="absolute top-1/2 rounded-l w-9 h-full flex items-center justify-center transform -translate-y-1/2 leading-0"
        :class="{
          [iconClasses]: true,
          'left-0 right-auto': iconSide === 'left',
          'right-0 left-auto': iconSide === 'right',
        }"
      >
        <FontAwesomeIcon :icon="icon" />
      </div>
      <input
        ref="input"
        v-model="state.internalValue"
        style="transition: all 0.15s ease 0s"
        class="w-full px-3 placeholder-gray-300 bg-white border rounded appearance-none border-dark-blue-100 focus:outline-none focus:shadow-outline"
        :type="type"
        :step="step"
        :class="inputClasses"
        :placeholder="placeholder"
        :required="state.isRequired"
        :autocomplete="autocomplete"
        :readonly="readOnly"
        :disabled="disabled"
        :min="min"
        :max="max"
      />
    </div>
    <span
      class="flex items-center mt-1 ml-1 text-xs tracking-wide"
      :class="{
        hidden: !state.hasErrorMessages && !helperText,
        'text-gray-600': helperText,
        'font-medium': state.hasErrorMessages,
        'pt-5': state.hasErrorMessages && helperText,
        'text-red-500': state.hasErrorMessages,
      }"
    >
      {{ state.firstErrorMessage || helperText }}
    </span>
  </label>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import useValidatable from '@/modules/shared/utils/validatable.js';

export default {
  props: {
    modelValue: { type: [String, Number], default: () => undefined },
    label: { type: String, default: () => undefined },
    step: { type: String, default: () => undefined },
    placeholder: { type: String, default: () => undefined },
    type: { type: String, default: 'text' },
    size: { type: String, default: 'normal', enum: ['normal', 'small'] },
    min: { type: String, default: undefined },
    max: { type: String, default: undefined },
    autofocus: { type: Boolean, default: () => false },
    rules: { type: Array, default: () => [] },
    helperText: { type: String, default: () => undefined },
    autocomplete: { type: String, default: () => undefined },
    readOnly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    showLabel: { type: Boolean, default: true },
    icon: { type: Object, default: undefined },
    iconSide: { type: String, default: 'left', enum: ['left', 'right'] },
    iconClasses: { type: String, default: 'text-gray-500' },
  },
  setup(props, { emit }) {
    const input = ref(null);

    const { state, validate } = useValidatable({ props, emit });

    function tryAutofocus() {
      if (typeof document === 'undefined' || !input.value || document.activeElement === input.value) return false;

      input.value.focus();

      return true;
    }

    onMounted(() => {
      if (props.autofocus) {
        tryAutofocus();
      }
    });

    const inputClasses = computed(() => ({
      'ring-1': state.hasErrorMessages,
      'ring-red-500': state.hasErrorMessages,
      'h-10 text-sm': props.size === 'normal',
      'h-8 text-xs': props.size === 'small',
      'pl-10': props.icon && props.iconSide === 'left',
      'pr-10': props.icon && props.iconSide === 'right',
    }));

    return {
      input,
      state,
      inputClasses,
      validate,
    };
  },
};
</script>

<style lang="scss" scoped>
.required::after {
  @apply content-asterisk ml-1 text-red-600 font-medium;
}
</style>
