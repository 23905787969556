<template>
  <div
    v-if="!hasMergeRequestLink"
    class="rounded-md bg-[#112064]"
  >
    <div
      v-if="!loading"
      class="flex flex-col justify-center gap-3 cursor-pointer p-4"
      @click="askedMergeRequestValidation = true"
    >
      <div class="flex flex-row justify-center items-center gap-2">
        <img
          src="@/assets/logo_gitlab_primary.svg"
          alt="gitlab logo"
        />
        <span class="text-xs text-primary">{{ $t('components.projects.statusTaskControl.mergeRequestValidation.title') }}</span>
      </div>
      <SkyForm
        v-if="askedMergeRequestValidation"
        class="flex flex-row items-start gap-2 justify-center"
        @submit="handleMergeRequestValidation"
      >
        <SkyInputText
          v-model="mergeRequestLink"
          placeholder="https://skyloud.dev/..."
          class="w-full"
          :rules="rules"
        />
        <SkyButton
          type="submit"
          :loading="loading"
          animation="none"
          size="small"
          variant="flat"
          color="light"
          class="h-10"
        >
          <FontAwesomeIcon :icon="faPaperPlane" />
        </SkyButton>
      </SkyForm>
    </div>
    <div
      v-else
      class="transform scale-50"
    >
      <SkyLoading />
    </div>
  </div>
  <div
    v-else-if="hasMergeRequestLink && !isMergeRequestAccepted"
    class="rounded-md bg-[#E24329] text-white-smoke p-4 flex flex-col gap-3 relative overflow-hidden"
  >
    <div class="z-10 flex flex-col gap-3">
      <div class="flex flex-row items-center gap-2">
        <img
          src="@/assets/logo_gitlab_white.svg"
          alt="gitlab logo"
        />
        <div class="font-medium uppercase">{{ $t('components.projects.statusTaskControl.mergeRequestValidation.waiting.title') }}</div>
      </div>

      <p class="text-sm">{{ $t('components.projects.statusTaskControl.mergeRequestValidation.waiting.description') }}</p>

      <div
        v-if="isMergeRequestReviewer"
        class="flex flex-col gap-2"
      >
        <SkyButton
          variant="flat"
          color="dark-2"
          size="small"
          animation="none"
          :loading="loading"
          @click="handleRequestValidation(true)"
        >
          {{ $t('components.projects.statusTaskControl.mergeRequestValidation.waiting.validateButton') }}
        </SkyButton>
        <SkyButton
          variant="flat"
          color="dark-3"
          size="small"
          animation="none"
          :loading="loading"
          @click="handleRequestValidation(false)"
        >
          {{ $t('components.projects.statusTaskControl.mergeRequestValidation.waiting.refuseButton') }}
        </SkyButton>
      </div>
      <SkyButton
        v-else
        variant="flat"
        color="dark-2"
        size="small"
        animation="none"
        :loading="loading"
        @click="handleCancelValidationRequest"
      >
        {{ $t('components.projects.statusTaskControl.mergeRequestValidation.waiting.cancelButton') }}
      </SkyButton>
    </div>
    <div class="absolute bg-[#FC6D26] top-1/2 bottom-0 right-0 h-[200%] w-1/2 transform -translate-y-1/2 rotate-[15deg]"></div>
    <div class="absolute bg-[#FCA326] top-1/2 bottom-0 right-0 h-[200%] w-1/2 transform translate-x-2/3 -translate-y-1/2 rotate-[15deg]"></div>
  </div>
  <div
    v-else
    class="rounded-md bg-[#112064] p-4 flex flex-row justify-center items-center gap-3"
  >
    <img
      src="@/assets/logo_gitlab_text_white.svg"
      alt="gitlab logo"
    />
    <div class="flex flex-row items-center gap-2 bg-success text-white rounded-full px-1.5 py-1">
      <FontAwesomeIcon :icon="faCircleCheck" />
      <span class="text-sm">{{ $t('components.projects.statusTaskControl.mergeRequestValidation.validated') }}</span>
    </div>
  </div>
</template>

<script setup>
import { useNotify } from '@/plugins/notify/index.js';
import { faCircleCheck, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { getIsRequiredRule, getIsRegexMatchRule } from '@/modules/shared/utils/formValidationRules.js';
import get from 'lodash/get';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
});

const notify = useNotify();
const store = useStore();
const { t } = useI18n();

const loading = ref(false);
const askedMergeRequestValidation = ref(false);
const currentUser = computed(() => store.getters['auth/currentUser']);
const isSkyloudAdmin = computed(() => currentUser.value.roles.includes('admin:skyloud'));
const hasMergeRequestLink = computed(() => !!get(props.task, 'mergeRequestLink'));
const rules = computed(() => [getIsRequiredRule(), getIsRegexMatchRule(/^https:\/\/[a-z0-9.-]+\.[a-z]{2,4}\/?/, t('inputs.invalidWebsite'))]);
const isMergeRequestAccepted = computed(() => !!get(props.task, 'mergeRequestAcceptedAt'));
const isMergeRequestReviewer = computed(() => isSkyloudAdmin.value && ['etienne@skyloud.fr', 'philippe@skyloud.fr'].includes(currentUser.value.email));
const mergeRequestLink = ref(undefined);

async function handleMergeRequestValidation() {
  loading.value = true;
  try {
    await store.dispatch('projects/updateTaskMergeRequestValidationStatus', { _id: props.task._id, mergeRequestLink: mergeRequestLink.value });
    notify.success('Successfully asked for merge request validation');
  } catch (err) {
    notify.success(err.message);
  }
  askedMergeRequestValidation.value = false;
  loading.value = false;
}

async function handleCancelValidationRequest() {
  loading.value = true;
  try {
    await store.dispatch('projects/updateTaskMergeRequestValidationStatus', { _id: props.task._id, mergeRequestLink: undefined });
    notify.success('Successfully cancelled merge request validation');
  } catch (err) {
    notify.success(err.message);
  }
  loading.value = false;
}

async function handleRequestValidation(validated) {
  loading.value = true;
  try {
    await store.dispatch('projects/updateTaskMergeRequestValidation', { _id: props.task._id, validated });
    notify.success(validated ? 'Successfully validated merge request' : 'Successfully refused merge request');
  } catch (err) {
    notify.success(err.message);
  }
  loading.value = false;
}
</script>
