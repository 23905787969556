<template>
  <div
    class="border w-4 h-4 min-w-[16px] min-h-[16px] rounded p-0.5"
    :class="{
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
    }"
    @click="handleCheckboxChanges"
  >
    <span
      v-if="internalValue"
      class="bg-primary w-full h-full block rounded-sm"
    ></span>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const emit = defineEmits(['change']);
const props = defineProps({
  checked: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
});

const internalValue = ref(props.checked);

function handleCheckboxChanges() {
  if (props.disabled) return;
  internalValue.value = !internalValue.value;
  emit('change', internalValue.value);
}

watch(
  () => props.checked,
  (value) => (internalValue.value = value),
);
</script>

<style lang="scss" scoped></style>
