/**
 * @abstract
 */
export class ValueObject {
  constructor(value) {
    this._value = value;
    this.validate();
  }

  /**
   * @abstract
   * @protected
   */
  validate() {}

  /**
   * @param {ValueObject} object
   * @return {boolean}
   */
  isSame(object) {
    return this._value === object._value;
  }
}
