import convertersApi from '@/modules/shared/api/converters.js';

// eslint-disable-next-line import/prefer-default-export
export async function postHtmlToPdf({ html }) {
  const { data: pdf } = await convertersApi().post(
    '/pdf/html',
    { html },
    {
      responseType: 'blob',
    },
  );

  return URL.createObjectURL(pdf);
}
