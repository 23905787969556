<template>
  <div
    ref="targetEl"
    :style="{ minHeight: `${fixedMinHeight ? fixedMinHeight : minHeight}px` }"
  >
    <slot v-if="shouldRender" />
    <div
      v-else
      class="w-full h-full skeleton-loader rounded-md"
      :style="{ height: `${fixedMinHeight ? fixedMinHeight : minHeight}px` }"
    ></div>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from '@vueuse/core';
import { ref, nextTick } from 'vue';

function onIdle(cb = () => {}) {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(cb);
  } else {
    setTimeout(() => {
      nextTick(cb);
    }, 300);
  }
}

const props = defineProps({
  renderOnIdle: Boolean,
  unrender: Boolean,
  minHeight: {
    type: Number,
    default: 300,
  },
  unrenderDelay: {
    type: Number,
    default: 10000,
  },
});

const shouldRender = ref(false);
const targetEl = ref();
const fixedMinHeight = ref(0);
let unrenderTimer;
let renderTimer;
const { stop } = useIntersectionObserver(
  targetEl,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      clearTimeout(unrenderTimer);
      if (props.unrender) {
        renderTimer = setTimeout(() => (shouldRender.value = true), props.unrender ? 200 : 0);
      } else {
        shouldRender.value = true;
      }

      if (!props.unrender) {
        stop();
      }
    } else if (props.unrender) {
      clearTimeout(renderTimer);
      unrenderTimer = setTimeout(() => {
        if (targetEl.value) {
          fixedMinHeight.value = targetEl.value?.clientHeight;
        }
        shouldRender.value = false;
      }, props.unrenderDelay);
    }
  },
  {
    rootMargin: '600px',
  },
);
if (props.renderOnIdle) {
  onIdle(() => {
    shouldRender.value = true;
    if (!props.unrender) {
      stop();
    }
  });
}
</script>

<style lang="scss" scoped>
.skeleton-loader {
  display: block;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), rgba(229, 231, 235, 0.4);
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes shine {
  to {
    background-position: 110% 0;
  }
}
</style>
