export const TASKS_FETCH_LIMIT = 15;
export const TASK_ELLAPSED_TIME_STEP_IN_MS = 900_000;

export const TASK_ELLAPSED_BAR = {
  OVERFLOW: 'bg-warning',
  WITHOUT_TIME: 'bg-gray-200 text-gray-400',
  TOO_MUCH_OVERLFLOW: 'bg-error',
  IN_RANGE: 'bg-success',
};

export const TASK_STATE_TYPE = {
  to_define: 0,
  to_estimate: 1,
  estimate_waiting: 2,
  to_assign: 3,
  todo: 4,
  in_progress: 5,
  waiting: 6,
  completed: 7,
};

export const TASK_TODO_TYPE = {
  to_define: 0,
  to_estimate: 1,
  estimate_waiting: 2,
  to_assign: 3,
  todo: 4,
  in_progress: 5,
  waiting: 6,
  completed: 7,
  blocked: 8,
  abandoned: 9,
  reassigned: 10,
  todo_next_sprint: 11,
  waiting_next_sprint: 12,
};

export const LABEL_FILTERS = {
  no_categories: 0,
  no_themes: 1,
  no_tags: 2,
};

export const MILESTONE_FILTERS = {
  current_sprint: 0,
  no_sprint: 1,
  no_current_sprint: 2,
};

export const LABEL_TYPE = {
  category: 'category',
  tag: 'tag',
  theme: 'theme',
};

export const dependenciesType = {
  [LABEL_TYPE.theme]: LABEL_TYPE.category,
  [LABEL_TYPE.tag]: LABEL_TYPE.theme,
};

export const taskKinds = {
  fr: {
    basic: 'Classique',
    alert: 'Alerte',
    issue: 'Problème',
  },
  en: {
    basic: 'Classic',
    alert: 'Alert',
    issue: 'Issue',
  },
};

export const taskPriorities = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
  MAJOR: 3,
  CRITICAL: 4,
};

export const taskPrioritiesLabels = {
  fr: [
    {
      code: 0,
      label: 'Faible',
    },
    {
      code: 1,
      label: 'Moyen',
    },
    {
      code: 2,
      label: 'Haut',
    },
    {
      code: 3,
      label: 'Majeur',
    },
    {
      code: 4,
      label: 'Critique',
    },
  ],
  en: [
    {
      code: 0,
      label: 'Low',
    },
    {
      code: 1,
      label: 'Medium',
    },
    {
      code: 2,
      label: 'High',
    },
    {
      code: 3,
      label: 'Major',
    },
    {
      code: 4,
      label: 'Critical',
    },
  ],
};

export const TASK_PENDING_STATUS = {
  COMPLETED: 'completed',
  MERGE_REQUEST: 'merge_request',
  CUSTOMER: 'customer',
};

export const taskEstimationStates = {
  fr: {
    without: 'Sans estimation',
    awaitingValidation: 'En attente de validation',
    refused: 'Refusée',
    accepted: 'Acceptée',
  },
  en: {
    without: 'Without estimation',
    awaitingValidation: 'Awaiting validation',
    refused: 'Refused',
    accepted: 'Accepted',
  },
};

export const taskEstimationRequests = {
  without: { estimatedTime: false },
  awaitingValidation: {
    estimatedTime: true,
    estimatedTimeAcceptedAt: false,
    estimatedTimeRefusedAt: false,
  },
  refused: {
    estimatedTime: true,
    estimatedTimeRefusedAt: true,
  },
  accepted: {
    estimatedTime: true,
    estimatedTimeAcceptedAt: true,
  },
};

export const taskDateComparators = {
  fr: {
    lt: 'Avant',
    eq: 'À date',
    gt: 'Après',
  },
  en: {
    lt: 'Before',
    eq: 'On date',
    gt: 'After',
  },
};

export const labelsRules = [
  {
    name: '@pricingTaskUpdate',
    params: ['unitHourlyAmountInCents', 'unitDailyAmountInCents'],
  },
];
