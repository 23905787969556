import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import fr from './locales/fr.json';

export const defaultLanguage = 'fr';

export const messages = {
  en,
  fr,
};

export const createI18nConfiguration = (locale) =>
  createI18n({
    locale,
    fallbackLocale: defaultLanguage,
    legacy: false,
    globalInjection: true,
    messages,
  });

export const i18n = createI18nConfiguration({
  locale: defaultLanguage,
});
