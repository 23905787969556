<template>
  <div class="flex flex-col gap-2 p-4">
    <div class="font-medium uppercase">
      <FontAwesomeIcon :icon="faLifeRing" />
      {{ $t('components.projects.statusTaskControl.default.title') }}
    </div>
    <div v-if="currentUser.roles.includes('admin:skyloud')">
      <p class="text-sm">{{ $t('components.projects.statusTaskControl.default.newRequest') }}</p>
      <WaitingButtonsControl :task="task" />
    </div>
    <p
      v-else
      class="text-sm"
    >
      {{ $t('components.projects.statusTaskControl.default.takedIntoAccount') }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { faLifeRing } from '@fortawesome/pro-light-svg-icons';
import WaitingButtonsControl from '@/modules/projects/components/TaskControls/WaitingButtonsControl.vue';

defineProps({
  task: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const currentUser = computed(() => store.getters['auth/currentUser']);
</script>
