<template>
  <div
    ref="dragAndDropZone"
    class="tasks-dnd"
  >
    <slot />
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref } from 'vue';

export default {
  emits: ['move'],

  setup(props, { emit }) {
    const dragAndDropZone = ref(null);

    /** @type {{ selectedCard: HTMLDivElement, position: DOMRect }} */
    const localState = {
      zone: undefined,
      selectedCard: undefined,
      placeholderCard: undefined,
      dropZones: undefined,
      isDraggingReady: false,
      hasMoved: false,
      initialMouseX: undefined,
      initialMouseY: undefined,
      initialCardX: undefined,
      initialCardY: undefined,
      initialListId: undefined,
    };

    function allowCardToMoveFreely(card) {
      dragAndDropZone.value.classList.add('in-dragging');

      const { selectedCard, initialCardX, initialCardY } = localState;

      const placeholderCard = selectedCard.cloneNode(true);
      placeholderCard.classList.add('placeholder', 'opacity-50');
      placeholderCard.classList.remove('mouse-dragging');
      selectedCard.insertAdjacentElement('afterend', placeholderCard);
      localState.placeholderCard = placeholderCard;

      selectedCard.style.width = `${card.clientWidth}px`;
      selectedCard.style.height = `${card.clientHeight}px`;
      selectedCard.style.left = `${initialCardX}px`;
      selectedCard.style.top = `${initialCardY}px`;
      selectedCard.classList.add('dragging-ready');
      dragAndDropZone.value.appendChild(card);
      localState.isDraggingReady = true;
    }

    /** @param {MouseEvent} event */
    function moveCard(event) {
      if (!localState.selectedCard) {
        return;
      }

      if (!localState.isDraggingReady) allowCardToMoveFreely(localState.selectedCard);

      const { x: clickX, y: clickY } = event;

      const newCardX = clickX - localState.initialMouseX + localState.initialCardX;
      const newCardY = clickY - localState.initialMouseY + localState.initialCardY;

      localState.selectedCard.classList.add('mouse-dragging');
      localState.selectedCard.style.left = `${newCardX}px`;
      localState.selectedCard.style.top = `${newCardY}px`;
      localState.hasMoved = true;
    }

    function dropZoneIn(event) {
      const dropZoneInElement = event.target.closest('.tasks-list');
      if (!dropZoneInElement) {
        return;
      }

      dropZoneInElement.classList.add('mouse-over');
    }

    function dropZoneOut(event) {
      const dropZoneOuElement = event.target.closest('.tasks-list');
      if (!dropZoneOuElement) {
        return;
      }

      dropZoneOuElement.classList.remove('mouse-over');
    }

    function resetDropZones() {
      if (!localState.dropZones) {
        return;
      }

      for (let i = 0; i < localState.dropZones.length; i += 1) {
        localState.dropZones[i].removeEventListener('mouseover', dropZoneIn);
        localState.dropZones[i].removeEventListener('mouseout', dropZoneOut);
        localState.dropZones[i].classList.remove('mouse-over');
      }
    }

    function moveBackCardToInitialZone() {
      localState.placeholderCard.insertAdjacentElement('afterend', localState.selectedCard);
    }

    function resetCardStyle() {
      localState.selectedCard.classList.remove('dragging-ready', 'mouse-dragging');
      ['top', 'left', 'width', 'height'].forEach((property) => localState.selectedCard.style.removeProperty(property));
    }

    function stopMoving(event) {
      dragAndDropZone.value.classList.remove('in-dragging');

      localState.zone.removeEventListener('mousemove', moveCard);
      localState.zone.removeEventListener('mouseleave', stopMoving);
      resetDropZones();

      if (!localState.selectedCard) {
        return;
      }
      resetCardStyle();

      if (!localState.hasMoved) {
        return;
      }
      localState.hasMoved = false;
      localState.isDraggingReady = false;

      const dropZoneElement = event.target.closest('.tasks-list');
      if (!dropZoneElement) {
        moveBackCardToInitialZone();
        localState.placeholderCard.remove();
        return;
      }

      const { x: clickX, y: clickY } = event;
      const { initialX, initialY } = localState;

      // Si le déplacement est insuffisant, on simule le clic
      if (Math.abs(clickX - initialX) + Math.abs(clickY - initialY) < 10) {
        localState.selectedCard.click();
        return;
      }

      const dropListId = dropZoneElement.dataset.listId;

      if (localState.initialListId === dropListId) {
        moveBackCardToInitialZone();
        localState.placeholderCard.remove();
        return;
      }

      localState.selectedCard.remove();
      localState.placeholderCard.remove();
      emit('move', {
        ...localState.selectedCard.dataset,
        ...dropZoneElement.dataset,
      });
    }

    function startMoving(event) {
      const selectedCard = event.target.closest('.task-card');

      if (!selectedCard) {
        return;
      }

      localState.selectedCard = selectedCard;

      if (event.stopPropagation) event.stopPropagation();
      if (event.preventDefault) event.preventDefault();

      const { top: cardTopPosition, left: cardLeftPostition } = selectedCard.getBoundingClientRect();

      localState.initialListId = event.target.closest('.tasks-list').dataset.listId;
      localState.initialMouseX = event.x;
      localState.initialMouseY = event.y;
      localState.initialCardX = cardLeftPostition + 4;
      localState.initialCardY = cardTopPosition + 1;

      localState.zone.addEventListener('mouseleave', stopMoving);
      localState.zone.addEventListener('mousemove', moveCard);

      localState.dropZones = localState.zone.querySelectorAll('.tasks-list');
      for (let i = 0; i < localState.dropZones.length; i += 1) {
        localState.dropZones[i].addEventListener('mouseover', dropZoneIn);
        localState.dropZones[i].addEventListener('mouseout', dropZoneOut);
      }
    }

    onMounted(() => {
      /** @type HTMLDivElement */
      localState.zone = dragAndDropZone.value;

      localState.zone.addEventListener('mousedown', startMoving);
      localState.zone.addEventListener('mouseup', stopMoving);
    });

    onBeforeUnmount(() => {
      if (localState.zone) {
        localState.zone.removeEventListener('mousedown', startMoving);
        localState.zone.removeEventListener('mouseup', stopMoving);
        localState.zone.removeEventListener('mouseleave', stopMoving);
      }

      resetDropZones();
      localState.zone = undefined;
      localState.dropZones = undefined;
      localState.selectedCard = undefined;
      localState.hasMoved = false;
      localState.isDraggingReady = false;
      localState.placeholderCard = undefined;
      localState.initialMouseX = undefined;
      localState.initialMouseY = undefined;
      localState.initialCardX = undefined;
      localState.initialCardY = undefined;
      localState.initialListId = undefined;
    });

    return {
      dragAndDropZone,
    };
  },
};
</script>

<style lang="scss">
.tasks-dnd {
  @apply flex flex-col lg:flex-row h-full;

  &.in-dragging {
    cursor: grabbing;

    .task-card {
      @apply pointer-events-none;
    }
  }

  .dragging-ready {
    @apply fixed z-10 pointer-events-none;
  }
}
</style>
