<template>
  <th class="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
    <slot />
  </th>
</template>

<script>
export default {};
</script>

<style></style>
