import { i18n } from '@/plugins/i18n/index.js';
import { postUploadInternalFiles, getFile, downloadFile } from './storages.api.js';

const stateData = {
  files: [],
};

const getters = {
  getFiles: (state) => state.files,
};

const mutations = {
  setFiles(state, { files }) {
    state.files = files;
  },
};

const actions = {
  async uploadInternalFiles({ commit }, { files }) {
    try {
      const uploadedFiles = await postUploadInternalFiles({ files });
      commit('setFiles', { files: uploadedFiles });
      return uploadedFiles;
    } catch (err) {
      throw new Error(i18n.global.t('storages.store.uploadInternalFiles.requestError'));
    }
  },
  async fetchFile({ commit }, { fileId }) {
    try {
      const file = await getFile({ fileId });
      commit('setFiles', { files: [file] });
      return file;
    } catch (err) {
      throw new Error(i18n.global.t('storages.store.fetchFile.requestError'));
    }
  },
  async downloadFile(_, { fileId }) {
    const fileBlob = await downloadFile({ fileId });
    return fileBlob;
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
