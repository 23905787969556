<template>
  <div class="relative label-filters">
    <div
      class="relative text-xs transition-colors duration-150 rounded hover:opacity-75"
      :class="{
        'text-primary hover:bg-gray-100': !open && activeFilters.length === 0,
        'text-white bg-primary': open,
        'text-white bg-green-400': !open && activeFilters.length > 0,
      }"
    >
      <button
        type="button"
        class="py-2 px-2.5 outline-none focus:outline-none appearance-none"
        @click="open = !open"
      >
        <FontAwesomeIcon :icon="faTags" />
        {{ activeFilters.length > 0 ? `${activeFilters.length} label${activeFilters.length > 1 ? 's' : ''}` : '' }}
      </button>
      <button
        v-show="activeFilters.length > 0"
        type="button"
        class="px-2 py-2 bg-white outline-none appearance-none focus:outline-none bg-opacity-30 hover:bg-opacity-10"
        @click="(activeFilters = []), (open = false)"
      >
        <FontAwesomeIcon :icon="faTimes" />
      </button>
    </div>
    <SkyPopup
      :open="open"
      class="p-5 sky-popup-positionning"
    >
      <SkyTitle class="mb-4 text-2xl">{{ $t('components.projects.labelFiltersSelect.title') }}</SkyTitle>

      <SkyForm class="mb-2">
        <SkyInputText
          v-model="currentSearch"
          placeholder="Search here..."
        />
      </SkyForm>

      <div class="grid grid-cols-2 md:grid-cols-3 w-max gap-6">
        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium text-primary">{{ $t('components.projects.labelFiltersSelect.categories') }}</span>
          <div class="flex flex-col gap-1">
            <label
              v-for="label in filteredCategoryLabels.list"
              :key="`label-${label._id}-filter`"
              :for="`label-${label._id}-filter`"
              class="px-2 py-2 text-sm font-light rounded cursor-pointer select-none whitespace-nowrap bg-gray-100 hover:opacity-75 flex flex-row items-center"
            >
              <input
                :id="`label-${label._id}-filter`"
                v-model="activeFilters"
                type="checkbox"
                name="labels"
                :value="label._id"
                class="hidden"
              />
              <div class="flex flex-row items-center">
                <span
                  class="flex items-center justify-center w-4 h-4 overflow-hidden border rounded"
                  :class="{
                    'text-transparent border-gray-300': !activeFilters.includes(label._id),
                    'bg-primary border-primary text-white': activeFilters.includes(label._id),
                  }"
                >
                  <FontAwesomeIcon
                    :icon="faCheck"
                    style="font-size: 0.6rem"
                  />
                </span>
                <LabelTag
                  :label="label"
                  class="text-xs"
                />
              </div>
            </label>
          </div>
          <span
            v-if="!showAllCategoryLabels && filteredCategoryLabels.left > 0"
            class="text-xs underline cursor-pointer label-filters"
            @click="showAllCategoryLabels = true"
          >
            + {{ filteredCategoryLabels.left }} {{ $t('components.projects.labelFiltersSelect.left') }}
          </span>
          <span
            v-if="filteredCategoryLabels.list.length === 0"
            class="text-xs whitespace-nowrap text-gray-500"
          >
            {{ $t('components.projects.labelFiltersSelect.noElement') }}
          </span>
        </div>

        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium text-primary">{{ $t('components.projects.labelFiltersSelect.themes') }}</span>
          <div class="flex flex-col gap-1">
            <label
              v-for="label in filteredThemeLabels.list"
              :key="`label-${label._id}-filter`"
              :for="`label-${label._id}-filter`"
              class="px-2 py-2 text-sm font-light rounded cursor-pointer select-none whitespace-nowrap bg-gray-100 hover:opacity-75"
            >
              <input
                :id="`label-${label._id}-filter`"
                v-model="activeFilters"
                type="checkbox"
                name="labels"
                :value="label._id"
                class="hidden"
              />
              <div class="flex flex-row items-center">
                <span
                  class="flex items-center justify-center w-4 h-4 overflow-hidden border rounded"
                  :class="{
                    'text-transparent border-gray-300': !activeFilters.includes(label._id),
                    'bg-primary border-primary text-white': activeFilters.includes(label._id),
                  }"
                >
                  <FontAwesomeIcon
                    :icon="faCheck"
                    style="font-size: 0.6rem"
                  />
                </span>
                <LabelTag
                  :label="label"
                  class="text-xs"
                />
              </div>
            </label>
          </div>
          <span
            v-if="!showAllThemeLabels && filteredThemeLabels.left > 0"
            class="text-xs underline cursor-pointer label-filters"
            @click="showAllThemeLabels = true"
          >
            + {{ filteredThemeLabels.left }} {{ $t('components.projects.labelFiltersSelect.left') }}
          </span>
          <span
            v-if="filteredThemeLabels.list.length === 0"
            class="text-xs whitespace-nowrap text-gray-500"
          >
            {{ $t('components.projects.labelFiltersSelect.noElement') }}
          </span>
        </div>

        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium text-primary">{{ $t('components.projects.labelFiltersSelect.tags') }}</span>
          <div class="flex flex-col gap-1">
            <label
              v-for="label in filteredTagLabels.list"
              :key="`label-${label._id}-filter`"
              :for="`label-${label._id}-filter`"
              class="px-2 py-2 text-sm font-light rounded cursor-pointer select-none whitespace-nowrap bg-gray-100 hover:opacity-75 flex flex-row items-center"
            >
              <input
                :id="`label-${label._id}-filter`"
                v-model="activeFilters"
                type="checkbox"
                name="labels"
                :value="label._id"
                class="hidden"
              />
              <div class="flex flex-row items-center">
                <span
                  class="flex items-center justify-center w-4 h-4 overflow-hidden border rounded"
                  :class="{
                    'text-transparent border-gray-300': !activeFilters.includes(label._id),
                    'bg-primary border-primary text-white': activeFilters.includes(label._id),
                  }"
                >
                  <FontAwesomeIcon
                    :icon="faCheck"
                    style="font-size: 0.6rem"
                  />
                </span>
                <LabelTag
                  :label="label"
                  class="text-xs"
                />
              </div>
            </label>
          </div>
          <span
            v-if="!showAllTagLabels && filteredTagLabels.left > 0"
            class="text-xs underline cursor-pointer label-filters"
            @click="showAllTagLabels = true"
          >
            + {{ filteredTagLabels.left }} {{ $t('components.projects.labelFiltersSelect.left') }}
          </span>
          <span
            v-if="filteredTagLabels.list.length === 0"
            class="text-xs whitespace-nowrap text-gray-500"
          >
            {{ $t('components.projects.labelFiltersSelect.noElement') }}
          </span>
        </div>
      </div>
    </SkyPopup>
  </div>
</template>

<script>
import { computed, reactive, toRefs, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import LabelTag from '@/modules/projects/components/LabelTag.vue';
import { faTags, faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { toLower } from 'lodash';
import { LABEL_TYPE } from '../projects.constants.js';

export default {
  components: {
    LabelTag,
  },
  setup() {
    const store = useStore();

    const state = reactive({
      open: false,
      labelsFilters: computed(() => store.getters['projects/getLabelFilters']),
      activeFilters: computed({
        get() {
          return store.getters['projects/getLabelFilters'];
        },
        set(value) {
          store.commit('projects/setLabelFilters', { labelFilters: value });
        },
      }),
      currentSearch: undefined,
      showAllCategoryLabels: false,
      showAllThemeLabels: false,
      showAllTagLabels: false,
      isSearchDefined: computed(() => state.currentSearch !== undefined && state.currentSearch !== ''),
      labels: computed(() => store.getters['projects/getLabels']),
      categoryLabels: computed(() => state.labels.filter((label) => label.type === LABEL_TYPE.category)),
      themeLabels: computed(() => state.labels.filter((label) => label.type === LABEL_TYPE.theme)),
      tagLabels: computed(() => state.labels.filter((label) => label.type === LABEL_TYPE.tag)),
      filteredCategoryLabels: computed(() => {
        const filtered = state.categoryLabels.filter((label) => !state.isSearchDefined || toLower(label.name).includes(toLower(state.currentSearch)));
        const list = filtered.slice(0, 10);
        return {
          list: state.showAllCategoryLabels ? filtered : list,
          left: filtered.length - list.length,
        };
      }),
      filteredThemeLabels: computed(() => {
        const filtered = state.themeLabels.filter((label) => !state.isSearchDefined || toLower(label.name).includes(toLower(state.currentSearch)));
        const list = filtered.slice(0, 10);
        return {
          list: state.showAllThemeLabels ? filtered : list,
          left: filtered.length - list.length,
        };
      }),
      filteredTagLabels: computed(() => {
        const filtered = state.tagLabels.filter((label) => !state.isSearchDefined || toLower(label.name).includes(toLower(state.currentSearch)));
        const list = filtered.slice(0, 10);
        return {
          list: state.showAllTagLabels ? filtered : list,
          left: filtered.length - list.length,
        };
      }),
    });

    function handleClickOutside(event) {
      if (state.open === false) {
        return;
      }

      const { target } = event;
      const isOutsideClick = !target.closest('.label-filters');

      if (!isOutsideClick) {
        return;
      }

      state.showAllCategoryLabels = false;
      state.showAllThemeLabels = false;
      state.showAllTagLabels = false;

      state.open = false;
    }

    function resetLabelsFilters() {
      state.activeFilters = [];
      state.open = false;
      store.commit('projects/setLabelFilters', { labelFilters: [] });
    }

    onMounted(() => {
      document.querySelector('body').addEventListener('click', handleClickOutside, { passive: true });
    });

    onUnmounted(() => {
      document.querySelector('body').removeEventListener('click', handleClickOutside);
    });

    watch(
      () => state.labelsFilters,
      () => {
        store.dispatch('projects/fetchTasks');
        if (state.isAdminSkyloud) {
          store.dispatch('projects/fetchTasksStats');
        }
      },
    );

    return {
      ...toRefs(state),
      resetLabelsFilters,
      faTags,
      faCheck,
      faTimes,
    };
  },
};
</script>

<style lang="scss" scoped>
.sky-popup-positionning {
  @apply max-w-screen-full transition-transform right-auto left-0;
  --tw-translate-x: calc(-50% + 16px);

  @media screen and (min-width: 768px) {
    @apply left-auto right-0;
    --tw-translate-x: 0;
  }

  @media screen and (min-width: 1280px) {
    --tw-translate-x: calc(50% - 18px);

    &.active-label-filters {
      --tw-translate-x: calc(50% - 18px + 80px);
    }
  }
}
</style>
