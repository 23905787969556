<template>
  <div>
    <lottie
      :options="lottieOptions"
      @anim-created="handleAnimation"
    />
  </div>
</template>

<script>
import lottie from 'vue-lottie/src/lottie.vue';

export default {
  components: {
    lottie,
  },
  props: {
    loop: {
      type: Boolean,
      default: () => false,
    },
    data: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      anim: null,
    };
  },
  computed: {
    lottieOptions() {
      return {
        loop: this.loop,
        animationData: this.data,
        autoplay: true,
        renderer: 'svg',
      };
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
  },
};
</script>

<style></style>
