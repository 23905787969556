<template>
  <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
    <slot />
  </td>
</template>

<script>
export default {};
</script>

<style></style>
