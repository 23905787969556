import { faUsers, faWallet, faCalendarAlt, faExchange, faChartArea, faCloud } from '@fortawesome/pro-solid-svg-icons';

export default {
  accounts: {
    path: '/users',
    icon: faUsers,
    label: 'Utilisateurs',
    advantages: ["Sécurité d'accès", 'Conformité RGPD', 'Gestion des rôles'],
  },
  payments: {
    path: '/payments',
    icon: faWallet,
    label: 'Transactions',
    advantages: ['Connexion avec Stripe', 'Stockage sécurisée', 'Gestion des taxes'],
  },
  bookings: {
    path: '/bookings',
    icon: faCalendarAlt,
    label: 'Réservations',
    advantages: ['Gestion des réservations', 'Calendrier synchonisé', 'Gestion des tarifs'],
  },
  converters: {
    path: '/converters',
    icon: faExchange,
    label: 'Conversions',
    advantages: ['HTML en PDF', "Tailles d'images", 'Optimisations webp'],
  },
  stats: {
    path: '/stats',
    icon: faChartArea,
    label: 'Statistiques',
    advantages: ['Gestion en temps réel', 'Désengorgement des flux', 'Visualisation du trafic'],
  },
  clouds: {
    path: '/clouds',
    icon: faCloud,
    label: 'Clouds',
    advantages: ['Gestion centralisée', 'Support avancé', 'Analyse et monitoring'],
  },
};
