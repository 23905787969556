<template>
  <span class="text-xl sky-title">
    <span
      class="relative"
      v-bind="$attrs"
    >
      <slot />
    </span>
  </span>
</template>

<style lang="scss" scoped>
.sky-title {
  @apply text-dark-purple relative font-semibold;
}
</style>
