<template>
  <button
    :type="type"
    :class="classes"
    :disabled="disabled || loading"
  >
    <div class="absolute w-6 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
      <svg
        v-show="loading"
        class="animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        />
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
    <div
      class="sky-btn__slot-container"
      :class="{ invisible: loading }"
    >
      <slot />
    </div>
  </button>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['tiny', 'small', 'medium'].includes(value),
    },
    variant: {
      type: String,
      default: 'plain',
      validator: (value) => ['plain', 'link', 'outline', 'flat'].includes(value),
    },
    color: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'gray', 'white', 'red', 'dark', 'light', 'dark-2', 'dark-3'].includes(value),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  setup(props) {
    const classes = computed(() => ({
      'sky-btn': true,

      // VARIANTS
      'sky-btn--flat': props.variant === 'flat',
      'sky-btn--plain': props.variant === 'plain',
      'sky-btn--link': props.variant === 'link',
      'sky-btn--outline': props.variant === 'outline',

      // COLORS
      'sky-btn--dark': props.color === 'dark',
      'sky-btn--dark-2': props.color === 'dark-2',
      'sky-btn--dark-3': props.color === 'dark-3',
      'sky-btn--light': props.color === 'light',
      'sky-btn--primary': props.color === 'primary',
      'sky-btn--gray': props.color === 'gray',
      'sky-btn--white': props.color === 'white',
      'sky-btn--red': props.color === 'red',

      // SIZES
      'sky-btn--tiny': props.size === 'tiny',
      'sky-btn--small': props.size === 'small',
      'sky-btn--medium': props.size === 'medium',

      // ANIMATIONS
      'sky-btn--reduce-anim': props.animation === 'reduce',
      'sky-btn--no-anim': props.animation === 'none',

      // STATE
      'sky-btn--loading': props.loading,
      'sky-btn--disabled': props.disabled || props.loading,
    }));

    return { classes };
  },
};
</script>

<style lang="scss" scoped>
.sky-btn {
  @apply relative outline-none cursor-pointer appearance-none;
  transition: all 0.15s ease 0s;

  &:focus {
    @apply outline-none;
  }

  /* VARIANTS */
  &--plain {
    @apply rounded-md;

    /* COLORS */
    &.sky-btn--primary {
      @apply bg-gradient-to-br from-secondary to-primary text-white-smoke;

      &:hover,
      &:focus {
        @apply text-primary from-[#1B1B4C] to-[#1B1B4C];
      }
    }

    &.sky-btn--gray {
      @apply text-white bg-gray-500 bg-opacity-75;

      &:hover,
      &:focus {
        @apply bg-gray-600;
      }
    }

    &.sky-btn--white {
      @apply bg-white text-primary;

      &:hover,
      &:focus {
        @apply bg-[#516FDD] text-white;
      }
    }

    &.sky-btn--red {
      @apply bg-red-600 text-white;

      &:hover,
      &:focus {
        @apply bg-red-700;
      }
    }

    /* SIZES */
    &.sky-btn--tiny {
      @apply py-1 px-2 text-xs;
    }

    &.sky-btn--small {
      @apply py-2 px-4 text-xs;
    }

    &.sky-btn--medium {
      @apply px-6 py-3 text-sm;
    }
  }

  &--link {
    @apply px-1 py-2 underline;

    &:hover,
    &:focus {
      @apply no-underline;
    }

    /* COLORS */
    &.sky-btn--primary {
      @apply text-primary;
    }

    &.sky-btn--white {
      @apply text-white;
    }

    &.sky-btn--red {
      @apply text-red-600;
    }

    /* SIZES */
    &.sky-btn--small {
      @apply text-xs;
    }

    &.sky-btn--medium {
      @apply text-sm;
    }

    /* STATES */
    &.sky-btn--disabled {
      .sky-btn__slot-container:after,
      .sky-btn__slot-container:after {
        @apply content-none;
      }
    }

    &.sky-btn--loading {
      @apply bg-gray-400 rounded-md;

      .sky-btn__slot-container:after,
      .sky-btn__slot-container:after {
        @apply content-none;
      }
    }
  }

  &--outline {
    @apply rounded-md border;

    /* COLORS */
    &.sky-btn--primary {
      @apply border-primary text-primary;

      &:hover,
      &:focus {
        @apply bg-primary text-white;
      }
    }

    &.sky-btn--white {
      @apply border-white text-white;

      &:hover,
      &:focus {
        @apply bg-white text-primary;
      }
    }

    &.sky-btn--red {
      @apply border-red-600 text-red-600;

      &:hover,
      &:focus {
        @apply bg-red-600 text-white;
      }
    }

    /* SIZES */
    &.sky-btn--tiny {
      @apply py-1 px-2 text-xs;
    }

    &.sky-btn--small {
      @apply py-2 px-4 text-xs;
    }

    &.sky-btn--medium {
      @apply px-6 py-3 text-sm;
    }
  }

  &--flat {
    @apply rounded-md;

    /* COLORS */

    &.sky-btn--primary {
      @apply border border-primary text-primary bg-primary bg-opacity-20;
    }

    &.sky-btn--dark-2 {
      @apply bg-[#171321] text-white-smoke;
    }

    &.sky-btn--dark-3 {
      @apply bg-[#2C263D] text-white-smoke;
    }

    &.sky-btn--dark {
      @apply bg-[#112064] text-white-smoke;
    }

    &.sky-btn--light {
      @apply text-[#112064] bg-[#667EEA];
    }

    /* SIZES */
    &.sky-btn--tiny {
      @apply py-1 px-2 text-xs;
    }

    &.sky-btn--small {
      @apply py-2 px-4 text-xs;
    }

    &.sky-btn--medium {
      @apply px-6 py-3 text-sm;
    }
  }

  /* STATES */
  &--disabled {
    @apply opacity-60 cursor-not-allowed;
  }

  &--loading {
    @apply cursor-wait;
  }
}
</style>
