import dayjs from 'dayjs';
import map from 'lodash/map';
import some from 'lodash/some';
import find from 'lodash/find';
import toLower from 'lodash/toLower';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import zipObject from 'lodash/zipObject';
import isUndefined from 'lodash/isUndefined';
import includes from 'lodash/includes';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { max } from 'lodash';
import { ONE_BUSINESS_DAY_IN_MS, roundEllapsedTime } from '../../utils/format.js';
import { TASK_ELLAPSED_BAR, TASK_ELLAPSED_TIME_STEP_IN_MS } from './projects.constants.js';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export function isTaskMatchEstimationFilters({ estimationFilters, estimatedTime, estimatedTimeAcceptedAt, estimatedTimeRefusedAt }) {
  const filtersToCheck = pick(
    {
      without: isUndefined(estimatedTime) || estimatedTime === 0,
      awaitingValidation: !isUndefined(estimatedTime) && estimatedTime > 0 && isEmpty(estimatedTimeAcceptedAt) && isEmpty(estimatedTimeRefusedAt),
      refused: !isEmpty(estimatedTimeRefusedAt),
      accepted: !isEmpty(estimatedTimeAcceptedAt),
    },
    ...estimationFilters,
  );

  return some(filtersToCheck, Boolean);
}

export function handleDecimalValueInput(event) {
  const { keyCode } = event;
  const authorizedControlsKeys = [8, 46, 37, 38, 39, 40];
  if (!includes(authorizedControlsKeys, keyCode) && !/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
}

export function isTaskMatchDateFilter({ dateFilter, comparatorFilter, date }) {
  if (!date) {
    return false;
  }

  const taskDate = dayjs.utc(date);
  const comparatorDate = dayjs.utc(dateFilter);

  const filtersResult = {
    before: comparatorFilter === 'lt' && taskDate.isBefore(comparatorDate, 'day'),
    at: comparatorFilter === 'eq' && taskDate.isSame(comparatorDate, 'day'),
    after: comparatorFilter === 'gt' && taskDate.isAfter(comparatorDate, 'day'),
  };

  return some(filtersResult, Boolean);
}

export function formatLabelRulesToObject(rules) {
  return zipObject(
    map(rules, 'name'),
    map(rules, (rule) => zipObject(map(rule.params, 'key'), map(rule.params, 'value'))),
  );
}

export function formatLabelRulesToArray(rules) {
  return map(rules, (ruleValues, label) => ({
    name: label,
    params: map(ruleValues, (value, key) => ({ key, value })),
  }));
}

export function getTaskLegalMaxEllapsedTimeAllowed(estimatedTime) {
  const oneWorkDay = estimatedTime + ONE_BUSINESS_DAY_IN_MS;
  const fifthyPercent = estimatedTime * 1.5;
  return max([oneWorkDay, fifthyPercent]);
}

export function getTaskEllapsedTimeColors({ task, labels }) {
  if (!task) return '';

  const progressTotal = Math.round((task.ellapsedTime / task.estimatedTime) * 100);
  const progressNormalPerc = Math.min(progressTotal, 100);
  const ellapsedTimeWarningThreshold = task.estimatedTime;
  const ellapsedTimeErrorThreshold = getTaskLegalMaxEllapsedTimeAllowed(task.estimatedTime);
  const isProgressAboveEstimation = task.ellapsedTime > ellapsedTimeWarningThreshold;
  const isProgressEqualToLimitAuthorizedByCgv = task.ellapsedTime > ellapsedTimeErrorThreshold;
  const isProgressAboveLimitAuthorizedByCgv = task.ellapsedTime > ellapsedTimeErrorThreshold;
  const hasMissionLabel = !!find(labels, (label) => toLower(label.name) === 'mission');
  const isTaskHasBeenEstimated = !!get(task, 'estimatedTime');
  const isTaskEstimatedTimeAlreadyAccepted = !!get(task, 'estimatedTimeAcceptedAt');
  const isTaskEstimatedTimeAlreadyRefused = !!get(task, 'estimatedTimeRefusedAt');
  const isWaitingEstimationValidation = isTaskHasBeenEstimated && !isTaskEstimatedTimeAlreadyAccepted && !isTaskEstimatedTimeAlreadyRefused;

  return {
    [TASK_ELLAPSED_BAR.WITHOUT_TIME]: progressNormalPerc === 0,
    [TASK_ELLAPSED_BAR.IN_RANGE]: progressNormalPerc > 0 && (isTaskEstimatedTimeAlreadyAccepted || isWaitingEstimationValidation || !hasMissionLabel) && !isProgressAboveEstimation,
    [TASK_ELLAPSED_BAR.OVERFLOW]:
      (isTaskEstimatedTimeAlreadyAccepted || isWaitingEstimationValidation || !hasMissionLabel) && isProgressAboveEstimation && !isProgressAboveLimitAuthorizedByCgv,
    [TASK_ELLAPSED_BAR.TOO_MUCH_OVERLFLOW]:
      ((isTaskEstimatedTimeAlreadyAccepted || isWaitingEstimationValidation) && (isProgressEqualToLimitAuthorizedByCgv || isProgressAboveLimitAuthorizedByCgv)) ||
      isTaskEstimatedTimeAlreadyRefused,
  };
}

export function getTasksFiltersChecks({ task, state }) {
  const { name, taskId, description, labelIds = [], kind, priority, pendingAt, estimatedTime, milestoneIds, estimatedTimeAcceptedAt, estimatedTimeRefusedAt, dueAt } = task;
  return {
    search:
      isEmpty(state.currentSearch) ||
      name.toLowerCase().includes(state.currentSearch.toLowerCase()) ||
      taskId.toLowerCase().includes(state.currentSearch.toLowerCase()) ||
      description.toLowerCase().includes(state.currentSearch.toLowerCase()),
    labels: isEmpty(state.labelFilters) || some(labelIds, (labelId) => state.labelFilters.includes(labelId)),
    milestones: isEmpty(state.milestonesFilters) || some(milestoneIds, (milestoneId) => state.milestonesFilters.includes(milestoneId)),
    kind: isEmpty(state.kindFilters) || state.kindFilters.includes(kind),
    priority: isEmpty(state.prioritiesFilters) || state.prioritiesFilters.includes(priority),
    pendingAt: isUndefined(state.isPendingFilter) || (state.isPendingFilter === true && pendingAt !== undefined) || (state.isPendingFilter === false && pendingAt === undefined),
    estimation:
      isEmpty(state.estimationFilters) ||
      isTaskMatchEstimationFilters({
        estimationFilters: state.estimationFilters,
        estimatedTime,
        estimatedTimeAcceptedAt,
        estimatedTimeRefusedAt,
      }),
    deliveryDate:
      isEmpty(state.deliveryDateFilter) ||
      isTaskMatchDateFilter({
        dateFilter: state.deliveryDateFilter,
        comparatorFilter: state.deliveryComparatorFilter,
        date: dueAt,
      }),
  };
}

export function getTaskWorklogEllapsedTime(workLog) {
  const startedAt = get(workLog, 'startedAt', 0) ? new Date(get(workLog, 'startedAt', 0)).getTime() : new Date().getTime();
  const stoppedAt = get(workLog, 'stoppedAt') ? new Date(get(workLog, 'stoppedAt')).getTime() : new Date().getTime();
  const pauseTimeInMs = get(workLog, 'pausedAt')
    ? new Date().getTime() - new Date(get(workLog, 'pausedAt')).getTime() + get(workLog, 'pauseTimeInMs', 0)
    : get(workLog, 'pauseTimeInMs', 0);
  return stoppedAt - startedAt - pauseTimeInMs;
}

export function getWorklogsBillableTime(worklogs) {
  return worklogs.reduce((acc, worklog) => {
    const ellapsedTime = getTaskWorklogEllapsedTime(worklog);
    return acc + roundEllapsedTime(ellapsedTime, TASK_ELLAPSED_TIME_STEP_IN_MS);
  }, 0);
}

export function getCurrentDayMaxWorkHours(date) {
  const currentDay = date ? dayjs(date) : dayjs();
  const fridayWeekDay = 5;

  return currentDay.day() === fridayWeekDay ? 7 : 8;
}
export function getInvoicableWorklogs({ workLogs, acceptedEstimatedTime }) {
  if (acceptedEstimatedTime === 0) return [];
  const legalMaxAllowedTime = getTaskLegalMaxEllapsedTimeAllowed(acceptedEstimatedTime);
  let currentAdditiveEllapsedTime = 0;

  return workLogs.reduce((acc, workLog) => {
    const workLogEllapsedTime = getTaskWorklogEllapsedTime(workLog);
    const roundedWorkLogEllapsedTime = roundEllapsedTime(workLogEllapsedTime, 900_000);
    if (currentAdditiveEllapsedTime >= legalMaxAllowedTime) return acc;
    currentAdditiveEllapsedTime += roundedWorkLogEllapsedTime;
    return [...acc, workLog];
  }, []);
}
