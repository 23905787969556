import { ImmutableCollection } from '@/utils/collections/immutable-collection.js';
import { ProbeEventsDay } from '@/modules/stats/availability/domain/probe-event/collection/probe-events-day.js';

/**
 * @extends {ImmutableCollection<ProbeEventsDay>}
 */
export class ProbeEventsWeek extends ImmutableCollection {
  innerTypeOf() {
    return ProbeEventsDay;
  }

  /**
   * @param {ProbeEvent[]} events
   */
  static fromEvents(events) {
    return new this().addEvents(events);
  }

  /**
   * @param {ProbeEvent} event
   * @returns {ProbeEventsWeek}
   */
  addEvent(event) {
    return this.replaceOrCreate(
      (day) => day.isEventInSameDay(event),
      (day) => day.addEvent(event),
      () => new ProbeEventsDay(event.lifeTime.startedAt, new ImmutableCollection(event)),
    ).sortByDays();
  }

  /**
   * @param {ProbeEvent[]} events
   * @returns {ProbeEventsWeek}
   */
  addEvents(events) {
    return events.reduce((weeks, event) => weeks.addEvent(event), this);
  }

  sortByDays() {
    return this.sort((dayA, dayB) => dayA.compareEventsDayTo(dayB));
  }

  /**
   * @param {ProbeEventsWeek} eventsWeek
   * @return {0|1|-1}
   */
  compareEventsWeekTo(eventsWeek) {
    return this.first().compareEventsDayTo(eventsWeek.first());
  }

  /**
   * @param {ProbeEvent} event
   * @returns {boolean}
   */
  isEventPartOfTheWeek(event) {
    return this.first().isEventPartOfTheWeek(event);
  }

  /**
   * @param {ProbeEventsDay} eventsDay
   */
  isEventsDayInSameWeek(eventsDay) {
    return this.first()?.isEventsDayInSameWeek(eventsDay) || false;
  }

  /**
   * @return {ImmutableCollection<ProbeEvent>}
   */
  toEventsCollection() {
    return this.reduce((accumulator, day) => {
      const probeEvents = day.toEventsCollection();
      const events = probeEvents.filter((event) => !accumulator.some((eventInAccumulator) => eventInAccumulator.isSame(event)));

      return accumulator.add(...events);
    }, new ImmutableCollection());
  }

  /**
   * @return {ImmutableCollection<ProbeEventsDay>}
   */
  toDaysCollection() {
    return this.reduce((accumulator, day) => accumulator.add(day), new ImmutableCollection());
  }
}
