<template>
  <div>
    <AvailabilitySummaryWidget
      v-for="(probe, index) in firstFourProbes"
      :key="index"
      :probe="probe"
      :probe-events="getEventsFromProbes(probe)"
    />
    <router-link to="/stats">
      <SkyButton
        class="mx-auto block mt-4"
        animation="none"
        variant="outline"
      >
        {{ $t('components.stats.availabilitySummariesWidget.seeAllMonitors') }}
      </SkyButton>
    </router-link>
  </div>
</template>

<script setup>
import { ConsolidatedProbesHistory } from '@/modules/stats/availability/domain/consolidated-probes-history.js';
import { availabilityInjectors } from '@/modules/stats/availability/infrastructure/presenters/availability-injectors.js';
import { computed, inject, onMounted, ref } from 'vue';
import { ProbeEvents } from '@/modules/stats/availability/domain/probe-event/collection/probe-events.js';
import AvailabilitySummaryWidget from '@/modules/stats/availability/infrastructure/presenters/organisms/widget/AvailabilitySummaryWidget.vue';
import take from 'lodash/take';
import filter from 'lodash/filter';
import { Probes } from '@/modules/stats/availability/domain/probe/probes.js';

const props = defineProps({
  history: {
    type: ConsolidatedProbesHistory,
    required: true,
  },
});

const probes = inject(availabilityInjectors.probes);
const firstFourProbes = ref(Probes.fromProbes([]));
const events = computed(() => props.history.toProbeEvents().toEventsCollection());

function getEventsFromProbes(probe) {
  const filteredEvents = filter(events.value._items, (event) => event.probeId === probe.id);
  return ProbeEvents.fromEvents(filteredEvents);
}

function sortByLatestAvailable(items) {
  return items.sort((curr, next) => {
    const currentMostRecentEvent = getEventsFromProbes(curr).lastAvailableAt?._value || 0;
    const nextMostRecentEvent = getEventsFromProbes(next).lastAvailableAt?._value || 0;

    return nextMostRecentEvent - currentMostRecentEvent;
  });
}

onMounted(() => {
  const sorted = sortByLatestAvailable(probes._items);
  firstFourProbes.value = Probes.fromProbes(take(sorted, 4));
});
</script>
