import { ProbeEventsDayState } from '@/modules/stats/availability/domain/probe-event/collection/day-states/probe-events-day-state.js';
import { UnknownStatus } from '@/modules/stats/availability/domain/probe-event/status/unknown-status.js';

/**
 * @implements {ProbeEventsDayState}
 */
export class ProbeEventsDayStateFuture extends ProbeEventsDayState {
  getDayStatus() {
    return new UnknownStatus();
  }
}
