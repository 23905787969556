<template>
  <div
    :key="randomKey"
    class="relative"
    @click="openEditMenu = !openEditMenu"
  >
    <Teleport to="body">
      <div
        v-if="elementIsInView"
        :class="{ hidden: !editable || !openEditMenu }"
      >
        <div
          ref="tooltipElementRef"
          class="milestone-icon-editor bg-white border border-primary border-opacity-25 rounded flex flex-col gap-2 p-2 absolute transform-gpu -translate-x-1/2 -translate-y-full transition-all z-50"
        >
          <SkyFilterList
            v-model="filteredMilestones"
            :elements="milestones"
            search-on="name"
            sort="startAt"
            class="min-w-[200px]"
            @search="handleSearchNewMilestones"
          >
            <div class="flex flex-row gap-2">
              <div
                v-for="mile of filteredMilestones"
                :key="mile._id"
                class="flex flex-row items-center gap-2"
              >
                <SkyCheckbox
                  :checked="internalTaskMilestonesValue.includes(mile._id)"
                  @change="() => handleSelectMilestones(mile)"
                />
                <span class="text-xs whitespace-nowrap">{{ mile.name }}</span>
              </div>
            </div>
          </SkyFilterList>
          <SkyButton
            animation="none"
            size="tiny"
            :loading="loading"
            class="w-full mt-2"
            @click="handleUpdate"
          >
            Valider
          </SkyButton>
        </div>
      </div>
    </Teleport>
    <div
      ref="hostElementRef"
      class="btn-milestone-icon-editor"
    >
      <div
        v-if="!task || task?.milestoneIds?.length > 0"
        class="flex flex-row items-center px-2 py-1 gap-1 border border-[#F1F4FF] font-medium rounded flex-nowrap text-[#98A2D2] bg-white"
        :class="{
          'cursor-pointer': editable,
        }"
        v-bind="$attrs"
      >
        <FontAwesomeIcon :icon="faFlag" />
        {{ milestoneName }}
        <span v-if="task?.milestoneIds?.length > 1">+</span>
      </div>
      <div
        v-else
        class="bg-[#EBEEFE] px-2 py-1 rounded text-xs opacity-60"
        :class="{ 'cursor-pointer': editable }"
      >
        N/A
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import get from 'lodash/get';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { useStore } from 'vuex';
import { useNotify } from '@/plugins/notify/index.js';
import { difference, filter, last, map, sortBy, uniq } from 'lodash';
import { useTooltip } from '@/components/ui/tooltips/use-tooltip.js';

const props = defineProps({
  milestone: {
    type: Object,
    default: undefined,
  },
  task: {
    type: Object,
    default: undefined,
  },
  editable: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const notify = useNotify();

const randomKey = ref(Math.random());
const { hostElementRef, tooltipElementRef, elementIsInView } = useTooltip();

const getMilestone = store.getters['projects/getMilestone'];

const openEditMenu = ref(false);
const filteredMilestones = ref([]);
const loading = ref(false);

const milestones = computed(() => store.getters['projects/getMilestones'].list);
const internalTaskMilestonesValue = ref(props.task?.milestoneIds || []);
const currentMilestones = computed(() =>
  sortBy(
    map(internalTaskMilestonesValue.value, (milestoneId) => getMilestone({ milestoneId })),
    ['startAt'],
  ),
);

const internalMilestoneValue = ref(props.milestone || last(currentMilestones.value));

const milestoneName = computed(() => {
  if (!internalMilestoneValue.value) return undefined;

  const parts = internalMilestoneValue.value.name.split(' ');
  const firstLetter = get(parts, '[0][0]');
  const number = get(parts, '[1]');

  return `${firstLetter}${number}`;
});

async function handleSelectMilestones(milestone) {
  if (internalTaskMilestonesValue.value.includes(milestone._id)) {
    internalTaskMilestonesValue.value = filter(internalTaskMilestonesValue.value, (milestoneId) => milestoneId !== milestone._id);
    return;
  }

  internalTaskMilestonesValue.value = uniq([...internalTaskMilestonesValue.value, milestone._id]);
}

async function handleUpdate() {
  if (!props.editable || !props.task) return;
  try {
    loading.value = true;

    await store.dispatch('admin/connectUserToApp', { appId: props.task.appId });

    const removedMilestoneIds = difference(props.task.milestoneIds, internalTaskMilestonesValue.value);
    const addedMilestoneIds = difference(internalTaskMilestonesValue.value, props.task.milestoneIds);

    const attachMilestones = map(uniq(addedMilestoneIds), (milestoneId) => store.dispatch('projects/attachTaskMilestone', { _id: props.task._id, milestoneId }));
    const detachMilestones = map(uniq(removedMilestoneIds), (milestoneId) => store.dispatch('projects/detachTaskMilestone', { _id: props.task._id, milestoneId }));
    await Promise.allSettled([...attachMilestones, ...detachMilestones]);

    internalMilestoneValue.value = last(currentMilestones.value);
    const milestoneIdsToUpdate = [...(props.task.milestoneIds || []), ...addedMilestoneIds].filter((labelId) => !removedMilestoneIds.includes(labelId));
    store.commit('admin/updateBacklogTask', { taskId: props.task._id, payload: { milestoneIds: milestoneIdsToUpdate } });
  } catch (err) {
    notify.error(err.message);
  }
  loading.value = false;
  openEditMenu.value = false;
}

async function handleSearchNewMilestones(search) {
  try {
    await store.dispatch('admin/connectUserToApp', { appId: props.task.appId });
    await store.dispatch('projects/fetchMilestones', { search });
  } catch (err) {
    notify.error(err.message);
  }
}

function handleClickOutside(event) {
  if (openEditMenu.value === false) {
    return;
  }

  const { target } = event;
  const isOutsideClick = !target.closest('.milestone-icon-editor') && !target.closest('.btn-milestone-icon-editor');

  if (!isOutsideClick) {
    return;
  }

  openEditMenu.value = false;
}

onMounted(() => {
  document.querySelector('body').addEventListener('click', handleClickOutside, { passive: true });
});

onUnmounted(() => {
  document.querySelector('body').removeEventListener('click', handleClickOutside);
});

watch([() => props.milestone, () => props.task], () => {
  internalTaskMilestonesValue.value = props.task?.milestoneIds || [];
  internalMilestoneValue.value = props.milestone || last(currentMilestones.value);
});

watch(
  () => elementIsInView.value,
  () => {
    if (elementIsInView.value) {
      setTimeout(() => {
        randomKey.value = Math.random();
      }, 200);
    }
  },
);
</script>
