<template>
  <SkyTooltip
    v-if="!(dayHistory.status instanceof AvailableStatus)"
    variant="white-smoke"
  >
    <template #content>
      <div class="flex flex-col text-center gap-4 py-2 text-sm">
        <span
          v-if="!(dayHistory.status instanceof UnknownStatus)"
          class="inline-flex items-center gap-2 mx-auto"
          :class="dayHistory.status.getTextColor()"
        >
          <AvailabilityDot
            :status="dayHistory.status"
            size="small"
          />
          {{
            // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
            $t(dayHistory.status.getTranslateTextKey())
          }}
        </span>
        <div
          v-if="!!(dayHistory.status instanceof UnknownStatus)"
          class="flex flex-col"
        >
          <span>
            {{ $t('stats.availability.status.unknown') }}
          </span>
          <strong>{{ formatDate(dayHistory._date._value, 'DD/MM/YYYY') }}</strong>
        </div>
        <div
          v-else
          class="flex flex-col"
        >
          <div class="flex flex-col gap-2">
            <span>{{ formatDate(dayHistory._date._value, 'DD/MM/YYYY') }}</span>
            <div
              v-for="report in reportsOfTheDay"
              :key="report._id"
              class="flex flex-col gap-1 bg-gray-200 bg-opacity-60 p-2 rounded-md min-w-[150px]"
            >
              <strong>{{ report.incidentsIds.length }} Incident(s)</strong>
              <span class="text-sm">{{ formatDate(report.createdAt, 'HH:mm') }}</span>
              <SkyButton
                v-if="getCanSeeReport(report)"
                size="tiny"
                @click="() => handleSelectReport(report._id)"
              >
                Rapport
              </SkyButton>
              <span
                v-else
                class="italic text-xs"
              >
                Aucun rapport associé
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <slot />
  </SkyTooltip>
  <div
    v-else
    class="z-10"
  >
    <slot />
  </div>
</template>

<script setup>
import SkyTooltip from '@/components/ui/tooltips/SkyTooltip.vue';
import AvailabilityDot from '@/modules/stats/availability/infrastructure/presenters/atoms/AvailabilityDot.vue';
import { AvailableStatus } from '@/modules/stats/availability/domain/probe-event/status/available-status.js';
import { UnknownStatus } from '@/modules/stats/availability/domain/probe-event/status/unknown-status.js';
import { ProbeEventsDay } from '@/modules/stats/availability/domain/probe-event/collection/probe-events-day.js';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { formatDate } from '@/utils/format.js';
import { includes } from 'lodash';
import { getAvailabilityReportTotalTime } from '@/modules/stats/stats.functions.js';
import { REPORT_REQUEST_LIMIT } from '@/modules/stats/stats.constants.js';

const props = defineProps({
  dayHistory: {
    type: ProbeEventsDay,
    required: true,
  },
});

const router = useRouter();
const store = useStore();
const history = computed(() => store.getters['stats/probesEventsHistory']);
const currentUser = computed(() => store.getters['auth/currentUser']);
const currentApp = computed(() => store.getters['apps/currentApp']);
const canEditReports = computed(() => includes(currentUser.value.roles, 'admin:skyloud'));

const reports = computed(() => store.getters['stats/reports']);
const reportsOfTheDay = computed(() =>
  props.dayHistory.events.reduce((acc, event) => {
    const report = reports.value.find((r) => r.incidentsIds.includes(event._id._value));

    if (!report) return acc;

    const reportAlreadyIn = acc.find((r) => r._id === report._id);

    if (reportAlreadyIn) return acc;

    return [...acc, report];
  }, []),
);

function getCanSeeReport(report) {
  return (
    canEditReports.value ||
    currentApp.value.privileged ||
    getAvailabilityReportTotalTime(history.value.eventsFromIds(report.incidentsIds)) >= REPORT_REQUEST_LIMIT ||
    report.publishedAt
  );
}

function handleSelectReport(reportId) {
  router.push(`/stats/reports/${reportId}`);
}
</script>
