import { incidentTypes } from '@/modules/stats/stats.constants.js';
import { ProbeEvent } from '@/modules/stats/availability/domain/probe-event/probe-event.js';
import { UnavailableStatus } from '@/modules/stats/availability/domain/probe-event/status/unavailable-status.js';
import { UnknownStatus } from '@/modules/stats/availability/domain/probe-event/status/unknown-status.js';
import { ProbeEventStartedAt } from '@/modules/stats/availability/domain/probe-event/life-times/probe-event-started-at.js';
import { ProbeId } from '@/modules/stats/availability/domain/probe/probe-id.js';
import { ProbeName } from '@/modules/stats/availability/domain/probe/probe-name.js';
import { NullProbeName } from '@/modules/stats/availability/domain/probe/null-probe-name.js';
import { NullProbeId } from '@/modules/stats/availability/domain/probe/null-probe-id.js';
import { ProbeEventLifeTime } from '@/modules/stats/availability/domain/probe-event/life-times/probe-event-life-time.js';
import { ProbeEventId } from '@/modules/stats/availability/domain/probe-event/ids/probe-event-id.js';
import { ProbeEventResolvedAt } from '@/modules/stats/availability/domain/probe-event/life-times/probe-event-resolved-at.js';
import { ProbeEventAcknowledgedAt } from './life-times/probe-event-acknowledged-at.js';

export class ProbeEventBuilder {
  _id = null;

  _probeId = new NullProbeId();

  _startedAt = null;

  _resolvedAt = null;

  _acknowledgedAt = null;

  _status = new UnknownStatus();

  _name = new NullProbeName();

  _type = Object.keys(incidentTypes)[0];

  _cause = undefined;

  /**
   * @param {string} id
   * @return {ProbeEventBuilder}
   */
  withNativeId(id) {
    this._id = new ProbeEventId(id);
    return this;
  }

  withNativeType(type) {
    this._type = type;
    return this;
  }

  withNativeCause(cause) {
    this._cause = cause;
    return this;
  }

  withNativeName(name) {
    this._name = new ProbeName(name);
    return this;
  }

  /**
   * @param {DateValueObject | Date | string | number} date
   * @returns {ProbeEventBuilder}
   */
  withNativeStartedAtWithoutTime(date) {
    this._startedAt = ProbeEventStartedAt.fromNative(date).withoutTime();
    return this;
  }

  withNativeStartedAtTime(date) {
    this._startedAt = ProbeEventStartedAt.fromNative(date);
    return this;
  }

  /**
   * @param {DateValueObject | Date | string | number} date
   * @returns {ProbeEventBuilder}
   */
  withNativeResolvedAtWithoutTime(date) {
    this._resolvedAt = ProbeEventResolvedAt.fromNative(date).withoutTime();
    return this;
  }

  withNativeResolvedAtTime(date) {
    this._resolvedAt = ProbeEventResolvedAt.fromNative(date);
    return this;
  }

  /**
   * @param {DateValueObject | Date | string | number} date
   * @returns {ProbeEventBuilder}
   */
  withNativeAcknowledgedAtWithoutTime(date) {
    this._acknowledgedAt = ProbeEventAcknowledgedAt.fromNative(date).withoutTime();
    return this;
  }

  withNativeAcknowledgedAtTime(date) {
    this._acknowledgedAt = ProbeEventAcknowledgedAt.fromNative(date);
    return this;
  }

  withNativeProbeId(probeId) {
    this._probeId = new ProbeId(probeId);
    return this;
  }

  /**
   * @deprecated
   */
  unavailable() {
    this._status = new UnavailableStatus();
    return this;
  }

  /**
   * @returns {ProbeEvent}
   */
  build() {
    return new ProbeEvent(this._id, this._probeId, this._type, this._cause, this._name, new ProbeEventLifeTime(this._startedAt, this._acknowledgedAt, this._resolvedAt));
  }

  /**
   * @returns {ProbeEvent}
   */
  buildResolvedAt1HourLater() {
    return new ProbeEvent(this._id, this._probeId, this._type, this._cause, this._name, new ProbeEventLifeTime(this._startedAt, this._acknowledgedAt, this._startedAt.addHours(1)));
  }
}
