import { ValueObject } from '@/utils/value-objects/value-object.js';

/**
 * @abstract
 */
export class NullValueObject extends ValueObject {
  constructor() {
    super(null);
  }

  validate() {}

  /**
   * @param {ValueObject} object
   * @return {boolean}
   */
  isSame(object) {
    return object instanceof this.constructor;
  }
}
