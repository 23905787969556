<template>
  <div class="flex gap-4 text-sm">
    <div
      v-for="weekDay in WEEKDAY_LABELS"
      :key="weekDay"
      class="text-center text-dark-blue-800 opacity-[.35]"
      :class="`w-${size}`"
    >
      {{ weekDay }}
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed, inject } from 'vue';
import { availabilityInjectors } from '../availability-injectors.js';

const { locale } = useI18n();
const WEEKDAY_LABELS = computed(
  () =>
    ({
      fr: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
      en: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    }[locale.value]),
);

const size = inject(availabilityInjectors.dayWidth);
</script>
