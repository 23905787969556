<template>
  <label
    class="sky-switch"
    :class="{
      'sky-switch--disabled': disabled,
    }"
  >
    <div
      v-if="label"
      class="sky-switch__label"
    >
      {{ label }}
    </div>
    <button
      type="button"
      class="sky-switch__container"
      :class="{
        'bg-gray-400': !state.internalValue,
        'bg-primary': state.internalValue,
      }"
      :disabled="disabled"
      @click="state.internalValue = !state.internalValue"
    >
      <div class="sky-switch__wrapper">
        <div
          class="sky-switch__toggle"
          :class="{
            'sky-switch__toggle--on': state.internalValue,
          }"
        />
      </div>
    </button>
    <input
      ref="input"
      v-model="state.internalValue"
      type="checkbox"
      class="hidden"
      :placeholder="placeholder"
      :disabled="disabled"
    />
    <span
      class="flex items-center h-4 mt-1 ml-1 text-xs font-medium tracking-wide text-red-500"
      :class="{ hidden: !state.hasErrorMessages }"
    >
      {{ state.firstErrorMessage }}
    </span>
  </label>
</template>

<script>
import { computed, ref } from 'vue';
import useValidatable from '@/modules/shared/utils/validatable.js';

export default {
  props: {
    modelValue: { type: Boolean },
    label: { type: String, default: () => undefined },
    placeholder: { type: String, default: () => undefined },
    rules: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: () => false },
  },
  setup(props, { emit }) {
    const input = ref(null);

    const { state, validate } = useValidatable({ props, emit });

    const inputClasses = computed(() => ({
      'ring-1': state.hasErrorMessages,
      'ring-red-500': state.hasErrorMessages,
    }));

    return {
      input,
      state,
      inputClasses,
      validate,
    };
  },
};
</script>

<style lang="scss" scoped>
.sky-switch {
  @apply block;

  &--disabled {
    @apply cursor-not-allowed opacity-40 pointer-events-none;
  }

  &__label {
    @apply block text-sm font-medium text-primary;
  }

  &__container {
    @apply rounded-full h-6 my-2 w-12 p-1 cursor-pointer;

    &:focus {
      @apply outline-none;
    }
  }

  &__wrapper {
    @apply overflow-hidden w-full;
  }

  &__toggle {
    @apply relative h-4 w-4 bg-white rounded-full left-0 transition-all;

    &--on {
      @apply left-6;
    }

    @media screen and (prefers-reduced-motion) {
      @apply transition-none;
    }
  }

  &:active &__toggle {
    @apply w-6;

    &--on {
      @apply left-4;
    }
  }
}
</style>
