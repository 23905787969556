import { ImmutableCollection } from '@/utils/collections/immutable-collection.js';
import { ProbeEventsMonth } from '@/modules/stats/availability/domain/probe-event/collection/probe-events-month.js';
import { UnavailableStatus } from '@/modules/stats/availability/domain/probe-event/status/unavailable-status.js';
import { ProbeEventsDay } from '@/modules/stats/availability/domain/probe-event/collection/probe-events-day.js';
import { ProbeEventsWeek } from '@/modules/stats/availability/domain/probe-event/collection/probe-events-week.js';
import { ProbeEventsDayStateFuture } from '@/modules/stats/availability/domain/probe-event/collection/day-states/probe-events-day-state-future.js';
import { AvailableStatus } from '@/modules/stats/availability/domain/probe-event/status/available-status.js';
import { MaintenanceStatus } from '@/modules/stats/availability/domain/probe-event/status/maintenance-status.js';

/**
 * @extends {ImmutableCollection<ProbeEventsMonth, ProbeEvent>}
 */
export class ProbeEvents extends ImmutableCollection {
  /**
   * @public
   */
  static MAXIMUM_MONTHS = 3;

  innerTypeOf() {
    return ProbeEventsMonth;
  }

  /**
   * @param {ProbeEvent[]} probeEvents
   */
  static fromEvents(probeEvents) {
    return new this().addEvents(probeEvents);
  }

  /**
   * @param {DateProvider} dateProvider
   * @returns {ProbeEvents}
   */
  fillEventsForMonths(dateProvider, months = ProbeEvents.MAXIMUM_MONTHS) {
    return ProbeEvents.createHistoryForMonths(dateProvider, months, this.toEventsCollection());
  }

  /**
   * @param {DateProvider} dateProvider
   * @param {ImmutableCollection<ProbeEvent>} [events=new ImmutableCollection()]
   * @returns {ProbeEvents}
   */
  static createHistoryForMonths(dateProvider, months = this.MAXIMUM_MONTHS, events = new ImmutableCollection()) {
    const eventsDays = this._createHistoryDaysForMonths(dateProvider, events, months);
    const eventsWeeks = this._createHistoryWeeksFromEventsDays(eventsDays);
    const eventsMonths = this._createHistoryMonthsFromEventsWeeks(eventsWeeks);

    return new this(...eventsMonths.takeTail(months));
  }

  /**
   * @param {ImmutableCollection<ProbeEventsWeek>} eventsWeeks
   * @private
   */
  static _createHistoryMonthsFromEventsWeeks(eventsWeeks) {
    return eventsWeeks.reduce(
      (accumulator, eventWeek) =>
        accumulator.replaceOrCreate(
          (eventMonth) => eventMonth.isEventsWeekInSameMonth(eventWeek),
          (eventMonth) => eventMonth.add(eventWeek),
          () => new ProbeEventsMonth(eventWeek),
        ),
      /** @type {ImmutableCollection<ProbeEventsMonth>} */
      new ImmutableCollection(),
    );
  }

  /**
   * @param {ImmutableCollection<ProbeEventsDay>} eventsDays
   * @private
   */
  static _createHistoryWeeksFromEventsDays(eventsDays) {
    return eventsDays.reduce(
      (accumulator, eventDay) =>
        accumulator.replaceOrCreate(
          (eventWeek) => eventWeek.isEventsDayInSameWeek(eventDay),
          (eventWeek) => eventWeek.add(eventDay),
          () => new ProbeEventsWeek(eventDay),
        ),
      /** @type {ImmutableCollection<ProbeEventsWeek>} */
      new ImmutableCollection(),
    );
  }

  /**
   * @param {DateProvider} dateProvider
   * @param {ImmutableCollection<ProbeEvent>} events
   * @returns {ImmutableCollection<ProbeEventsDay>}
   * @private
   */
  static _createHistoryDaysForMonths(dateProvider, events, months) {
    const currentDate = dateProvider.now();
    const threeMonthsAgo = currentDate.removeMonths(months);
    const eventsDays = threeMonthsAgo.getAllDaysBetween(currentDate).map((day) => {
      const eventsOfTheDay = events.filter((event) => event.lifeTime.isPartOfTheDay(day));

      return new ProbeEventsDay(day, eventsOfTheDay);
    });
    const futureDays = currentDate
      .getAllDaysUntilEndOfMonth()
      .removeFirst()
      .map((day) => new ProbeEventsDay(day, new ImmutableCollection(), new ProbeEventsDayStateFuture()));

    return new ImmutableCollection(...eventsDays, ...futureDays);
  }

  /**
   * @return {DateValueObject | null} null if there is no last event
   */
  get lastAvailableAt() {
    const events = this.toEventsCollection();
    if (events.isEmpty() || this.haveEventsStillOngoing()) {
      return null;
    }

    /** @type {ProbeEvent} */
    const lastEvent = events.last();

    return lastEvent.lifeTime.resolvedAt;
  }

  get status() {
    if (this.haveOnlyMaintenanceEventsOngoing()) {
      return new MaintenanceStatus();
    }

    if (this.haveEventsStillOngoing()) {
      return new UnavailableStatus();
    }

    return new AvailableStatus();
  }

  /**
   * @param {Probes} probes
   * @returns {ProbeName | null}
   */
  getProbeNameFromProbes(probes) {
    const firstProbeEvent = this.toEventsCollection().first();
    const probeFound = probes.find((probe) => probe.hasSameProbe(firstProbeEvent));
    if (!probeFound) {
      return null;
    }

    return probeFound.name;
  }

  haveOnlyMaintenanceEventsOngoing() {
    if (this.toEventsCollection().length === 0) return false;

    return this.toEventsCollection().reduce((acc, event) => (event.lifeTime.isStillOngoing() && event.type === 'MAINTENANCE' ? acc : false), true);
  }

  haveEventsStillOngoing() {
    return this.toEventsCollection()
      .reverse()
      .some((event) => event.lifeTime.isStillOngoing());
  }

  /**
   * @param {ProbeEvent} event
   * @returns {ProbeEvents}
   */
  addEvent(event) {
    return this.replaceOrCreate(
      (month) => month.isEventPartOfTheMonth(event),
      (month) => month.addEvent(event),
      () => ProbeEventsMonth.fromEvents([event]),
    ).sortByMonths();
  }

  /**
   * @param {ProbeEvent[] | ImmutableCollection<ProbeEvent>} events
   * @returns {ProbeEvents}
   */
  addEvents(events) {
    return events.reduce((accumulator, event) => accumulator.addEvent(event), this);
  }

  /**
   * @param {ProbeEvents} probeEvents
   * @returns {ProbeEvents}
   */
  addEventsFromProbeEvents(probeEvents) {
    return this.addEvents(probeEvents.toEventsCollection());
  }

  /**
   * @param {ProbeEvent} event
   */
  hasSameProbe(event) {
    const eventsMonth = this.first();
    /** @type {ProbeEventsWeek | undefined} */
    const eventsWeek = eventsMonth?.first();
    /** @type {ProbeEventsDay | undefined} */
    const eventsDay = eventsWeek?.first();

    return eventsDay.hasSameProbe(event);
  }

  sortByMonths() {
    return this.sort((monthA, monthB) => monthA.compareMonthTo(monthB));
  }

  /**
   * @return {ImmutableCollection<ProbeEvent>}
   */
  toEventsCollection() {
    return this.reduce(
      (accumulator, month) => {
        const probeEvents = month.toEventsCollection();
        const events = probeEvents.filter((event) => !accumulator.some((eventInAccumulator) => eventInAccumulator.isSame(event)));

        return accumulator.add(...events);
      },
      /** @type {ImmutableCollection<ProbeEvent>} */
      new ImmutableCollection(),
    );
  }

  /**
   * @return {ImmutableCollection<ProbeEventsDay>}
   */
  toDaysCollection() {
    return this.reduce((accumulator, month) => accumulator.add(...month.toDaysCollection()), new ImmutableCollection());
  }
}
