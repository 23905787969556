import { MILESTONE_FILTERS, TASK_STATE_TYPE } from '../projects/projects.constants.js';

const ONE_HOUR = 1000 * 60 * 60;
export const SPRINT_DURATION = ONE_HOUR * 24 * 7;

export const FIRST_SPRINT_START_DATE = Object.freeze(new Date('2023-01-23T07:00:00.000Z'));
export const SPRINT_DURATION_V1 = ONE_HOUR * 24 * 14;

export const SPRINT_V2_START_DATE = Object.freeze(new Date('2024-03-11'));
export const SPRINT_DURATION_V2 = ONE_HOUR * 24 * 7;

export const defaultSkyCalendarRange = {
  start: 9,
  end: 18,
};

export const availableSubscriptions = [
  {
    slug: 'gcp',
    name: 'Google Cloud Platform',
  },
  {
    slug: 'scw',
    name: 'Scaleway',
  },
  {
    slug: 'aws',
    name: 'Amazon Web Services',
  },
];

export const defaultsLabels = [
  {
    name: 'Infogérance',
    type: 'category',
    backgroundColor: '#7f96ff',
  },
  {
    name: 'Mission',
    type: 'category',
    backgroundColor: '#eec20c',
    rules: {
      '@pricingTaskUpdate': {
        unitHourlyAmountInCents: 16500,
        unitDailyAmountInCents: 95000,
      },
    },
  },
  {
    name: 'Projet devis',
    type: 'category',
    backgroundColor: '#26dd6f',
  },
  {
    name: 'Interne',
    type: 'category',
    backgroundColor: '#e6726a',
    apps: { $nin: ['skyp', 'sky', 'skym', 'skyd', 'skys', 'skyv'] },
  },
  {
    name: 'Dév.',
    type: 'theme',
    dependsOn: ['Mission', 'Projet devis', 'Interne'],
    backgroundColor: '#98a2d2',
  },
  {
    name: 'ScaleOps',
    type: 'theme',
    backgroundColor: '#98a2d2',
    dependsOn: ['Projet devis', 'Interne'],
    apps: { $nin: ['skys', 'skyp'] },
  },
  {
    name: 'Build',
    type: 'theme',
    backgroundColor: '#98a2d2',
    dependsOn: ['Mission', 'Projet devis'],
    apps: { $nin: ['skyp'] },
  },
  {
    name: 'Run',
    type: 'theme',
    backgroundColor: '#98a2d2',
    dependsOn: ['Mission', 'Projet devis', 'Interne', 'Infogérance'],
    apps: { $in: ['skyp', 'skym', 'skyd', 'skys', 'skyv'] },
  },
  {
    name: 'Infogérance',
    type: 'theme',
    dependsOn: ['Infogérance', 'Interne'],
    backgroundColor: '#98a2d2',
  },
  {
    name: 'Pilotage',
    type: 'theme',
    dependsOn: ['Mission', 'Projet devis', 'Interne'],
    backgroundColor: '#98a2d2',
  },
  {
    name: 'Formation',
    type: 'theme',
    apps: { $nin: ['sky'] },
    dependsOn: ['Mission', 'Projet devis', 'Interne'],
    backgroundColor: '#98a2d2',
  },
  {
    name: 'Sales',
    type: 'theme',
    backgroundColor: '#98a2d2',
    dependsOn: ['Projet devis', 'Interne'],
    apps: { $nin: ['skyv'] },
  },
  {
    name: 'Marketing',
    type: 'theme',
    backgroundColor: '#98a2d2',
    dependsOn: ['Interne'],
    apps: { $nin: ['skym', 'skyv'] },
  },
  {
    name: 'Graphisme',
    type: 'theme',
    backgroundColor: '#98a2d2',
    dependsOn: ['Interne'],
    apps: { $nin: ['skym'] },
  },
  {
    name: 'RH',
    type: 'theme',
    backgroundColor: '#98a2d2',
    dependsOn: ['Interne'],
    apps: { $nin: ['skym'] },
  },
  {
    name: 'Conformité & Sécurité',
    type: 'theme',
    backgroundColor: '#98a2d2',
    dependsOn: ['Interne'],
    apps: { $nin: ['sky', 'skys', 'skyv'] },
  },
  {
    name: 'À compléter',
    type: 'tag',
    backgroundColor: '#98a2d2',
  },
  {
    name: 'UpSell',
    type: 'tag',
    dependsOn: ['Run', 'Infogérance', 'Dév.', 'Sales'],
    backgroundColor: '#98a2d2',
  },
  {
    name: 'Deploy cluster public',
    type: 'tag',
    backgroundColor: '#98a2d2',
    dependsOn: ['Run', 'Infogérance', 'Dév.'],
    apps: { $in: ['skyp', 'sky', 'skym', 'skyd', 'skys', 'skyv'] },
  },
  {
    name: 'TMI cluster public',
    type: 'tag',
    backgroundColor: '#98a2d2',
    dependsOn: ['Run', 'Infogérance', 'Dév.'],
    apps: { $in: ['skyp', 'sky', 'skym', 'skyd', 'skys', 'skyv'] },
  },
  {
    name: 'TMI',
    type: 'tag',
    backgroundColor: '#98a2d2',
    dependsOn: ['Run', 'Dév.'],
    apps: { $in: ['skyp', 'sky', 'skym', 'skyd', 'skys', 'skyv'] },
  },
  {
    name: 'TMA',
    type: 'tag',
    backgroundColor: '#98a2d2',
    dependsOn: ['Run', 'Dév.'],
    apps: { $in: ['skyp', 'sky', 'skym', 'skyd', 'skys', 'skyv'] },
  },
];

export const skyloudProjectsAdminEmails = ['pierre-olivier@skyloud.fr', 'julien@skyloud.fr', 'etienne@skyloud.fr', 'dimitri@skyloud.fr', 'lucas@skyloud.fr'];

export const defaultBacklogColumns = [
  { text: 'ID', value: 'taskId', sortable: true, width: 80 },
  { text: 'Name', value: 'name', sortable: true },
  { text: 'Sprint', value: 'milestoneIds', width: 90 },
  { text: 'Creation', value: 'createdAt', sortable: true, width: 65 },
  { text: 'Priority', value: 'priority', sortable: true, width: 60 },
  { text: 'Category', value: 'type', width: 80 },
  { text: 'Theme', value: 'pole', width: 60 },
  { text: 'Tag', value: 'tag', width: 90 },
  { text: 'State', value: 'state', width: 75 },
  { text: '% Progress.', value: 'progress', sortable: true, width: 115 },
  { text: 'Est.', value: 'estimatedTime', sortable: true, width: 40 },
  { text: 'Time left', value: 'ellapsedTime', sortable: true, width: 150 },
  { text: 'Who', value: 'memberIds', width: 45 },
  { text: 'TO-DO', value: 'todo', width: 75 },
];

const notCompletedTaskState = Object.values(TASK_STATE_TYPE).filter((state) => state !== TASK_STATE_TYPE.completed);
export const defaultBacklogFilters = {
  deletedAt: false,
  state: notCompletedTaskState,
  milestones: [MILESTONE_FILTERS.current_sprint],
};

export const statsFiltersOptions = [
  { name: 'Totaux temps par phase', value: 'total_phase_time' },
  { name: 'Totaux facturation “au réel”', value: 'total_actual_billing' },
  { name: 'Totaux facturation “au forfait”', value: 'total_contract_billing' },
  { name: 'Total des temps par personnes', value: 'total_each_person_time' },
  { name: 'Total des dépassements “au réel” à la charge du client', value: 'total_actual_overtaking_client' },
  { name: 'Total des dépassements à notre charge', value: 'total_overtaking_skyloud' },
];

export const STATS_PHASE_TAGS = {
  project: 'Gestion de projet',
  design: 'Phase Design',
  build: 'Phase Build',
  deploy: 'Phase Deploy',
};
