<template>
  <div
    class="task-worklog-container"
    :class="{
      'task-worklog-container__not-invoicable': !isInvoicable,
    }"
  >
    <div class="header">
      <SkyTooltip
        v-if="!isInvoicable"
        variant="white-smoke"
        class="mr-1 inline-block"
      >
        <FontAwesomeIcon
          :icon="faInfoCircle"
          class="text-primary cursor-pointer"
        />
        <template #content>
          <div class="flex flex-col items-center justify-center">
            <span>{{ $t('components.projects.taskWorklog.notInvoicable.worklog') }}</span>
            <span v-if="!task?.invoicedAt">{{ $t('components.projects.taskWorklog.notInvoicable.estimation') }}</span>
          </div>
        </template>
      </SkyTooltip>
      <User :user-id="workLog.userId">
        <template #default="{ user }">
          <div class="user">
            <span
              v-if="!user || !user.picture"
              class="relative flex items-center justify-center text-xs text-white rounded-full w-7 h-7 bg-primary"
            >
              <FontAwesomeIcon :icon="faUser" />
            </span>
            <img
              v-else
              :src="user.picture"
              alt=""
              class="rounded-full w-7 h-7"
            />

            <p v-if="user">{{ user.firstName }}</p>
            <p
              v-else
              class="text-xs whitespace-nowrap"
            >
              Deleted User
            </p>
          </div>
        </template>
      </User>
      <div class="time-and-date-container">
        <div>
          <FontAwesomeIcon
            :icon="faClock"
            class="mr-1 text-primary"
          />
          <p>{{ formatDurationFromMs(timeSpentInMs, timeSpentInMs < 60000 ? 's' : 'm', 'h') }}</p>
        </div>
        <div>
          <FontAwesomeIcon
            :icon="faCalendarAlt"
            class="mr-1 text-primary"
          />
          <p>{{ formatDate(workLog.startedAt, 'DD/MM/YYYY') }}</p>
        </div>
      </div>

      <div
        v-if="isAdminSkyloudGranted"
        class="actions-container"
      >
        <button
          v-if="workLog.stoppedAt && workLog.note && !formWorkLog.open"
          type="button"
          class="outline-none appearance-none focus:outline-none"
          @click="isDisplayNote = !isDisplayNote"
        >
          <FontAwesomeIcon
            :icon="faChevronDown"
            class="flex transition-transform duration-200 transform"
            :class="{
              'rotate-180': isDisplayNote,
            }"
          />
        </button>
        <FontAwesomeIcon
          v-if="workLog.pausedAt"
          :icon="faPause"
        />
        <div
          v-if="!workLog.stoppedAt && !workLog.pausedAt"
          class="active-worklog-led"
        >
          <span />
          <span />
        </div>
      </div>
    </div>

    <div
      v-if="isAdminSkyloudGranted"
      class="worklog-details"
    >
      <SkyTooltip
        v-if="workLog.type === 'quick'"
        class="mr-1 inline-block"
      >
        <FontAwesomeIcon
          :icon="faFlag"
          class="text-primary"
        />
        <template #content><span>Quick worklog</span></template>
      </SkyTooltip>
      <FontAwesomeIcon :icon="faShield" />
      {{
        $t('components.projects.taskWorklog.duration', {
          start: formatDate(workLog.startedAt, 'HH:mm'),
          end: formatDate(workLog.stoppedAt || workLog.pausedAt || new Date(), 'HH:mm'),
        })
      }}
      <span v-if="workLog.pauseTimeInMs">
        {{
          $t('components.projects.taskWorklog.pause', {
            value: formatDurationFromMs(workLog.pauseTimeInMs),
          })
        }}
      </span>
      <span>
        =>
        {{ formatDurationFromMs(realEllapsedTimeInMs, realEllapsedTimeInMs < 60000 ? 's' : 'm', 'h') }}
      </span>
      <button
        v-if="isAdminSkyloudProjectGranted"
        type="button"
        class="ml-1"
        @click="formWorkLog.open = !formWorkLog.open"
      >
        <FontAwesomeIcon
          :icon="formWorkLog.open ? faCircleXmark : faPencil"
          class="text-blue-700"
        />
      </button>
    </div>

    <SkyForm
      v-if="formWorkLog.open"
      id="edit-task-modal-add-worklog"
      class="edit-task-modal-add-worklog"
      @valid="handleUpdateWorkLog"
    >
      <SkyInputText
        v-model="formWorkLog.form.note"
        :rules="formWorkLog.rules.note"
        :disabled="formWorkLog.loading"
        :placeholder="$t('components.projects.taskWorklog.inputs.note.placeholder')"
        :label="$t('components.projects.taskWorklog.inputs.note.label')"
        autofocus
      />
      <SkyDatePicker
        v-model="formWorkLog.form.startedAt"
        :rules="formWorkLog.rules.startedAt"
        :disabled="formWorkLog.loading"
        type="datetime-local"
        :label="$t('components.projects.taskWorklog.inputs.startedAt.label')"
      />
      <SkyDatePicker
        v-model="formWorkLog.form.stoppedAt"
        :rules="formWorkLog.rules.stoppedAt"
        :disabled="formWorkLog.loading"
        type="datetime-local"
        :label="$t('components.projects.taskWorklog.inputs.stoppedAt.label')"
      />
      <span class="text-primary font-medium">{{ $t('components.projects.editTaskModal.inputs.worklogs.pauseTime.label') }}</span>
      <div class="flex flex-row justify-between gap-2 mt-1">
        <SkyInputText
          v-model="formWorkLog.form.pauseTimeInHours"
          class="w-full"
          :helper-text="$t('components.projects.taskSettingForm.estimation.inputs.hours')"
          step="1"
          type="number"
          min="0"
          @keydown="handleDecimalValueInput"
        />
        <SkyInputText
          v-model="formWorkLog.form.pauseTimeInMinutes"
          class="w-full"
          :helper-text="$t('components.projects.taskSettingForm.estimation.inputs.minutes')"
          step="1"
          type="number"
          min="0"
          @keydown="handleDecimalValueInput"
        />
      </div>
      <div class="flex text-primary">
        <button
          type="button"
          class="flex-1 border rounded-l border-primary hover:text-white hover:bg-primary"
          @click="handleFormWorkLogDay(-1)"
        >
          <FontAwesomeIcon :icon="faLongArrowLeft" />
        </button>
        <button
          type="button"
          class="flex-1 border border-l-0 rounded-r border-primary hover:text-white hover:bg-primary"
          @click="handleFormWorkLogDay(+1)"
        >
          <FontAwesomeIcon :icon="faLongArrowRight" />
        </button>
      </div>
      <span
        class="flex p-1 text-sm font-light text-gray-500"
        :class="{
          invisible: !isPauseIncluded,
          visible: isPauseIncluded,
        }"
      >
        <FontAwesomeIcon
          :icon="faInfoCircle"
          class="mr-1"
        />
        <p>{{ $t('components.projects.taskWorklog.pauseIncluded') }}</p>
      </span>
      <SkyButton
        form="edit-task-modal-add-worklog"
        size="small"
        type="submit"
        :loading="formWorkLog.loading"
      >
        {{ $t('components.projects.taskWorklog.saveButton') }}
      </SkyButton>
      <SkyButtonConfirm
        size="small"
        :loading="deleteLoading"
        @confirm="handleDeleteWorkLog"
      >
        <FontAwesomeIcon
          :icon="faTrashAlt"
          class="mr-1"
        />
        {{ $t('components.projects.taskWorklog.deleteButton') }}
      </SkyButtonConfirm>
    </SkyForm>

    <div
      v-show="isDisplayNote && !formWorkLog.open"
      class="p-3 bg-gray-100 rounded"
    >
      {{ workLog.note }}
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import User from '@/modules/users/components/User.vue';
import { durationPartsFromMs, formatDate, formatDurationFromMs, sumDurationToMilliseconds, roundEllapsedTime } from '@/utils/format.js';
import { getIsAfter, getIsModulo, getIsRequiredRule, getIsTimeDiffInHoursLessThanRule } from '@/modules/shared/utils/formValidationRules.js';
import { faUser, faClock, faCalendarAlt, faChevronDown, faPause, faPencil, faLongArrowLeft, faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faShield, faInfoCircle, faTrashAlt, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import get from 'lodash/get';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { handleDecimalValueInput } from '../projects.functions.js';
import { TASK_ELLAPSED_TIME_STEP_IN_MS } from '../projects.constants.js';

export default {
  components: {
    User,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    workLog: {
      type: Object,
      default: () => undefined,
    },
    isInvoicable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['delete', 'update'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();

    const hasRole = store.getters['auth/hasRole'];

    const state = reactive({
      isDisplayNote: false,
      deleteLoading: false,
      currentUser: computed(() => store.getters['auth/currentUser']),
      isAdminSkyloudGranted: computed(() => hasRole('admin:skyloud')),
      isAdminSkyloudProjectGranted: computed(() => hasRole('admin:skyloud:project')),
      diffInMinutes: computed(() => dayjs(state.formWorkLog.form.stoppedAt).diff(state.formWorkLog.form.startedAt, 'minutes')),
      isPauseIncluded: computed(() => dayjs(state.formWorkLog.form.stoppedAt).toDate() - dayjs(state.formWorkLog.form.startedAt).toDate() >= 1000 * 60 * 60 * 7),
      realEllapsedTimeInMs: computed(() => {
        const startedAt = get(props.workLog, 'startedAt', 0) ? new Date(get(props.workLog, 'startedAt', 0)).getTime() : new Date().getTime();
        const stoppedAt = get(props.workLog, 'stoppedAt') ? new Date(get(props.workLog, 'stoppedAt')).getTime() : new Date().getTime();
        const pauseTimeInMs = get(props.workLog, 'pausedAt')
          ? new Date().getTime() - new Date(get(props.workLog, 'pausedAt')).getTime() + get(props.workLog, 'pauseTimeInMs', 0)
          : get(props.workLog, 'pauseTimeInMs', 0);

        return stoppedAt - startedAt - pauseTimeInMs;
      }),
      timeSpentInMs: computed(() => roundEllapsedTime(state.realEllapsedTimeInMs, TASK_ELLAPSED_TIME_STEP_IN_MS)),
      formattedPauseTimeInMs: computed(() => durationPartsFromMs(state.formWorkLog.form.pauseTimeInMs, 'm', 'h', true)),
      formWorkLog: {
        loading: false,
        open: false,
        form: {
          note: props.workLog.note,
          pauseTimeInMs: props.workLog.pauseTimeInMs,
          pauseTimeInHours: computed({
            get() {
              return get(state.formattedPauseTimeInMs, 'h', 0);
            },
            set(value) {
              state.formWorkLog.form.pauseTimeInMs = sumDurationToMilliseconds(0, value, state.formWorkLog.form.pauseTimeInMinutes);
            },
          }),
          pauseTimeInMinutes: computed({
            get() {
              return get(state.formattedPauseTimeInMs, 'm', 0);
            },
            set(value) {
              state.formWorkLog.form.pauseTimeInMs = sumDurationToMilliseconds(0, state.formWorkLog.form.pauseTimeInHours, value);
            },
          }),
          startedAt: dayjs(props.workLog.startedAt).format('YYYY-MM-DD[T]HH:mm'),
          stoppedAt: dayjs(props.workLog.stoppedAt).format('YYYY-MM-DD[T]HH:mm'),
        },
        rules: {
          note: [],
          timeStep: [getIsModulo(1, t('components.projects.editTaskModal.rules.timeStep'))],
          startedAt: [getIsRequiredRule()],
          stoppedAt: [getIsRequiredRule(), getIsAfter(() => state.formWorkLog.form.startedAt), getIsTimeDiffInHoursLessThanRule(() => state.formWorkLog.form.startedAt)],
        },
      },
    });

    function handleDeleteWorkLog() {
      state.deleteLoading = true;
      emit('delete', {
        workLogId: props.workLog._id,
      });
    }

    function handleUpdateWorkLog() {
      state.formWorkLog.open = false;
      emit('update', {
        workLogId: props.workLog._id,
        pauseTimeInMs: state.shouldUseDefaultPause ? 3600000 : state.formWorkLog.form.pauseTimeInMs,
        startedAt: state.formWorkLog.form.startedAt,
        stoppedAt: state.formWorkLog.form.stoppedAt,
        note: state.formWorkLog.form.note,
      });
    }

    function handleFormWorkLogDay(duration) {
      const startedAt = dayjs(state.formWorkLog.form.startedAt).add(duration, 'days');

      state.formWorkLog.form.startedAt = startedAt.set('hours', 9).set('minutes', 0).format('YYYY-MM-DD[T]HH:mm');

      state.formWorkLog.form.stoppedAt = startedAt.set('hours', 18).set('minutes', 0).format('YYYY-MM-DD[T]HH:mm');
    }

    return {
      ...toRefs(state),
      formatDate,
      formatDurationFromMs,
      handleDeleteWorkLog,
      handleUpdateWorkLog,
      handleFormWorkLogDay,
      handleDecimalValueInput,
      faUser,
      faClock,
      faCalendarAlt,
      faChevronDown,
      faPause,
      faPencil,
      faLongArrowLeft,
      faLongArrowRight,
      faShield,
      faCircleXmark,
      faInfoCircle,
      faTrashAlt,
      faFlag,
    };
  },
};
</script>

<style lang="scss">
.task-worklog-container {
  @apply flex flex-col gap-2 p-2 text-xs bg-white border-2 rounded border-dark-blue-50;

  &__not-invoicable {
    @apply opacity-50;
  }

  .header {
    @apply flex justify-end items-center;

    .user {
      @apply flex items-center gap-1 text-sm font-semibold text-primary;
    }

    .time-and-date-container {
      @apply flex ml-auto w-40 justify-end;

      > *:first-child {
        @apply flex;
      }

      > *:nth-child(2) {
        @apply flex ml-2;
      }
    }

    .actions-container {
      @apply flex ml-2 w-3;
      margin-right: 3px;

      .active-worklog-led {
        @apply relative flex w-3 h-3;

        > * {
          @apply absolute w-full h-full bg-green-500 rounded-full;

          &:last-child {
            @apply opacity-75 animate-ping;
          }
        }
      }
    }
  }

  .worklog-details {
    @apply text-gray-800;
  }

  .edit-task-modal-add-worklog {
    @apply flex flex-col gap-2;
  }
}
</style>
