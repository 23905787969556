<template>
  <div>
    <div class="flex flex-row items-center justify-between">
      <span
        v-if="worklogs.length > 1"
        class="font-semibold text-xs"
      >
        {{ $t('components.projects.runningWorklogs.multiple') }}
      </span>
      <span
        v-else
        class="font-semibold text-xs"
      >
        {{ $t('components.projects.runningWorklogs.one') }}
      </span>
      <span
        class="border-gray-400 border-[1.5px] flex h-8 justify-center items-center cursor-pointer text-sm w-9 text-gray-400 font-semibold outline-none focus:outline-none hover:text-primary hover:border-primary rounded transition-colors"
        @click="$emit('close')"
      >
        <FontAwesomeIcon :icon="faTimesCircle" />
      </span>
    </div>

    <SkyForm
      v-if="!loading"
      class="flex flex-col mt-4 gap-2 text-sm"
      @valid="handleStopWorklogs"
    >
      <div
        v-for="{ _id: workLogId, taskId } in worklogs"
        :key="workLogId"
        class="space-y-2"
      >
        <div class="flex flex-row items-center gap-2">
          <span class="bg-gray-200 text-gray-500 font-bold rounded px-1 whitespace-nowrap">{{ getLocalTask(taskId)?.taskId }}</span>
          <span class="line-clamp-1">{{ getLocalTask(taskId)?.name }}</span>
        </div>
        <SkyInputText
          v-model="form[workLogId]"
          :rules="[getIsRequiredRule()]"
          placeholder="Enter worklog note here..."
          size="small"
        />
      </div>

      <SkyButton
        size="tiny"
        type="submit"
      >
        Valider
      </SkyButton>
    </SkyForm>
    <div v-else>
      <SkyLoading />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import compact from 'lodash/compact.js';
import { useStore } from 'vuex';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { getIsRequiredRule } from '@/modules/shared/utils/formValidationRules.js';
import { useNotify } from '@/plugins/notify/index.js';

const emit = defineEmits(['close', 'validate']);
const props = defineProps({
  worklogs: {
    type: Array,
    default: () => [],
  },
});

const store = useStore();
const notify = useNotify();

const getTask = store.getters['projects/getTask'];
const tasks = computed(() => store.getters['projects/tasks']);
const form = ref({});
const localTasks = ref([]);
const loading = ref(false);

function getLocalTask(taskId) {
  return localTasks.value.find(({ _id }) => _id === taskId);
}

async function handleStopWorklogs() {
  try {
    loading.value = true;
    const worklogsQueries = Object.keys(form.value).map(async (worklogId) => {
      const worklog = props.worklogs.find((w) => w._id === worklogId);
      await store.dispatch('projects/patchStopWorklog', {
        taskId: worklog.taskId,
        appId: worklog.appId,
        worklogId,
        note: form.value[worklogId],
      });
    });

    await Promise.allSettled(worklogsQueries);
    form.value = {};
    notify.success('Chronos stoppés avec succès');
    emit('validate');
  } catch (err) {
    notify.error("Une erreur est survenu lors de l'arret d'un chrono");
  }
  loading.value = false;
}

onMounted(async () => {
  try {
    loading.value = true;
    const fetchTasks = props.worklogs.map(async ({ taskId }) => {
      if (getTask({ taskId }) || getLocalTask(taskId)) return undefined;
      return await store.dispatch('projects/adminFetchTask', { taskId });
    });

    const results = await Promise.allSettled(compact(fetchTasks));
    localTasks.value = results.reduce(
      (acc, promiseRes) => {
        if (promiseRes.status !== 'fulfilled') return acc;
        return [...acc, promiseRes.value];
      },
      [...tasks.value],
    );
  } catch (err) {
    notify.error('Impossible de récuperer le ticket');
  }

  loading.value = false;
});
</script>
