<template>
  <div class="message-container">
    <div
      class="message"
      :class="{
        [`message--is-action-${message.action}`]: message.kind === 'system' && message.action,
      }"
    >
      <span class="message__status">
        <FontAwesomeIcon
          v-if="['estimatedTimeAccepted', 'taskValidationAccepted'].includes(message.action)"
          :icon="faCheck"
        />
        <FontAwesomeIcon
          v-else-if="['estimatedTimeRefused', 'taskValidationRefused'].includes(message.action)"
          :icon="faTimes"
        />
        <FontAwesomeIcon
          v-else-if="message.action === 'estimatedTimeRestored'"
          :icon="faArrowRotateLeft"
        />
        <FontAwesomeIcon
          v-else-if="message.action === 'pendingAtOn'"
          :icon="faPause"
        />
        <FontAwesomeIcon
          v-else-if="message.action === 'pendingAtOff'"
          :icon="faPlay"
        />
        <FontAwesomeIcon
          v-else-if="message.action === 'deletedAt'"
          :icon="faBoxArchive"
        />
        <FontAwesomeIcon
          v-else-if="['memeberIdAdd', 'memberIdRemove', 'userId'].includes(message.action)"
          :icon="faUser"
        />
        <FontAwesomeIcon
          v-else
          :icon="faInfo"
        />
      </span>
      <p
        class="message__body"
        v-html="parse(message.text || '')"
      />
    </div>
    <span class="message-date">- {{ formatDate(message.createdAt, 'HH:mm') }}</span>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import includes from 'lodash/includes';
import { faCheck, faTimes, faArrowRotateLeft } from '@fortawesome/pro-light-svg-icons';
import { faPause, faPlay, faBoxArchive, faUser, faInfo } from '@fortawesome/pro-solid-svg-icons';
import { formatDate } from '@/utils/format.js';
import { parse } from 'marked';

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const getUser = store.getters['users/getUser'];

    const state = reactive({
      currentUser: computed(() => getUser({ userId: props.message.authorId })),
    });

    function isAdminSkyloud({ roles }) {
      if (!roles) return false;

      return includes(roles, 'admin:skyloud', 0);
    }

    return {
      ...toRefs(state),
      isAdminSkyloud,
      formatDate,
      parse,
      faCheck,
      faTimes,
      faArrowRotateLeft,
      faInfo,
      faPause,
      faPlay,
      faUser,
      faBoxArchive,
    };
  },
};
</script>

<style lang="scss" scoped>
$status-size: 2rem;

.message-container {
  @apply flex flex-row items-center gap-2;

  .message {
    @apply relative pl-4 pr-3 py-3 rounded-md ml-3 flex flex-row gap-2 justify-between items-center flex-wrap;
    background-color: #f5f5f5;

    .message__status {
      @apply absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-1/2 rounded text-xs text-white h-5 w-5 flex justify-center items-center;
      background-color: #edb828;
    }

    &--is-action-pendingAtOff,
    &--is-action-estimatedTimeAccepted,
    &--is-action-taskValidationAccepted {
      .message__status {
        @apply bg-success;
      }
    }

    &--is-action-estimationOverflow,
    &--is-action-priority,
    &--is-action-userId,
    &--is-action-labelAdd,
    &--is-action-labelRemove,
    &--is-action-milestoneAdd,
    &--is-action-milestoneRemove,
    &--is-action-kind,
    &--is-action-name,
    &--is-action-description,
    &--is-action-listId,
    &--is-action-memberIdAdd,
    &--is-action-dueAt,
    &--is-action-memberIdRemove,
    &--is-action-estimatedTimeRestored {
      .message__status {
        background-color: #babff8;
      }
    }

    &--is-action-deletedAt,
    &--is-action-pendingAtOn,
    &--is-action-estimatedTimeRefused,
    &--is-action-taskValidationRefused {
      .message__status {
        @apply bg-error;
      }
    }

    .message__body {
      @apply text-xs flex items-center flex-wrap;
    }
  }

  .message-date {
    @apply text-xs italic text-gray-500 invisible whitespace-nowrap;
  }

  &:hover .message-date {
    @apply visible;
  }
}
</style>
