import { ImmutableCollection } from '@/utils/collections/immutable-collection.js';
import { ProbeEventsWeek } from '@/modules/stats/availability/domain/probe-event/collection/probe-events-week.js';

/**
 * @extends {ImmutableCollection<ProbeEventsWeek, ProbeEvent>}
 */
export class ProbeEventsMonth extends ImmutableCollection {
  innerTypeOf() {
    return ProbeEventsWeek;
  }

  /**
   * @param {ProbeEvent[]} probeEvents
   */
  static fromEvents(probeEvents) {
    return new this().addEvents(probeEvents);
  }

  /**
   * @param {ProbeEvent} event
   * @returns {boolean}
   */
  isEventPartOfTheMonth(event) {
    return this.getFirstEventsDay().isEventPartOfTheMonth(event);
  }

  /**
   * @param {ProbeEvent} event
   * @returns {ProbeEventsMonth}
   */
  addEvent(event) {
    return this.replaceOrCreate(
      (week) => week.isEventPartOfTheWeek(event),
      (week) => week.addEvent(event),
      () => ProbeEventsWeek.fromEvents([event]),
    ).sortByWeeks();
  }

  /**
   * @param {ProbeEvent[]} events
   * @returns {ProbeEventsMonth}
   */
  addEvents(events) {
    return events.reduce((month, event) => month.addEvent(event), this);
  }

  sortByWeeks() {
    return this.sort((weekA, weekB) => weekA.compareEventsWeekTo(weekB));
  }

  /**
   * @returns {string}
   */
  get currentMonthLabel() {
    const eventsWeek = this.first();
    /** @type {ProbeEventsDay} */
    const eventsDay = eventsWeek.first();

    return eventsDay.currentMonthLabel;
  }

  /**
   * @param {ProbeEventsWeek} eventsWeek
   * @returns {boolean}
   */
  isEventsWeekInSameMonth(eventsWeek) {
    const eventsDay = this.first()?.first();
    const eventsDayFromWeek = eventsWeek.first();

    return !!eventsDayFromWeek && eventsDay?.isEventsDayInSameMonth(eventsDayFromWeek);
  }

  /**
   * @returns {ProbeEventsDay | undefined}
   */
  getFirstEventsDay() {
    const week = this.first();

    return week?.first();
  }

  /**
   * @param {ProbeEventsMonth} month
   * @return {0|1|-1}
   */
  compareMonthTo(month) {
    const firstEventsDay = this.getFirstEventsDay();
    const firstEventsDayFromMonth = month.getFirstEventsDay();

    return firstEventsDay.compareEventsDayTo(firstEventsDayFromMonth);
  }

  /**
   * @return {ImmutableCollection<ProbeEvent>}
   */
  toEventsCollection() {
    return this.reduce((accumulator, week) => {
      const probeEvents = week.toEventsCollection();
      const events = probeEvents.filter((event) => !accumulator.some((eventInAccumulator) => eventInAccumulator.isSame(event)));

      return accumulator.add(...events);
    }, new ImmutableCollection());
  }

  /**
   * @return {ImmutableCollection<ProbeEventsDay>}
   */
  toDaysCollection() {
    return this.reduce((accumulator, week) => accumulator.add(...week.toDaysCollection()), new ImmutableCollection());
  }
}
