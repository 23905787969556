<template>
  <label class="block">
    <div
      v-if="label"
      class="block mb-2 text-sm font-medium text-primary"
      :class="{ required: state.isRequired, 'sr-only': !showLabel }"
    >
      {{ label }}
    </div>
    <textarea
      ref="input"
      v-model="state.internalValue"
      style="transition: all 0.15s ease 0s"
      class="block w-full px-3 py-3 text-sm placeholder-gray-300 bg-white border rounded border-dark-blue-100 focus:outline-none focus:shadow-outline"
      :rows="rows"
      :class="inputClasses"
      :placeholder="placeholder"
      :required="state.isRequired"
      :readonly="readOnly"
    />
    <span
      class="flex items-center h-4 mt-1 ml-1 text-xs font-medium tracking-wide text-red-500"
      :class="{ hidden: !state.hasErrorMessages }"
    >
      {{ state.firstErrorMessage }}
    </span>
  </label>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import useValidatable from '@/modules/shared/utils/validatable.js';

export default {
  props: {
    modelValue: { type: String, default: () => undefined },
    label: { type: String, default: () => undefined },
    placeholder: { type: String, default: () => undefined },
    autofocus: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    rows: { type: String, default: () => '3' },
    showLabel: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const input = ref(null);

    const { state, validate } = useValidatable({ props, emit });

    function tryAutofocus() {
      if (typeof document === 'undefined' || !input.value || document.activeElement === input.value) return false;

      input.value.focus();

      return true;
    }

    onMounted(() => {
      if (props.autofocus) {
        tryAutofocus();
      }
    });

    const inputClasses = computed(() => ({
      'ring-1': state.hasErrorMessages,
      'ring-red-500': state.hasErrorMessages,
    }));

    return {
      input,
      state,
      inputClasses,
      validate,
    };
  },
};
</script>

<style lang="scss" scoped>
.required::after {
  @apply content-asterisk ml-1 text-red-600 font-medium;
}
</style>
