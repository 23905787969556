<template>
  <router-link
    ref="root"
    :to="`/projects/tasks/${task.taskId}`"
    class="task-card"
    :class="{
      'opacity-50': hasRole('admin:skyloud') && (isWaitingEstimationValidation || isPending),
    }"
    :data-task-id="task._id"
  >
    <div v-if="hasRole('admin:skyloud') && task.listId !== 'done'">
      <div
        v-if="taskLabels.length < 1"
        class="task-waiting-status"
      >
        <FontAwesomeIcon :icon="faTag" />
        {{ $t('components.projects.taskCard.status.toBeCategorized') }}
      </div>
      <div
        v-else-if="hasMissionLabel && !isTaskHasBeenEstimated"
        class="task-waiting-status"
      >
        <FontAwesomeIcon :icon="faStopwatch" />
        {{ $t('components.projects.taskCard.status.toBeEstimated') }}
      </div>
      <div
        v-else-if="isTaskEstimatedTimeAlreadyRefused && !isPending"
        class="task-waiting-status"
      >
        <FontAwesomeIcon :icon="faStopwatch" />
        {{ $t('components.projects.taskCard.status.estimationRefused') }}
      </div>
      <div
        v-else-if="isTaskEstimatedTimeAlreadyAccepted && isTaskDosNotHaveAnyWorklog && !isPending"
        class="task-waiting-status"
      >
        <FontAwesomeIcon :icon="faStopwatch" />
        {{ $t('components.projects.taskCard.status.estimationAccepted') }}
      </div>
    </div>
    <div v-else-if="hasRole('admin') && task.listId !== 'done'">
      <div
        v-if="isWaitingEstimationValidation"
        class="task-waiting-status"
      >
        <FontAwesomeIcon :icon="faPause" />
        {{ $t('components.projects.taskCard.status.awaitingValidation') }}
      </div>
      <div
        v-else-if="isCustomerPending && task.listId !== 'done'"
        class="task-waiting-status"
      >
        <FontAwesomeIcon :icon="faPause" />
        {{ $t('components.projects.taskCard.status.missingInformation') }}
      </div>
      <div
        v-else-if="isTaskValidationPending && task.listId !== 'done'"
        class="task-waiting-status"
      >
        <FontAwesomeIcon :icon="faPause" />
        {{ $t('components.projects.taskCard.status.taskValidation') }}
      </div>
    </div>
    <div class="flex flex-wrap gap-2 mb-1 text-xs text-white">
      <TaskMilestoneIcon
        v-if="latestTaskMilestone"
        :milestone="latestTaskMilestone"
        :task="task"
      />
      <TaskPriorityIcon :priority="task.priority" />
      <LabelTag
        v-for="label of categoryTaskLabels"
        :key="label._id"
        :label="label"
      />
      <LabelTag
        v-for="label of themeTaskLabels"
        :key="label._id"
        :label="label"
      />
    </div>
    <div class="task-card__body">
      <div class="flex flex-row flex-1">
        <div class="flex-1 leading-3">
          <span class="mr-1 text-xs font-bold text-gray-300">
            {{ task.taskId }}
          </span>
          <span class="text-sm text-paragraph">{{ task.name }}</span>
        </div>
      </div>
      <div v-show="task.workLogs.length > 0">
        <div
          v-for="userId of taskUsers"
          :key="userId"
          class="relative flex float-right"
        >
          <User :user-id="userId">
            <template #default="{ user }">
              <span class="relative">
                <div
                  class="overflow-hidden rounded-full"
                  style="outline: 3px solid white"
                >
                  <span
                    v-if="task.workLogs.some((log) => !log.stoppedAt)"
                    class="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-success"
                  />
                  <span
                    v-if="!user || !user.picture"
                    class="relative flex items-center justify-center w-6 h-6 text-xs text-white rounded-full bg-primary"
                  >
                    <FontAwesomeIcon :icon="faUser" />
                  </span>
                  <img
                    v-else
                    class="block w-6 h-6"
                    :src="user.picture"
                    alt="user picture"
                  />
                </div>
              </span>
            </template>
          </User>
        </div>
      </div>
    </div>
    <div class="task-card__footer">
      <div class="task-card__actions">
        <div
          v-if="isTaskHasBeenEstimated"
          class="py-0.5 px-2 rounded whitespace-nowrap font-medium text-white"
          :class="progressColor"
        >
          {{
            task.ellapsedTime && task.ellapsedTime > 0
              ? `${formatDurationFromMs(task.ellapsedTime)} / ${formatDurationFromMs(task.estimatedTime)}`
              : formatDurationFromMs(task.estimatedTime)
          }}
        </div>
        <div
          v-if="isTaskHasBeenEstimated"
          class="relative flex w-full h-2 overflow-hidden rounded-full"
          :class="{
            'bg-gray-200': !isProgressAboveEstimation,
            'bg-warning bg-opacity-25':
              (isTaskEstimatedTimeAlreadyAccepted || isWaitingEstimationValidation || (!hasMissionLabel && progressTotal > 0)) &&
              isProgressAboveEstimation &&
              !isProgressAboveLimitAuthorizedByCgv,
            'bg-error bg-opacity-25': isProgressEqualToLimitAuthorizedByCgv || isProgressAboveLimitAuthorizedByCgv,
          }"
        >
          <div
            :style="{
              width: `${progressNormalPerc}%`,
            }"
            class="absolute top-0 left-0 h-full rounded-full"
            :class="progressColor"
          />
          <div
            :style="{
              width: `${progressOvertakingPerc}%`,
            }"
            class="absolute top-0 left-0 h-full rounded-full bg-error-dark"
          />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import get from 'lodash/get';
import map from 'lodash/map';
import flow from 'lodash/flow';
import find from 'lodash/find';
import fpGet from 'lodash/fp/get';
import fpMap from 'lodash/fp/map';
import fpUniq from 'lodash/fp/uniq';
import fpLast from 'lodash/fp/last';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import toLower from 'lodash/toLower';
import fpSortBy from 'lodash/fp/sortBy';
import fpSampleSize from 'lodash/fp/sampleSize';
import { faUser, faTag, faStopwatch, faPause } from '@fortawesome/pro-light-svg-icons';
import User from '@/modules/users/components/User.vue';
import LabelTag from '@/modules/projects/components/LabelTag.vue';
import { formatShortDate, formatDurationFromMs } from '@/utils/format.js';
import TaskPriorityIcon from '@/modules/projects/components/TaskPriorityIcon.vue';
import TaskMilestoneIcon from '@/modules/projects/components/TaskMilestoneIcon.vue';
import { getTaskLegalMaxEllapsedTimeAllowed, getTaskEllapsedTimeColors } from '@/modules/projects/projects.functions.js';
import last from 'lodash/last';
import { sortBy } from 'lodash';
import { LABEL_TYPE, TASK_PENDING_STATUS } from '../projects.constants.js';

export default {
  components: {
    TaskPriorityIcon,
    TaskMilestoneIcon,
    User,
    LabelTag,
  },
  props: {
    task: {
      type: Object,
      default: () => undefined,
    },
  },
  setup(props) {
    const store = useStore();

    const getLabel = store.getters['projects/getLabel'];
    const getMilestone = store.getters['projects/getMilestone'];
    const hasRole = store.getters['auth/hasRole'];

    const state = reactive({
      currentUser: computed(() => store.getters['auth/currentUser']),
      taskMilestones: computed(() => {
        const milestoneIds = get(props.task, 'milestoneIds', []);
        const milestones = map(milestoneIds, (milestoneId) => getMilestone({ milestoneId }));
        return sortBy(compact(milestones), ['endAt']);
      }),
      latestTaskMilestone: computed(() => last(state.taskMilestones)),
      progressTotal: computed(() => Math.round((props.task.ellapsedTime / props.task.estimatedTime) * 100)),
      progressNormalPerc: computed(() => (state.isProgressAboveEstimation ? state.progressTotal - 100 : state.progressTotal)),
      progressOvertakingPerc: computed(() => props.task.ellapsedTime % props.task.ellapsedTime),
      ellapsedTimeWarningThreshold: computed(() => props.task.estimatedTime),
      ellapsedTimeErrorThreshold: computed(() => getTaskLegalMaxEllapsedTimeAllowed(props.task.estimatedTime)),
      taskLabels: computed(() => {
        const labels = map(props.task.labelIds, (labelId) => getLabel({ labelId }));
        return compact(labels);
      }),
      categoryTaskLabels: computed(() => {
        return state.taskLabels.filter((label) => label.type === LABEL_TYPE.category);
      }),
      themeTaskLabels: computed(() => {
        return state.taskLabels.filter((label) => label.type === LABEL_TYPE.theme);
      }),
      isProgressAboveEstimation: computed(() => props.task.ellapsedTime > state.ellapsedTimeWarningThreshold),
      isProgressEqualToLimitAuthorizedByCgv: computed(() => props.task.ellapsedTime > state.ellapsedTimeErrorThreshold),
      isProgressAboveLimitAuthorizedByCgv: computed(() => props.task.ellapsedTime > state.ellapsedTimeErrorThreshold),
      taskUsers: computed(() => flow([fpMap('userId'), fpUniq, fpSampleSize(3)])(props.task.workLogs)),
      mainUserId: computed(() =>
        flow([
          fpMap((log) => ({
            ...log,
            startedAt: new Date(log.startedAt),
          })),
          fpSortBy('startedAt'),
          fpLast,
          fpGet('userId'),
        ])(props.task.workLogs),
      ),
      isPending: computed(() => get(props.task, 'pendingAt')),
      isCustomerPending: computed(() => state.isPending && props.task.pendingStatus === TASK_PENDING_STATUS.CUSTOMER),
      isTaskValidationPending: computed(() => state.isPending && props.task.pendingStatus === TASK_PENDING_STATUS.COMPLETED),
      isTaskHasBeenEstimated: computed(() => !!get(props.task, 'estimatedTime')),
      isTaskEstimatedTimeAlreadyAccepted: computed(() => !!get(props.task, 'estimatedTimeAcceptedAt')),
      isTaskEstimatedTimeAlreadyRefused: computed(() => !!get(props.task, 'estimatedTimeRefusedAt')),
      isWaitingEstimationValidation: computed(
        () => state.hasMissionLabel && state.isTaskHasBeenEstimated && !state.isTaskEstimatedTimeAlreadyAccepted && !state.isTaskEstimatedTimeAlreadyRefused,
      ),
      lastTaskWorklogStartedAt: computed(() => get(props.task, 'workLogs[-1].startedAt', undefined)),
      isTaskDosNotHaveAnyWorklog: computed(() => isEmpty(props.task.workLogs)),
      hasMissionLabel: computed(() => !!find(state.taskLabels, (label) => toLower(label.name) === 'mission')),
      progressColor: computed(() => getTaskEllapsedTimeColors({ task: props.task, labels: state.taskLabels })),
    });

    return {
      ...toRefs(state),
      formatShortDate,
      formatDurationFromMs,
      hasRole,
      faUser,
      faTag,
      faStopwatch,
      faPause,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes slide-right-glass-effect {
  0% {
    top: 0;
    left: -100%;
  }

  20% {
    top: 0;
    left: 150%;
  }

  21% {
    top: -100%;
    left: 150%;
  }

  22% {
    top: -100%;
    left: -100%;
  }

  100% {
    top: 0;
    left: -100%;
  }
}

.task-card {
  @apply bg-white rotate-0 p-3 py-2.5 rounded-lg flex flex-col gap-1 transform border-[0.5px] border-[#D0DAFD];
  transition: transform 0.35s, opacity 0.35s;

  .task-waiting-status {
    @apply bg-gray-100 font-medium rounded p-1.5 mb-2 text-xs flex flex-row justify-center items-center gap-2 transition-colors overflow-hidden relative;

    &::before {
      @apply bg-white bg-opacity-50 w-3/5 h-full top-0 absolute;
      content: '';
      transform: skew(45deg);
      left: -100%;
      animation: slide-right-glass-effect 10s 1s ease infinite;
    }
  }

  &:hover,
  &.dragging-ready,
  &.mouse-dragging {
    --tw-scale-x: 1.02;
    --tw-scale-y: 1.02;

    .task-waiting-status {
      @apply bg-gray-200;
    }
  }

  &.dragging-ready,
  &.mouse-dragging {
    // animation: 200ms ease-in-out 0s rotate;
    // animation-fill-mode: forwards;
  }

  &__body {
    @apply flex gap-2;
  }

  &__footer {
    @apply flex flex-col justify-center items-center;
  }

  &__actions {
    @apply flex flex-row items-center text-xs gap-3 w-full mt-3;
  }

  &__icons {
    @apply text-xs text-gray-500 tracking-tighter flex flex-row justify-between w-full items-end gap-2;
  }

  &:hover {
    transform: scale(1.02);
    opacity: 1;

    .task-waiting-status {
      @apply bg-gray-200;
    }
  }
}

.animate-bell-ding {
  @apply relative;

  animation: 2s ease-in-out 2s ring;
  transform-origin: top center;
}

@keyframes rotate {
  0% {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(0deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  100% {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(8deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
}

@keyframes ring {
  0% {
    transform: translate(-0.25px, 0.5px) rotate(0deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  14.3% {
    transform: translate(-0.25px, 0.5px) rotate(25deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  28.6% {
    transform: translate(-0.25px, 0.5px) rotate(-20deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  42.9% {
    transform: translate(-0.25px, 0.5px) rotate(15deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  57.2% {
    transform: translate(-0.25px, 0.5px) rotate(-10deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  71.5% {
    transform: translate(-0.25px, 0.5px) rotate(5deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  100% {
    transform: translate(-0.25px, 0.5px) rotate(0deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
</style>
