<template>
  <SkyListItem
    v-bind="$attrs"
    class="relative"
  >
    <slot />
    <button
      type="button"
      class="absolute right-0 p-4 duration-500 transform -translate-y-1/2 outline-none top-1/2 focus:outline-none"
      :class="{
        'rotate-180': !isCollapsed,
      }"
      @click.prevent="isCollapsed = !isCollapsed"
    >
      <FontAwesomeIcon :icon="faChevronDown" />
    </button>
  </SkyListItem>
  <SkyList
    v-if="!isCollapsed"
    class="ml-4"
  >
    <slot name="items" />
  </SkyList>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';

export default {
  setup() {
    const state = reactive({
      isCollapsed: true,
    });

    return {
      ...toRefs(state),
      faChevronDown,
    };
  },
};
</script>

<style></style>
