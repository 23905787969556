<template>
  <table class="min-w-full leading-normal">
    <slot />
  </table>
</template>

<script>
export default {};
</script>

<style></style>
