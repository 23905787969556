import axios from 'axios';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import flow from 'lodash/flow';
import trim from 'lodash/trim';
import times from 'lodash/times';
import sumBy from 'lodash/sumBy';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import fpFilter from 'lodash/fp/filter';
import fpReduce from 'lodash/fp/reduce';
import findIndex from 'lodash/findIndex';
import { i18n } from '@/plugins/i18n/index.js';
import {
  faChild,
  faMale,
  faUtensils,
  faGlassCheers,
  faMusic,
  faSpa,
  faFlower,
  faFlowerTulip,
  faFireAlt,
  faHotTub,
  faHouseUser,
  faQuestion,
} from '@fortawesome/pro-light-svg-icons';
import {
  getBookings,
  getBookingsFromDateRange,
  getOneBooking,
  getRooms,
  postAddRoom,
  patchUpdateRoom,
  patchUpdateBooking,
  postAddBooking,
  deleteBooking,
  postAddBookingOption,
  patchUpdateBookingOption,
  deleteBookingOption,
  postAddBookingPayment,
  deleteBookingPayment,
} from '@/modules/bookings/bookings.api.js';
import { getEventColorFromBookingStatus, createHtmlGiftVoucher } from '@/modules/bookings/bookings.functions.js';

dayjs.extend(dayjsUtc);

const stateData = {
  pagination: {
    limit: 20,
    skip: 0,
    total: 0,
  },

  bookings: [],
  rooms: [],
  filter: {
    userId: undefined,
    searchText: undefined,
    giftsOnly: false,
    status: ['confirmed', 'failed', 'pending', 'closed', 'awaiting_payment'],
  },
};

const getters = {
  getRoom:
    (state) =>
    ({ roomId }) =>
      find(state.rooms, { _id: roomId }),
  getRooms: (state) => state.rooms,
  getBooking:
    (state) =>
    ({ bookingId }) =>
      find(state.bookings, { _id: bookingId }),
  getBookingFromEvent:
    (state) =>
    ({ _id: eventId }) =>
      find(state.bookings, { _id: eventId }),
  getEventsStatusFilter: (state) => state.filter.status,
  getGiftsOnlyFilter: (state) => state.filter.giftsOnly,
  getBookings: (state) => filter(state.bookings, ({ status }) => includes(state.filter.status, status)),
  getSearchText: (state) => state.filter.searchText,
  hasNext: (state) => state.pagination.total > state.pagination.skip + state.pagination.limit,
  hasPrevious: (state) => state.pagination.skip > 0,
  getBookingTotalAmountInCents:
    (state, localGetters) =>
    ({ bookingId }) => {
      const booking = localGetters.getBooking({ bookingId });
      if (!booking) return 0;

      const roomTotalAmount = booking.unitAmountInCents * booking.quantity;
      const optionsTotalAmount = reduce(booking.selectedOptions, (total, { unitAmountInCents, quantity }) => total + unitAmountInCents * quantity, 0);
      return roomTotalAmount + optionsTotalAmount;
    },
  getBookingTotalPaidAmountInCents:
    (state, localGetters) =>
    ({ bookingId }) => {
      const booking = localGetters.getBooking({ bookingId });
      if (!booking) return 0;

      return sumBy(booking.payments, 'amountInCents');
    },
  getBookingBalanceAmountInCents:
    (state, localGetters) =>
    ({ bookingId }) => {
      const totalAmountInCents = localGetters.getBookingTotalAmountInCents({ bookingId });
      const totalPaidAmountIncents = localGetters.getBookingTotalPaidAmountInCents({ bookingId });

      return totalAmountInCents - totalPaidAmountIncents;
    },
  getRoomBookingOptions:
    (state) =>
    ({ roomId }) => {
      const room = getters.getRoom(state)({ roomId });
      return get(room, 'bookingOptions', []);
    },
  getBookingIcons:
    (state, localGetters, rootState, rootGetters) =>
    ({ bookingId }) => {
      const getProduct = rootGetters['payments/getProduct'];
      const booking = localGetters.getBooking({ bookingId });
      if (!booking) {
        return [];
      }

      const defaultIcons = [];

      if (booking.adults > 0) {
        defaultIcons.push({ icon: faMale, quantity: booking.adults });
      }

      if (booking.children > 0) {
        defaultIcons.push({ icon: faChild, quantity: booking.children });
      }

      const iconsDictionnary = {
        'fa-utensils': faUtensils,
        'fa-glass-cheers': faGlassCheers,
        'fa-music': faMusic,
        'fa-spa': faSpa,
        'fa-child': faChild,
        'fa-flower': faFlower,
        'fa-flower-tulip': faFlowerTulip,
        'fa-fire-alt': faFireAlt,
        'fa-hot-tub': faHotTub,
        'fa-house-user': faHouseUser,
        'fa-question': faQuestion,
      };

      return reduce(
        booking.selectedOptions,
        (accIcons, { productId, quantity }) => {
          const optionProduct = getProduct({ productId });
          const icon = get(optionProduct, 'metadata.icon');
          if (isEmpty(icon)) {
            return accIcons;
          }

          return [
            ...accIcons,
            {
              icon: get(iconsDictionnary, icon, faQuestion),
              quantity,
            },
          ];
        },
        defaultIcons,
      );
    },
  getEvents: (state, localGetters, rootState, rootGetters) => {
    const getProduct = rootGetters['payments/getProduct'];

    return flow(
      fpFilter(({ status, arrivalDate }) => includes(state.filter.status, status) && !isEmpty(arrivalDate)),
      fpReduce((events, booking) => {
        const product = getProduct({ productId: booking.productId });
        const icons = localGetters.getBookingIcons({ bookingId: booking._id });

        const arrivalDate = dayjs.utc(booking.arrivalDate);
        const numberOfEvents = dayjs.utc(booking.departureDate).diff(arrivalDate, 'days');

        const eventsToAdd = [];

        times(numberOfEvents, (index) => {
          eventsToAdd.push({
            // eslint-disable-next-line no-underscore-dangle
            _id: booking._id,
            date: arrivalDate.add(index, 'day').toDate(),
            title: get(product, 'name', 'Réservation'),
            isGift: booking.isGift,
            icons,
            theme: getEventColorFromBookingStatus(booking),
          });
        });

        return [...events, ...eventsToAdd];
      }, []),
    )(state.bookings);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, stateData);
  },
  setEventsStatusFilter(state, { status }) {
    state.filter.status = status;
  },
  setEventsUserIdFilter(state, { userId }) {
    state.filter.userId = userId;
  },
  setGiftsOnlyFilter(state, { giftsOnly }) {
    state.filter.giftsOnly = giftsOnly;
  },
  setBookings(state, { bookings }) {
    state.bookings = bookings;
  },
  setRooms(state, { rooms }) {
    state.rooms = rooms;
  },
  addRoom(state, { room }) {
    state.rooms = [...state.rooms, room];
  },
  updateRoom(state, { roomId, ...updatedRoom }) {
    const roomIndex = findIndex(state.rooms, { _id: roomId });
    if (roomIndex === -1) {
      return;
    }
    state.rooms[roomIndex] = {
      ...state.rooms[roomIndex],
      ...updatedRoom,
    };
  },
  updateBooking(state, { bookingId, ...updatedBooking }) {
    const bookingIndex = findIndex(state.bookings, { _id: bookingId });
    if (bookingIndex === -1) {
      return;
    }
    state.bookings[bookingIndex] = {
      ...state.bookings[bookingIndex],
      ...updatedBooking,
    };
  },
  addBooking(state, { booking }) {
    state.bookings = [...reject(state.bookings, { _id: booking._id }), booking];
  },
  removeBooking(state, { bookingId }) {
    state.bookings = reject(state.bookings, { _id: bookingId });
  },
  addBookingOption(state, { bookingId, bookingOption }) {
    const bookingIndex = findIndex(state.bookings, { _id: bookingId });
    if (bookingIndex === -1) {
      return;
    }
    state.bookings[bookingIndex].selectedOptions = [...state.bookings[bookingIndex].selectedOptions, bookingOption];
  },
  removeBookingOption(state, { bookingId, bookingOptionId }) {
    const bookingIndex = findIndex(state.bookings, { _id: bookingId });
    if (bookingIndex === -1) {
      return;
    }

    state.bookings[bookingIndex].selectedOptions = reject(state.bookings[bookingIndex].selectedOptions, { _id: bookingOptionId });
  },
  updateBookingOption(state, { bookingId, bookingOptionId, ...updatedBookingOption }) {
    const bookingIndex = findIndex(state.bookings, { _id: bookingId });
    if (bookingIndex === -1) {
      return;
    }

    const bookingOptionIndex = findIndex(state.bookings[bookingIndex].selectedOptions, {
      _id: bookingOptionId,
    });
    if (bookingOptionIndex === -1) {
      return;
    }

    state.bookings[bookingIndex].selectedOptions[bookingOptionIndex] = {
      ...state.bookings[bookingIndex].selectedOptions[bookingOptionIndex],
      ...updatedBookingOption,
    };
  },
  addBookingPayment(state, { bookingId, bookingPayment }) {
    const bookingIndex = findIndex(state.bookings, { _id: bookingId });
    if (bookingIndex === -1) {
      return;
    }

    state.bookings[bookingIndex].payments = [...state.bookings[bookingIndex].payments, bookingPayment];
  },
  removeBookingPayment(state, { bookingId, bookingPaymentId }) {
    const bookingIndex = findIndex(state.bookings, { _id: bookingId });
    if (bookingIndex === -1) {
      return;
    }

    state.bookings[bookingIndex].payments = reject(state.bookings[bookingIndex].payments, {
      _id: bookingPaymentId,
    });
  },
  setPaginationTotal(state, { total }) {
    state.pagination.total = total;
  },
  setPagination(state, { limit, skip }) {
    state.pagination = {
      limit,
      skip,
    };
  },
  nextPagination(state) {
    const { limit, skip } = state.pagination;
    state.pagination = {
      ...state.pagination,
      skip: skip + limit,
    };
  },
  previousPagination(state) {
    const { limit, skip } = state.pagination;
    state.pagination = {
      ...state.pagination,
      skip: Math.max(0, skip - limit),
    };
  },
  setSearchText(state, { searchText }) {
    state.filter.searchText = isEmpty(searchText) ? undefined : trim(searchText);
    state.pagination = {
      ...stateData.pagination,
    };
  },
};

const actions = {
  async fetchRooms({ state, commit }) {
    try {
      if (!isEmpty(state.rooms)) {
        return;
      }
      const rooms = await getRooms();
      commit('setRooms', { rooms });
    } catch (err) {
      throw new Error(i18n.global.t('bookings.store.fetchRooms.requestError'));
    }
  },
  async fetchOneBooking({ commit, getters: localGetters, dispatch }, { bookingId, force = false }) {
    try {
      const localBooking = localGetters.getBooking({ bookingId });
      if (!isEmpty(localBooking) && !force) {
        return;
      }

      await dispatch('fetchRooms');

      const booking = await getOneBooking({ bookingId });
      commit('addBooking', { booking });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('bookings.store.fetchOneBooking.requestError'));
      }
      throw err;
    }
  },
  async fetchBookings({ commit, state }) {
    try {
      const { list: bookings, total } = await getBookings({
        ...state.pagination,
        search: state.filter.searchText,
        giftsOnly: state.filter.giftsOnly,
      });
      commit('setBookings', { bookings });
      commit('setPaginationTotal', { total });
    } catch (err) {
      throw new Error(i18n.global.t('bookings.store.fetchBookings.requestError'));
    }
  },
  async fetchBookingsFromDateRange(
    { commit, state, dispatch },
    { start, end } = {
      start: dayjs().utc().startOf('month').toDate(),
      end: dayjs().utc().startOf('month').add(1, 'month').toDate(),
    },
  ) {
    try {
      await dispatch('fetchRooms');
      const filterQuery = {
        userId: state.filter.userId,
        searchText: state.filter.searchText,
      };

      if (!filterQuery.userId) {
        filterQuery.start = start.toISOString();
        filterQuery.end = end.toISOString();
      }

      const bookings = await getBookingsFromDateRange(filterQuery);
      commit('setBookings', { bookings });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('bookings.store.fetchBookingsFromDateRange.requestError'));
      }
      throw err;
    }
  },
  async addRoom({ commit }, { productId, priceId, taxRateId, bookingOptions, bookingRestrictions, priceList, paymentAdvancePercent }) {
    try {
      const room = await postAddRoom({
        productId,
        priceId,
        taxRateId,
        bookingOptions,
        bookingRestrictions,
        priceList,
        paymentAdvancePercent,
      });
      commit('addRoom', { room });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('bookings.store.addRoom.requestError'));
      }
      throw err;
    }
  },
  async updateRoom({ commit }, { _id: roomId, productId, priceId, taxRateId, bookingOptions, bookingRestrictions, priceList, paymentAdvancePercent }) {
    try {
      await patchUpdateRoom({
        roomId,
        productId,
        priceId,
        taxRateId,
        bookingOptions,
        bookingRestrictions,
        priceList,
        paymentAdvancePercent,
      });
      commit('updateRoom', {
        roomId,
        productId,
        priceId,
        taxRateId,
        bookingOptions,
        bookingRestrictions,
        priceList,
        paymentAdvancePercent,
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('bookings.store.updateRoom.requestError'));
      }
      throw err;
    }
  },
  async updateBooking({ commit }, { _id: bookingId, status, arrivalDate: rawArrivalDate, departureDate: rawDepartureDate, note, roomId, priceId, adults, children }) {
    try {
      const arrivalDate = rawArrivalDate && dayjs.utc(rawArrivalDate);
      const departureDate = rawDepartureDate && dayjs.utc(rawDepartureDate);
      const quantity = arrivalDate && departureDate && departureDate.diff(arrivalDate, 'days');

      await patchUpdateBooking({
        bookingId,
        status,
        arrivalDate,
        quantity,
        note,
        roomId,
        priceId,
        adults,
        children,
      });
      commit('updateBooking', {
        bookingId,
        status,
        arrivalDate,
        departureDate,
        quantity,
        note,
        roomId,
        priceId,
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 409) {
          throw new Error(i18n.global.t('bookings.store.updateBooking.conflict'));
        }
        throw new Error(i18n.global.t('bookings.store.updateBooking.requestError'));
      }
      throw err;
    }
  },
  async addBooking({ commit }, { roomId, status, departureDate, arrivalDate, note, isGift, priceId, userId, adults, children }) {
    try {
      const booking = await postAddBooking({
        roomId,
        status,
        arrivalDate: dayjs.utc(arrivalDate),
        departureDate: dayjs.utc(departureDate),
        note,
        isGift,
        priceId,
        userId,
        adults,
        children,
      });

      commit('addBooking', { booking });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 409) {
          throw new Error(i18n.global.t('bookings.store.addBooking.conflict'));
        }
        throw new Error(i18n.global.t('bookings.store.addBooking.requestError'));
      }
      throw err;
    }
  },
  async removeBooking({ commit }, { bookingId }) {
    try {
      await deleteBooking({ bookingId });

      commit('removeBooking', { bookingId });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('bookings.store.removeBooking.requestError'));
      }
      throw err;
    }
  },
  async addBookingOption({ commit }, { bookingId, productId, priceId, taxRateId, quantity }) {
    try {
      const bookingOption = await postAddBookingOption({
        bookingId,
        productId,
        priceId,
        taxRateId,
        quantity,
      });

      commit('addBookingOption', { bookingId, bookingOption });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('bookings.store.addBookingOption.requestError'));
      }
      throw err;
    }
  },
  async removeBookingOption({ commit }, { bookingId, bookingOptionId }) {
    try {
      await deleteBookingOption({ bookingId, bookingOptionId });

      commit('removeBookingOption', { bookingId, bookingOptionId });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('bookings.store.removeBookingOption.requestError'));
      }
      throw err;
    }
  },
  async updateBookingOption({ commit }, { bookingId, bookingOptionId, quantity }) {
    try {
      await patchUpdateBookingOption({
        bookingId,
        bookingOptionId,
        quantity,
      });

      commit('updateBookingOption', { bookingId, bookingOptionId, quantity });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('bookings.store.updateBookingOption.requestError'));
      }
      throw err;
    }
  },
  async addBookingPayment({ commit }, { bookingId, method, description, amountInCents, paymentAt }) {
    try {
      const bookingPayment = await postAddBookingPayment({
        bookingId,
        method,
        description,
        amountInCents,
        paymentAt: dayjs.utc(paymentAt),
      });

      commit('addBookingPayment', { bookingId, bookingPayment });
      commit('payments/addPayment', { payment: bookingPayment }, { root: true });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('bookings.store.addBookingPayment.requestError'));
      }
      throw err;
    }
  },
  async removeBookingPayment({ commit }, { bookingId, bookingPaymentId }) {
    try {
      await deleteBookingPayment({ bookingId, bookingPaymentId });

      commit('removeBookingPayment', { bookingId, bookingPaymentId });
      commit('payments/removePayment', { paymentId: bookingPaymentId }, { root: true });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('bookings.store.removeBookingPayment.requestError'));
      }
      throw err;
    }
  },
  async generateGiftVoucher({ dispatch, getters: localGetters, rootGetters }, { bookingId }) {
    const localBooking = localGetters.getBooking({ bookingId });
    const localRoom = localGetters.getRoom({ roomId: get(localBooking, 'roomId') });
    if (isEmpty(localBooking) || isEmpty(localRoom)) {
      throw new Error(i18n.global.t('bookings.store.generateGiftVoucher.collectGitVoucherDataError'));
    }
    await dispatch('payments/fetchPrice', { priceId: localBooking.priceId }, { root: true });
    const price = rootGetters['payments/getPrice']({ priceId: localBooking.priceId });

    const getProduct = rootGetters['payments/getProduct'];
    const roomProduct = getProduct({
      productId: localRoom.productId,
    });

    const selectedOptions = map(localBooking.selectedOptions, ({ productId, ...selectedOption }) => ({
      product: getProduct({ productId }),
      ...selectedOption,
    }));

    const { html } = createHtmlGiftVoucher({
      booking: localBooking,
      room: roomProduct,
      selectedOptions,
      price,
    });
    const pdf = await dispatch('converters/htmlToPdf', { html }, { root: true });
    return pdf;
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
