/* eslint-disable no-console */
import mitt from 'mitt';
import config from '@/config/index.js';
import { register } from 'register-service-worker';
import notifsApi from '@/modules/shared/api/notifs.js';
import { urlBase64ToUint8Array } from '@/utils/transform.js';

// eslint-disable-next-line import/prefer-default-export
export const emitter = mitt();

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration) {
      console.log('App is being served from cache by a service worker.\n For more details, visit https://goo.gl/AFskqB');

      emitter.on('loggedIn', async ({ userId }) => {
        const pushManagerOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(config.notifs.publicVapidKey),
        };

        try {
          if (!registration.pushManager) return;

          const permissionState = await registration.pushManager.permissionState(pushManagerOptions);

          if (permissionState === 'denied') {
            return;
          }

          if (permissionState === 'prompt') {
            localStorage.removeItem('workspace_subscriptions');
          }

          const ws = {
            registeredUsers: [],
            ...JSON.parse(localStorage.getItem('workspace_subscriptions') || '{}'),
          };

          if (permissionState === 'granted' && ws.registeredUsers.includes(userId)) {
            return;
          }

          ws.subscription = ws.subscription || (await registration.pushManager.subscribe(pushManagerOptions));

          await notifsApi().post(`${config.endpoints.notifs}/private/devices/browser/subscribe`, ws.subscription);

          ws.registeredUsers = [...ws.registeredUsers, userId];

          localStorage.setItem('workspace_subscriptions', JSON.stringify(ws));
        } catch (err) {
          console.error('Error checking or handling pushManager : ', err);
        }
      });
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');

      const updateBox = document.createElement('button');
      updateBox.type = 'button';
      updateBox.id = 'update-box';
      updateBox.classList.add('py-2', 'px-2', 'text-center', 'bg-primary', 'text-white', 'w-full', 'font-light', 'hover:bg-dark-blue-400');
      updateBox.addEventListener('click', () => window.location.reload());
      updateBox.innerText = 'Une nouvelle version est disponible 🚀';

      const existingUpdateBox = document.getElementById('update-box');
      existingUpdateBox?.remove();
      document.body.prepend(updateBox);
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
