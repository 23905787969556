<template>
  <SkyAsyncSelect
    :model-value="modelValue"
    :options="users"
    :loading="loading"
    path-label="email"
    :label="$t('components.users.userSelect.label')"
    :placeholder="$t('components.users.userSelect.placeholder')"
    v-bind="$attrs"
    @search="handleSearch"
  >
    <template #default="{ option }">
      <div>{{ option.firstName }} {{ option.lastName }}</div>
      <div class="text-sm text-gray-400">
        {{ option.email }}
      </div>
    </template>
  </SkyAsyncSelect>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';

export default {
  props: {
    modelValue: {
      type: String,
      default: () => undefined,
    },
  },

  setup(props) {
    const store = useStore();

    const state = reactive({
      users: computed(() => store.getters['users/getUsers']),
      loading: false,
      timerBounce: undefined,
    });

    function handleSearch(search) {
      state.loading = true;
      clearTimeout(state.timerBounce);

      state.timerBounce = setTimeout(async () => {
        try {
          await store.dispatch('users/searchUsers', { search });
        } catch (err) {
          console.error(err);
        }
        state.loading = false;
      }, 500);
    }

    onMounted(async () => {
      try {
        if (!props.modelValue) {
          return;
        }
        await store.dispatch('users/fetchUser', {
          userId: props.modelValue,
        });
      } catch (err) {
        console.error(err);
      }
    });

    return {
      handleSearch,
      ...toRefs(state),
    };
  },
};
</script>

<style></style>
