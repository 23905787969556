<template>
  <div class="icon-select">
    <div
      v-show="panelOpen"
      class="icon-select__panel"
    >
      <div>
        <div class="grid grid-cols-4">
          <button
            v-for="icon of icons"
            :key="icon"
            class="p-1 hover:bg-gray-100"
            type="button"
            @click="
              $emit('update:modelValue', icon);
              panelOpen = false;
            "
            @mousedown.prevent
          >
            <FontAwesomeIcon :icon="icon" />
          </button>
        </div>
      </div>
    </div>
    <button
      v-bind="$attrs"
      type="button"
      class="icon-select__button"
      @click="panelOpen = !panelOpen"
      @blur="panelOpen = false"
    >
      <FontAwesomeIcon :icon="getFontAwesomeIconObj(modelValue)" />
    </button>
  </div>
</template>

<script>
import get from 'lodash/get';
import { reactive, toRefs } from 'vue';
import { faUtensils, faGlassCheers, faMusic, faSpa, faChild, faFlower, faFlowerTulip, faFireAlt, faHotTub, faHouseUser, faQuestion } from '@fortawesome/pro-light-svg-icons';

const icons = [faUtensils, faGlassCheers, faMusic, faSpa, faChild, faFlower, faFlowerTulip, faFireAlt, faHotTub, faHouseUser];

const iconsDictionnary = {
  'fa-utensils': faUtensils,
  'fa-glass-cheers': faGlassCheers,
  'fa-music': faMusic,
  'fa-spa': faSpa,
  'fa-child': faChild,
  'fa-flower': faFlower,
  'fa-flower-tulip': faFlowerTulip,
  'fa-fire-alt': faFireAlt,
  'fa-hot-tub': faHotTub,
  'fa-house-user': faHouseUser,
  'fa-question': faQuestion,
};

export default {
  props: {
    modelValue: {
      type: String,
      default: () => undefined,
    },
  },

  emits: ['update:modelValue'],

  setup() {
    const state = reactive({
      panelOpen: false,
    });

    function getFontAwesomeIconObj(iconName) {
      return get(iconsDictionnary, iconName, faQuestion);
    }

    return {
      ...toRefs(state),
      getFontAwesomeIconObj,
      icons,
      faQuestion,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon-select {
  @apply relative;

  &__button {
    @apply py-2 px-4 border border-dark-blue-100 rounded;
  }

  &__panel {
    @apply absolute bottom-full h-24 w-48 mb-2;

    > div {
      @apply absolute bg-white border border-dark-blue-100 rounded h-full w-full z-20;
    }

    &::before {
      content: '';
      position: absolute;
      height: 8px;
      width: 8px;
      transform-origin: top left;
      transform: translate(18px, -50%) rotate(45deg);
      @apply bg-dark-blue-100 block top-full z-10;
    }
  }
}
</style>
