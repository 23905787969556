import storagesApi from '@/modules/shared/api/storages.js';

export async function postUploadInternalFiles({ files }) {
  const form = new FormData();
  form.append('files', files[0]);
  const { data } = await storagesApi().postForm('/admin/storages/internal', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
}

export async function getFile({ fileId }) {
  const { data } = await storagesApi().get(`/admin/files/${fileId}`);

  return data;
}

export async function downloadFile({ fileId }) {
  const { data } = await storagesApi().get(`/admin/files/${fileId}/download`, { responseType: 'blob' });

  return data;
}
