<template>
  <div class="flex justify-around gap-4">
    <SkyGauge
      :fill-prct="calculatedGTD.fillPrct"
      label="GTD"
    >
      <template #value>
        {{ calculatedGTD.value }}
      </template>
    </SkyGauge>
    <SkyGauge
      :fill-prct="calculatedGTI.fillPrct"
      label="GTI"
    >
      <template #value>
        {{ calculatedGTI.value === '0s' ? '< 1min' : calculatedGTI.value }}
      </template>
    </SkyGauge>
    <SkyGauge
      :fill-prct="calculatedGTR.fillPrct"
      label="GTR"
    >
      <template #value>
        {{ calculatedGTR.value === '0s' ? '< 1min' : calculatedGTR.value }}
      </template>
    </SkyGauge>
  </div>
</template>

<script setup>
import { useNotify } from '@/plugins/notify/index.js';
import { formatDurationFromMs } from '@/utils/format.js';
import round from 'lodash/round';
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  from: {
    type: String,
    default: undefined,
  },
  to: {
    type: String,
    default: undefined,
  },
});

const store = useStore();
const notify = useNotify();

const calculatedGTI = computed(() => {
  const gti = store.getters['stats/getGTI'];

  if (!gti) {
    return {
      value: 'N/A',
      fillPrct: undefined,
    };
  }

  if (gti.unit !== 'm') {
    console.warn('Unprocessable GTI unit');

    return {
      value: 'N/A',
      fillPrct: undefined,
    };
  }

  const fillPrct = gti.data >= 60 ? -1 : round(1 - gti.data / 60, 2);

  return {
    value: formatDurationFromMs(gti.data * 60_000),
    fillPrct,
  };
});
const calculatedGTR = computed(() => {
  const gtr = store.getters['stats/getGTR'];

  if (!gtr) {
    return {
      value: 'N/A',
      fillPrct: undefined,
    };
  }

  if (gtr.unit !== 'm') {
    console.warn('Unprocessable GTR unit');

    return {
      value: 'N/A',
      fillPrct: undefined,
    };
  }

  const fillPrct = gtr.data > 479 ? -1 : round(1 - gtr.data / 480, 3);

  return {
    value: formatDurationFromMs(gtr.data * 60_000),
    fillPrct,
  };
});
const calculatedGTD = computed(() => {
  const gtd = store.getters['stats/getGTD'];

  if (!gtd) {
    return {
      value: 'N/A',
      fillPrct: undefined,
    };
  }

  if (gtd.unit !== '%') {
    console.warn('Unprocessable GTD unit');

    return {
      value: 'N/A',
      fillPrct: undefined,
    };
  }

  const value = `${round(gtd.data * 100, 2)}${gtd.unit}`;

  if (gtd.data >= 0.999) {
    return {
      value,
      fillPrct: 1,
    };
  }

  if (gtd.data >= 0.998) {
    return {
      value,
      fillPrct: 0.75,
    };
  }

  if (gtd.data >= 0.997) {
    return {
      value,
      fillPrct: 0.5,
    };
  }

  if (gtd.data >= 0.996) {
    return {
      value,
      fillPrct: 0.25,
    };
  }

  return {
    value,
    fillPrct: -1,
  };
});

async function fetchStats() {
  try {
    await store.dispatch('stats/fetchGTI', { from: props.from, to: props.to });
  } catch (err) {
    notify.error(err.message);
  }

  try {
    await store.dispatch('stats/fetchGTR', { from: props.from, to: props.to });
  } catch (err) {
    notify.error(err.message);
  }

  try {
    await store.dispatch('stats/fetchGTD', { from: props.from, to: props.to });
  } catch (err) {
    notify.error(err.message);
  }
}

onMounted(async () => {
  await fetchStats();
});

watch(props, () => fetchStats(), { deep: true });
</script>
