import { ProbeEventBuilder } from '@/modules/stats/availability/domain/probe-event/probe-event-builder.js';
import { ProbeEvents } from '@/modules/stats/availability/domain/probe-event/collection/probe-events.js';

/**
 * @typedef {{
 *   _id: string,
 *   providerMetadata: {
 *     eventId: string,
 *     monitorId: string,
 *   },
 *   name: string,
 *   startedAt: string,
 *   type: string,
 *   cause: string,
 *   resolvedAt?: string,
 *   acknowledgedAt?: string,
 * }} FindAllProbeEventsItem
 * @typedef {FindAllProbeEventsItem[]} FindAllProbeEventsResponseData
 */

export class ApiFindAllProbeEventsResponse {
  /**
   * @param {FindAllProbeEventsResponseData} response
   */
  constructor(response) {
    this.response = response;
  }

  toProbeEvents() {
    const events = this.response.map((event) => {
      const eventBuilder = new ProbeEventBuilder()
        .withNativeId(event.providerMetadata.eventId)
        .withNativeProbeId(event.providerMetadata.monitorId)
        .withNativeType(event.type)
        .withNativeCause(event.cause)
        .withNativeName(event.name)
        .withNativeStartedAtTime(event.startedAt);

      if (event.acknowledgedAt) {
        eventBuilder.withNativeAcknowledgedAtTime(event.acknowledgedAt);
      }

      if (event.resolvedAt) {
        eventBuilder.withNativeResolvedAtTime(event.resolvedAt);
      }

      return eventBuilder.build();
    });

    return ProbeEvents.fromEvents(events);
  }
}
