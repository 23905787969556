<template>
  <fieldset>
    <legend
      v-if="legend"
      class="block mb-1 text-xs font-bold uppercase"
      :class="{ required: required }"
    >
      {{ legend }}
    </legend>
    <div class="radios-container">
      <div
        v-for="(choice, index) in choices"
        :key="choice.value"
        :class="{ 'cursor-not-allowed': readOnly || choice.isDisabled }"
      >
        <input
          :id="`${name}-${index}`"
          v-model="internalValue"
          class="hidden"
          :class="{ 'cursor-not-allowed': readOnly || choice.isDisabled }"
          type="radio"
          :icon="name"
          :value="choice.value"
          :checked="hasOneDefaultChoice ? choice.isDefault : index === 0"
          :disabled="readOnly || choice.isDisabled"
          :autofocus="autofocus"
          :required="required && index === 0"
        />
        <label
          :for="`${name}-${index}`"
          class="block text-xs font-medium text-gray-500 whitespace-nowrap"
        >
          {{ choice.label }}
        </label>
      </div>
    </div>
    <span
      class="flex items-center h-4 mt-3 ml-1 text-xs tracking-wide text-center"
      :class="{
        hidden: !hasErrorMessages && !helperText,
        'text-gray-600': helperText,
        'font-medium': hasErrorMessages,
        'text-red-500': hasErrorMessages,
      }"
    >
      {{ firstErrorMessage || helperText }}
    </span>
  </fieldset>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import every from 'lodash/every';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import isBoolean from 'lodash/isBoolean';
import useValidatable from '@/modules/shared/utils/validatable.js';

export default {
  props: {
    modelValue: { type: [String, Number], default: () => undefined },
    legend: { type: String, default: () => undefined },
    autofocus: { type: Boolean, default: () => false },
    helperText: { type: String, default: () => undefined },
    readOnly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    name: { type: String, default: () => undefined },
    choices: {
      type: Array,
      default: () => [],
      validator: (entries) =>
        map(entries, (entry) =>
          every(
            isString(entry.label) && !isEmpty(entry.label),
            isString(entry.value) && !isEmpty(entry.value),
            isUndefined(entry.isDisabled) || isBoolean(entry.isDisabled),
            isUndefined(entry.isDefault) || isBoolean(entry.isDefault),
          ),
        ),
    },
    rules: { type: Array, default: () => [] },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const hasOneDefaultChoice = computed(() => {
      const nbOfDefaultChoice = sumBy(props.choices, (choice) => (choice.isDefault === true ? 1 : 0));
      if (nbOfDefaultChoice > 1) {
        console.warn('[SkyInputRadioSwitch] Warn: multiple default choices. It can only have one !');
      }
      return nbOfDefaultChoice === 1;
    });

    const defaultState = reactive({
      value: hasOneDefaultChoice.value ? props.choices.find((choice) => choice.isDefault)?.value : props.choices[0]?.value,
      hasErrorMessages: false,
      firstErrorMessage: '',
    });

    const { state, validate } = useValidatable({ props, emit, defaultState });

    return {
      ...toRefs(state),
      hasOneDefaultChoice,
      validate,
    };
  },
};
</script>

<style lang="scss" scoped>
.required:after {
  @apply content-asterisk ml-1 text-red-600 font-medium;
}

.radios-container {
  @apply flex gap-2 items-center justify-around rounded p-2;

  label {
    @apply flex items-center justify-center py-1 px-2.5 bg-gray-200 hover:bg-dark-blue-50 rounded cursor-pointer transition-colors;
  }

  input:checked + label {
    @apply text-white bg-primary;
  }
}
</style>
