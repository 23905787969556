<template>
  <div
    :data-testid="`availability-history-day-${status.getTranslateTextKey()}`"
    class="rounded-md"
    :class="{
      [`w-${size} h-${size}`]: true,
      [`outline-2 outline-offset-[2px] outline ${status.getOutlineColor()}`]: isCurrentDay,
      [`${status.getBackgroundColor()} `]: isInProbeActivity,
      'bg-dark-blue-50': !isInProbeActivity,
    }"
  />
</template>

<script setup>
import { ProbeEventsDay } from '@/modules/stats/availability/domain/probe-event/collection/probe-events-day.js';
import { DateValueObject } from '@/utils/value-objects/date/date-value-object.js';
import { computed, inject } from 'vue';
import { availabilityInjectors } from '../availability-injectors.js';
import { Probe } from '../../../domain/probe/probe.js';

const props = defineProps({
  dayHistory: {
    type: ProbeEventsDay,
    required: true,
  },
  probe: {
    type: Probe,
    default: undefined,
  },
});

const size = inject(availabilityInjectors.dayWidth);

const isInProbeActivity = computed(() => props.probe?.isActiveAt(props.dayHistory._date));
const status = computed(() => props.dayHistory.status);
const isCurrentDay = computed(() => {
  const today = new DateValueObject(new Date());
  return props.dayHistory._date.isEqual(today);
});
</script>
