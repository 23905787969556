<template>
  <div
    class="flex flex-row items-center px-2 py-1 gap-1 font-medium rounded flex-nowrap text-[#98A2D2] bg-[#f1f4ff]"
    v-bind="$attrs"
  >
    <FontAwesomeIcon
      :icon="labelIcon || faTag"
      :style="{
        color: label.backgroundColor,
      }"
    />
    {{ label.name }}
    <FontAwesomeIcon
      v-if="icon"
      class="ml-1"
      :icon="icon"
    />
  </div>
</template>

<script setup>
import { faFlask } from '@fortawesome/pro-regular-svg-icons';
import { faCircleEuro, faFileLines, faMessageQuestion, faTag } from '@fortawesome/pro-solid-svg-icons';
import { computed } from 'vue';
import { LABEL_TYPE } from '../projects.constants.js';

const props = defineProps({
  label: {
    type: Object,
    required: true,
  },
  icon: {
    type: Object,
    default: undefined,
  },
});

const icons = {
  Mission: faCircleEuro,
  'Projet Devis': faFileLines,
  Infogérance: faMessageQuestion,
  Interne: faFlask,
};

const labelIcon = computed(() => (props.label.type !== LABEL_TYPE.category ? faTag : icons[props.label.name]));
</script>
