import { ProbeEventStatus } from '@/modules/stats/availability/domain/probe-event/status/probe-event-status.js';

export class MaintenanceStatus extends ProbeEventStatus {
  getTranslateTextKey() {
    return 'stats.availability.status.maintenance';
  }

  getBackgroundColor() {
    return 'bg-primary';
  }

  getTextColor() {
    return 'text-primary';
  }

  getOutlineColor() {
    return 'outline-primary';
  }
}
