<template>
  <div class="flex flex-col gap-2">
    <SkyForm>
      <SkyInputText
        v-model="currentSearch"
        :placeholder="$t('components.ui.skyFilterList.searchHere')"
      />
    </SkyForm>
    <slot v-if="filteredElements.length > 0" />
    <span
      v-else
      class="text-xs text-gray-500"
    >
      {{ $t('components.ui.skyFilterList.noElement') }}
    </span>
  </div>
</template>

<script setup>
import { sortBy, toLower } from 'lodash';
import { computed, ref, watch } from 'vue';

const emit = defineEmits(['update:model-value', 'search']);
const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  elements: {
    type: Array,
    default: () => [],
  },
  searchOn: {
    type: String,
    default: 'name',
  },
  max: {
    type: Number,
    default: 5,
  },
  sort: {
    type: String,
    default: 'name',
  },
});

const currentSearch = ref(undefined);
const timer = ref(undefined);
const isSearchDefined = computed(() => currentSearch.value !== undefined && currentSearch.value !== '');
const filteredElements = computed(() => {
  const sortedElements = sortBy(props.elements, [props.sort]);
  const filtered = sortedElements.filter((element) => !isSearchDefined.value || toLower(element[props.searchOn]).includes(toLower(currentSearch.value)));
  return filtered.reverse().slice(0, props.max);
});

function handleEmitSearchEvent() {
  if (timer.value) {
    clearTimeout(timer.value);
  }

  if (!isSearchDefined.value) return;

  timer.value = setTimeout(() => {
    emit('search', currentSearch.value);
  }, 500);
}

watch(
  () => currentSearch.value,
  () => handleEmitSearchEvent(),
);

watch(
  () => filteredElements.value,
  () => emit('update:model-value', filteredElements.value),
  { deep: true, immediate: true },
);
</script>
