<template>
  <div>
    <div class="relative">
      <div class="flex flex-row items-center justify-start select-header">
        <span class="mr-2 text-sm font-medium text-primary">{{ name }}</span>
        <span
          class="flex items-center justify-center btn-open-label-pannel"
          @click="open = !open"
        >
          <FontAwesomeIcon
            :icon="faPlus"
            class="bg-primary text-white text-xs px-1.5 py-1 rounded cursor-pointer"
          />
        </span>
      </div>
      <SkyPopup
        :open="open"
        class="absolute top-full set-label-pannel"
      >
        <span
          v-if="typeLabels.length === 0"
          class="px-4 text-sm text-gray-500 whitespace-nowrap"
        >
          {{ $t('components.projects.labelsSelect.unavailable') }}
        </span>
        <label
          v-for="label of typeLabels"
          :key="`label-${label._id}`"
          :for="`label-${label._id}`"
          class="flex flex-row items-center justify-start cursor-pointer select-none"
        >
          <input
            :id="`label-${label._id}`"
            v-model="internalLabelIds"
            type="checkbox"
            name="labels"
            :value="label._id"
            class="hidden"
          />
          <div
            class="flex items-center justify-center h-6 mr-2 overflow-hidden border border-gray-100 rounded w-7"
            :class="{
              'bg-primary text-white border-primary': modelValue.includes(label._id),
            }"
          >
            <FontAwesomeIcon
              :icon="faCheck"
              class="text-xs text-white"
            />
          </div>
          <LabelTag
            :label="label"
            class="w-full text-xs"
          />
        </label>
      </SkyPopup>
    </div>
    <span
      v-if="selectedLabels.length === 0"
      class="mt-3 text-xs text-gray-400"
    >
      {{ $t('components.projects.labelsSelect.empty') }}
    </span>
    <div
      v-else
      class="flex flex-row flex-wrap items-start justify-start gap-2 mt-3"
    >
      <LabelTag
        v-for="label in selectedLabels"
        :key="label._id"
        :label="label"
        class="text-xs"
      />
    </div>
  </div>
</template>

<script setup>
import { faCheck, faPlus } from '@fortawesome/pro-solid-svg-icons';
import filter from 'lodash/filter';
import LabelTag from '@/modules/projects/components/LabelTag.vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import { difference, map } from 'lodash';
import { dependenciesType } from '../projects.constants.js';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  name: {
    type: String,
    default: 'Catégories',
  },
  type: {
    type: String,
    default: 'category',
  },
  multi: {
    type: Boolean,
    default: true,
  },
  useDependencies: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const open = ref(false);
const labels = computed(() => store.getters['projects/getLabels']);
const needDependencies = computed(() => Object.keys(dependenciesType).includes(props.type));
const dependenciesLabels = computed(() =>
  filter(labels.value, (label) => needDependencies.value && label.type === dependenciesType[props.type] && !label.disabledAt && props.modelValue.includes(label._id)),
);
const typeLabels = computed(() =>
  filter(labels.value, (label) => {
    const isDependency =
      !props.useDependencies || (props.useDependencies && (label.dependencies.length === 0 || dependenciesLabels.value.some((l) => label.dependencies.includes(l._id))));
    return label.type === props.type && !label.disabledAt && isDependency;
  }),
);
const selectedLabels = computed(() => filter(labels.value, (label) => label.type === props.type && props.modelValue.includes(label?._id)));

function getLabelsValidDependencies(value) {
  const currentValueLabels = filter(labels.value, (label) => value.includes(label?._id));
  const validValues = currentValueLabels.filter((label) => {
    if (!label.dependencies || label.dependencies.length === 0) return true;
    const dependenciesValid = label.dependencies.some((labelId) => value.includes(labelId));
    if (dependenciesValid) return true;
    return false;
  });
  return map(validValues, (label) => label._id);
}

const internalLabelIds = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (props.multi) {
      emit('update:modelValue', getLabelsValidDependencies(value));
      return;
    }
    const selectedLabelsIds = selectedLabels.value.map((label) => label._id);
    const uniqLabelsCategory = difference(value, selectedLabelsIds);
    emit('update:modelValue', getLabelsValidDependencies(uniqLabelsCategory));
  },
});
function handleClickOutside(event) {
  if (open.value === false) {
    return;
  }

  const { target } = event;
  const isOutsideClick = !target.closest('.set-label-pannel') && !target.closest('.btn-open-label-pannel');

  if (!isOutsideClick) {
    return;
  }

  open.value = false;
}

onMounted(() => {
  document.querySelector('body').addEventListener('click', handleClickOutside, { passive: true });
});

onUnmounted(() => {
  document.querySelector('body').removeEventListener('click', handleClickOutside);
});
</script>
