import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import '@/index.css';
import App from '@/App.vue';
import '@/registerServiceWorker.js';
import store from '@/store/index.js';
import router from '@/router/index.js';
import ui from '@/components/ui/index.js';
import notify from '@/plugins/notify/index.js';
import { i18n } from '@/plugins/i18n/index.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import config from './config/index.js';

dayjs.locale('fr');

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://f6c640ca57ad4a1e904c6f227bb103ab@sentry.skyloud.dev/3',
  integrations: [
    Sentry.browserTracingIntegration({
      router,
    }),
    Sentry.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  environment: config.appUrl === 'https://skyloud.app' ? 'production' : 'staging',
  enabled: config.appUrl.startsWith('https://'),
  release: config.appVersion,
  ignoreErrors: ['ChunkLoadError', 'CanceledError'],
});

app.use(store).use(router).use(i18n).use(notify).use(ui).component('FontAwesomeIcon', FontAwesomeIcon).mount('#app');
