import accountsApi from '@/modules/shared/api/accounts.js';
import * as utils from '@/utils/index.js';

export async function getUsers({ limit, skip, search }) {
  const query = utils.queryString({ limit, skip, search });
  const { data } = await accountsApi().get(`/admin/users?${query}`);
  return {
    list: data.list,
    total: data.total,
  };
}

export async function getUser({ userId }) {
  const { data: user } = await accountsApi().get(`/admin/users/${userId}`);
  return user;
}

export async function postCreateUser({ email, firstName, lastName, job, metadata, address, phone, roles }) {
  const { data: user } = await accountsApi().post('/admin/users/', {
    email,
    firstName,
    lastName,
    job,
    metadata,
    address,
    phone,
    roles,
  });
  return user;
}

export async function patchUser({ userId, email, firstName, lastName, job, metadata, address, phone, roles }) {
  const { data: user } = await accountsApi().patch('/admin/users/', {
    userId,
    email,
    firstName,
    lastName,
    job,
    metadata,
    address,
    phone,
    roles,
  });
  return user;
}

export async function deleteUser({ userId }) {
  await accountsApi().delete(`/admin/users/${userId}`);
}

export async function patchUserProfile({ firstName, lastName, address, phone, locale }) {
  await accountsApi().patch('/private/users/me', {
    firstName,
    lastName,
    address,
    phone,
    locale,
  });
}
