<template>
  <SkyModal
    v-bind="$attrs"
    :open="open"
    @close="$emit('close')"
  >
    <SkyTitle class="text-2xl">
      <FontAwesomeIcon
        :icon="faArchive"
        class="mr-2"
      />
      {{ $t('components.projects.archivedTasksListModal.title') }}
    </SkyTitle>
    <TasksList
      class="mt-5"
      :watch-scroll="hasMore"
      @on-scroll-bottom="nextPagination"
    >
      <SkyLoading v-if="loading" />
      <div
        v-if="deletedTasks.length === 0"
        class="flex items-center justify-center flex-1 my-8"
      >
        <FontAwesomeIcon
          :icon="faTransporterEmpty"
          size="4x"
          class="text-gray-300"
        />
      </div>
      <TaskCard
        v-for="task of deletedTasks"
        :key="task._id"
        :task="task"
      />
    </TasksList>
  </SkyModal>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import { useNotify } from '@/plugins/notify/index.js';
import TasksList from '@/modules/projects/components/TasksList.vue';
import TaskCard from '@/modules/projects/components/TaskCard.vue';
import { faTransporterEmpty } from '@fortawesome/pro-duotone-svg-icons';
import { faArchive } from '@fortawesome/pro-light-svg-icons';

export default {
  components: {
    TasksList,
    TaskCard,
  },
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['close'],
  setup(props) {
    const store = useStore();
    const notify = useNotify();

    const state = reactive({
      deletedTasks: computed(() => store.getters['projects/getFilteredDeletedTasks']),
      hasMore: computed(() => store.getters['projects/getHasMoreDeletedTasks']),
      loading: true,
    });

    async function fetchDeletedTasks() {
      try {
        if (!props.open) {
          return;
        }
        await store.dispatch('projects/fetchDeletedTasks');
      } catch (err) {
        notify.error(err.message);
      }
      state.loading = false;
    }

    async function nextPagination() {
      try {
        await store.dispatch('projects/fetchDeletedTasks', { fetchNext: true });
      } catch (err) {
        notify.error(err.message);
      }
    }

    watch(() => props.open, fetchDeletedTasks);

    return {
      ...toRefs(state),
      nextPagination,
      faArchive,
      faTransporterEmpty,
    };
  },
};
</script>

<style></style>
