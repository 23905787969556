import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import uniqBy from 'lodash/uniqBy';
import config from '@/config/index.js';
import { i18n } from '@/plugins/i18n/index.js';
import { getThreadMessages, postCreateMessage } from '@/modules/chats/chats.api.js';

const stateData = {
  messagesSocket: undefined,
  threads: {},
};

const getters = {
  getThreadMessages:
    (state) =>
    ({ threadId }) =>
      get(state.threads, [threadId, 'messages'], []),
};

const mutations = {
  resetState(state) {
    Object.assign(state, stateData);
  },
  setMessageSocket(state, { messagesSocket }) {
    state.messagesSocket = messagesSocket;
  },
  addThread(state, { threadId }) {
    state.threads[threadId] = {
      hasMore: false,
      messages: [],
    };
  },
  saveThreadMessages(state, { threadId, hasMore, threadMessages }) {
    state.threads[threadId] = {
      hasMore,
      messages: threadMessages,
    };
  },
  addThreadMessage(state, { message }) {
    const { threadId } = message;
    if (!hasIn(state.threads, threadId)) {
      return;
    }
    state.threads[threadId].messages = uniqBy([message, ...state.threads[threadId].messages], '_id');
  },
};

const actions = {
  async fetchThreadMessages({ commit }, { threadId }) {
    const { hasMore, threadMessages } = await getThreadMessages({ threadId });
    commit('saveThreadMessages', { hasMore, threadId, threadMessages });
  },
  async sendMessage({ commit }, { text, threadId, taskId, isPrivateThread }) {
    try {
      const message = await postCreateMessage({
        threadId,
        taskId,
        text,
        isPrivateThread,
      });
      commit('addThreadMessage', { threadId, message });
      return message;
    } catch (err) {
      throw new Error(i18n.global.t('chats.store.sendMessage.requestError'));
    }
  },
  watchMessagesOpen({ commit, state, rootGetters, dispatch }) {
    if (state.messagesSocket) return;
    const token = rootGetters['auth/token'];
    if (!token) {
      return;
    }

    const { host, protocol } = new URL(config.endpoints.chats);
    const socketProtocol = protocol === 'http:' ? 'ws:' : 'wss:';

    const messagesSocket = new WebSocket(`${socketProtocol}//${host}?token=${token}`);

    messagesSocket.onmessage = ({ data }) => {
      try {
        const { type, payload } = JSON.parse(data);
        if (!payload.document) {
          return;
        }
        commit(type, { message: payload.document });
      } catch (err) {
        console.error(err);
      }
    };

    messagesSocket.onopen = () => {
      console.debug('socket:chats#open');
    };

    messagesSocket.onclose = () => {
      commit('setMessageSocket', { messagesSocket: undefined });
      console.debug('socket:chats#closed');
      setTimeout(() => {
        dispatch('watchMessagesOpen');
      }, 5000);
    };

    commit('setMessageSocket', { messagesSocket });
  },
  watchMessagesClose({ commit, state }) {
    if (!state.messagesSocket) return;
    state.messagesSocket.close();
    commit('setMessageSocket', { messagesSocket: undefined });
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
