import { ApiFindAllProbeEventsResponse } from '@/modules/stats/availability/infrastructure/repositories/probe-events/api-find-all-probe-events-response.js';
import { ProbeEventsRepository } from '@/modules/stats/availability/domain/repositories/probe-events-repository.js';
import get from 'lodash/get';

/**
 * @implements {ProbeEventsRepository}
 */
export class ApiProbeEventsRepository extends ProbeEventsRepository {
  constructor(httpClient) {
    super();
    this._httpClient = httpClient;
  }

  async findAll(period) {
    const from = get(period, 'from');
    const to = get(period, 'to');

    if (!from || !to) {
      const response = await this._httpClient.get('/admin/probes/incidents');
      return new ApiFindAllProbeEventsResponse(response.data).toProbeEvents();
    }

    const response = await this._httpClient.get(`/admin/probes/incidents?from=${from}&to=${to}`);
    return new ApiFindAllProbeEventsResponse(response.data).toProbeEvents();
  }
}
