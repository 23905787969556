<template>
  <div class="flex flex-col">
    <div class="p-4">
      <div class="font-medium uppercase">
        <FontAwesomeIcon :icon="faInfoCircle" />
        {{ $t('components.projects.statusTaskControl.awaitingValidation.title') }}
      </div>
      <p
        v-if="currentUser.roles.includes('admin:skyloud')"
        class="text-sm"
      >
        {{ $t('components.projects.statusTaskControl.awaitingValidation.waitingClient') }}
      </p>
      <div v-else-if="currentUser.roles.includes('admin')">
        <p class="text-sm">
          {{ $t('components.projects.statusTaskControl.awaitingValidation.clientAnswer.taskEstimation', { taskId: task.taskId }) }}
          <strong>{{ formatDurationFromMs(task.estimatedTime) }}</strong>
          .
        </p>
        <p class="mt-2 text-sm">{{ $t('components.projects.statusTaskControl.awaitingValidation.clientAnswer.actionNeeded') }}</p>
        <div class="grid">
          <SkyButton
            class="mt-4"
            size="small"
            variant="plain"
            color="white"
            @click="confirmEstimationTime"
          >
            {{ $t('components.projects.statusTaskControl.awaitingValidation.clientAnswer.acceptButton') }}
          </SkyButton>
          <SkyButton
            class="mt-4"
            size="small"
            variant="outline"
            color="white"
            @click="refuseEstimationTime"
          >
            {{ $t('components.projects.statusTaskControl.awaitingValidation.clientAnswer.refuseButton') }}
          </SkyButton>
        </div>
      </div>
      <p
        v-else
        class="text-sm"
      >
        {{ $t('components.projects.statusTaskControl.requestLocked') }}
      </p>
    </div>
    <AppTaskPrice
      v-if="shouldDisplayTaskPrice"
      class="w-full rounded-t-none"
      is-folded
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import AppTaskPrice from '@/modules/apps/components/AppTaskPrice.vue';
import projectsApi from '@/modules/shared/api/projects.js';
import { formatDurationFromMs } from '@/utils/format.js';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import find from 'lodash/find';
import includes from 'lodash/includes';
import get from 'lodash/get';
import { useNotify } from '@/plugins/notify/index.js';

const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const notify = useNotify();

const hasRole = store.getters['auth/hasRole'];

const currentUser = computed(() => store.getters['auth/currentUser']);
const missionLabel = computed(() => find(get(store, 'state.projects.labels', []), (label) => label.name === 'Mission'));
const shouldDisplayTaskPrice = computed(() => !hasRole('admin:skyloud') && hasRole('admin') && includes(get(props, 'task.labelIds', []), get(missionLabel.value, '_id')));

async function fetchTask() {
  try {
    if (!props.task._id) return;
    await store.dispatch('projects/fetchTask', { taskId: props.task._id });
  } catch (err) {
    notify.error(err.message);
  }
}

async function confirmEstimationTime() {
  try {
    await projectsApi().post(`/admin/tasks/${props.task._id}/confirm`);
    await store.dispatch('projects/updateTaskPendingStatus', { _id: props.task._id, isPending: false });
    await fetchTask();
  } catch (err) {
    notify.error(err.message);
  }
}

async function refuseEstimationTime() {
  try {
    await projectsApi().post(`/admin/tasks/${props.task._id}/refuse`);
    await store.dispatch('projects/updateTaskPendingStatus', { _id: props.task._id, isPending: false });
    await fetchTask();
  } catch (err) {
    notify.error(err.message);
  }
}
</script>
