<template>
  <div
    v-show="open"
    ref="root"
    class="absolute left-0 z-10 flex flex-col gap-1 p-2 mt-2 transform bg-white border border-gray-100 rounded-xl shadow-xl outline-none top-full"
    tabindex="-1"
  >
    <slot />
  </div>
</template>

<script>
import { nextTick, onMounted, ref, watch } from 'vue';

export default {
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const root = ref(null);

    onMounted(() => {
      if (!props.open) {
        return;
      }
      nextTick(() => root.value?.focus());
    });

    watch(
      () => props.open,
      (value) => {
        if (!value) {
          return;
        }
        nextTick(() => root.value?.focus());
      },
    );

    return {
      root,
    };
  },
};
</script>

<style></style>
