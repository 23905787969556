import dayjs from 'dayjs';
import bookingsApi from '@/modules/shared/api/bookings.js';
import * as utils from '@/utils/index.js';

export async function getBookings({ limit, skip, search, giftsOnly }) {
  const query = utils.queryString({
    limit,
    skip,
    search,
    giftsOnly,
  });
  const { data } = await bookingsApi().get(`/admin/bookings?${query}`);
  return {
    list: data.list,
    total: data.total,
  };
}

export async function getBookingsFromDateRange({ start, end }) {
  const query = utils.queryString({
    start,
    end,
  });
  const { data: bookings } = await bookingsApi().get(`/admin/bookings/date-range?${query}`);
  return bookings;
}

export async function getOneBooking({ bookingId }) {
  const { data: booking } = await bookingsApi().get(`/admin/bookings/${bookingId}`);
  return booking;
}

export async function getRooms() {
  const { data: rooms } = await bookingsApi().get('/admin/rooms');
  return rooms;
}

export async function postAddRoom({ productId, priceId, taxRateId, bookingOptions, bookingRestrictions, priceList, paymentAdvancePercent }) {
  const { data: room } = await bookingsApi().post('/admin/rooms', {
    productId,
    priceId,
    taxRateId,
    bookingOptions,
    bookingRestrictions,
    priceList,
    paymentAdvancePercent,
  });
  return room;
}

export async function patchUpdateRoom({ roomId, productId, priceId, taxRateId, bookingOptions, bookingRestrictions, priceList, paymentAdvancePercent }) {
  await bookingsApi().patch(`/admin/rooms/${roomId}`, {
    productId,
    priceId,
    taxRateId,
    bookingOptions,
    bookingRestrictions,
    priceList,
    paymentAdvancePercent,
  });
}

export async function patchUpdateBooking({ bookingId, status, arrivalDate, quantity, note, roomId, priceId: giftPriceId, adults, children }) {
  await bookingsApi().patch(`/admin/bookings/${bookingId}`, {
    status,
    arrivalDate,
    quantity,
    note,
    roomId,
    giftPriceId,
    adults,
    children,
  });
}

export async function postAddBooking({ roomId, status, arrivalDate, departureDate, note, isGift, priceId, userId, adults, children }) {
  const { data: booking } = await bookingsApi().post('/admin/bookings', {
    roomId,
    status,
    arrivalDate,
    quantity: dayjs(departureDate).diff(arrivalDate, 'days'),
    note,
    isGift,
    priceId,
    userId,
    adults,
    children,
  });

  return booking;
}

export async function deleteBooking({ bookingId }) {
  await bookingsApi().delete(`/admin/bookings/${bookingId}`);
}

export async function postAddBookingOption({ bookingId, priceId, productId, taxRateId, quantity }) {
  const { data } = await bookingsApi().post(`/admin/bookings/${bookingId}/options`, {
    priceId,
    productId,
    taxRateId,
    quantity,
  });

  return data;
}

export async function deleteBookingOption({ bookingId, bookingOptionId }) {
  await bookingsApi().delete(`/admin/bookings/${bookingId}/options/${bookingOptionId}`);
}

export async function patchUpdateBookingOption({ bookingId, bookingOptionId, quantity }) {
  await bookingsApi().patch(`/admin/bookings/${bookingId}/options/${bookingOptionId}`, {
    quantity,
  });
}

export async function postAddBookingPayment({ bookingId, method, description, amountInCents, paymentAt }) {
  const { data } = await bookingsApi().post(`/admin/bookings/${bookingId}/payments`, {
    method,
    description,
    amountInCents,
    paymentAt,
  });

  return data;
}

export async function deleteBookingPayment({ bookingId, bookingPaymentId }) {
  await bookingsApi().delete(`/admin/bookings/${bookingId}/payments/${bookingPaymentId}`);
}
