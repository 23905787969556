import cloudsApi from '@/modules/shared/api/clouds.js';

export async function getServers() {
  const { data } = await cloudsApi().get('/admin/servers');
  const { hasMore = false, list: servers = [] } = data;
  return { hasMore, servers };
}

export async function getServer({ serverId }) {
  const { data } = await cloudsApi().get(`/admin/servers/${serverId}`);
  return data;
}

export async function getKubernetesById({ kubernetesId }) {
  const { data } = await cloudsApi().get(`/admin/kubernetes/${kubernetesId}`);
  return data;
}

export async function postServerAction({ serverId, action }) {
  const { data } = await cloudsApi().post(`/admin/servers/${serverId}/action`, {
    action,
  });
  return data;
}

export async function getProviderServerTypesFromZone({ provider, zone }) {
  const { data } = await cloudsApi().get(`/admin/servers/types?provider=${provider}&zone=${zone}`);
  const { hasMore = false, list: serverTypes = [] } = data;

  return { hasMore, serverTypes };
}

export async function getKubernetes() {
  const { data } = await cloudsApi().get('/admin/kubernetes');
  const { hasMore = false, list: kubernetes = [] } = data;
  return { hasMore, kubernetes };
}

export async function postCreateServer({ zone, commercialType, image, provider }) {
  const { data } = await cloudsApi().post('/admin/servers', {
    provider,
    config: {
      zone,
      commercialType,
      image,
    },
  });
  return data;
}
