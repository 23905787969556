<template>
  <div
    class="relative text-left outline-none h-10 leading-10"
    @blur="open = false"
  >
    <div
      class="rounded-md bg-gradient-radial bg-gradient-right-top from-primary to-secondary text-white-smoke pl-4 flex flex-row items-center cursor-pointer select-none p-2"
      :class="{ 'rounded-none rounded-t-md': open }"
      @click="open = !open"
    >
      <FontAwesomeIcon
        v-if="icon"
        :icon="icon"
        class="mr-2"
      />
      <div class="flex flex-row items-center gap-2 justify-between">
        <span class="text-xs">{{ selected.label }}</span>
        <div class="flex flex-col items-center justify-center text-[8px]">
          <FontAwesomeIcon :icon="faChevronUp" />
          <FontAwesomeIcon :icon="faChevronDown" />
        </div>
      </div>
    </div>
    <div
      class="items text-black rounded-b-md border border-secondary relative bg-[#F8F9FC] left-0 right-0 z-50 w-full"
      :class="{ hidden: !open }"
    >
      <div
        v-for="(option, i) of filteredOptions"
        :key="i"
        class="pl-4 cursor-pointer select-none hover:bg-black hover:bg-opacity-5 text-xs w-full p-2"
        @click="
          selected = option;
          open = false;
          emit('input', { option });
        "
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { computed, onMounted, ref } from 'vue';

const emit = defineEmits(['input']);
const props = defineProps({
  default: {
    type: String || Number,
    default: undefined,
  },
  icon: {
    type: Object,
    default: undefined,
  },
  options: {
    type: Array,
    required: true,
  },
});

const selected = ref(props.default ? props.default : props.options[0]);
const open = ref(false);

const filteredOptions = computed(() => props.options.filter((o) => o.label !== selected.value.label));

onMounted(() => {
  emit('input', { option: selected.value, init: true });
});
</script>

<style scoped></style>
