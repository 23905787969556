<template>
  <AvailabilityTemplateWidget
    :history="consolidatedProbesEventsHistory"
    :date-provider="dateProvider"
    :probes="probes"
    :months-to-display="1"
  />
</template>

<script setup>
import { RealDateProvider } from '@/modules/stats/availability/infrastructure/dates/real-date-provider.js';
import AvailabilityTemplateWidget from '@/modules/stats/availability/infrastructure/presenters/organisms/widget/AvailabilityTemplateWidget.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const dateProvider = new RealDateProvider();

const probes = computed(() => store.getters['stats/probes']);
const consolidatedProbesEventsHistory = computed(() => store.getters['stats/probesEventsHistory']);
</script>
