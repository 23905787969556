<template>
  <div class="flex flex-col gap-2 p-4">
    <div class="font-medium uppercase">
      <FontAwesomeIcon :icon="faCheckDouble" />
      {{ $t('components.projects.statusTaskControl.done.title') }}
    </div>
    <p
      v-if="currentUser.roles.includes('admin:skyloud')"
      class="text-sm"
    >
      {{ $t('components.projects.statusTaskControl.done.requestCompletedBillable') }} 💰
      <br />
      <span>
        {{ $t('components.projects.statusTaskControl.isPending.skyloudWaiting.willBeArchived') }} : {{ formatDate(taskWillBeArchivedAt, 'DD MMMM YYYY - HH:mm') }}
        {{ $t('components.projects.statusTaskControl.isPending.skyloudWaiting.exceptDevisMission') }}
      </span>
    </p>
    <p
      v-else
      class="text-sm"
    >
      {{ $t('components.projects.statusTaskControl.done.requestCompletedWillBeArchived') }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import { formatDate } from '@/utils/format.js';
import { faCheckDouble } from '@fortawesome/pro-light-svg-icons';

const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const getThreadMessages = store.getters['chats/getThreadMessages'];

const currentUser = computed(() => store.getters['auth/currentUser']);
const messages = computed(() => (props.task ? getThreadMessages({ threadId: props.task.threadId }) : []));
const lastCompletedMessage = computed(() => messages.value.find((message) => /a déplacé la tâche de .+ à \*\*✅ Terminé\*\*/.test(message.text)));
const taskWillBeArchivedAt = computed(() => dayjs(lastCompletedMessage.value?.createdAt).add(45, 'day').toDate());
</script>
