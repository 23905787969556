import chatsApi from '@/modules/shared/api/chats.js';

export async function getThreadMessages({ threadId }) {
  const { data } = await chatsApi().get(`/admin/threads/${threadId}/messages`);
  const { hasMore, list: threadMessages } = data;

  return { hasMore, threadMessages };
}

export async function postCreateMessage({ threadId, taskId, text, isPrivateThread }) {
  const { data } = await chatsApi().post('/admin/messages', {
    threadId,
    taskId,
    text,
    isPrivateThread,
  });

  return data;
}
