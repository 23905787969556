/**
 * @interface
 */
export class ProbesRepository {
  /**
   * @return {Promise<Probes>}
   */
  async findAll() {
    throw new Error('Not implemented');
  }
}
