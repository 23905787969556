<template>
  <div class="flex flex-col gap-1">
    <label class="relative block">
      <div
        v-if="label"
        class="block mb-2 text-sm font-medium text-primary"
        :class="{ required: state.isRequired }"
      >
        {{ label }}
      </div>
      <input
        ref="input"
        v-model="inputTextValue"
        type="text"
        autocomplete="off"
        style="transition: all 0.15s ease 0s"
        class="w-full h-10 px-3 text-sm placeholder-gray-400 bg-white border rounded appearance-none focus:outline-none focus:shadow-outline"
        :class="inputClasses"
        :placeholder="placeholder"
        :disabled="disabled"
        @focus="selectPopupOpen = true"
        @blur="handleCleanBlur"
      />
      <span
        class="flex items-center mt-1 ml-1 text-xs tracking-wide"
        :class="{
          hidden: !state.hasErrorMessages,
          'font-medium pt-2 text-red-500': state.hasErrorMessages,
        }"
      >
        {{ state.firstErrorMessage }}
      </span>
      <div
        v-show="selectPopupOpen"
        class="absolute z-10 w-full mt-2 overflow-y-auto bg-white border rounded shadow-xl top-full max-h-48"
        @mousedown.prevent
      >
        <div
          v-if="loading"
          class="px-2 py-1 text-sm text-center text-gray-400"
        >
          {{ $t('components.ui.skyAsyncSelect.loading') }}
        </div>
        <div
          v-else-if="options.length === 0"
          class="px-2 py-1 text-sm text-center text-gray-400"
        >
          {{ $t('components.ui.skyAsyncSelect.notFound') }}
        </div>
        <div v-show="!loading">
          <div
            v-for="option of filteredOptions"
            :key="option[pathValue]"
            class="px-4 py-2 cursor-pointer hover:bg-gray-50"
            @click="(option.isRegistered || !needRegistered) && handleSelectOption(option)"
          >
            <slot :option="option">
              <div
                class="flex flex-row items-center gap-2"
                :class="{
                  'cursor-not-allowed text-gray-400': needRegistered && !option.isRegistered,
                }"
              >
                <img
                  v-if="option.logo"
                  :src="option.logo"
                  class="max-w-[80px]"
                  alt="app logo"
                />
                <span v-else>
                  <FontAwesomeIcon :icon="faImageSlash" />
                </span>
                <div class="flex flex-row items-center">
                  <span>[{{ option.slug.toUpperCase() }}] {{ option[pathLabel] }}</span>
                  <span
                    v-if="needRegistered && !option.isRegistered"
                    class="ml-2"
                  >
                    - Account required
                  </span>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </label>
    <div
      v-if="isMulti"
      v-show="modelValue.length > 0"
      class="flex flex-col flex-wrap text-xs mt-2"
    >
      <slot
        v-for="modelItem of modelValue"
        :key="modelItem"
        name="item"
        :item="getApp(modelItem)"
        :handle-remove-item="handleRemoveItem"
      />
    </div>
  </div>
</template>

<script setup>
import useValidatable from '@/modules/shared/utils/validatable.js';
import { faImageSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import { computed, onMounted, ref, watch } from 'vue';

const emit = defineEmits(['search', 'update:modelValue']);
const props = defineProps({
  modelValue: { type: [String, Object, Number, Array], default: () => undefined },
  default: { type: [String, Object, Number, Array], default: () => undefined },
  label: { type: String, default: () => undefined },
  placeholder: { type: String, default: () => undefined },
  disabled: { type: Boolean, default: () => false },
  needRegistered: { type: Boolean, default: () => true },
  rules: { type: Array, default: () => [] },
  pathLabel: { type: String, default: () => 'name' },
  pathValue: { type: String, default: () => '_id' },
  loading: { type: Boolean, default: () => false },
  options: {
    type: Array,
    default: () => [],
  },
});

const { state } = useValidatable({ props, emit });

const inputClasses = computed(() => ({
  'ring-1': state.hasErrorMessages,
  'ring-red-500': state.hasErrorMessages,
}));

const selectPopupOpen = ref(false);
const isMulti = computed(() => isArray(props.modelValue));
const inputTextValue = ref('');
const internalValue = ref();
const filteredOptions = computed(() =>
  props.options
    .filter((app) => {
      const isAppNameValid = app.name.toLowerCase().includes(inputTextValue.value.toLowerCase());
      const isAppSlugValid = app.slug.toLowerCase().includes(inputTextValue.value.toLowerCase());
      return isAppNameValid || isAppSlugValid;
    })
    .slice(0, 5),
);

function getApp(appId) {
  return props.options.find((app) => app[props.pathValue] === appId);
}

function handleSelectOption(option) {
  selectPopupOpen.value = false;

  if (!isMulti.value) {
    inputTextValue.value = option[props.pathLabel];
    internalValue.value = option[props.pathValue];
    emit('update:modelValue', internalValue.value);
    return;
  }

  inputTextValue.value = '';
  internalValue.value = uniq([...(internalValue.value || []), option[props.pathValue]]);
  emit('update:modelValue', internalValue.value);
}

function handleRemoveItem(modelItem) {
  internalValue.value = filter(internalValue.value, (value) => value !== modelItem);
  emit('update:modelValue', internalValue.value);
}

function handleCleanBlur() {
  selectPopupOpen.value = false;
}

function handleModelValue() {
  if (isMulti.value) {
    inputTextValue.value = '';
    internalValue.value = props.modelValue;
    return;
  }

  const value = props.modelValue || props.default;
  const option = find(props.options, {
    [props.pathValue]: value,
  });

  if (!option) {
    return;
  }

  inputTextValue.value = option[props.pathLabel];
  internalValue.value = option[props.pathValue];
  emit('update:modelValue', option[props.pathValue]);
}

onMounted(handleModelValue);

watch(() => {
  if (isMulti.value) {
    return `${props.options.length}_${props.modelValue.length}`;
  }
  return `${props.options.length}_${props.modelValue}`;
}, handleModelValue);

watch(() => props.modelValue, handleModelValue);
</script>

<style lang="scss" scoped>
.required::after {
  @apply content-asterisk ml-1 text-red-600 font-medium;
}
</style>
