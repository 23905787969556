<template>
  <a
    class="external-link"
    :style="{
      backgroundColor: secondaryColor,
    }"
    target="_blank"
    v-bind="$attrs"
  >
    <div
      class="external-link__radial"
      :style="{
        background: `radial-gradient(at top center, ${primaryColor}, transparent)`,
      }"
    />
    <div class="external-link__content">
      <img
        :src="logoUrl"
        class="h-10 max-w-full"
        :alt="name"
      />
    </div>
  </a>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    primaryColor: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
    logoUrl: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.external-link {
  @apply rounded-xl relative flex items-center justify-center cursor-pointer overflow-hidden;

  &__radial {
    @apply absolute h-full transition-transform transform ease-in-out;
    width: 200%;
    left: -100%;
  }

  &:hover > &__radial {
    @apply translate-x-1/2;
  }

  &__content {
    @apply relative p-6;
  }
}
</style>
