import { DateProvider } from '@/modules/stats/availability/domain/dates/date-provider.js';
import { DateValueObject } from '@/utils/value-objects/date/date-value-object.js';

/**
 * @implements {DateProvider}
 */
export class RealDateProvider extends DateProvider {
  /**
   * @type {Date}
   * @private
   */
  _date;

  constructor(date) {
    super();
    this._date = date;
  }

  now() {
    if (this._date) {
      return DateValueObject.fromNative(this._date);
    }

    return DateValueObject.fromNative(new Date());
  }
}
