<template>
  <div>
    <SkyModal
      v-bind="$attrs"
      :open="open"
      transparent
      @close="handleModalClose"
    >
      <SkyLoading v-if="!task" />
      <div
        v-else
        class="flex flex-wrap gap-4 lg:flex-row lg:items-start"
        :class="{
          'flex-col-reverse': staffOnly,
          'flex-col': !staffOnly,
        }"
        data-modal-close-on-click-sepcific-el
      >
        <div class="relative flex flex-col flex-1 min-w-0 gap-4 px-4 pb-4 bg-white rounded-lg shadow pointer-events-auto lg:w-3/4">
          <div class="flex flex-col flex-1 gap-1 text-primary sticky -top-1 left-0 bg-white z-40 pt-4 pb-1">
            <div class="flex items-start justify-between">
              <div>
                <SkyTitle>{{ task.taskId }} - {{ task.name }}</SkyTitle>
              </div>
            </div>
            <ul class="flex gap-2 text-xs">
              <li class="flex gap-1">
                <FontAwesomeIcon :icon="faUser" />
                {{ $t('components.projects.editTaskModal.createdBy') }}
                <User :user-id="task.userId" />
              </li>
              <li>
                <FontAwesomeIcon :icon="faClock" />
                {{ $t('components.projects.editTaskModal.createdAt') }} {{ formatDate(task.createdAt) }}
              </li>
              <li v-if="task.memberIds && task.memberIds.length > 1">
                <FontAwesomeIcon :icon="faUsers" />
                {{ task.memberIds.length }} {{ $t('components.projects.editTaskModal.subscribers') }}
              </li>
            </ul>
          </div>

          <div class="flex flex-wrap items-start gap-2 text-sm">
            <SkyTooltip
              v-if="latestTaskMilestone"
              variant="white-smoke"
            >
              <template #content>
                {{ formatDate(latestTaskMilestone.startAt) }}
                -
                {{ formatDate(latestTaskMilestone.endAt) }}
              </template>
              <TaskMilestoneIcon
                v-if="task"
                :milestone="latestTaskMilestone"
                :task="task"
              />
            </SkyTooltip>
            <TaskPriorityIcon
              v-if="task.priority"
              :priority="task.priority"
              class="text-sm"
            />
            <div
              v-if="task.dueAt"
              class="gap-2 px-2 py-1 border rounded text-primary border-primary"
            >
              <FontAwesomeIcon
                :icon="faHourglassStart"
                class="mr-1"
              />
              {{ formatDate(task.dueAt) }}
            </div>
            <div
              v-if="task.estimatedTime"
              class="gap-2 px-2 py-1 border rounded text-primary border-primary"
            >
              <FontAwesomeIcon
                :icon="faClock"
                class="mr-1"
              />
              {{ formatDurationFromMs(task.acceptedEstimatedTime) }}
            </div>
            <div
              v-if="task.deletedAt"
              class="px-2 py-1 text-white bg-yellow-600 border border-yellow-600 rounded"
            >
              {{ $t('components.projects.editTaskModal.archived') }}
            </div>
          </div>

          <div class="flex flex-col gap-6 lg:flex-row">
            <TaskForm
              :task="task"
              :read-only="!staffOnly"
              class="w-full"
              @valid="handleSubmit"
              @active="handleFormActive"
            >
              <template #footer>
                <div
                  v-if="staffOnly"
                  class="flex flex-col w-full"
                >
                  <div class="flex flex-row items-center justify-end mb-2">
                    <SkyButton
                      :loading="loading"
                      animation="none"
                      size="small"
                      type="submit"
                    >
                      <FontAwesomeIcon
                        :icon="faSave"
                        class="mr-2 text-base"
                      />
                      {{ $t('components.projects.editTaskModal.saveDetailsButton') }}
                    </SkyButton>
                  </div>
                </div>
              </template>
            </TaskForm>
          </div>
          <SkyTabs
            v-if="staffOnly"
            v-model="selectedThread"
            path="slug"
            :tabs="threadTabs"
          />
          <Thread
            v-if="task.threadId && selectedThread === 'client'"
            :thread-id="task.threadId"
            :task-id="task._id"
            @active="handleFormActive"
          />
          <Thread
            v-if="task.privateThreadId && selectedThread === 'internal' && staffOnly"
            :thread-id="task.privateThreadId"
            :task-id="task._id"
            is-private
            @active="handleFormActive"
          />
          <div
            v-if="!task.privateThreadId && selectedThread === 'internal' && staffOnly"
            class="flex items-center justify-center py-6"
            @click="handleCreateInternalThread"
          >
            <SkyButton
              size="small"
              :loading="threadLoading"
            >
              Débuter la discussion interne
            </SkyButton>
          </div>
        </div>
        <div
          class="flex flex-col gap-4 pointer-events-auto lg:w-1/3"
          data-modal-close-on-click-sepcific-el
        >
          <StatusTaskControl
            v-if="!task.invoicedAt"
            :task-id="taskId"
          />
          <div
            v-if="task.invoicedAt"
            class="flex flex-col gap-2 p-4 text-white bg-white rounded-lg shadow bg-gradient-to-tr from-green-600 to-green-500"
          >
            <div class="font-medium uppercase">
              <FontAwesomeIcon :icon="faReceipt" />
              {{ $t('components.projects.editTaskModal.invoiced') }}
            </div>
            <p class="text-sm">{{ $t('components.projects.editTaskModal.invoicedAt') }} {{ formatDate(task.invoicedAt) }}.</p>
            <a
              v-if="task.metadata && task.metadata.stripeInvoiceHostedUrl"
              :href="task.metadata.stripeInvoiceHostedUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="flex items-baseline px-4 py-1 mt-2 text-xs font-semibold uppercase border border-white rounded hover:bg-white hover:bg-opacity-20"
            >
              <FontAwesomeIcon
                :icon="faCloudDownload"
                class="mr-1"
              />
              <span class="flex-1 text-center">{{ $t('components.projects.editTaskModal.invoice') }} {{ task.metadata.stripeInvoiceNumber }}</span>
            </a>
          </div>
          <QuickWorklogManager
            v-if="staffOnly"
            :task="task"
            @add="$emit('update-worklogs')"
          />
          <div class="flex flex-col items-start justify-start gap-2 p-5 bg-white rounded-lg shadow">
            <SkyTitle class="text-lg">
              <FontAwesomeIcon
                :icon="faBriefcase"
                class="mr-1 text-primary"
              />
              {{ $t('components.projects.editTaskModal.availability.name') }}
            </SkyTitle>
            <div class="flex gap-1 text-xs text-gray-500">
              <span>{{ $t('components.projects.editTaskModal.availability.timeSpent') }} :</span>
              <FontAwesomeIcon
                v-if="staffOnly"
                :icon="faFileInvoiceDollar"
              />
              <span>{{ formatDurationFromMs(billableTime, 'm', 'h') }}</span>
              <FontAwesomeIcon
                v-if="staffOnly"
                :icon="faShield"
              />
              <span v-if="staffOnly">{{ formatDurationFromMs(realEllapsedTime, realEllapsedTime < 60000 ? 's' : 'm', 'h') }}</span>
            </div>
            <div
              v-if="staffOnly"
              class="w-full"
            >
              <ul
                v-if="taskWorkLogsErrors.length > 0"
                class="space-y-1 text-sm"
              >
                <li
                  v-for="(taskErrorLabel, index) of taskWorkLogsErrors"
                  :key="index"
                  class="w-full px-2 py-1 text-xs text-white rounded-md bg-error relative"
                >
                  <span class="absolute inset-0 z-0 bg-error worklog-error-ping rounded-md"></span>
                  <span class="relative z-10">
                    <FontAwesomeIcon
                      :icon="faExclamationCircle"
                      class="mr-1"
                    />
                    {{ taskErrorLabel }}
                  </span>
                </li>
              </ul>
              <div
                v-if="
                  !openWorklogRunning &&
                  (taskWorkLogsErrors.length === 0 || (taskWorkLogsErrors.length > 0 && hasNeedNewEstimationError) || (taskWorkLogsErrors.length > 0 && hasEstimatedWaitingError))
                "
                class="flex gap-2"
                :class="{
                  'mt-4': hasNeedNewEstimationError || hasEstimatedWaitingError,
                }"
              >
                <button
                  v-for="action of workLogActionsAvailable"
                  :key="action"
                  type="button"
                  class="flex-1 py-2.5 px-2 text-xs text-white rounded hover:opacity-75"
                  :disabled="workLogsActionLoading"
                  :class="{
                    'bg-success': action === 'start',
                    'bg-error': action === 'stop',
                    'bg-gray-700': action === 'pause',
                  }"
                  @click="handleVerifyWorklogAction({ action })"
                >
                  <FontAwesomeIcon
                    class="mr-1"
                    :icon="getWorklogActionIcon({ action })"
                  />
                  {{ getActionLabel(action) }}
                </button>
                <button
                  class="btn-clock-add"
                  type="button"
                  @click="formWorkLog.open = !formWorkLog.open"
                >
                  <FontAwesomeIcon
                    v-if="!formWorkLog.open"
                    :icon="faClock"
                  />
                  <FontAwesomeIcon :icon="!formWorkLog.open ? faPlusCircle : faTimesCircle" />
                </button>
              </div>
            </div>

            <RunningWorklogs
              v-if="openWorklogRunning"
              :worklogs="runningWorklogs"
              @close="openWorklogRunning = false"
              @validate="handleClosedRunningWorklogs"
            />

            <SkyForm
              v-if="formWorkLog.open"
              id="edit-task-modal-add-worklog"
              class="flex flex-col w-full gap-2 text-sm"
              @valid="addWorkLog"
            >
              <SkyInputText
                v-model="formWorkLog.form.note"
                :rules="formWorkLog.rules.note"
                :disabled="formWorkLog.loading"
                :placeholder="$t('components.projects.editTaskModal.inputs.worklogs.note.placeholder')"
                :label="$t('components.projects.editTaskModal.inputs.worklogs.note.label')"
                autofocus
              />
              <SkyDatePicker
                v-model="formWorkLog.form.startedAt"
                :rules="formWorkLog.rules.startedAt"
                :disabled="formWorkLog.loading"
                :icon="faCalendarAlt"
                type="datetime-local"
                :label="$t('components.projects.editTaskModal.inputs.worklogs.startedAt.label')"
              />
              <SkyDatePicker
                v-model="formWorkLog.form.stoppedAt"
                :rules="formWorkLog.rules.stoppedAt"
                :disabled="formWorkLog.loading"
                :icon="faCalendarAlt"
                type="datetime-local"
                :label="$t('components.projects.editTaskModal.inputs.worklogs.stoppedAt.label')"
              />
              <span class="text-primary font-medium">{{ $t('components.projects.editTaskModal.inputs.worklogs.pauseTime.label') }}</span>
              <div class="flex flex-row justify-between gap-2 mt-1">
                <SkyInputText
                  v-model="formWorkLog.form.pauseTimeInHours"
                  class="w-full"
                  :helper-text="$t('components.projects.taskSettingForm.estimation.inputs.hours')"
                  step="1"
                  type="number"
                  min="0"
                  @keydown="handleDecimalValueInput"
                />
                <SkyInputText
                  v-model="formWorkLog.form.pauseTimeInMinutes"
                  class="w-full"
                  :helper-text="$t('components.projects.taskSettingForm.estimation.inputs.minutes')"
                  step="1"
                  type="number"
                  :rules="formWorkLog.rules.timeStep"
                  min="0"
                  @keydown="handleDecimalValueInput"
                />
              </div>
              <div class="flex text-primary">
                <button
                  type="button"
                  class="flex-1 border rounded-l border-primary hover:text-white hover:bg-primary"
                  @click="handleFormWorkLogDay(-1)"
                >
                  <FontAwesomeIcon :icon="faLongArrowLeft" />
                </button>
                <button
                  type="button"
                  class="flex-1 border border-l-0 rounded-r border-primary hover:text-white hover:bg-primary"
                  @click="handleFormWorkLogDay(+1)"
                >
                  <FontAwesomeIcon :icon="faLongArrowRight" />
                </button>
              </div>
              <span
                class="flex p-1 text-sm font-light text-gray-500"
                :class="{
                  invisible: !isPauseIncluded || formWorkLog.form.pauseTimeInHours > 0 || formWorkLog.form.pauseTimeInMinutes > 0,
                  visible: isPauseIncluded && formWorkLog.form.pauseTimeInHours === 0 && formWorkLog.form.pauseTimeInMinutes === 0,
                }"
              >
                <FontAwesomeIcon
                  :icon="faInfoCircle"
                  class="mr-1"
                />

                <p>{{ $t('components.projects.editTaskModal.worklogs.pauseIncluded') }}</p>
              </span>
              <SkyButton
                form="edit-task-modal-add-worklog"
                size="small"
                type="submit"
                :disabled="!isWorlogMoreThanOneMinute"
                :loading="formWorkLog.loading"
              >
                {{ $t('components.projects.editTaskModal.worklogs.addButton') }}
              </SkyButton>
            </SkyForm>

            <div
              v-show="!formWorkLog.open && !openWorklogRunning"
              class="flex flex-col flex-1 w-full gap-2 overflow-y-auto rounded"
              :style="isAdminSkyloud ? 'max-height: 28rem' : 'max-height: 24rem'"
            >
              <div
                v-if="workLogsDesc.length === 0"
                class="flex items-center justify-center flex-1 my-8"
              >
                <FontAwesomeIcon
                  :icon="faTransporterEmpty"
                  size="4x"
                  class="text-gray-300"
                />
              </div>
              <TaskWorkLog
                v-for="workLog of workLogsDesc"
                :key="workLog._id"
                :task="task"
                :work-log="workLog"
                :is-invoicable="isWorklogInvoicable(workLog)"
                @delete="handleDeleteTaskWorkLog"
                @update="handleUpdateTaskWorkLog"
              />
            </div>
          </div>
          <div
            v-if="staffOnly"
            class="flex flex-col gap-2 p-4 bg-white rounded-lg shadow"
          >
            <TaskSettingsForm
              :task="task"
              @valid="handleSubmit"
              @active="handleFormActive"
            >
              <template #footer>
                <div class="flex justify-end flex-1 mt-2">
                  <SkyButton
                    :loading="loading"
                    animation="none"
                    size="small"
                    type="submit"
                  >
                    <FontAwesomeIcon
                      :icon="faSave"
                      class="mr-2 text-base"
                    />

                    {{ $t('components.projects.editTaskModal.saveSettingsButton') }}
                  </SkyButton>
                </div>
              </template>
            </TaskSettingsForm>
          </div>
          <SkyButton @click="copyToClipboard">
            <FontAwesomeIcon
              :icon="faLink"
              class="mr-1"
            />
            {{ $t('components.projects.editTaskModal.shareButton') }}
          </SkyButton>
          <SkyButtonConfirm
            v-if="!task.deletedAt && isCurrentUserSkyManager"
            class="px-4 shadow"
            :loading="archiveLoading"
            @confirm="handleArchiveTask"
          >
            <FontAwesomeIcon
              :icon="faArchive"
              class="mr-1"
            />

            {{ $t('components.projects.editTaskModal.archiveButton') }}
          </SkyButtonConfirm>
          <SkyButtonConfirm
            v-if="task.deletedAt && isCurrentUserSkyManager"
            class="px-4 shadow"
            :loading="archiveLoading"
            cooldown="500"
            @confirm="handleUnArchiveTask"
          >
            <FontAwesomeIcon
              :icon="faArchive"
              class="mr-1"
            />

            {{ $t('components.projects.editTaskModal.unArchiveButton') }}
          </SkyButtonConfirm>
        </div>
      </div>
    </SkyModal>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import get from 'lodash/get';
import map from 'lodash/map';
import last from 'lodash/last';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import toLower from 'lodash/toLower';
import reverse from 'lodash/reverse';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce.js';
import isNumber from 'lodash/isNumber';
import includes from 'lodash/includes';
import config from '@/config/index.js';
import { useNotify } from '@/plugins/notify/index.js';
import User from '@/modules/users/components/User.vue';
import Thread from '@/modules/chats/components/Thread.vue';
import TaskForm from '@/modules/projects/components/TaskForm.vue';
import TaskWorkLog from '@/modules/projects/components/TaskWorkLog.vue';
import TaskSettingsForm from '@/modules/projects/components/TaskSettingsForm.vue';
import TaskPriorityIcon from '@/modules/projects/components/TaskPriorityIcon.vue';
import RunningWorklogs from '@/modules/projects/components/RunningWorklogs.vue';
import QuickWorklogManager from '@/modules/projects/components/QuickWorklogManager.vue';
import { formatDate, formatDurationFromMs, formatAmount, roundEllapsedTime, sumDurationToMilliseconds } from '@/utils/format.js';
import { TASK_ELLAPSED_TIME_STEP_IN_MS } from '@/modules/projects/projects.constants.js';
import { getTaskLegalMaxEllapsedTimeAllowed, getTaskWorklogEllapsedTime, handleDecimalValueInput, getInvoicableWorklogs } from '@/modules/projects/projects.functions.js';
import { getIsRequiredRule, getIsAfter, getIsTimeDiffInHoursLessThanRule, getIsModulo } from '@/modules/shared/utils/formValidationRules.js';
import { faCalendarAlt, faSave, faReceipt, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { faLink, faPlay, faPause, faStop, faLock, faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons';
import {
  faUser,
  faUsers,
  faClock,
  faHourglassStart,
  faCloudDownload,
  faBriefcase,
  faPlusCircle,
  faTimesCircle,
  faLongArrowLeft,
  faLongArrowRight,
  faInfoCircle,
  faTransporterEmpty,
  faArchive,
  faFileInvoiceDollar,
  faShield,
} from '@fortawesome/pro-light-svg-icons';
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import StatusTaskControl from './StatusTaskControl.vue';
import TaskMilestoneIcon from './TaskMilestoneIcon.vue';

export default {
  components: {
    TaskForm,
    TaskSettingsForm,
    RunningWorklogs,
    TaskPriorityIcon,
    TaskWorkLog,
    Thread,
    User,
    StatusTaskControl,
    FontAwesomeIcon,
    TaskMilestoneIcon,
    QuickWorklogManager,
  },
  props: {
    taskId: {
      type: String,
      default: () => undefined,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'update-worklogs'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const notify = useNotify();
    const { t } = useI18n();

    const hasRole = store.getters['auth/hasRole'];
    const getTask = store.getters['projects/getTask'];
    const getLabel = store.getters['projects/getLabel'];
    const getMilestone = store.getters['projects/getMilestone'];
    const getThreadMessages = store.getters['chats/getThreadMessages'];

    const state = reactive({
      latestsTasks: computed(() => store.getters['projects/getLatestsTasks']),
      currentUser: computed(() => store.getters['auth/currentUser']),
      task: computed(() => getTask({ taskId: props.taskId })),
      messages: computed(() => (state.task ? getThreadMessages({ threadId: state.task.threadId }) : [])),
      isTaskEstimated: computed(() => get(state.task, 'estimatedTime', 0) > 0),
      isWaitingEstimationValidation: computed(() => state.hasMissionLabel && state.isTaskEstimated && !state.task.estimatedTimeAcceptedAt && !state.task.estimatedTimeRefusedAt),
      staffOnly: computed(() => hasRole('admin:skyloud')),
      isAdminSkyloudProjectGranted: computed(() => hasRole('admin:skyloud:project')),
      isAdminSkyloud: computed(() => hasRole('admin:skyloud')),
      isCurrentUserSkyManager: computed(
        () =>
          window.location.hostname === 'localhost' || state.isAdminSkyloudProjectGranted || includes(['etienne@skyloud.fr', 'dimitri@skyloud.fr'], get(state, 'currentUser.email')),
      ),
      diffInMinutes: computed(() => dayjs(state.formWorkLog.form.stoppedAt).diff(state.formWorkLog.form.startedAt, 'minutes')),
      isWorlogMoreThanOneMinute: computed(() => {
        const ONE_MINUTE = 60_000;
        const pauseTimeInMs = state.isPauseIncluded ? 3600000 : 0;
        const startedAt = new Date(state.formWorkLog.form.startedAt).getTime();
        const stoppedAt = new Date(state.formWorkLog.form.stoppedAt).getTime();
        return stoppedAt - startedAt - pauseTimeInMs >= ONE_MINUTE;
      }),
      isPauseIncluded: computed(() => state.diffInMinutes >= 9 * 60),
      loading: false,
      archiveLoading: false,
      workLogsActionLoading: false,
      formWorkLog: {
        loading: false,
        open: false,
        form: {
          note: undefined,
          startedAt: dayjs().set('hours', 9).set('minutes', 0).format('YYYY-MM-DD[T]HH:mm'),
          stoppedAt: dayjs().set('hours', 18).set('minutes', 0).format('YYYY-MM-DD[T]HH:mm'),
          pauseTimeInHours: 0,
          pauseTimeInMinutes: 0,
        },
        rules: {
          note: [],
          timeStep: [getIsModulo(1, t('components.projects.editTaskModal.rules.timeStep'))],
          startedAt: [getIsRequiredRule()],
          stoppedAt: [getIsRequiredRule(), getIsAfter(() => state.formWorkLog.form.startedAt), getIsTimeDiffInHoursLessThanRule(() => state.formWorkLog.form.startedAt)],
        },
      },
      workLogsDesc: computed(() => {
        const workLogs = get(state.task, 'workLogs', []);
        const sortedWorkLogs = sortBy(workLogs, 'startedAt');
        return reverse(sortedWorkLogs);
      }),
      lastCurrentUserWorkLog: computed(() => {
        const workLogs = get(state.task, 'workLogs', []);
        const currentUserWorkLogs = filter(workLogs, { userId: state.currentUser._id });
        const sortedWorkLogs = sortBy(currentUserWorkLogs, 'startedAt');

        return last(sortedWorkLogs);
      }),
      workLogActionsAvailable: computed(() => {
        if (!state.lastCurrentUserWorkLog) return ['start'];

        if (state.lastCurrentUserWorkLog.stoppedAt) return ['start'];

        if (state.lastCurrentUserWorkLog.pausedAt) return ['start', 'stop'];

        return ['pause', 'stop'];
      }),
      taskMilestones: computed(() => {
        const milestoneIds = get(state.task, 'milestoneIds', []);
        const milestones = map(milestoneIds, (milestoneId) => getMilestone({ milestoneId }));
        return sortBy(compact(milestones), ['endAt']);
      }),
      latestTaskMilestone: computed(() => last(state.taskMilestones)),
      taskLabels: computed(() => {
        const labelIds = get(state.task, 'labelIds', []);
        if (isEmpty(labelIds)) {
          return [];
        }
        const labels = map(labelIds, (labelId) => getLabel({ labelId }));
        return compact(labels);
      }),
      getMissionLabel: computed(() => find(state.taskLabels, (label) => toLower(label.name) === 'mission')),
      hasMissionLabel: computed(() => !!state.getMissionLabel),
      isTaskInvoicable: computed(() => state.hasMissionLabel),
      taskWorkLogsErrors: computed(() => {
        const errors = new Set();
        if (!state.task) {
          return [...errors];
        }

        const { billableTime } = state;
        const { labelIds, estimatedTime, acceptedEstimatedTime, firstEstimatedTime, estimatedTimeRefusedAt } = state.task;

        if (isEmpty(labelIds)) {
          errors.add(t('components.projects.editTaskModal.taskWorkLogsErrors.labelsRequired'));
        }

        if (state.hasMissionLabel && !estimatedTime) {
          errors.add(t('components.projects.editTaskModal.taskWorkLogsErrors.estimationRequired'));
        }

        const isMissionWaitingEstimation = state.hasMissionLabel && state.isWaitingEstimationValidation;
        if (isMissionWaitingEstimation && firstEstimatedTime > 0 && acceptedEstimatedTime > 0) {
          errors.add(t('components.projects.editTaskModal.taskWorkLogsErrors.newEstimateWaiting'));
        }

        if (isMissionWaitingEstimation && !firstEstimatedTime) {
          errors.add(t('components.projects.editTaskModal.taskWorkLogsErrors.awaitingEstimationValidation'));
        }

        const legalMaxEllapsedTimeAllowed = getTaskLegalMaxEllapsedTimeAllowed(firstEstimatedTime > 0 ? acceptedEstimatedTime : estimatedTime);
        if (state.hasMissionLabel && estimatedTime && !isEmpty(estimatedTimeRefusedAt) && isNumber(billableTime) && billableTime >= legalMaxEllapsedTimeAllowed) {
          errors.add(t('components.projects.editTaskModal.taskWorkLogsErrors.missionRefused'));
        }

        if (state.hasMissionLabel && isNumber(estimatedTime) && isNumber(billableTime) && billableTime >= legalMaxEllapsedTimeAllowed) {
          errors.add(t('components.projects.editTaskModal.taskWorkLogsErrors.needNewEstimation'));
        }

        return [...errors];
      }),
      hasNeedNewEstimationError: computed(() => includes(state.taskWorkLogsErrors, t('components.projects.editTaskModal.taskWorkLogsErrors.needNewEstimation'))),
      hasEstimatedWaitingError: computed(() => includes(state.taskWorkLogsErrors, t('components.projects.editTaskModal.taskWorkLogsErrors.newEstimateWaiting'))),
      isFormActive: false,
      selectedThread: 'client',
      threadLoading: false,
      threadTabs: computed(() => [
        { slug: 'client', label: t('components.projects.editTaskModal.clientThread'), icon: faUserSolid, color: 'text-primary', backgroundColor: 'bg-[#7f96ff2A]' },
        { slug: 'internal', label: t('components.projects.editTaskModal.internalThread'), icon: faLock, color: 'text-[#EB6464]', backgroundColor: 'bg-[#EB64642A]' },
      ]),
      realEllapsedTime: computed(() => reduce(state.task?.workLogs || [], (acc, workLog) => acc + getTaskWorklogEllapsedTime(workLog), 0)),
      billableTime: computed(() =>
        reduce(
          get(state.task, 'workLogs'),
          (acc, workLog) => {
            const time = getTaskWorklogEllapsedTime(workLog);
            return acc + roundEllapsedTime(time, TASK_ELLAPSED_TIME_STEP_IN_MS);
          },
          0,
        ),
      ),
      openWorklogRunning: false,
      runningWorklogs: [],
      shouldUseDefaultPause: computed(() => state.isPauseIncluded && state.formWorkLog.form.pauseTimeInHours === 0 && state.formWorkLog.form.pauseTimeInMinutes === 0),
    });

    async function fetchTask() {
      state.loading = true;
      try {
        if (!props.taskId) {
          state.loading = false;
          return;
        }
        await store.dispatch('projects/fetchTask', { taskId: props.taskId });
        await store.dispatch('projects/fetchLabels');
        if (/^\w+-\d+$/.test(props.taskId)) {
          state.loading = false;
          return;
        }
        router.push(`/projects/tasks/${state.task.taskId}`);
      } catch (err) {
        notify.error(err.message);
      }
      state.loading = false;
    }

    async function handleSubmit({ labelIds = state.task.labelIds, milestoneIds, memberIds, ...task }) {
      state.loading = true;
      try {
        if (!state.task) {
          state.loading = false;
          return;
        }
        await store.dispatch('projects/updateTaskModalAction', {
          task: state.task,
          taskForm: task,
          memberIds,
          labelIds,
          milestoneIds,
          hasMissionLabel: state.hasMissionLabel,
          isWaitingEstimationValidation: state.isWaitingEstimationValidation,
          notify,
          t,
        });
      } catch (err) {
        notify.error(err.message);
      }
      state.loading = false;
    }

    async function handleArchiveTask() {
      state.archiveLoading = true;
      try {
        await store.dispatch('projects/archiveTask', { taskId: state.task._id });
        notify.warning(t('components.projects.editTaskModal.notifications.archiveTask', { taskId: props.taskId }));
      } catch (err) {
        notify.error(err.message);
      }
      state.archiveLoading = false;
    }

    async function handleUnArchiveTask() {
      state.archiveLoading = true;
      try {
        await store.dispatch('projects/unArchiveTask', { taskId: state.task._id });
        notify.warning(t('components.projects.editTaskModal.notifications.unArchiveTask', { taskId: props.taskId }));
      } catch (err) {
        notify.error(err.message);
      }
      state.archiveLoading = false;
    }

    async function handleWorkLogAction({ action }) {
      try {
        state.workLogsActionLoading = true;
        // eslint-disable-next-line no-alert
        const note = action === 'stop' ? prompt("Votre rapport d'intervention :") : undefined;

        await store.dispatch('projects/sendTaskWorkLogAction', {
          taskId: state.task._id,
          action,
          note,
          type: 'action',
        });
        state.workLogsActionLoading = false;
        emit('update-worklogs');
      } catch (err) {
        notify.error(err.message);
        state.workLogsActionLoading = false;
      }
    }

    function handleClosedRunningWorklogs() {
      state.openWorklogRunning = false;
      handleWorkLogAction({ action: 'start' });
    }

    async function handleVerifyWorklogAction({ action }) {
      if (action !== 'start') {
        handleWorkLogAction({ action });
        return;
      }

      state.workLogsActionLoading = true;
      const runningWorklogs = await store.dispatch('projects/fetchRunningWorklogs');

      if (runningWorklogs.length === 0) {
        handleWorkLogAction({ action });
        return;
      }

      state.runningWorklogs = runningWorklogs;
      state.openWorklogRunning = true;
      state.workLogsActionLoading = false;
    }

    async function handleDeleteTaskWorkLog({ workLogId }) {
      try {
        await store.dispatch('projects/removeTaskWorkLog', { taskId: state.task._id, workLogId });
        emit('update-worklogs');
      } catch (err) {
        notify.error(err.message);
      }
    }

    async function handleUpdateTaskWorkLog({ workLogId, startedAt, stoppedAt, note, pauseTimeInMs }) {
      try {
        await store.dispatch('projects/updateTaskWorkLog', {
          taskId: state.task._id,
          workLogId,
          startedAt,
          stoppedAt,
          note,
          pauseTimeInMs,
        });
        emit('update-worklogs');
      } catch (err) {
        notify.error(err.message);
      }
    }

    function getActionLabel(action) {
      if (action === 'start') return 'Lancer';
      if (action === 'pause') return 'Pause';
      return 'Arrêter';
    }

    async function addWorkLog() {
      state.formWorkLog.loading = true;
      try {
        const pauseTimeInMs = sumDurationToMilliseconds(0, state.formWorkLog.form.pauseTimeInHours, state.formWorkLog.form.pauseTimeInMinutes);
        await store.dispatch('projects/createTaskWorkLog', {
          taskId: state.task._id,
          startedAt: state.formWorkLog.form.startedAt,
          stoppedAt: state.formWorkLog.form.stoppedAt,
          pauseTimeInMs: state.shouldUseDefaultPause ? 3600000 : pauseTimeInMs,
          note: state.formWorkLog.form.note,
          type: 'manual',
        });
        state.formWorkLog.open = false;
        state.formWorkLog.form.note = undefined;
        state.formWorkLog.form.pauseTimeInHours = 0;
        state.formWorkLog.form.pauseTimeInMinutes = 0;
        state.formWorkLog.form.startedAt = dayjs().set('hours', 9).set('minutes', 0).format('YYYY-MM-DD[T]HH:mm');
        state.formWorkLog.form.stoppedAt = dayjs().set('hours', 18).set('minutes', 0).format('YYYY-MM-DD[T]HH:mm');
        notify.success(t('components.projects.editTaskModal.notifications.addWorklog'));
        emit('update-worklogs');
      } catch (err) {
        notify.error(err.message);
      }
      state.formWorkLog.loading = false;
    }

    function handleFormWorkLogDay(duration) {
      const startedAt = dayjs(state.formWorkLog.form.startedAt).add(duration, 'days');

      state.formWorkLog.form.startedAt = startedAt.set('hours', 9).set('minutes', 0).format('YYYY-MM-DD[T]HH:mm');

      state.formWorkLog.form.stoppedAt = startedAt.set('hours', 18).set('minutes', 0).format('YYYY-MM-DD[T]HH:mm');
    }

    function copyToClipboard() {
      const shareUrl = `${config.appUrl}/apps/${state.task.appId}?redirect=/projects/tasks/${state.task.taskId}`;
      navigator.clipboard.writeText(shareUrl);
      notify.success(t('components.projects.editTaskModal.notifications.shareTask'));
    }

    function getWorklogActionIcon({ action }) {
      const icons = {
        start: faPlay,
        pause: faPause,
        stop: faStop,
      };
      return icons[action];
    }

    function handleModalClose(options = { force: false }) {
      if (state.isFormActive && !options.force) {
        return;
      }
      emit('close');
    }

    function handleFormActive(value) {
      state.isFormActive = value;
    }

    function isWorklogInvoicable(workLog) {
      if (!state.isTaskInvoicable) return true;
      if (state.task.firstEstimatedTime) return true;

      const invoicaleWorklogs = getInvoicableWorklogs({
        workLogs: state.task.workLogs,
        acceptedEstimatedTime: state.task.acceptedEstimatedTime,
      });
      return !!invoicaleWorklogs.find((w) => w._id === workLog._id);
    }

    async function handleCreateInternalThread() {
      try {
        state.threadLoading = true;
        await store.dispatch('projects/createTaskInternalThread', {
          taskId: state.task._id,
        });
      } catch (err) {
        notify.error(err.message);
      }
      state.threadLoading = false;
    }

    onMounted(() => {
      fetchTask();
    });

    watch(() => props.taskId, fetchTask);

    return {
      ...toRefs(state),
      handleSubmit,
      handleArchiveTask,
      handleUnArchiveTask,
      handleWorkLogAction,
      getActionLabel,
      formatDate,
      formatAmount,
      formatDurationFromMs,
      fetchTask,
      addWorkLog,
      handleFormWorkLogDay,
      handleDeleteTaskWorkLog,
      handleUpdateTaskWorkLog,
      getWorklogActionIcon,
      handleDecimalValueInput,
      isWorklogInvoicable,
      copyToClipboard,
      handleVerifyWorklogAction,
      handleClosedRunningWorklogs,
      faCalendarAlt,
      faUser,
      faUsers,
      faClock,
      faHourglassStart,
      faCloudDownload,
      faBriefcase,
      faPlusCircle,
      faTimesCircle,
      faLongArrowLeft,
      faLongArrowRight,
      faInfoCircle,
      faTransporterEmpty,
      faArchive,
      faLink,
      faSave,
      faReceipt,
      faExclamationCircle,
      getTaskLegalMaxEllapsedTimeAllowed,
      handleModalClose,
      handleFormActive,
      handleCreateInternalThread,
      faFileInvoiceDollar,
      faShield,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-clock-add {
  @apply border-gray-400 relative text-sm w-10 text-gray-400 font-semibold outline-none focus:outline-none hover:text-primary hover:border-primary rounded transition-colors;
  border-width: 1.5px;

  > svg {
    @apply absolute block top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-opacity;

    &:not(:first-child) {
      @apply opacity-0;
    }
  }

  &:hover > svg:first-child {
    @apply opacity-0;
  }

  &:hover > svg:last-child {
    @apply opacity-100;
  }
}

@keyframes worklog-error {
  75%,
  100% {
    transform: scaleX(1.05) scaleY(1.5);
    opacity: 0;
  }
}

.worklog-error-ping {
  animation: worklog-error 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
</style>
