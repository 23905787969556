import SkyTable from '@/components/ui/tables/SkyTable.vue';
import SkyTableColumn from '@/components/ui/tables/SkyTableColumn.vue';
import SkyTableHead from '@/components/ui/tables/SkyTableHead.vue';

export default {
  install: (app) => {
    app.component('SkyTable', SkyTable);
    app.component('SkyTableColumn', SkyTableColumn);
    app.component('SkyTableHead', SkyTableHead);
  },
};
