import { InconsistentCollection } from '@/modules/stats/availability/domain/exceptions/inconsistent-collection.js';
import { required } from '@/utils/validate/required.js';
import { ByPassValidationInnerType } from '@/utils/collections/by-pass-validation-inner-type.js';

/**
 * @template T, FlatT=T
 * @implements {Iterable<T, FlatT>}
 */
export class ImmutableCollection {
  /**
   * @type{T[]}
   * @readonly
   * @private
   */
  _items = [];

  constructor(...items) {
    this._items = items;
    this._items.forEach((item) => this.validateInnerType(item));
  }

  /**
   * @return {unknown}
   * @protected
   * @abstract
   */
  innerTypeOf() {
    return ByPassValidationInnerType;
  }

  /**
   * @param {T} item
   * @protected
   */
  validateInnerType(item) {
    const innerType = this.innerTypeOf();
    if (innerType === ByPassValidationInnerType) {
      return;
    }

    required(item instanceof innerType, InconsistentCollection.expectedInstanceOf(this.constructor, innerType, item));
  }

  [Symbol.iterator]() {
    let index = 0;
    return {
      next: () => ({
        value: this._items[index++],
        done: index > this._items.length,
      }),
    };
  }

  get length() {
    return this._items.length;
  }

  isEmpty() {
    return this._items.length === 0;
  }

  /**
   * @returns {T | undefined}
   */
  first() {
    return this.nth(0);
  }

  nth(n) {
    return this._items[n];
  }

  /**
   * @return {FlatT | undefined}
   */
  firstDeep() {
    const first = this.first();
    if (first instanceof ImmutableCollection) {
      return first.firstDeep();
    }

    return first;
  }

  slice(start, end) {
    return this.new(...this._copyItems().slice(start, end));
  }

  /**
   * @return {ImmutableCollection<T, FlatT>}
   */
  removeFirst() {
    return this.new(...this._copyItems().slice(1));
  }

  /**
   * @return {ImmutableCollection<T, FlatT>}
   */
  removeLast() {
    return this.new(...this._copyItems().slice(0, -1));
  }

  /**
   * @return {T | undefined}
   */
  last() {
    return this._items[this._items.length - 1];
  }

  /**
   * @return {FlatT | undefined}
   */
  lastDeep() {
    const last = this.last();
    if (last instanceof ImmutableCollection) {
      return last.lastDeep();
    }

    return last;
  }

  removeLastDeep() {
    const last = this.last();
    if (last instanceof ImmutableCollection) {
      return this.new(...this._copyItems().slice(0, -1), last.removeLastDeep());
    }

    return this.new(...this._copyItems().slice(0, -1));
  }

  /**
   * @param {T} args
   */
  new(...args) {
    return new this.constructor(...args);
  }

  /**
   * @param {T} item
   */
  add(...item) {
    return this.new(...this._items, ...item);
  }

  /**
   * @param {T} items
   */
  concat(...items) {
    return this.new(...this._items, ...items);
  }

  forEach(callback) {
    this._items.forEach(callback);
  }

  filter(callback) {
    return this.new(...this._items.filter(callback));
  }

  /**
   * @param {function(T): boolean} callback
   * @return {this is T[]}
   */
  every(callback) {
    return this._items.every(callback);
  }

  /**
   * @param {function(T): boolean} callback
   * @returns {[T[], T[]]}
   */
  partition(callback) {
    return this._items.reduce(([left, right], item) => (callback(item) ? [left.add(item), right] : [left, right.add(item)]), [this.new(), this.new()]);
  }

  map(callback) {
    return this._items.map(callback);
  }

  /**
   * @template InitialValue
   * @template R
   * @param {function(InitialValue, T, number, T[]): R} callback
   * @param {InitialValue} initialValue
   * @returns {R}
   */
  reduce(callback, initialValue) {
    return this._items.reduce(callback, initialValue);
  }

  /**
   * @param {function(T, number, T[]): boolean} callback
   * @returns {boolean}
   */
  some(callback) {
    return this._items.some(callback);
  }

  /**
   * @param {function(T, number, T[]): boolean} predicate
   * @returns {T | undefined}
   */
  find(predicate) {
    return this._items.find(predicate);
  }

  /**
   * @param {function(T): boolean} predicate
   */
  findFlatMap(predicate) {
    return new ImmutableCollection(...this.flatMap(predicate)).find((item) => typeof item !== 'undefined');
  }

  indexOf(searchElement, fromIndex) {
    return this._items.indexOf(searchElement, fromIndex);
  }

  flatMap(callback, thisArg) {
    return new ImmutableCollection(...this._items.flatMap(callback, thisArg));
  }

  replace(predicate, newItem) {
    const index = this._items.findIndex(predicate);
    if (index === -1) {
      return this;
    }

    return this.replaceByIndex(index, newItem);
  }

  /**
   * @param {number} index
   * @param {T} newItem
   */
  replaceByIndex(index, newItem) {
    return this.new(...this._copyItems().slice(0, index), newItem, ...this._copyItems().slice(index + 1));
  }

  /**
   * @param {function(T): boolean} predicate
   * @param {function(T): T} replaceCallback
   * @param {function(): T} createCallback
   */
  replaceOrCreate(predicate, replaceCallback, createCallback) {
    const index = this._items.findIndex(predicate);
    if (index === -1) {
      return this.add(createCallback());
    }

    return this.replaceByIndex(index, replaceCallback(this._items[index]));
  }

  /**
   * @param {function(T): T} replaceCallback
   */
  replaceEach(replaceCallback) {
    return this.new(...this._items.map(replaceCallback));
  }

  /**
   * @param {function(T): string} keyCallback
   * @param {(function(T): ImmutableCollection<T>)} [createCollectionCallback=ImmutableCollection<T>]
   * @return {Object<string, ImmutableCollection<T>>}
   */
  keyBy(keyCallback, createCollectionCallback = () => new ImmutableCollection()) {
    return this._items.reduce((accumulator, item) => {
      const key = keyCallback(item);

      return {
        ...accumulator,
        [key]: (accumulator[key] || createCollectionCallback()).add(item),
      };
    }, {});
  }

  /**
   * @param {function(T, T): 0 | 1 | -1} compareFunction
   */
  sort(compareFunction) {
    return this.new(...this._copyItems().sort(compareFunction));
  }

  /**
   * @param {number} number
   */
  take(number) {
    return this.new(...this._copyItems().slice(0, number));
  }

  /**
   * @param {number} number
   */
  takeTail(number) {
    return this.new(...this._copyItems().slice(-number));
  }

  reverse() {
    return this.new(...this._copyItems().reverse());
  }

  /**
   * @return {ImmutableCollection<T>}
   */
  toImmutableCollection() {
    return new ImmutableCollection(...this._items);
  }

  toFlatImmutableCollection() {
    return new ImmutableCollection(...this.toFlatArray());
  }

  /**
   * @return {FlatT[]}
   */
  toFlatArray() {
    return this._items.reduce((accumulator, item) => accumulator.concat(ImmutableCollection.flattenItem(item)), []);
  }

  /**
   * @return {T[]}
   */
  toArray() {
    return this._copyItems();
  }

  /**
   * @param {T} item
   * @return {T[]|T}
   * @private
   */
  static flattenItem(item) {
    if (item instanceof ImmutableCollection) {
      return item.toFlatArray();
    }

    if (Array.isArray(item)) {
      return item.flatMap(this.flattenItem);
    }

    return item;
  }

  /**
   * @return {T[]}
   * @private
   */
  _copyItems() {
    return [...this._items];
  }
}
