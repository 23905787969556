<template>
  <label class="block">
    <div
      v-if="label"
      class="block mb-2 text-sm font-medium text-primary"
      :class="{ required: isRequired }"
    >
      {{ label }}
    </div>
    <div class="relative">
      <FontAwesomeIcon
        :icon="faChevronRight"
        class="absolute text-lg text-blue-200 transform -translate-y-1/2 pointer-events-none top-1/2 right-3"
      />
      <select
        ref="input"
        v-model="internalValue"
        style="transition: all 0.15s ease 0s"
        class="w-full h-10 px-3 text-sm placeholder-gray-400 bg-white border rounded appearance-none focus:outline-none focus:shadow-outline"
        :class="inputClasses"
        :placeholder="placeholder"
        :disabled="disabled || readOnly"
        :required="isRequired"
      >
        <slot />
      </select>
    </div>
    <span
      class="flex items-center h-4 mt-1 ml-1 text-xs font-medium tracking-wide text-red-500"
      :class="{ hidden: !hasErrorMessages }"
    >
      {{ firstErrorMessage }}
    </span>
  </label>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import useValidatable from '@/modules/shared/utils/validatable.js';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

export default {
  props: {
    modelValue: { type: [String, Object, Number, Boolean], default: () => undefined },
    label: { type: String, default: () => undefined },
    placeholder: { type: String, default: () => undefined },
    disabled: { type: Boolean, default: () => false },
    rules: { type: Array, default: () => [] },
    readOnly: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const input = ref(null);

    const { state, validate } = useValidatable({ props, emit });

    const inputClasses = computed(() => ({
      'border-red-500': state.hasErrorMessages,
      'border-dark-blue-100': !state.hasErrorMessages,
    }));

    return {
      ...toRefs(state),
      input,
      inputClasses,
      validate,
      faChevronRight,
    };
  },
};
</script>

<style lang="scss" scoped>
.required::after {
  @apply content-asterisk ml-1 text-red-600 font-medium;
}
</style>
