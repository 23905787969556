import { ValueObject } from '@/utils/value-objects/value-object.js';
import { InvalidStringValueObject } from '@/utils/value-objects/string/invalid-string-value-object.js';
import { required } from '@/utils/validate/required.js';

export class StringValueObject extends ValueObject {
  validate() {
    required(typeof this._value === 'string', InvalidStringValueObject.fromValue(this._value));
  }

  /**
   * @param {ProbeEventId} id
   * @return {Boolean}
   */
  equal(id) {
    return this._value === id._value;
  }

  toString() {
    return this._value;
  }
}
