// @ts-ignore
import packageJson from '../../package.json';

export default {
  appUrl: process.env.VUE_APP_URL || 'http://localhost:8080',
  appVersion: packageJson.version,
  endpoints: {
    accounts: process.env.VUE_APP_ENDPOINTS_ACCOUNTS || 'http://localhost:8000/v1',
    mails: process.env.VUE_APP_ENDPOINTS_MAILS || 'http://localhost:8001/v1',
    bookings: process.env.VUE_APP_ENDPOINTS_BOOKINGS || 'http://localhost:8002/v1',
    payments: process.env.VUE_APP_ENDPOINTS_PAYMENTS || 'http://localhost:8003/v1',
    converters: process.env.VUE_APP_ENDPOINTS_CONVERTERS || 'http://localhost:8004/v1',
    clouds: process.env.VUE_APP_ENDPOINTS_CLOUDS || 'http://localhost:8006/v1',
    projects: process.env.VUE_APP_ENDPOINTS_PROJECTS || 'http://localhost:8007/v1',
    chats: process.env.VUE_APP_ENDPOINTS_CHATS || 'http://localhost:8008/v1',
    storages: process.env.VUE_APP_ENDPOINTS_STORAGES || 'http://localhost:8009/v1',
    notifs: process.env.VUE_APP_ENDPOINTS_NOTIFS || 'http://localhost:8010/v1',
    stats: process.env.VUE_APP_ENDPOINTS_STATS || 'http://localhost:8005/v1',
  },
  notifs: {
    publicVapidKey: process.env.VUE_APP_NOTIFS_PUBLIC_VAPID_KEY || 'BA7VrZuG3w9WgX2uJp5XIgH1OpfiKfUt681Zjaqi59sS3HnPNeAKGD5CRlz7xtAc4uCa0dSxj1bVK_3QmAwH43s',
  },
  payments: {
    stripePublicKey: process.env.VUE_APP_PAYMENTS_STRIPE_PUBLIC_KEY || 'pk_test_79ffCsGLdl8J24c0lGCMVPnP',
  },
};
