import SkyList from '@/components/ui/lists/SkyList.vue';
import SkyListItem from '@/components/ui/lists/SkyListItem.vue';
import SkyListCollapse from '@/components/ui/lists/SkyListCollapse.vue';

export default {
  install: (app) => {
    app.component('SkyList', SkyList);
    app.component('SkyListItem', SkyListItem);
    app.component('SkyListCollapse', SkyListCollapse);
  },
};
