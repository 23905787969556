<template>
  <SkyForm
    class="flex flex-col gap-5"
    v-bind="$attrs"
    @valid="handleSubmit"
  >
    <UserSelect
      v-model="form.userId"
      :label="$t('components.projects.taskSettingForm.owner.label')"
      :rules="rules.userId"
    />
    <span class="h-0.5 bg-gray-200 bg-opacity-50"></span>
    <UsersSelect
      v-model="form.memberIds"
      label="Membres"
      class="flex-1"
    />
    <MilestonesSelect v-model="form.milestoneIds" />
    <LabelsSelect v-model="form.labelIds" />
    <SkySelect
      v-model="form.kind"
      :rules="rules.kind"
      :label="$t('components.projects.taskSettingForm.kind.label')"
      required
      :read-only="readOnly"
    >
      <option
        v-for="(kindLabel, kindValue) in taskKinds"
        :key="kindValue"
        :value="kindValue"
      >
        {{ kindLabel }}
      </option>
    </SkySelect>
    <SkySelect
      v-model="form.priority"
      :rules="rules.priority"
      :label="$t('components.projects.taskSettingForm.priority.label')"
    >
      <option
        v-for="{ code, label } in taskPriorities"
        :key="code"
        :value="code"
      >
        {{ label }}
      </option>
    </SkySelect>
    <SkyDatePicker
      v-model="form.dueAt"
      :icon="faCalendarAlt"
      :label="$t('components.projects.taskSettingForm.deliveryDate.label')"
    />
    <div class="flex flex-col">
      <span class="text-sm font-medium text-primary">{{ $t('components.projects.taskSettingForm.estimation.title') }}</span>
      <p class="mt-2 text-xs text-gray-400">
        <FontAwesomeIcon :icon="faInfoCircle" />
        {{ $t('components.projects.taskSettingForm.estimation.helperText') }}
      </p>
      <div class="flex flex-row justify-between gap-2 mt-1">
        <SkyInputText
          v-model="estimatedTimeInDays"
          class="w-full"
          :helper-text="$t('components.projects.taskSettingForm.estimation.inputs.days')"
          step="1"
          type="number"
          min="0"
          @keydown="handleDecimalValueInput"
        />
        <SkyInputText
          v-model="estimatedTimeInHours"
          class="w-full"
          :helper-text="$t('components.projects.taskSettingForm.estimation.inputs.hours')"
          step="1"
          type="number"
          min="0"
          @keydown="handleDecimalValueInput"
        />
        <SkyInputText
          v-model="estimatedTimeInMinutes"
          class="w-full"
          :helper-text="$t('components.projects.taskSettingForm.estimation.inputs.minutes')"
          step="15"
          type="number"
          :rules="rules.timeStep"
          min="0"
          @keydown="handleDecimalValueInput"
        />
        <SkyButton
          v-if="task.estimatedTime && isSkyloudAdminProject"
          class="flex items-center justify-center w-10 h-10"
          @click="handleRevertEstimation"
        >
          <FontAwesomeIcon :icon="faArrowRotateLeft" />
        </SkyButton>
      </div>
      <p class="mt-2 text-xs text-gray-400">
        {{ $t('components.projects.taskSettingForm.workingTime', { value: oneSkyloudDayInMs }) }} : {{ formatDurationFromMs(form.estimatedTime, 'm', 'j', true) }}
      </p>
      <p class="text-xs text-gray-400">
        {{ $t('components.projects.taskSettingForm.clientTime', { value: oneBusinessDayInMs }) }} : {{ formatDurationFromMs(form.estimatedTime, 'm', 'h') }}
        <i>({{ formatDurationFromMs(form.estimatedTime, 'm', 'j') }})</i>
      </p>
    </div>
    <slot name="footer" />
  </SkyForm>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import get from 'lodash/get';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import { useNotify } from '@/plugins/notify/index.js';
import { durationPartsFromMs, ONE_BUSINESS_DAY_IN_MS, ONE_SKYLOUD_DAY_IN_MS, formatDurationFromMs, sumDurationToMilliseconds } from '@/utils/format.js';
import UsersSelect from '@/modules/users/components/UsersSelect.vue';
import { taskKinds, taskPriorities, taskPrioritiesLabels } from '@/modules/projects/projects.constants.js';
import { handleDecimalValueInput } from '@/modules/projects/projects.functions.js';
import UserSelect from '@/modules/users/components/UserSelect.vue';
import LabelsSelect from '@/modules/projects/components/LabelsSelect.vue';
import { getIsRequiredRule, getIsModulo } from '@/modules/shared/utils/formValidationRules.js';
import { faCalendarAlt, faInfoCircle, faArrowRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import MilestonesSelect from '@/modules/projects/components/MilestonesSelect.vue';

const defaultForm = {
  priority: taskPriorities.MEDIUM,
  kind: 'basic',
  dueAt: undefined,
  userId: undefined,
  estimatedTime: undefined,
  labelIds: [],
  memberIds: [],
  milestoneIds: [],
};

export default {
  components: {
    UsersSelect,
    UserSelect,
    LabelsSelect,
    MilestonesSelect,
    FontAwesomeIcon,
  },
  props: {
    task: {
      type: Object,
      default: () => undefined,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['valid', 'active'],

  setup(props, { emit }) {
    const store = useStore();
    const notify = useNotify();
    const { t, locale } = useI18n();

    const hasRole = store.getters['auth/hasRole'];

    const state = reactive({
      formatedEstimatedTime: computed(() => durationPartsFromMs(state.form.estimatedTime, 'm', 'j', true)),
      oneBusinessDayInMs: computed(() => ONE_BUSINESS_DAY_IN_MS / (1000 * 60 * 60)),
      oneSkyloudDayInMs: computed(() => ONE_SKYLOUD_DAY_IN_MS / (1000 * 60 * 60)),
      taskKinds: computed(() => taskKinds[locale.value]),
      taskPriorities: computed(() => taskPrioritiesLabels[locale.value]),
      estimatedTimeInDays: computed({
        get() {
          return get(state.formatedEstimatedTime, 'j', 0);
        },
        set(value) {
          state.form.estimatedTime = sumDurationToMilliseconds(value, state.estimatedTimeInHours, state.estimatedTimeInMinutes);
        },
      }),
      estimatedTimeInHours: computed({
        get() {
          return get(state.formatedEstimatedTime, 'h', 0);
        },
        set(value) {
          state.form.estimatedTime = sumDurationToMilliseconds(state.estimatedTimeInDays, value, state.estimatedTimeInMinutes);
        },
      }),
      estimatedTimeInMinutes: computed({
        get() {
          return get(state.formatedEstimatedTime, 'm', 0);
        },
        set(value) {
          state.form.estimatedTime = sumDurationToMilliseconds(state.estimatedTimeInDays, state.estimatedTimeInHours, value);
        },
      }),
      form: { ...defaultForm },
      rules: {
        priority: [getIsRequiredRule()],
        kind: [getIsRequiredRule()],
        userId: [getIsRequiredRule()],
        timeStep: [getIsModulo(15, t('components.projects.editTaskModal.rules.timeStep'))],
      },
      loading: false,
      isSkyloudAdminProject: computed(() => hasRole('admin:skyloud:project')),
      isEditingForm: computed(() => {
        const activeKeys = keys(state.form);
        return reduce(
          activeKeys,
          (acc, key) => {
            const formValue = get(state.form, key);
            const taskValue = get(props.task, key);
            return !acc ? !isEqual(formValue, taskValue) : acc;
          },
          false,
        );
      }),
    });

    function handleSubmit() {
      emit('valid', state.form);
    }

    function updateFormWithTask() {
      if (!props.task) return;
      state.form = {
        priority: props.task.priority,
        kind: props.task.kind,
        userId: props.task.userId,
        dueAt: props.task.dueAt,
        estimatedTime: props.task.estimatedTime,
        labelIds: get(props.task, 'labelIds', []),
        memberIds: get(props.task, 'memberIds', []),
        milestoneIds: get(props.task, 'milestoneIds', []),
      };
    }

    function handleTaskEdition() {
      if (!props.task) return;
      emit('active', state.isEditingForm);
    }

    async function handleRevertEstimation() {
      try {
        await store.dispatch('projects/restoreEstimation', { _id: props.task._id });
        notify.success(t('components.projects.editTaskModal.notifications.revertEstimatedTimeSuccess'));
      } catch (error) {
        notify.error(error.message);
      }
    }

    watch(() => pick(props.task, 'memberIds', ...Object.keys(defaultForm)), updateFormWithTask);

    watch(() => state.form, handleTaskEdition, { deep: true });

    onMounted(updateFormWithTask);

    return {
      ...toRefs(state),
      handleSubmit,
      durationPartsFromMs,
      handleDecimalValueInput,
      formatDurationFromMs,
      faCalendarAlt,
      faInfoCircle,
      faArrowRotateLeft,
      handleRevertEstimation,
    };
  },
};
</script>
