/**
 * @interface
 */
export class DateProvider {
  /**
   * @return {DateValueObject}
   */
  now() {
    throw new Error('Not implemented');
  }
}
