<template>
  <div
    class="border border-primary rounded-[5px] p-2 absolute right-0 bg-[#F9FAFF] shadow z-[2] flex justify-between"
    :class="{
      'flex-col pt-1': eventHeight >= minEventHeight,
      'flex-row items-center gap-2': eventHeight < minEventHeight,
      'border-success': isNotStopped || isPaused,
      'border-t-0 rounded-t-none before:absolute before:w-full before:top-0 before:left-0 before:border-t before:border-t-primary before:border-dashed': !isStartedToday,
      'border-b-0 rounded-b-none after:absolute after:w-full after:bottom-0 after:left-0 after:border-b after:border-b-primary after:border-dashed': !isStoppedoday,
      'px-2 py-1': eventHeight < 20,
    }"
    :style="{
      top: `${startedAtPercentage}%`,
      height: `${eventHeight}px`,
      zIndex: `${offset + 1}`,
      width: `calc(100% - ${offset > 0 ? eventSizeOffset : '30'}px)`,
    }"
  >
    <div
      class="flex-1 leading-3 overflow-hidden truncate"
      :title="`${event.slug} ${event.title} -> ${formatDate(event.startedAt, 'HH:mm')} - ${formatDate(event.stoppedAt, 'HH:mm') || 'Actif'}`"
    >
      <span
        v-if="event.slug"
        class="mr-1 text-xs font-bold text-gray-300"
      >
        {{ event.slug }}
      </span>
      <span
        class="text-xs text-paragraph break-all"
        :class="{
          'break-all': eventHeight >= minEventHeight,
          'whitespace-nowrap': eventHeight < minEventHeight,
        }"
      >
        {{ showNotes && !!event.note ? `Note: ${event.note}` : event.title }}
      </span>
    </div>
    <div
      class="text-[#7F96FF] text-xs flex flex-row items-center"
      :title="`${event.slug} ${event.title} -> ${formatDate(event.startedAt, 'HH:mm')} - ${formatDate(event.stoppedAt, 'HH:mm') || 'Actif'}`"
      :class="{
        'flex-row': eventHeight >= minEventHeight,
        'flex-col': eventHeight < minEventHeight,
        'text-success': isNotStopped || isPaused,
      }"
    >
      <span class="whitespace-nowrap">
        {{ isStartedToday ? formatDate(event.startedAt, 'HH:mm') : formatDate(event.startedAt, 'DD/MM') }} - {{ formatDate(event.stoppedAt, 'HH:mm') || 'Actif' }}
      </span>
      <span v-if="event.pauseTimeInMs">{{ $t('components.ui.skyCalendarDayEvent.pauseTime', { value: formatDurationFromMs(event.pauseTimeInMs) }) }}</span>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { formatDate, formatDurationFromMs } from '@/utils/index.js';
import dayjs from 'dayjs';

const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  date: {
    type: Date,
    default: new Date(),
  },
  showNotes: {
    type: Boolean,
    default: false,
  },
  offset: {
    type: Number,
    default: 0,
  },
});

const dayHourHeight = 60;
const eventMaximumSize = 115;
const minEventHeight = 55;

const calendarDayMaxHours = inject('calendarDayMaxHours');
const isNotStopped = computed(() => !props.event.stoppedAt && !props.event.pausedAt);
const isPaused = computed(() => !!props.event.pausedAt);
const isStartedToday = computed(() => props.date.toDateString() === new Date(props.event.startedAt).toDateString());
const isStoppedoday = computed(() => props.date.toDateString() === new Date(props.event.stoppedAt).toDateString());

const eventSizeOffset = computed(() => {
  return eventMaximumSize + (props.offset - 1) * (eventMaximumSize / 1.3);
});

const stoppedAtPercentage = computed(() => {
  const { stoppedAt } = props.event;
  const end = new Date(stoppedAt);
  const minutes = end.getMinutes();

  return (minutes / 60) * 100;
});

const startedAtPercentage = computed(() => {
  const { startedAt } = props.event;
  const start = new Date(startedAt);

  if (!isStartedToday.value) return 0;

  const minutes = start.getMinutes();
  return (minutes / 60) * 100;
});

const stoppedEventHeight = computed(() => {
  const { stoppedAt } = props.event;
  const startedAt = isStartedToday.value ? new Date(props.event.startedAt) : dayjs().set('hour', 0).set('minutes', 0).toDate();
  const stoppedAtMinutesHeight = (stoppedAtPercentage.value / 100) * dayHourHeight;
  const startedAtMinutesHeight = (startedAtPercentage.value / 100) * dayHourHeight;
  return (new Date(stoppedAt).getHours() - startedAt.getHours()) * dayHourHeight + stoppedAtMinutesHeight - startedAtMinutesHeight;
});

const onGoingEventHeight = computed(() => {
  const { startedAt } = props.event;
  const stoppedAt = props.date;
  stoppedAt.setHours(calendarDayMaxHours.value);
  const startedAtMinutesHeight = (startedAtPercentage.value / 100) * dayHourHeight;

  return (new Date(stoppedAt).getHours() - new Date(startedAt).getHours()) * dayHourHeight - startedAtMinutesHeight;
});
const eventHeight = computed(() => (isNotStopped.value || isPaused.value ? onGoingEventHeight.value : stoppedEventHeight.value));
</script>
