import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index.js';
import Home from '@/views/Home.vue';
import NotReady from '@/views/NotReady.vue';

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/layouts/Security.vue'),
    meta: { requireAuth: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: '/projects/tasks/:taskId?',
        name: 'Tasks',
        component: Home,
      },
      {
        path: '/projects/recap',
        name: 'ProjectsRecap',
        component: () => import('@/views/projects/Recap.vue'),
      },
      {
        path: '/bookings/:bookingId?',
        name: 'BookingsCalendar',
        component: () => import('@/views/bookings/Calendar.vue'),
      },
      {
        path: '/bookings/list',
        name: 'BookingsList',
        component: () => import('@/views/bookings/Bookings.vue'),
      },
      {
        path: '/bookings/rooms',
        name: 'BookingsRooms',
        component: () => import('@/views/bookings/Rooms.vue'),
      },
      {
        path: '/bookings/settings',
        name: 'BookingsSettings',
        component: () => import('@/views/bookings/Settings.vue'),
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/Users.vue'),
      },
      {
        path: '/profile',
        name: 'Profile',
        // component: () => import('@/views/Profile.vue'),
        component: NotReady,
      },
      {
        path: '/payments',
        name: 'Payments',
        component: () => import('@/views/Payments.vue'),
      },
      {
        path: '/stats',
        name: 'Stats',
        component: () => import('@/views/stats/AvailabilitySuspense.vue'),
      },
      {
        path: '/stats/reports/:reportId?',
        name: 'Report',
        component: () => import('@/views/stats/AvailabilitySuspense.vue'),
      },
      {
        path: '/converters',
        name: 'Converters',
        component: NotReady,
      },
      {
        path: '/mails',
        name: 'Mails',
        component: NotReady,
      },
      // {
      //   path: '/projects',
      //   name: 'Projects',
      //   component: NotReady,
      // },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/Settings.vue'),
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/Profile.vue'),
      },
      {
        path: '/clouds',
        name: 'Clouds',
        component: () => import('@/views/clouds/layouts/Panel.vue'),
        children: [
          {
            path: '/clouds/',
            name: 'CloudsServers',
            component: () => import('@/views/clouds/Servers.vue'),
          },
          {
            path: '/clouds/kubernetes',
            name: 'CloudsK8s',
            component: () => import('@/views/clouds/Kubernetes.vue'),
          },
          {
            path: '/clouds/kubernetes/:kubernetesId',
            name: 'CloudsKubernetesId',
            component: () => import('@/views/clouds/KubernetesId.vue'),
          },
          {
            path: '/clouds/servers/:serverId',
            name: 'CloudsServerId',
            component: () => import('@/views/clouds/ServerId.vue'),
          },
          {
            path: '/clouds/servers/create',
            name: 'CloudsServerCreate',
            component: () => import('@/views/clouds/ServerCreate.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/',
    name: 'Translation',
    component: () => import('@/layouts/StatelessTranslation.vue'),
    children: [
      {
        path: '/admin',
        name: 'Admin',
        component: () => import('@/layouts/Admin.vue'),
        children: [
          {
            path: '/admin/',
            name: 'AdminDashboard',
            component: () => import('@/views/admin/layouts/Panel.vue'),
            meta: { requireAdminAuth: true },
            children: [
              {
                path: '/admin/',
                name: 'AdminHome',
                component: () => import('@/views/admin/Home.vue'),
              },
              {
                path: '/admin/tasks/:taskId?',
                name: 'AdminTasks',
                component: () => import('@/views/admin/Home.vue'),
              },
              {
                path: '/admin/backlog',
                name: 'AdminBacklog',
                component: () => import('@/views/admin/Backlog.vue'),
              },
              {
                path: '/admin/backlog/tasks/:taskId?',
                name: 'AdminBacklogTasks',
                component: () => import('@/views/admin/Backlog.vue'),
              },
              {
                path: '/admin/apps',
                name: 'AdminApps',
                component: () => import('@/views/admin/Apps.vue'),
              },
              {
                path: '/admin/teams',
                name: 'AdminTeams',
                component: () => import('@/views/admin/Teams.vue'),
              },
              {
                path: '/admin/apps/:appId',
                name: 'AdminAppSettings',
                component: () => import('@/views/admin/AppSettings.vue'),
              },
              {
                path: '/admin/apps/:appId/login',
                name: 'AdminSwitchApp',
                component: () => import('@/views/admin/SwitchApp.vue'),
              },
              {
                path: '/admin/register',
                name: 'AdminRegister',
                component: () => import('@/views/Register.vue'),
              },
              {
                path: '/admin/monitoring',
                name: 'Monitoring',
                component: () => import('@/views/admin/Monitoring.vue'),
              },
            ],
          },
          {
            path: '/admin/login',
            name: 'AdminLogin',
            component: () => import('@/views/admin/Login.vue'),
          },
        ],
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
      },
      {
        path: '/recovery',
        name: 'Recovery',
        component: () => import('@/views/Recovery.vue'),
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/Register.vue'),
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/ResetPassword.vue'),
      },
      {
        path: '/logout',
        name: 'Logout',
        component: () => import('@/views/Logout.vue'),
      },
    ],
  },
  {
    path: '/apps/:appId',
    name: 'SwitchApp',
    component: () => import('@/views/SwitchApp.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.onError((err) => {
  const isChunkLoadError = /ChunkLoadError:.*failed./i.test(err.message);
  const isLoadingChunkFailed = /Loading.*chunk.*failed./i.test(err.message);
  if (isChunkLoadError || isLoadingChunkFailed) {
    window.location.reload();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/isAuthenticated']) {
      next();
      return;
    }
    localStorage.setItem('after_auth_path', to.path);
    next('/login');
    return;
  }

  if (to.matched.some((record) => record.meta.requireAdminAuth)) {
    if (localStorage.getItem('token_admin')) {
      next();
      return;
    }
    localStorage.setItem('after_auth_path', to.path);
    next('/admin/login');
    return;
  }

  next();
});

export default router;
