import { ProbeEvents } from '@/modules/stats/availability/domain/probe-event/collection/probe-events.js';
import { ImmutableCollection } from '@/utils/collections/immutable-collection.js';

/**
 * @extends {ImmutableCollection<ProbeEvents>}
 */
export class ConsolidatedProbesHistory extends ImmutableCollection {
  innerTypeOf() {
    return ProbeEvents;
  }

  /**
   * @param {ProbeEvent[]} events
   * @returns {ConsolidatedProbesHistory}
   */
  static fromEvents(events) {
    return events.reduce(
      (consolidated, event) =>
        consolidated.replaceOrCreate(
          (probeEvents) => probeEvents.hasSameProbe(event),
          (probeEvents) => probeEvents.addEvent(event),
          () => ProbeEvents.fromEvents([event]),
        ),
      new ConsolidatedProbesHistory(),
    );
  }

  /**
   * @param {ProbeEvents} probeEvents
   */
  static fromProbeEvents(probeEvents) {
    return this.fromEvents(probeEvents.toEventsCollection().toArray());
  }

  /**
   * @returns {ProbeEvents}
   */
  toProbeEvents() {
    return this.reduce((accumulator, probeEvents) => accumulator.addEventsFromProbeEvents(probeEvents), new ProbeEvents());
  }

  /**
   * @param {String[]} ids
   * @returns {ProbeEvent[]}
   */
  eventsFromIds(ids = []) {
    const allEvents = this.toFlatArray().reduce((acc, probeEventDay) => [...acc, ...probeEventDay.events], []);

    if (ids.length === 0) {
      return allEvents;
    }

    return allEvents.filter((probeEvent) => ids.includes(probeEvent.id));
  }
}
