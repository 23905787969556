<template>
  <SkyAsyncSelect
    :model-value="modelValue"
    :options="users"
    :loading="loading"
    path-label="email"
    :placeholder="$t('components.users.usersSelect.placeholder')"
    v-bind="$attrs"
    @search="handleSearch"
  >
    <template #default="{ option }">
      <div>
        <div>{{ option.firstName }} {{ option.lastName }}</div>
        <div class="text-sm text-gray-400">
          {{ option.email }}
        </div>
      </div>
    </template>
    <template #item="{ item, handleRemoveItem }">
      <button
        type="button"
        class="flex items-center justify-between w-full p-2 transition-colors rounded hover:bg-gray-200"
        @click="handleRemoveItem(item)"
      >
        <User :user-id="item">
          <template #default="{ user }">
            <div class="flex flex-row items-center gap-2">
              <span
                v-if="!user || !user.picture"
                class="flex items-center justify-center w-8 h-8 text-xs text-white rounded-full bg-primary"
              >
                <FontAwesomeIcon :icon="faUser" />
              </span>
              <img
                v-else
                :src="user.picture"
                alt=""
                class="w-8 h-8 rounded-full"
              />
              <span
                v-if="user"
                class="font-medium"
              >
                {{ user.firstName }}
              </span>
              <span
                v-else
                class="font-medium"
              >
                Deleted User
              </span>
            </div>
          </template>
        </User>
        <span class="user__remove-icon" />
      </button>
    </template>
  </SkyAsyncSelect>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import User from '@/modules/users/components/User.vue';
import { faUser } from '@fortawesome/pro-light-svg-icons';

export default {
  components: {
    User,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => undefined,
    },
  },

  setup() {
    const store = useStore();

    const state = reactive({
      users: computed(() => store.getters['users/getUsers']),
      loading: false,
      timerBounce: undefined,
    });

    function handleSearch(search) {
      state.loading = true;
      clearTimeout(state.timerBounce);

      state.timerBounce = setTimeout(async () => {
        try {
          await store.dispatch('users/searchUsers', { search });
        } catch (err) {
          console.error(err);
        }
        state.loading = false;
      }, 500);
    }

    return {
      handleSearch,
      ...toRefs(state),
      faUser,
    };
  },
};
</script>

<style lang="scss" scoped>
.user__remove-icon {
  @apply bg-error rounded text-xs w-5 h-5 relative;

  &::after {
    @apply absolute w-1/2 bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded;
    content: '';
    height: 2px;
  }
}
</style>
