import { ImmutableCollection } from '@/utils/collections/immutable-collection.js';
import { ProbeEventsDayStatePast } from '@/modules/stats/availability/domain/probe-event/collection/day-states/probe-events-day-state-past.js';
import { DateValueObject } from '@/utils/value-objects/date/date-value-object.js';

export class ProbeEventsDay {
  /**
   * @type {DateValueObject}
   * @public
   */
  _date;

  /**
   * @type {ImmutableCollection<ProbeEvent>}
   * @private
   */
  _events;

  /**
   * @type {ProbeEventsDayState}
   * @private
   */
  _state;

  /**
   * @param {DateValueObject} date
   * @param {ImmutableCollection<ProbeEvent>} [events=new ImmutableCollection()]
   * @param {ProbeEventsDayState} [state=ProbeEventsDayStatePast]
   */
  constructor(date, events = new ImmutableCollection(), state = new ProbeEventsDayStatePast()) {
    this._date = date;
    this._state = state;
    this._events = new ImmutableCollection(...events);
  }

  /**
   * @param {ProbeEvent[]} events
   */
  static fromEvents(events) {
    const probeEvents = new ImmutableCollection(...events);
    if (probeEvents.isEmpty()) {
      throw new Error('Cannot determine date of the day from empty events');
    }

    /** @type {ProbeEvent} */
    const firstEvent = probeEvents.first();
    return new ProbeEventsDay(firstEvent.lifeTime.startedAt, probeEvents);
  }

  /**
   * @param {string} date
   * @return {ProbeEventsDay}
   */
  static emptyFromNativeDate(date) {
    return new ProbeEventsDay(DateValueObject.fromNative(date));
  }

  /**
   * @param {ProbeEventsDay} eventsDay
   */
  isEventsDayInSameWeek(eventsDay) {
    return eventsDay._date.hasSameISO8601WeekAndMonth(this._date);
  }

  /**
   * @param {DateValueObject} date
   * @returns {boolean}
   */
  hasSameMonth(date) {
    return this._date.hasSameMonth(date);
  }

  /**
   * @param {ProbeEventsDay} eventsDay
   * @return {boolean}
   */
  isEventsDayInSameMonth(eventsDay) {
    return this.hasSameMonth(eventsDay._date);
  }

  /**
   * @param {ProbeEvent} event
   * @returns {ProbeEventsDay}
   */
  addEvent(event) {
    return new ProbeEventsDay(
      this._date,
      this._events.add(event).sort((eventA, eventB) => eventA.lifeTime.compareToStartedAt(eventB.lifeTime)),
    );
  }

  hasSameProbe(event) {
    return this._events.some((probeEvent) => probeEvent.hasSameProbe(event));
  }

  /**
   * @param {ProbeEvent} event
   * @returns {boolean}
   */
  isEventInSameDay(event) {
    return event.lifeTime.isPartOfTheDay(this._date);
  }

  /**
   * @param {ProbeEvent} event
   * @returns {boolean}
   */
  isEventPartOfTheMonth(event) {
    return event.lifeTime.isPartOfTheMonth(this._date);
  }

  /**
   * @param {ProbeEvent} event
   * @returns {boolean}
   */
  isEventPartOfTheWeek(event) {
    return event.lifeTime.isPartOfTheISO8601Week(this._date);
  }

  get status() {
    return this._state.getDayStatus(this.toEventsCollection());
  }

  get currentMonthLabel() {
    return this._date.currentMonthLabel;
  }

  get startedAtLocalString() {
    if (this._events.isEmpty()) {
      return this._date.toLocaleString();
    }

    return this._events.first().lifeTime.startedAtLocalString;
  }

  get events() {
    return this._events.toFlatArray();
  }

  /**
   * @param {ProbeEventsDay} eventsDay
   * @return {0|1|-1}
   */
  compareEventsDayTo(eventsDay) {
    return this._date.compareTo(eventsDay._date);
  }

  /**
   * @return {ImmutableCollection<ProbeEvent>}
   */
  toEventsCollection() {
    return this._events.reduce((accumulator, event) => accumulator.add(event), new ImmutableCollection());
  }
}
