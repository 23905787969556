export class InconsistentProbe extends Error {
  static missingId() {
    return new this('Missing required probe id');
  }

  static missingName() {
    return new this('Missing required probe name');
  }

  static missingCreatedAt() {
    return new this('Missing required probe created at date');
  }
}
