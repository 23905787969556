<template>
  <div class="flex flex-col gap-1">
    <label class="relative block">
      <div
        v-if="label"
        class="block mb-2 text-sm font-medium text-primary"
        :class="{ required: isRequired }"
      >
        {{ label }}
      </div>
      <input
        ref="input"
        v-model="internalInputTextValue"
        type="text"
        autocomplete="off"
        style="transition: all 0.15s ease 0s"
        class="w-full h-10 px-3 text-sm placeholder-gray-400 bg-white border rounded appearance-none focus:outline-none focus:shadow-outline"
        :class="inputClasses"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="isRequired"
        @focus="selectPopupOpen = true"
        @blur="handleCleanBlur"
      />
      <span
        class="flex items-center h-4 mt-1 ml-1 text-xs font-medium tracking-wide text-error"
        :class="{ hidden: !hasErrorMessages }"
      >
        {{ firstErrorMessage }}
      </span>
      <div
        v-show="selectPopupOpen"
        class="absolute z-10 w-full mt-2 overflow-y-auto bg-white border rounded shadow-xl top-full max-h-48"
        @mousedown.prevent
      >
        <div
          v-if="loading"
          class="px-2 py-1 text-sm text-center text-gray-400"
        >
          {{ $t('components.ui.skyAsyncSelect.loading') }}
        </div>
        <div
          v-else-if="options.length === 0"
          class="px-2 py-1 text-sm text-center text-gray-400"
        >
          {{ $t('components.ui.skyAsyncSelect.notFound') }}
        </div>
        <div v-show="!loading">
          <div
            v-for="option of options"
            :key="option[pathValue]"
            class="px-4 py-2 cursor-pointer hover:bg-gray-50"
            @click="handleSelectOption(option)"
          >
            <slot :option="option">
              {{ option[pathLabel] }}
            </slot>
          </div>
        </div>
      </div>
    </label>
    <div
      v-if="isMulti"
      v-show="modelValue.length > 0"
      class="flex flex-wrap gap-1 text-xs"
    >
      <slot
        v-for="modelItem of modelValue"
        :key="modelItem"
        name="item"
        :item="modelItem"
        :handle-remove-item="handleRemoveItem"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import filter from 'lodash/filter';
import useValidatable from '@/modules/shared/utils/validatable.js';

export default {
  props: {
    modelValue: { type: [String, Object, Number, Array], default: () => undefined },
    label: { type: String, default: () => undefined },
    placeholder: { type: String, default: () => undefined },
    disabled: { type: Boolean, default: () => false },
    rules: { type: Array, default: () => [] },
    pathLabel: { type: String, default: () => 'name' },
    pathValue: { type: String, default: () => '_id' },
    loading: { type: Boolean, default: () => false },
    options: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['search', 'update:modelValue'],

  setup(props, { emit }) {
    const input = ref(null);
    const { state, validate } = useValidatable({
      props,
      emit,
      selectPopupOpen: false,
      isMulti: computed(() => props.modelValue instanceof Array),
      inputTextValue: undefined,
      internalInputTextValue: computed({
        get() {
          return state.inputTextValue;
        },
        set(value) {
          state.inputTextValue = value;
          if (value === '') {
            state.selectPopupOpen = false;
            if (!state.isMulti) {
              state.internalValue = undefined;
            }
            return;
          }
          state.selectPopupOpen = true;
          if (!state.isMulti) {
            state.internalValue = value;
          }
          emit('search', value);
        },
      }),
    });

    const inputClasses = computed(() => ({
      'border-red-500': state.hasErrorMessages,
      'border-dark-blue-100': !state.hasErrorMessages,
    }));

    function handleSelectOption(option) {
      state.selectPopupOpen = false;

      if (!state.isMulti) {
        state.inputTextValue = option[props.pathLabel];
        state.internalValue = option[props.pathValue];
        return;
      }

      state.inputTextValue = '';
      state.internalValue = uniq([...state.internalValue, option[props.pathValue]]);
    }

    function handleRemoveItem(modelItem) {
      state.internalValue = filter(state.internalValue, (value) => value !== modelItem);
    }

    function handleModelValue() {
      if (!props.modelValue || state.inputTextValue !== undefined) {
        return;
      }
      const option = find(props.options, {
        [props.pathValue]: props.modelValue,
      });
      if (!option || !props.modelValue || props.modelValue?.length === 0) {
        return;
      }
      state.inputTextValue = option[props.pathLabel];
    }

    function handleCleanBlur() {
      state.selectPopupOpen = false;
    }

    onMounted(handleModelValue);

    watch(() => {
      if (state.isMulti) {
        return `${props.options.length}_${props.modelValue.length}`;
      }
      return `${props.options.length}_${props.modelValue}`;
    }, handleModelValue);

    watch(() => props.modelValue, handleModelValue);

    return {
      ...toRefs(state),
      input,
      inputClasses,
      validate,
      handleSelectOption,
      handleCleanBlur,
      handleRemoveItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.required::after {
  @apply content-asterisk ml-1 text-red-600 font-medium;
}
</style>
