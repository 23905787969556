<template>
  <div class="flex flex-col gap-4">
    <div
      class="rounded-md bg-primary text-white-smoke"
      :class="{
        // default: bg-gradient-radial bg-gradient-right-bottom from-primary to-secondary
        'animate-pulse-bg':
          (taskState === 'default' && currentUser.roles.includes('admin:skyloud')) ||
          (taskState === 'isPending' && !staffOnly) ||
          (['awaitingValidation', 'awaitingSolution'].includes(taskState) && currentUser.roles.includes('admin')) ||
          (taskState === 'done' && currentUser.roles.includes('admin:skyloud')),
        // 'from-[#112064] to-[#100A33]': taskState === 'awaitingValidation' && currentUser.roles.includes('admin'),
      }"
    >
      <DefaultTaskControl
        v-if="taskState === 'default'"
        :task="task"
      />
      <AwaitingCustomerControl
        v-if="taskState === 'isPending'"
        :task="task"
      />
      <EstimationPendingControl
        v-if="taskState === 'awaitingValidation'"
        :task="task"
      />
      <CustomerCompletedValidationControl
        v-if="taskState === 'customerCompletedValidation'"
        :task="task"
      />
      <EstimationRefusedControl
        v-if="taskState === 'awaitingSolution'"
        :task="task"
      />
      <EstimationAcceptedControl
        v-if="taskState === 'awaitingPlanning'"
        :task="task"
      />
      <WorkInProgressControl
        v-if="taskState === 'workInProgress'"
        :task="task"
      />
      <DoneTaskControl
        v-if="taskState === 'done'"
        :task="task"
      />
    </div>
    <MergeRequestValidationControl
      v-if="currentUser.roles.includes('admin:skyloud')"
      :task="task"
    />
  </div>
</template>

<script>
import { reactive, computed, toRefs } from 'vue';
import { useStore } from 'vuex';
import get from 'lodash/get';
import find from 'lodash/find';
import toLower from 'lodash/toLower';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { formatDate } from '@/utils/format.js';
import WorkInProgressControl from '@/modules/projects/components/TaskControls/WorkInProgressControl.vue';
import EstimationRefusedControl from '@/modules/projects/components/TaskControls/EstimationRefusedControl.vue';
import EstimationAcceptedControl from '@/modules/projects/components/TaskControls/EstimationAcceptedControl.vue';
import EstimationPendingControl from '@/modules/projects/components/TaskControls/EstimationPendingControl.vue';
import AwaitingCustomerControl from '@/modules/projects/components/TaskControls/AwaitingCustomerControl.vue';
import DefaultTaskControl from '@/modules/projects/components/TaskControls/DefaultTaskControl.vue';
import MergeRequestValidationControl from '@/modules/projects/components/TaskControls/MergeRequestValidationControl.vue';
import CustomerCompletedValidationControl from '@/modules/projects/components/TaskControls/CustomerCompletedValidationControl.vue';
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons';
import { faLifeRing, faInfoCircle, faTimesCircle, faCheckCircle, faBolt } from '@fortawesome/pro-light-svg-icons';
import DoneTaskControl from '@/modules/projects/components/TaskControls/DoneTaskControl.vue';
import { TASK_PENDING_STATUS } from '../projects.constants.js';

export default {
  components: {
    WorkInProgressControl,
    EstimationRefusedControl,
    EstimationAcceptedControl,
    EstimationPendingControl,
    CustomerCompletedValidationControl,
    DoneTaskControl,
    AwaitingCustomerControl,
    MergeRequestValidationControl,
    DefaultTaskControl,
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const getTask = store.getters['projects/getTask'];
    const getLabel = store.getters['projects/getLabel'];
    const hasRole = store.getters['auth/hasRole'];

    const state = reactive({
      task: computed(() => getTask({ taskId: props.taskId })),
      currentUser: computed(() => store.getters['auth/currentUser']),
      staffOnly: computed(() => hasRole('admin:skyloud')),
      missionLabel: computed(() => find(get(store, 'state.projects.labels', []), (label) => label.name === 'Mission')),
      taskLabels: computed(() => {
        const labelIds = get(state.task, 'labelIds', []);
        if (isEmpty(labelIds)) {
          return [];
        }
        const labels = map(labelIds, (labelId) => getLabel({ labelId }));
        return compact(labels);
      }),
      hasMissionLabel: computed(() => !!find(state.taskLabels, (label) => toLower(label.name) === 'mission')),
      taskState: computed(() => {
        const isWaitingEstimationValidation = state.hasMissionLabel && state.task.estimatedTime > 0 && !state.task.estimatedTimeAcceptedAt && !state.task.estimatedTimeRefusedAt;
        if (!state.task) {
          return 'default';
        }

        if (isWaitingEstimationValidation || (state.task.pendingAt && isWaitingEstimationValidation)) {
          return 'awaitingValidation';
        }

        if (state.task.pendingAt && state.task.pendingStatus === TASK_PENDING_STATUS.COMPLETED) {
          return 'customerCompletedValidation';
        }

        if (state.task.pendingAt && state.task.pendingStatus === TASK_PENDING_STATUS.CUSTOMER) {
          return 'isPending';
        }

        if (state.task.estimatedTimeRefusedAt) {
          return 'awaitingSolution';
        }

        if (state.task.listId === 'done') {
          return 'done';
        }

        if (state.task.listId === 'in_progress') {
          return 'workInProgress';
        }

        if (state.task.estimatedTimeAcceptedAt) {
          return 'awaitingPlanning';
        }

        return 'default';
      }),
    });

    return {
      ...toRefs(state),
      formatDate,
      faLifeRing,
      faInfoCircle,
      faTimesCircle,
      faCheckCircle,
      faBolt,
      faCheckDouble,
      hasRole,
    };
  },
};
</script>

<style lang="scss" scoped>
.animate-pulse-bg {
  animation: 1.5s linear 1s infinite alternate bg-glance;
}

@keyframes bg-glance {
  from {
    @apply bg-primary;
    // background-image: radial-gradient(circle at right bottom, #7f96ff, #4800bf);
  }

  to {
    background-color: lighten(rgb(127, 150, 255), 5%);
    // background-image: radial-gradient(circle at right bottom, lighten(rgb(127, 150, 255), 12%), lighten(rgb(72, 0, 191), 12%));
  }
}
</style>
