import { Probes } from '@/modules/stats/availability/domain/probe/probes.js';
import { Probe } from '@/modules/stats/availability/domain/probe/probe.js';
import { ProbeId } from '@/modules/stats/availability/domain/probe/probe-id.js';
import { ProbeName } from '@/modules/stats/availability/domain/probe/probe-name.js';
import { DateValueObject } from '@/utils/value-objects/date/date-value-object.js';

/**
 * @typedef {{
 *   _id: string,
 *   providersMonitorId: {
 *     betteruptime: string,
 *   },
 *   name: string,
 * }} FindAllProbesItem
 * @typedef {FindAllProbesItem[]} FindAllProbesResponseData
 */

export class ApiFindAllProbesResponse {
  /**
   * @param {FindAllProbesResponseData} response
   */
  constructor(response) {
    this.response = response;
  }

  toProbes() {
    const probes = this.response.map(
      (probe) => new Probe(new ProbeId(probe.providersMonitorId.betteruptime), new ProbeName(probe.name), new DateValueObject(new Date(probe.createdAt))),
    );

    return Probes.fromProbes(probes);
  }
}
