import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import set from 'lodash/set';
import get from 'lodash/get';
import sum from 'lodash/sum';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import head from 'lodash/head';
import slice from 'lodash/slice';
import isNil from 'lodash/isNil';
import sumBy from 'lodash/sumBy';
import first from 'lodash/first';
import every from 'lodash/every';
import omitBy from 'lodash/omitBy';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import config from '@/config/index.js';
import {
  deleteArchiveTask,
  fetchLabels,
  fetchMilestones,
  fetchMilestone,
  getTask,
  postTaskWorkLogAction,
  postAddTaskWorkLog,
  patchUpdateTask,
  patchRestoreEstimation,
  patchTaskCompletion,
  patchUpdateTaskPendingStatus,
  patchTaskCompletionPendingStatus,
  patchTaskMergeRequestValidationStatus,
  patchTaskMergeRequestValidation,
  postAddTask,
  getTasks,
  patchTaskLabel,
  deleteTaskLabel,
  patchTaskMember,
  deleteTaskMember,
  deleteTaskWorkLog,
  patchTaskWorkLog,
  unDeleteArchiveTask,
  getTasksFromPeriod,
  patchTaskMilestone,
  deleteTaskMilestone,
  postCreateThread,
  getRunningWorklogs,
  projectsStopWorklog,
  projectsGetTasksStats,
} from '@/modules/projects/projects.api.js';
import { i18n } from '@/plugins/i18n/index.js';
import { getTasksFiltersChecks } from '@/modules/projects/projects.functions.js';
import { taskDateComparators, TASKS_FETCH_LIMIT, taskEstimationRequests, TASK_PENDING_STATUS } from '@/modules/projects/projects.constants.js';
import trim from 'lodash/trim.js';
import { compact, difference, isEqual, map, toLower } from 'lodash';
import { hasActiveWorklogs } from '../admin/admin.functions.js';
import { adminProjectsAxios, projectsGetTask } from '../admin/admin.api.js';

dayjs.extend(dayjsUtc);

const stateData = {
  tasksSocket: undefined,
  tasksFiltersRequestController: undefined,
  tasks: [],
  tasksStats: {},
  todoTaskFilters: {
    hasMore: undefined,
    skip: 0,
  },
  inProgressTaskFilters: {
    hasMore: undefined,
    skip: 0,
  },
  doneTaskFilters: {
    hasMore: undefined,
    skip: 0,
  },
  deletedTaskFilters: {
    hasMore: undefined,
    skip: 0,
  },
  milestones: {
    hasMore: undefined,
    skip: 0,
    list: [],
  },
  labelFilters: [],
  kindFilters: [],
  prioritiesFilters: [],
  estimationFilters: [],
  membersFilters: [],
  milestonesFilters: [],
  deliveryDateFilter: undefined,
  deliveryComparatorFilter: first(keys(taskDateComparators)),
  isPendingFilter: undefined,
  labels: [],
  currentSearch: undefined,
  membersFilterVisibility: true,
};

const getters = {
  tasks: (state) => state.tasks,
  getTask:
    (state) =>
    ({ taskId: searchTaskId }) =>
      find(state.tasks, ({ _id, taskId }) => taskId === searchTaskId || _id === searchTaskId),
  getTasksStats: (state) => state.tasksStats,
  getFilteredTasks: (state, localGetters) =>
    filter(state.tasks, ({ deletedAt, memberIds = [], ...task }) => {
      if (deletedAt) {
        return false;
      }

      if (isEmpty(state.currentSearch) && isEmpty(state.labelFilters) && localGetters.countActiveStateFilters === 0) {
        return true;
      }

      const matchingFitlersResult = {
        ...getTasksFiltersChecks({ task, state }),
        members: isEmpty(state.membersFilters) || some(memberIds, (memberId) => state.membersFilters.includes(memberId)),
      };

      return every(matchingFitlersResult, Boolean);
    }),
  getFilteredDeletedTasks: (state, localGetters) =>
    filter(state.tasks, ({ deletedAt, memberIds = [], ...task }) => {
      if (!deletedAt) {
        return false;
      }

      if (isEmpty(state.currentSearch) && isEmpty(state.labelFilters) && localGetters.countActiveStateFilters === 0) {
        return true;
      }

      const matchingFitlersResult = {
        ...getTasksFiltersChecks({ task, state }),
        members: isEmpty(state.membersFilters) || some(memberIds, (memberId) => state.membersFilters.includes(memberId)),
      };

      return every(matchingFitlersResult, Boolean);
    }),
  getTasksByPriority: (state, localGetters, rootState, rootGetters) => {
    const { _id: userId } = rootGetters['auth/currentUser'];
    return reverse(
      sortBy(localGetters.getFilteredTasks, [
        ({ pendingAt }) => !pendingAt,
        ({ workLogs }) => hasActiveWorklogs({ workLogs, userIds: [userId] }),
        ({ priority }) => priority,
        ({ createdAt }) => -new Date(createdAt),
      ]),
    );
  },
  getTodoTasks: (state, localGetters) => filter(localGetters.getTasksByPriority, ({ listId = 'todo' }) => listId === 'todo'),
  getInProgressTasks: (state, localGetters) => filter(localGetters.getTasksByPriority, ({ listId = 'in_progress' }) => listId === 'in_progress'),
  getDoneTasks: (state, localGetters) => filter(localGetters.getTasksByPriority, ({ listId = 'done' }) => listId === 'done'),
  getDeletedTasks: (state) => filter(state.tasks, ({ deletedAt }) => !!deletedAt === true),
  getTodoTasksFilters: (state) => state.todoTaskFilters,
  getInProgressTasksFilters: (state) => state.inProgressTaskFilters,
  getDoneTasksFilters: (state) => state.doneTaskFilters,
  getHasMoreTodoTasks: (state) => state.todoTaskFilters.hasMore,
  getHasMoreInProgressTasks: (state) => state.inProgressTaskFilters.hasMore,
  getHasMoreDoneTasks: (state) => state.doneTaskFilters.hasMore,
  getHasMoreDeletedTasks: (state) => state.deletedTaskFilters.hasMore,
  getTodoEllapsedTime: (state, localGetters) => sumBy(localGetters.getTodoTasks, 'ellapsedTime'),
  getInProgressEllapsedTime: (state, localGetters) => sumBy(localGetters.getInProgressTasks, 'ellapsedTime'),
  getDoneEllapsedTime: (state, localGetters) => sumBy(localGetters.getDoneTasks, 'ellapsedTime'),
  getLatestsTasks: (state, localGetters) => reverse(slice(localGetters.getTasksByPriority, -3)),
  getUserTaskWithStartedWorkLog: (state, localGetters, rootState, rootGetters) => {
    const { _id: userId } = rootGetters['auth/currentUser'];
    return find(state.tasks, (task) => {
      const userWorkLogs = filter(task.workLogs, { userId });
      const workLogStarted = find(userWorkLogs, ({ stoppedAt }) => !stoppedAt);
      if (!workLogStarted) return false;
      return true;
    });
  },
  getLabels: (state) => state.labels,
  getMilestones: (state) => state.milestones,
  getLabelFilters: (state) => state.labelFilters,
  getKindFilters: (state) => state.kindFilters,
  getPrioritiesFilters: (state) => state.prioritiesFilters,
  getEstimationFilters: (state) => state.estimationFilters,
  getMembersFilters: (state) => state.membersFilters,
  getMilestonesFilters: (state) => state.milestonesFilters,
  getIsPendingFilter: (state) => state.isPendingFilter,
  getDeliveryDateFilters: (state) => state.deliveryDateFilters,
  getAllFilters: (state) =>
    omitBy(
      {
        kind: state.kindFilters,
        priority: state.prioritiesFilters,
        memberIds: state.membersFilters,
        milestoneIds: state.milestonesFilters,
        pendingAt: state.isPendingFilter,
        labelIds: state.labelFilters,
        dueAt: state.deliveryDateFilter
          ? {
              [state.deliveryComparatorFilter]: state.deliveryDateFilter,
            }
          : undefined,
        ...get(taskEstimationRequests, state.estimationFilters),
      },
      (e) => isNil(e) || isEmpty(e),
    ),
  getCurrentSearch: (state) => state.currentSearch,
  getTasksFiltersRequestController: (state) => state.tasksFiltersRequestController,
  getLabel:
    (state) =>
    ({ labelId }) =>
      find(state.labels, { _id: labelId }),
  getMilestone:
    (state) =>
    ({ milestoneId }) =>
      find(state.milestones.list, { _id: milestoneId }),
  countActiveStateFilters: (state) =>
    sum([
      state.kindFilters.length,
      state.prioritiesFilters.length,
      state.estimationFilters.length,
      state.milestonesFilters.length,
      !state.membersFilterVisibility ? 0 : state.membersFilters.length,
      state.deliveryDateFilter ? 1 : 0,
      state.isPendingFilter !== undefined ? 1 : 0,
    ]),
  getDeliveryDateFilter: (state) => state.deliveryDateFilter,
  getDeliveryComparatorFilter: (state) => state.deliveryComparatorFilter,
};

const mutations = {
  resetState(state) {
    Object.assign(state, stateData);
  },
  setMembersFilterVisibility(state, { visibility }) {
    state.membersFilterVisibility = visibility;
  },
  setTaskSocket(state, { tasksSocket }) {
    state.tasksSocket = tasksSocket;
  },
  setCurrentSearch(state, { search }) {
    state.currentSearch = trim(search, ' ');
  },
  refreshTasksFiltersRequestController(state, controller) {
    if (get(state, 'tasksFiltersRequestController') instanceof AbortController) {
      state.tasksFiltersRequestController.abort();
    }
    state.tasksFiltersRequestController = controller;
  },
  saveTask(state, { task }) {
    state.tasks = [...filter(state.tasks, ({ _id }) => _id !== task._id), task];
  },
  setTasksStats(state, { stats }) {
    state.tasksStats = stats;
  },
  saveTodoTasks(state, { hasMore, tasks }) {
    state.tasks = [...filter(state.tasks, ({ _id }) => !some(tasks, { _id })), ...tasks];
    state.todoTaskFilters.hasMore = hasMore;

    const nbOfTasksAlreadyFetched = filter(state.tasks, (task) => task.listId === 'todo').length;
    const nbOfTasksToFetch = state.todoTaskFilters.skip + tasks.length;
    const hasReducedNumberOfTasks = nbOfTasksToFetch >= nbOfTasksAlreadyFetched;

    const skip = hasReducedNumberOfTasks ? nbOfTasksAlreadyFetched : nbOfTasksToFetch;
    state.todoTaskFilters.skip = skip;
  },
  saveInProgressTasks(state, { hasMore, tasks }) {
    state.tasks = [...filter(state.tasks, ({ _id }) => !some(tasks, { _id })), ...tasks];
    state.inProgressTaskFilters.hasMore = hasMore;

    const nbOfTasksAlreadyFetched = filter(state.tasks, (task) => task.listId === 'in_progress').length;
    const nbOfTasksToFetch = state.inProgressTaskFilters.skip + tasks.length;
    const hasReducedNumberOfTasks = nbOfTasksToFetch >= nbOfTasksAlreadyFetched;

    const skip = hasReducedNumberOfTasks ? nbOfTasksAlreadyFetched : nbOfTasksToFetch;
    state.inProgressTaskFilters.skip = skip;
  },
  saveDoneTasks(state, { hasMore, tasks }) {
    state.tasks = [...filter(state.tasks, ({ _id }) => !some(tasks, { _id })), ...tasks];
    state.doneTaskFilters.hasMore = hasMore;

    const nbOfTasksAlreadyFetched = filter(state.tasks, (task) => task.listId === 'done').length;
    const nbOfTasksToFetch = state.doneTaskFilters.skip + tasks.length;
    const hasReducedNumberOfTasks = nbOfTasksToFetch >= nbOfTasksAlreadyFetched;

    const skip = hasReducedNumberOfTasks ? nbOfTasksAlreadyFetched : nbOfTasksToFetch;
    state.doneTaskFilters.skip = skip;
  },
  resetDeletedTasks(state) {
    state.deletedTaskFilters.hasMore = undefined;
    state.deletedTaskFilters.skip = 0;
  },
  saveDeletedTasks(state, { hasMore, tasks }) {
    state.tasks = [...filter(state.tasks, ({ _id }) => !some(tasks, { _id })), ...tasks];
    state.deletedTaskFilters.hasMore = hasMore;
    state.deletedTaskFilters.skip += tasks.length;
  },
  addTask(state, { task }) {
    state.tasks = [...filter(state.tasks, ({ _id }) => _id !== task._id), task];
  },
  updateTask(state, { task, removedFields }) {
    const taskIndex = findIndex(state.tasks, (item) => item._id === task._id || item.taskId === task.taskId);
    if (taskIndex === -1) {
      return;
    }

    forEach(task, (fieldValue, fieldKey) => {
      set(state.tasks, [taskIndex, ...fieldKey.split('.')], fieldValue);
    });

    if (!removedFields) {
      return;
    }
    state.tasks[taskIndex] = omit(state.tasks[taskIndex], ...removedFields);
  },
  updateTaskPendingStatus(state, { task }) {
    const taskIndex = findIndex(state.tasks, (item) => item._id === task._id || item.taskId === task.taskId);
    state.tasks[taskIndex].pendingAt = task.pendingAt;
    state.tasks[taskIndex].pendingStatus = task.pendingStatus;
  },
  updateTaskWorkLog(state, { taskId, workLogId, ...workLog }) {
    const taskIndex = findIndex(state.tasks, (item) => item._id === taskId || item.taskId === taskId);
    if (taskIndex === -1) {
      return;
    }

    const taskWorkLogIndex = findIndex(get(state.tasks[taskIndex], 'workLogs', []), (item) => item._id === workLogId);
    if (taskWorkLogIndex === -1) {
      return;
    }
    state.tasks[taskIndex].workLogs[taskWorkLogIndex] = {
      ...state.tasks[taskIndex].workLogs[taskWorkLogIndex],
      ...workLog,
    };
  },
  upsertTaskWorkLog(state, { taskId, workLog }) {
    const taskIndex = findIndex(state.tasks, { _id: taskId });
    if (taskIndex === -1) {
      return;
    }

    const task = state.tasks[taskIndex];
    state.tasks[taskIndex] = {
      ...task,
      workLogs: [...reject(task.workLogs, { _id: workLog._id }), workLog],
    };
  },
  setLabels(state, { labels }) {
    state.labels = labels;
  },
  setMilestones(state, { milestones, hasMore }) {
    const unsortedMilestones = [...filter(state.milestones.list, ({ _id }) => !some(milestones, { _id })), ...milestones];
    state.milestones.list = sortBy(unsortedMilestones, ['endAt', 'name']);
    state.milestones.hasMore = hasMore;

    const nbOfMilestonesAlreadyFetched = state.milestones.list.length;
    const nbOfMilestonesToFetch = state.milestones.skip + milestones.length;
    const hasReducedNumberOfMilestones = nbOfMilestonesToFetch >= nbOfMilestonesAlreadyFetched;

    const skip = hasReducedNumberOfMilestones ? nbOfMilestonesAlreadyFetched : nbOfMilestonesToFetch;
    state.milestones.skip = skip;
  },
  updateLabel(state, { labelId, name, backgroundColor, rules, dependencies }) {
    const labelToUpdate = find(state.labels, (label) => label._id === labelId);
    const otherLabels = filter(state.labels, (label) => label._id !== labelId);
    state.labels = [
      ...otherLabels,
      {
        ...labelToUpdate,
        name,
        backgroundColor,
        rules,
        dependencies,
      },
    ];
  },
  addLabel(state, { label }) {
    state.labels = [...state.labels, label];
  },
  removeLabel(state, { labelId }) {
    const otherLabels = filter(state.labels, (label) => label._id !== labelId);
    state.labels = otherLabels;
  },
  disableLabel(state, { labelId, disabledAt }) {
    const labelToUpdate = find(state.labels, (label) => label._id === labelId);
    const otherLabels = filter(state.labels, (label) => label._id !== labelId);
    state.labels = [
      ...otherLabels,
      {
        ...labelToUpdate,
        disabledAt,
      },
    ];
  },
  setLabelFilters(state, { labelFilters }) {
    state.labelFilters = labelFilters;
  },
  setKindFilters(state, { kindFilters }) {
    state.kindFilters = kindFilters;
  },
  setIsPendingFilter(state, { isPendingFilter }) {
    state.isPendingFilter = isPendingFilter;
  },
  setPrioritiesFilters(state, { prioritiesFilters }) {
    state.prioritiesFilters = prioritiesFilters;
  },
  setMembersFilters(state, { membersFilters }) {
    state.membersFilters = membersFilters;
  },
  setMilestonesFilters(state, { milestonesFilters }) {
    state.milestonesFilters = milestonesFilters;
  },
  setEstimationFilters(state, { estimationFilters }) {
    state.estimationFilters = estimationFilters;
  },
  setDeliveryDateFilter(state, { deliveryDateFilter }) {
    state.deliveryDateFilter = deliveryDateFilter;
  },
  setDeliveryComparatorFilter(state, { deliveryComparatorFilter }) {
    state.deliveryComparatorFilter = deliveryComparatorFilter;
  },
  addTaskLabel(state, { taskId, labelId }) {
    const taskIndex = findIndex(state.tasks, { _id: taskId });
    if (taskIndex === -1) {
      return;
    }

    const labelIds = get(state.tasks, [taskIndex, 'labelIds'], []);
    state.tasks[taskIndex].labelIds = uniq([...labelIds, labelId]);
  },
  removeTaskLabel(state, { taskId, labelId }) {
    const taskIndex = findIndex(state.tasks, { _id: taskId });
    if (taskIndex === -1) {
      return;
    }

    const labelIds = get(state.tasks, [taskIndex, 'labelIds'], []);
    state.tasks[taskIndex].labelIds = reject(labelIds, labelId);
  },
  addTaskMilestone(state, { taskId, milestoneId }) {
    const taskIndex = findIndex(state.tasks, { _id: taskId });
    if (taskIndex === -1) {
      return;
    }

    const milestoneIds = get(state.tasks, [taskIndex, 'labelIds'], []);
    state.tasks[taskIndex].milestoneIds = uniq([...milestoneIds, milestoneId]);
  },
  removeTaskMilestone(state, { taskId, milestoneId }) {
    const taskIndex = findIndex(state.tasks, { _id: taskId });
    if (taskIndex === -1) {
      return;
    }

    const milestoneIds = get(state.tasks, [taskIndex, 'milestoneIds'], []);
    state.tasks[taskIndex].milestoneIds = reject(milestoneIds, milestoneId);
  },
  addTaskMember(state, { taskId, memberId }) {
    const taskIndex = findIndex(state.tasks, { _id: taskId });
    if (taskIndex === -1) {
      return;
    }

    const memberIds = get(state.tasks, [taskIndex, 'memberIds'], []);
    state.tasks[taskIndex].memberIds = uniq([...memberIds, memberId]);
  },
  removeTaskMember(state, { taskId, memberId }) {
    const taskIndex = findIndex(state.tasks, { _id: taskId });
    if (taskIndex === -1) {
      return;
    }

    const memberIds = get(state.tasks, [taskIndex, 'memberIds'], []);
    state.tasks[taskIndex].memberIds = reject(memberIds, memberId);
  },
  removeTaskWorkLog(state, { taskId, workLogId }) {
    const taskIndex = findIndex(state.tasks, { _id: taskId });
    if (taskIndex === -1) {
      return;
    }

    const workLogs = get(state.tasks, [taskIndex, 'workLogs'], []);
    state.tasks[taskIndex].workLogs = reject(workLogs, ({ _id }) => workLogId === _id);
  },
};

const actions = {
  async fetchTasksFromPeriod(_, { start, end }) {
    return await getTasksFromPeriod({ start, end });
  },
  async fetchTasksStats({ commit, getters: localGetters }) {
    const filters = localGetters.getAllFilters;
    const [todoStats, inProgressStats, doneStats] = await Promise.all([
      projectsGetTasksStats({
        filters: { listId: 'todo', ...filters },
      }),
      projectsGetTasksStats({
        filters: { listId: 'in_progress', ...filters },
      }),
      projectsGetTasksStats({
        filters: { listId: 'done', ...filters },
      }),
    ]);

    commit('setTasksStats', { stats: { todoStats, inProgressStats, doneStats } });
  },
  async fetchTasks({ commit, getters: localGetters }) {
    try {
      commit('refreshTasksFiltersRequestController', new AbortController());
      const { signal } = localGetters.getTasksFiltersRequestController;
      const filters = localGetters.getAllFilters;
      const search = localGetters.getCurrentSearch;
      const [todoTasks, inProgressTasks, doneTasks] = await Promise.all([
        getTasks({
          filters: { listId: 'todo', ...filters },
          signal,
          search,
        }),
        getTasks({
          filters: { listId: 'in_progress', ...filters },
          signal,
          search,
        }),
        getTasks({
          filters: { listId: 'done', ...filters },
          signal,
          search,
        }),
      ]);
      commit('saveTodoTasks', { hasMore: todoTasks.hasMore, tasks: todoTasks.tasks });
      commit('saveInProgressTasks', {
        hasMore: inProgressTasks.hasMore,
        tasks: inProgressTasks.tasks,
      });
      commit('saveDoneTasks', { hasMore: doneTasks.hasMore, tasks: doneTasks.tasks });
    } catch (err) {
      if (err.name !== 'CanceledError') {
        throw new Error(get(err, 'response.data.message'));
      }
    }
  },
  async fetchTodoTasks({ commit, state, getters: localGetters }, { fetchNext = false } = {}) {
    if (state.todoTaskFilters.skip > 0 && !fetchNext) {
      return;
    }

    if (fetchNext && !state.todoTaskFilters.hasMore) {
      return;
    }
    const filters = localGetters.getAllFilters;

    const { hasMore, tasks } = await getTasks({
      skip: state.todoTaskFilters.skip,
      filters: { listId: 'todo', ...filters },
    });

    commit('saveTodoTasks', {
      hasMore,
      tasks,
    });
  },
  async fetchInProgressTasks({ commit, state, getters: localGetters }, { fetchNext = false } = {}) {
    if (state.inProgressTaskFilters.skip > 0 && !fetchNext) {
      return;
    }

    if (fetchNext && !state.inProgressTaskFilters.hasMore) {
      return;
    }
    const filters = localGetters.getAllFilters;

    const { hasMore, tasks } = await getTasks({
      skip: state.inProgressTaskFilters.skip,
      filters: { listId: 'in_progress', ...filters },
    });

    commit('saveInProgressTasks', { hasMore, tasks });
  },
  async fetchDoneTasks({ commit, state, getters: localGetters }, { fetchNext = false } = {}) {
    if (state.doneTaskFilters.skip > 0 && !fetchNext) {
      return;
    }

    if (fetchNext && !state.doneTaskFilters.hasMore) {
      return;
    }

    const filters = localGetters.getAllFilters;

    const { hasMore, tasks } = await getTasks({
      skip: state.doneTaskFilters.skip,
      filters: { listId: 'done', ...filters },
    });

    commit('saveDoneTasks', { hasMore, tasks });
  },
  async fetchDeletedTasks({ commit, state, getters: localGetters }, { fetchNext = false, ignorePagination = false } = {}) {
    if (!ignorePagination && state.deletedTaskFilters.skip > 0 && !fetchNext) {
      return;
    }

    if (!ignorePagination && fetchNext && !state.deletedTaskFilters.hasMore) {
      return;
    }

    const search = localGetters.getCurrentSearch;
    const { hasMore, tasks } = await getTasks({
      filters: { deletedAt: true },
      search,
      sort: { deletedAt: -1 },
      skip: ignorePagination ? 0 : state.deletedTaskFilters.skip,
    });

    commit('saveDeletedTasks', { hasMore, tasks });
  },
  async fetchTask({ commit, getters: localGetters }, { taskId }) {
    const localTask = localGetters.getTask({ taskId });
    if (localTask) {
      return;
    }
    try {
      const task = await getTask({ taskId });
      commit('saveTask', { task });
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.fetchTask.requestError', { taskId }));
    }
  },
  async addTask({ commit }, { name, description, userId, priority, kind, estimatedTime, dueAt }) {
    try {
      const task = await postAddTask({
        name,
        description,
        userId,
        priority,
        kind,
        estimatedTime,
        dueAt,
      });
      commit('addTask', { task });
      return task;
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.addTask.requestError'));
    }
  },
  async moveTaskToList({ commit }, { taskId, listId }) {
    try {
      await patchUpdateTask({ taskId, listId });
      commit('updateTask', {
        task: { _id: taskId, listId },
      });
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.moveTaskToList.requestError'));
    }
  },
  async updateTask({ commit }, { _id: taskId, name, description, userId, priority, kind, estimatedTime, dueAt }) {
    try {
      const taskPayload = omitBy(
        {
          taskId,
          name,
          description,
          priority,
          kind,
          estimatedTime,
          dueAt,
          userId,
        },
        isNil,
      );
      // TODO: send only updated fields according to state
      const updatedTask = await patchUpdateTask(taskPayload);
      commit('updateTask', {
        task: {
          _id: taskId,
          ...omit(updatedTask, 'taskId'),
        },
      });
    } catch (err) {
      if (!err.isAxiosError || !err.response) {
        throw new Error(i18n.global.t('projects.store.updateTask.unknownError'));
      }

      throw new Error(err.response.data.message);
    }
  },
  async restoreEstimation({ commit }, { _id: taskId }) {
    try {
      const updatedTask = await patchRestoreEstimation({ taskId });
      commit('updateTask', {
        task: {
          _id: taskId,
          ...omit(updatedTask, 'taskId'),
        },
      });
    } catch (error) {
      throw new Error(i18n.global.t('components.projects.editTaskModal.notifications.revertEstimatedTimeFailed'));
    }
  },
  async updateTaskCompletion({ commit }, { _id: taskId, completed }) {
    try {
      const updatedTask = await patchTaskCompletion({ taskId, completed });
      commit('updateTaskPendingStatus', {
        task: {
          _id: taskId,
          pendingAt: updatedTask.pendingAt,
          pendingStatus: TASK_PENDING_STATUS.COMPLETED,
        },
      });
    } catch (err) {
      throw new Error(get(err, 'response.data.message'));
    }
  },
  async updateTaskMergeRequestValidation({ commit }, { _id: taskId, validated }) {
    try {
      const updatedTask = await patchTaskMergeRequestValidation({ taskId, validated });
      commit('updateTaskPendingStatus', {
        task: {
          _id: taskId,
          mergeRequestLink: updatedTask.mergeRequestLink,
          mergeRequestAcceptedAt: updatedTask.mergeRequestAcceptedAt,
        },
      });
    } catch (err) {
      throw new Error(get(err, 'response.data.message'));
    }
  },
  async updateTaskMergeRequestValidationStatus({ commit }, { _id: taskId, mergeRequestLink }) {
    try {
      await patchTaskMergeRequestValidationStatus({ taskId, mergeRequestLink });
      commit('updateTaskPendingStatus', {
        task: {
          _id: taskId,
          mergeRequestLink,
        },
      });
    } catch (err) {
      throw new Error(get(err, 'response.data.message'));
    }
  },
  async updateTaskCompletionPendingStatus({ commit }, { _id: taskId, isWaitingForCompletion }) {
    try {
      const updatedTask = await patchTaskCompletionPendingStatus({ taskId, isWaitingForCompletion });
      commit('updateTaskPendingStatus', {
        task: {
          _id: taskId,
          pendingAt: updatedTask.pendingAt,
          pendingStatus: updatedTask.pendingStatus,
        },
      });
    } catch (err) {
      throw new Error(get(err, 'response.data.message'));
    }
  },
  async updateTaskPendingStatus({ commit, getters: localGetters }, { _id: taskId, isPending }) {
    const originalPendingAt = get(localGetters.getTask({ taskId }), 'pendingAt');
    const originalPendingStatus = get(localGetters.getTask({ taskId }), 'pendingStatus');
    try {
      commit('updateTaskPendingStatus', {
        task: {
          _id: taskId,
          pendingAt: isPending ? new Date().toISOString() : undefined,
          pendingStatus: isPending ? TASK_PENDING_STATUS.CUSTOMER : undefined,
        },
      });
      const updatedTask = await patchUpdateTaskPendingStatus({ taskId, isPending });
      commit('updateTaskPendingStatus', {
        task: {
          _id: taskId,
          pendingAt: updatedTask.pendingAt,
          pendingStatus: updatedTask.pendingStatus,
        },
      });
      return updatedTask;
    } catch (err) {
      await new Promise((resolve) => {
        setTimeout(() => {
          commit('updateTaskPendingStatus', {
            task: {
              _id: taskId,
              pendingAt: originalPendingAt,
              pendingStatus: originalPendingStatus,
            },
          });
          resolve();
        }, 100);
      });
      if (!err.isAxiosError || !err.response) {
        throw new Error(i18n.global.t('projects.store.updateTaskPendingStatus.unknownError'));
      }

      throw new Error(get(err, 'response.data.message'));
    }
  },
  async attachTaskLabel({ commit }, { _id: taskId, labelId }) {
    try {
      commit('addTaskLabel', { taskId, labelId });
      await patchTaskLabel({ taskId, labelId });
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.attachTaskLabel.requestError'));
    }
  },
  async detachTaskLabel({ commit }, { _id: taskId, labelId }) {
    try {
      commit('removeTaskLabel', { taskId, labelId });
      await deleteTaskLabel({ taskId, labelId });
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.detachTaskLabel.requestError'));
    }
  },
  async attachTaskMilestone({ commit }, { _id: taskId, milestoneId }) {
    try {
      commit('addTaskMilestone', { taskId, milestoneId });
      await patchTaskMilestone({ taskId, milestoneId });
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.attachTaskMilestone.requestError'));
    }
  },
  async detachTaskMilestone({ commit }, { _id: taskId, milestoneId }) {
    try {
      commit('removeTaskMilestone', { taskId, milestoneId });
      await deleteTaskMilestone({ taskId, milestoneId });
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.detachTaskMilestone.requestError'));
    }
  },
  async attachTaskMember({ commit }, { _id: taskId, memberId }) {
    try {
      commit('addTaskMember', { taskId, memberId });
      await patchTaskMember({ taskId, memberId });
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.attachTaskMember.requestError'));
    }
  },
  async detachTaskMember({ commit }, { _id: taskId, memberId }) {
    try {
      commit('removeTaskMember', { taskId, memberId });
      await deleteTaskMember({ taskId, memberId });
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.detachTaskMember.requestError'));
    }
  },
  async archiveTask({ commit }, { taskId }) {
    try {
      await deleteArchiveTask({ taskId });
      commit('updateTask', {
        task: {
          _id: taskId,
          deletedAt: new Date().toString(),
        },
      });
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.archiveTask.requestError'));
    }
  },
  async unArchiveTask({ commit }, { taskId }) {
    try {
      await unDeleteArchiveTask({ taskId });
      commit('updateTask', {
        task: {
          _id: taskId,
        },
        removedFields: ['deletedAt'],
      });
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.unArchiveTask.requestError'));
    }
  },
  async sendTaskWorkLogAction({ commit }, { taskId, action, note, type }) {
    try {
      const workLog = await postTaskWorkLogAction({ taskId, action, note, type });
      commit('upsertTaskWorkLog', { taskId, workLog });
    } catch (err) {
      const message = get(err, 'response.data.message', i18n.global.t('projects.store.sendTaskWorkLogAction.requestError'));
      throw new Error(message);
    }
  },
  async createTaskWorkLog({ commit }, { taskId, startedAt, stoppedAt, note, pauseTimeInMs, type }) {
    try {
      const workLog = await postAddTaskWorkLog({
        taskId,
        startedAt: dayjs(startedAt).utc(),
        stoppedAt: dayjs(stoppedAt).utc(),
        note,
        pauseTimeInMs,
        type,
      });
      commit('upsertTaskWorkLog', { taskId, workLog });
    } catch (err) {
      if (!err.isAxiosError || !err.response) {
        throw new Error(i18n.global.t('projects.store.createTaskWorkLog.requestError'));
      }

      if (!isEmpty(err.response.data.errors)) {
        throw new Error(get(head(err.response.data.errors), 'message'));
      }

      throw new Error(get(err, 'response.data.message'));
    }
  },

  async removeTaskWorkLog({ commit }, { taskId, workLogId }) {
    try {
      await deleteTaskWorkLog({
        taskId,
        workLogId,
      });
      commit('removeTaskWorkLog', { taskId, workLogId });
    } catch (err) {
      if (!err.isAxiosError) {
        throw new Error(i18n.global.t('projects.store.removeTaskWorkLog.requestError'));
      }

      if (err.response.status === 409) {
        throw new Error(i18n.global.t('projects.store.removeTaskWorkLog.conflict'));
      }

      throw new Error(i18n.global.t('projects.store.removeTaskWorkLog.default'));
    }
  },

  async updateTaskWorkLog({ commit }, { taskId, workLogId, startedAt, stoppedAt, note, pauseTimeInMs }) {
    try {
      await patchTaskWorkLog({
        taskId,
        workLogId,
        startedAt: dayjs(startedAt).utc(),
        stoppedAt: dayjs(stoppedAt).utc(),
        note,
        pauseTimeInMs,
      });
      commit('updateTaskWorkLog', {
        taskId,
        workLogId,
        startedAt,
        stoppedAt,
        note,
        pauseTimeInMs,
      });
    } catch (err) {
      if (!err.isAxiosError) {
        throw new Error(i18n.global.t('projects.store.updateTaskWorkLog.requestError'));
      }

      if (err.response.status === 409) {
        throw new Error(i18n.global.t('projects.store.updateTaskWorkLog.conflict'));
      }

      throw new Error(err.message);
    }
  },

  watchTasksOpen({ commit, state, rootGetters, dispatch }) {
    if (state.tasksSocket) return;
    const token = rootGetters['auth/token'];
    if (!token) {
      return;
    }

    const { host, protocol } = new URL(config.endpoints.projects);
    const socketProtocol = protocol === 'http:' ? 'ws:' : 'wss:';

    const tasksSocket = new WebSocket(`${socketProtocol}//${host}?token=${token}`);

    tasksSocket.onmessage = ({ data }) => {
      try {
        const { type, payload } = JSON.parse(data);
        if (!payload.document) {
          return;
        }
        commit(type, { task: payload.document, removedFields: payload.removedFields });
      } catch (err) {
        console.error(err);
      }
    };

    tasksSocket.onopen = () => {
      console.debug('socket:projects#open');
    };

    tasksSocket.onclose = () => {
      commit('setTaskSocket', { tasksSocket: undefined });
      console.debug('socket:projects#closed');
    };

    tasksSocket.onerror = () => {
      console.debug('socket:projects#error');
      setTimeout(() => {
        dispatch('watchTasksOpen');
      }, 5000);
    };

    commit('setTaskSocket', { tasksSocket });
  },
  watchTasksClose({ commit, state }) {
    if (!state.tasksSocket) return;
    state.tasksSocket.close();
    commit('setTaskSocket', { tasksSocket: undefined });
  },
  async fetchLabels({ commit }) {
    const labels = await fetchLabels();
    commit('setLabels', { labels });
  },
  async fetchMilestones({ commit, getters: localGetters }, params) {
    const search = params?.search;
    const startAt = params?.startAt;
    const endAt = params?.endAt;
    const needCommit = params?.needCommit || true;
    const currentMilestones = localGetters.getMilestones;

    if (!currentMilestones.hasMore && currentMilestones.hasMore !== undefined) return [];

    const { list, hasMore } = await fetchMilestones({ skip: currentMilestones.skip, search, startAt, endAt });
    if (needCommit) {
      commit('setMilestones', { milestones: list, hasMore });
    }
    return list;
  },
  async fetchMilestone({ commit }, { milestoneId }) {
    const milestone = await fetchMilestone({ milestoneId });
    commit('setMilestones', { milestones: [milestone], hasMore: true });
  },
  async createTaskInternalThread({ commit }, { taskId }) {
    const task = await postCreateThread({ taskId });
    commit('chats/createThread', { threadId: task.privateThreadId }, { root: true });
    commit('updateTask', { task });
  },
  resetTaskStateFilters({ commit, getters: localGetters }, { enableMembers = true }) {
    commit('setKindFilters', { kindFilters: [] });
    commit('setPrioritiesFilters', { prioritiesFilters: [] });
    commit('setEstimationFilters', { estimationFilters: [] });
    commit('setMilestonesFilters', { milestonesFilters: [] });
    commit('setDeliveryDateFilter', { deliveryDateFilter: undefined });
    commit('setIsPendingFilter', { isPendingFilter: undefined });

    if (enableMembers) {
      commit('setMembersFilters', { membersFilters: [] });
    }

    commit('saveTodoTasks', {
      hasMore: localGetters.getTodoTasks.length >= TASKS_FETCH_LIMIT,
      tasks: [],
    });
    commit('saveInProgressTasks', {
      hasMore: localGetters.getInProgressTasks.length >= TASKS_FETCH_LIMIT,
      tasks: [],
    });
    commit('saveDoneTasks', {
      hasMore: localGetters.getDoneTasks.length >= TASKS_FETCH_LIMIT,
      tasks: [],
    });
  },
  resetCurrentSearch({ commit }) {
    commit('setCurrentSearch', { search: undefined });
  },
  async updateTaskModalAction(
    { dispatch, getters: localGetters },
    { task, taskForm, memberIds, labelIds, milestoneIds, hasMissionLabel, isWaitingEstimationValidation, notify, t },
  ) {
    if (!isNil(memberIds)) {
      const removedMemberIds = difference(task.memberIds, memberIds);
      const addedMemberIds = difference(memberIds, task.memberIds);

      const attachMembers = map(uniq(addedMemberIds), (memberId) => dispatch('attachTaskMember', { _id: task._id, memberId }));
      const detachMembers = map(uniq(removedMemberIds), (memberId) => dispatch('detachTaskMember', { _id: task._id, memberId }));
      await Promise.allSettled([...attachMembers, ...detachMembers]);
    }

    const formLabels = compact(map(labelIds, (labelId) => localGetters.getLabel({ labelId })));
    const hasFormMissionLabel = !!find(formLabels, (label) => toLower(label.name) === 'mission');
    const isAddingMissionLabel = !hasMissionLabel && hasFormMissionLabel;
    const isRemovingMissionLabel = hasMissionLabel && !hasFormMissionLabel;

    if (!isNil(labelIds)) {
      const removedLabelIds = difference(task.labelIds, labelIds);
      const addedLabelIds = difference(labelIds, task.labelIds);

      const attachLabels = map(uniq(addedLabelIds), (labelId) => dispatch('attachTaskLabel', { _id: task._id, labelId }));
      const detachMLabels = map(uniq(removedLabelIds), (labelId) => dispatch('detachTaskLabel', { _id: task._id, labelId }));
      await Promise.allSettled([...attachLabels, ...detachMLabels]);
    }

    if (!isNil(milestoneIds)) {
      const removedMilestoneIds = difference(task.milestoneIds, milestoneIds);
      const addedMilestoneIds = difference(milestoneIds, task.milestoneIds);

      const attachMilestones = map(uniq(addedMilestoneIds), (milestoneId) => dispatch('attachTaskMilestone', { _id: task._id, milestoneId }));
      const detachMilestones = map(uniq(removedMilestoneIds), (milestoneId) => dispatch('detachTaskMilestone', { _id: task._id, milestoneId }));
      await Promise.allSettled([...attachMilestones, ...detachMilestones]);
    }

    const isWaitingValidationBeforeUpdate = isWaitingEstimationValidation;
    const pendingStatusBeforeUpdate = !!task.pendingAt;
    const isEstimationChanged = !isNil(taskForm.estimatedTime) && !isEqual(task.estimatedTime, taskForm.estimatedTime);
    const isGoingToPending = taskForm.estimatedTime > 0;

    const isAlreadyPending = !isNil(task.pendingAt);
    const isFuturePendingStatusDifferentFromCurrent = isAlreadyPending !== isGoingToPending;

    if ((isAddingMissionLabel && taskForm.estimatedTime > 0) || (hasMissionLabel && isEstimationChanged && isFuturePendingStatusDifferentFromCurrent && isGoingToPending)) {
      await dispatch('updateTaskPendingStatus', { _id: task._id, isPending: true });
    }

    try {
      const taskDataToUpdate = get(taskForm, 'estimatedTime') === get(task, 'estimatedTime') ? { ...taskForm, estimatedTime: undefined } : taskForm;
      await dispatch('updateTask', { _id: task._id, ...taskDataToUpdate });
    } catch (updateTaskErr) {
      if (isEstimationChanged && isFuturePendingStatusDifferentFromCurrent) {
        await dispatch('updateTaskPendingStatus', { _id: task._id, isPending: pendingStatusBeforeUpdate });
      }
      throw updateTaskErr;
    }

    if ((isRemovingMissionLabel && isWaitingValidationBeforeUpdate) || (hasMissionLabel && isEstimationChanged && isFuturePendingStatusDifferentFromCurrent && !isGoingToPending)) {
      await dispatch('updateTaskPendingStatus', { _id: task._id, isPending: false });
    }

    notify.success(t('components.projects.editTaskModal.notifications.updateTask', { taskId: task.taskId }));
  },
  async fetchRunningWorklogs() {
    const worklogs = await getRunningWorklogs();

    return worklogs;
  },
  async adminFetchTask(_, { taskId }) {
    try {
      const accessToken = localStorage.getItem('token_admin');
      adminProjectsAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      const task = await projectsGetTask({ taskId });
      return task;
    } catch (err) {
      throw new Error(i18n.global.t('projects.store.adminFetchTask.requestError'));
    }
  },
  async patchStopWorklog(_, { taskId, worklogId, note, appId }) {
    await projectsStopWorklog({ taskId, worklogId, note, appId });
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
