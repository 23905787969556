import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import reject from 'lodash/reject';
import sortBy from 'lodash/sortBy';
import compact from 'lodash/compact';
import { getApp, putAppConfigMails, putAppConfigPaymentsStripe, postSearchApps, patchCGVMetadata } from '@/modules/apps/apps.api.js';
import { i18n } from '@/plugins/i18n/index.js';
import existingFeatures from '@/modules/shared/features.constant.js';
import { accountsGetAdminUserApps, adminAccountsAxios } from '../admin/admin.api.js';

const stateData = {
  app: undefined,
  apps: [],
};

const getters = {
  getPaymentsConfig: (state) => get(state.app, 'config.payments.stripe', {}),
  getMailsConfig: (state) => get(state.app, 'config.mails', {}),
  getApps: (state) => state.apps,
  getAlternativeApps: (state) =>
    sortBy(
      reject(state.apps, (app) => app._id === state.app?._id),
      (app) => app.name,
    ),
  currentApp: (state) => state.app,
  getAppFeatures: (state) => get(state.app, 'features', []),
  enabledAppFeatures: (state, localGetters) => {
    const { currentApp } = localGetters;
    const features = get(currentApp, 'features', []);
    const availableFeatures = map(features, (featureName) => get(existingFeatures, featureName));
    return compact(availableFeatures);
  },
  getSubscriptionStatus: (state) => {
    if (!state.app) {
      return 'unknown';
    }
    return get(state.app, 'metadata.stripeSubscriptionStatus');
  },
  isStripeReady: (state) => !!get(state.app, 'metadata.stripeCustomerId'),
};

const mutations = {
  resetState(state) {
    Object.assign(state, stateData);
  },
  setApp(state, { app }) {
    state.app = app;
  },
  setApps(state, { apps }) {
    state.apps = apps;
  },
  resetApps(state) {
    state.apps = [];
  },
  setMailsConfig(state, { mailsConfig }) {
    set(state.app, 'config.mails', mailsConfig);
  },
  setPaymentsStripeConfig(state, { paymentsStripeConfig }) {
    set(state.app, 'config.payments.stripe', paymentsStripeConfig);
  },
};

const actions = {
  async fetchApp({ commit, state }) {
    if (state.app) {
      return;
    }
    const app = await getApp();
    commit('setApp', { app });
  },
  async updateMailsConfig({ commit, state }, { mailsConfig }) {
    if (!state.app) {
      return;
    }
    await putAppConfigMails({ mailsConfig });
    commit('setMailsConfig', { mailsConfig });
  },
  async updateCGVMetadata({ commit }, { key, value }) {
    const app = await patchCGVMetadata({ key, value });
    commit('setApp', { app });
  },
  async updatePaymentsStripeConfig({ commit, state }, { paymentsStripeConfig }) {
    if (!state.app) {
      return;
    }
    await putAppConfigPaymentsStripe({ paymentsStripeConfig });
    commit('setPaymentsStripeConfig', { paymentsStripeConfig });
  },
  async searchAssociatedEmailApps({ commit }, { email }) {
    try {
      const apps = await postSearchApps({ email });

      commit('setApps', { apps });
    } catch (err) {
      commit('apps/resetApps');
      if (get(err, 'isAxiosError', false) && get(err, 'response.status') === 429) {
        throw new Error(i18n.global.t('apps.store.searchAssociatedEmailApps.limiterError'));
      }
      if (get(err, 'isAxiosError', false) && get(err, 'response.status') === 400) {
        throw new Error(i18n.global.t('apps.store.searchAssociatedEmailApps.wrongEmailFormat'));
      }

      throw new Error(i18n.global.t('apps.store.searchAssociatedEmailApps.default'));
    }
  },
  async fetchSearchAdminApps(_, { search }) {
    try {
      const accessToken = localStorage.getItem('token_admin');
      adminAccountsAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      const { apps } = await accountsGetAdminUserApps({ search, limit: 10 });
      return apps;
    } catch (err) {
      throw new Error(i18n.global.t('apps.store.fetchSearchAdminApps.requestError'));
    }
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
