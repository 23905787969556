/**
 * @interface
 */
export class ProbeEventStatus {
  getTranslateTextKey() {
    throw new Error('Method must be implemented by subclass');
  }

  getBackgroundColor() {
    throw new Error('Method must be implemented by subclass');
  }

  getTextColor() {
    throw new Error('Method must be implemented by subclass');
  }

  getOutlineColor() {
    throw new Error('Method must be implemented by subclass');
  }
}
