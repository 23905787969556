<template>
  <div class="flex flex-col items-start justify-start gap-2 p-5 bg-white rounded-lg shadow">
    <h4 class="font-semibold">{{ $t('components.projects.editTaskModal.requestManagement.title') }}</h4>

    <p class="text-sm">{{ $t('components.projects.editTaskModal.requestManagement.subtitle') }}</p>
    <div class="flex flex-row gap-2 items-center justify-between w-full mt-2">
      <SkyButton
        class="w-full whitespace-nowrap"
        :class="{ 'bg-primary': form.duration === FIFTEEN_MINUTES }"
        variant="outline"
        size="small"
        :loading="quickWorkLogLoading"
        @click="handleOpenMessageForm(FIFTEEN_MINUTES)"
      >
        <span :class="{ 'text-white': form.duration === FIFTEEN_MINUTES }">+15min</span>
      </SkyButton>
      <SkyButton
        class="w-full whitespace-nowrap"
        :class="{ 'bg-primary': form.duration === THIRTY_MINUTES }"
        variant="outline"
        size="small"
        :loading="quickWorkLogLoading"
        @click="handleOpenMessageForm(THIRTY_MINUTES)"
      >
        <span :class="{ 'text-white': form.duration === THIRTY_MINUTES }">+30min</span>
      </SkyButton>
      <SkyButton
        class="w-full whitespace-nowrap"
        :class="{ 'bg-primary': form.duration === FORTY_FIVE_MINUTES }"
        variant="outline"
        size="small"
        :loading="quickWorkLogLoading"
        @click="handleOpenMessageForm(FORTY_FIVE_MINUTES)"
      >
        <span :class="{ 'text-white': form.duration === FORTY_FIVE_MINUTES }">+45min</span>
      </SkyButton>
    </div>
    <SkyForm
      v-if="displayMessageForm"
      class="flex flex-col w-full gap-5"
    >
      <div class="flex flex-col gap-2">
        <SkySelect
          v-model="form.message"
          :disabled="quickWorkLogLoading"
          class="w-full"
        >
          <option
            v-for="message in predefinedMessages"
            :key="message"
            :value="message"
          >
            {{ message }}
          </option>
          <option :value="undefined">Autre (préciser)</option>
        </SkySelect>

        <SkyInputText
          v-if="!form.message"
          v-model="form.customMessage"
          :disabled="quickWorkLogLoading"
          placeholder="Motif du chrono"
        />
      </div>

      <SkyButton
        size="tiny"
        :loading="quickWorkLogLoading"
        @click="handleAddQuickWorklog"
      >
        Valider
      </SkyButton>
    </SkyForm>
  </div>
</template>

<script setup>
import { useNotify } from '@/plugins/notify/index.js';
import dayjs from 'dayjs';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const emit = defineEmits(['add']);
const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const notify = useNotify();
const { t } = useI18n();

const FIFTEEN_MINUTES = 900_000;
const THIRTY_MINUTES = 1_800_000;
const FORTY_FIVE_MINUTES = 2_700_000;

const predefinedMessages = ['Gestion de la demande', 'Aide ponctuelle', 'Communication client'];

const quickWorkLogLoading = ref(false);
const displayMessageForm = ref(false);
const form = ref({
  duration: 0,
  message: predefinedMessages[0],
  customMessage: undefined,
});

function handleOpenMessageForm(durationInMs) {
  form.value.duration = durationInMs;
  displayMessageForm.value = true;
}

async function handleAddQuickWorklog() {
  quickWorkLogLoading.value = true;
  try {
    const workLogStoppedAt = new Date();
    const workLogStartedAt = dayjs(workLogStoppedAt).subtract(form.value.duration, 'ms').toDate();
    await store.dispatch('projects/createTaskWorkLog', {
      taskId: props.task._id,
      startedAt: workLogStartedAt,
      stoppedAt: workLogStoppedAt,
      pauseTimeInMs: 0,
      note: form.value.message || form.value.customMessage,
      type: 'quick',
    });
    notify.success(t('components.projects.editTaskModal.notifications.addWorklog'));
    form.value = {
      duration: 0,
      message: predefinedMessages[0],
      customMessage: undefined,
    };
    displayMessageForm.value = false;
    emit('add');
  } catch (err) {
    notify.error(err.message);
  }
  quickWorkLogLoading.value = false;
}
</script>
