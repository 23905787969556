import { createStore } from 'vuex';
import auth from '@/modules/auth/auth.store.js';
import apps from '@/modules/apps/apps.store.js';
import users from '@/modules/users/users.store.js';
import stats from '@/modules/stats/stats.store.js';
import chats from '@/modules/chats/chats.store.js';
import clouds from '@/modules/clouds/clouds.store.js';
import bookings from '@/modules/bookings/bookings.store.js';
import projects from '@/modules/projects/projects.store.js';
import payments from '@/modules/payments/payments.store.js';
import converters from '@/modules/converters/converters.store.js';
import storages from '@/modules/storages/storages.store.js';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    users,
    bookings,
    payments,
    converters,
    clouds,
    chats,
    projects,
    apps,
    stats,
    storages,
  },
});
