import pickBy from 'lodash/pickBy';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import qs from 'qs';
import projectsApi from '@/modules/shared/api/projects.js';
import { TASKS_FETCH_LIMIT, TASK_PENDING_STATUS } from '@/modules/projects/projects.constants.js';

export async function getTask({ taskId }) {
  const { data } = await projectsApi().get(`/admin/tasks/${taskId}`);

  return data;
}

export async function getTasks({ skip = 0, limit = TASKS_FETCH_LIMIT, search, filters, sort, signal = undefined } = {}) {
  const queryParams = pickBy({
    skip,
    limit,
    search,
    filters,
    sort,
  });
  const { data } = await projectsApi().get('/admin/tasks', { params: queryParams, signal });
  const { hasMore = false, list: tasks = [] } = data;
  return { hasMore, tasks };
}

export async function postAddTask({ name, description, userId, priority, kind, estimatedTime, dueAt }) {
  const { data } = await projectsApi().post('/admin/tasks', {
    name,
    description,
    userId,
    priority,
    kind,
    estimatedTime,
    dueAt,
  });

  return data;
}

export async function patchUpdateTask({ taskId, listId, userId, name, description, priority, kind, estimatedTime, dueAt }) {
  const request = await projectsApi().patch(
    `/admin/tasks/${taskId}`,
    omitBy(
      {
        listId,
        userId,
        name,
        description,
        priority,
        kind,
        estimatedTime,
        dueAt,
      },
      isNil,
    ),
  );

  return request.data;
}

export async function getTasksFromPeriod({ start, end }) {
  const { data } = await projectsApi().get(`/admin/tasks/period?from=${start}&to=${end}`);
  return data;
}

export async function patchRestoreEstimation({ taskId }) {
  const request = await projectsApi().patch(`/admin/tasks/${taskId}/restore-estimation`);

  return request.data;
}

export async function patchTaskCompletion({ taskId, completed }) {
  const { data } = await projectsApi().patch(`/admin/tasks/${taskId}/validate`, { completed });
  return data;
}

export async function patchTaskMergeRequestValidation({ taskId, validated }) {
  const { data } = await projectsApi().patch(`/admin/tasks/${taskId}/merge-request`, { validated });
  return data;
}

export async function patchTaskMergeRequestValidationStatus({ taskId, mergeRequestLink }) {
  const { data } = await projectsApi().patch(`/admin/tasks/${taskId}/status`, { value: mergeRequestLink, status: TASK_PENDING_STATUS.MERGE_REQUEST });
  return data;
}

export async function patchTaskCompletionPendingStatus({ taskId, isWaitingForCompletion }) {
  const { data } = await projectsApi().patch(`/admin/tasks/${taskId}/status`, { value: isWaitingForCompletion, status: TASK_PENDING_STATUS.COMPLETED });
  return data;
}

export async function patchUpdateTaskPendingStatus({ taskId, isPending }) {
  const { data } = await projectsApi().patch(`/admin/tasks/${taskId}/status`, { value: isPending, status: TASK_PENDING_STATUS.CUSTOMER });
  return data;
}

export async function patchTaskLabel({ taskId, labelId }) {
  await projectsApi().patch(`/admin/tasks/${taskId}/labels`, {
    labelId,
  });
}

export async function deleteTaskLabel({ taskId, labelId }) {
  await projectsApi().delete(`/admin/tasks/${taskId}/labels/${labelId}`);
}

export async function patchTaskMilestone({ taskId, milestoneId }) {
  await projectsApi().patch(`/admin/tasks/${taskId}/milestones`, { milestoneId });
}

export async function deleteTaskMilestone({ taskId, milestoneId }) {
  await projectsApi().delete(`/admin/tasks/${taskId}/milestones/${milestoneId}`);
}

export async function patchTaskMember({ taskId, memberId }) {
  await projectsApi().patch(`/admin/tasks/${taskId}/members`, {
    memberId,
  });
}

export async function deleteTaskMember({ taskId, memberId }) {
  await projectsApi().delete(`/admin/tasks/${taskId}/members/${memberId}`, {
    memberId,
  });
}

export async function deleteArchiveTask({ taskId }) {
  await projectsApi().delete(`/admin/tasks/${taskId}`);
}

export async function unDeleteArchiveTask({ taskId }) {
  await projectsApi().patch(`/admin/tasks/${taskId}/undelete`);
}

export async function postTaskWorkLogAction({ taskId, action, note, type }) {
  const { data } = await projectsApi().post(`/admin/tasks/${taskId}/workLogs/action`, {
    action,
    note,
    type,
  });

  return data;
}

export async function postAddTaskWorkLog({ taskId, startedAt, stoppedAt, note, pauseTimeInMs, type }) {
  const { data } = await projectsApi().post(`/admin/tasks/${taskId}/workLogs`, {
    startedAt,
    stoppedAt,
    note,
    pauseTimeInMs,
    type,
  });

  return data;
}

export async function deleteTaskWorkLog({ taskId, workLogId }) {
  await projectsApi().delete(`/admin/tasks/${taskId}/workLogs/${workLogId}`);
}

export async function patchTaskWorkLog({ taskId, workLogId, startedAt, stoppedAt, note, pauseTimeInMs }) {
  const { data } = await projectsApi().patch(`/admin/tasks/${taskId}/workLogs/${workLogId}`, {
    startedAt,
    stoppedAt,
    note,
    pauseTimeInMs,
  });

  return data;
}

export async function fetchLabels() {
  const { data } = await projectsApi().get('/admin/labels');

  return data;
}

export async function fetchMilestones({ skip, limit = 15, search = undefined, startAt = undefined, endAt = undefined }) {
  const { data } = await projectsApi().get('/admin/milestones', { params: { skip, limit, search, startAt, endAt } });

  return data;
}

export async function fetchMilestone({ milestoneId }) {
  const { data } = await projectsApi().get(`/admin/milestone/${milestoneId}`);

  return data;
}

export async function postCreateThread({ taskId }) {
  const { data } = await projectsApi().post(`/admin/tasks/${taskId}/thread`);

  return data;
}

export async function getRunningWorklogs() {
  const { data } = await projectsApi().get('/admin/tasks/worklogs/running');

  return data;
}

export async function projectsStopWorklog({ taskId, appId, worklogId, note }) {
  await projectsApi().patch(`/admin/tasks/${taskId}/worklogs/stop`, { appId, worklogId, note });
}

export async function projectsGetTasksStats({ filters }) {
  const queryParams = pickBy({
    filters,
  });

  const queryString = qs.stringify(queryParams, { arrayFormat: 'brackets' });
  const { data } = await projectsApi().get(`/admin/tasks/stats?${queryString}`);

  return data;
}
