<template>
  <label class="block">
    <div
      v-if="label"
      class="block mb-1 text-xs font-bold uppercase"
    >
      {{ label }}
    </div>
    <div class="flex flex-wrap px-2 py-1 text-sm placeholder-gray-400 bg-white border rounded border-dark-blue-100 focus:outline-none focus:shadow-outline">
      <div
        v-for="(item, index) in modelValue"
        :key="item"
        class="flex items-center justify-center px-2 py-1 m-1 font-medium text-yellow-100 rounded-full bg-primary"
        @delete="removeItem(index)"
      >
        <div class="flex-initial max-w-full text-xs font-normal leading-none">
          <FontAwesomeIcon
            v-if="icon"
            :icon="icon"
            class="mx-1"
          />
          {{ item }}
        </div>
        <div class="flex flex-row-reverse flex-auto">
          <div @click="removeItem(index)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="w-4 h-4 ml-2 rounded-full cursor-pointer feather feather-x hover:text-yellow-400"
            >
              <line
                x1="18"
                y1="6"
                x2="6"
                y2="18"
              />
              <line
                x1="6"
                y1="6"
                x2="18"
                y2="18"
              />
            </svg>
          </div>
        </div>
      </div>
      <input
        v-model="itemToAdd"
        class="m-1 outline-none appearance-none"
        :placeholder="placeholder"
        @blur="handleBlur"
        @keydown="handleKeydown"
      />
    </div>
    <span
      v-show="helpText"
      class="px-4 text-xs text-gray-500"
    >
      {{ helpText }}
    </span>
  </label>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import split from 'lodash/split';
import size from 'lodash/size';
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

export default {
  props: {
    modelValue: { type: Array, default: () => [] },
    placeholder: { type: String, default: undefined },
    icon: { type: Object, default: undefined },
    label: { type: String, default: undefined },
    helpText: { type: String, default: undefined },
    rules: { type: Array, default: () => [] },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const state = reactive({
      itemToAdd: undefined,
    });

    function addItems(itemsToAdd) {
      const itemsToAddNotEmpty = reject(itemsToAdd, isEmpty);
      emit('update:modelValue', uniq([...props.modelValue, ...itemsToAddNotEmpty]));
      state.itemToAdd = undefined;
    }

    function removeItem(itemIndex) {
      emit('update:modelValue', [...props.modelValue.slice(0, itemIndex), ...props.modelValue.slice(itemIndex + 1)]);
    }

    function handleBlur() {
      if (isEmpty(state.itemText)) return;
      addItems([state.itemToAdd]);
    }

    function handleKeydown(event) {
      if (event.code === 'Backspace' && isEmpty(state.itemToAdd)) {
        removeItem(props.modelValue.length - 1);
        return;
      }
      if (['Enter', 'Space'].includes(event.code)) {
        addItems([state.itemToAdd]);
        event.preventDefault();
      }
    }

    watch(
      () => state.itemToAdd,
      (value) => {
        const itemsToAdd = split(value, /(?:,|;|\s)/);
        if (size(itemsToAdd) < 2) {
          return;
        }
        addItems(itemsToAdd);
      },
    );

    return {
      ...toRefs(state),
      handleBlur,
      handleKeydown,
      removeItem,
    };
  },
};
</script>
