<template>
  <div>
    <button
      type="button"
      class="flex w-full px-4 py-2 text-center text-white rounded bg-primary"
      v-bind="$attrs"
      @click="internalCollapsed = !internalCollapsed"
    >
      <div class="flex-1">
        {{ label }}
      </div>
      <div class="text-center">
        <FontAwesomeIcon
          :icon="faChevronDown"
          class="transition-transform duration-500 transform rotate-0"
          :class="{
            'rotate-180': !internalCollapsed,
          }"
        />
      </div>
    </button>
    <div
      v-show="!internalCollapsed"
      class="py-4 space-y-4"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';

export default {
  props: {
    label: {
      type: String,
      default: () => undefined,
    },
    collapsed: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props) {
    const state = reactive({
      internalCollapsed: props.collapsed,
    });

    return {
      ...toRefs(state),
      faChevronDown,
    };
  },
};
</script>

<style></style>
