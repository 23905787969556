<template>
  <div class="flex flex-col gap-1">
    <div class="relative">
      <div class="flex flex-row items-center justify-start select-header">
        <span class="mr-2 text-sm font-medium text-primary">Milestones</span>
        <span
          class="flex items-center justify-center btn-open-milestone-pannel"
          @click="open = !open"
        >
          <FontAwesomeIcon
            :icon="faPlus"
            class="bg-primary text-white text-xs px-1.5 py-1 rounded cursor-pointer"
          />
        </span>
      </div>
      <SkyPopup
        :open="open"
        class="absolute top-full set-milestone-pannel"
      >
        <SkyFilterList
          v-model="filteredMilestones"
          :elements="milestones"
          search-on="name"
          sort="startAt"
          @search="handleSearchNewMilestones"
        >
          <label
            v-for="milestone of filteredMilestones"
            :key="`milestone-${milestone._id}`"
            :for="`milestone-${milestone._id}`"
            class="flex flex-row items-center justify-start cursor-pointer select-none"
          >
            <input
              :id="`milestone-${milestone._id}`"
              v-model="internalMilestoneIds"
              type="checkbox"
              name="milestones"
              :value="milestone._id"
              class="hidden"
            />
            <div
              class="flex items-center justify-center h-6 mr-2 overflow-hidden border border-gray-100 rounded w-7"
              :class="{
                'bg-primary text-white border-primary': modelValue.includes(milestone._id),
              }"
            >
              <FontAwesomeIcon
                :icon="faCheck"
                class="text-xs text-white"
              />
            </div>
            <TaskMilestoneIcon
              :milestone="milestone"
              class="w-full text-xs"
            />
          </label>
        </SkyFilterList>
      </SkyPopup>
    </div>
    <span
      v-if="selectedMilestones.length === 0"
      class="text-xs text-gray-400"
    >
      {{ $t('components.projects.labelsSelect.empty') }}
    </span>
    <div
      v-else
      class="flex flex-row flex-wrap items-start justify-start gap-2 mt-3"
    >
      <TaskMilestoneIcon
        v-for="milestone in selectedMilestones"
        :key="milestone._id"
        :milestone="milestone"
        class="text-xs"
      />
    </div>
  </div>
</template>

<script setup>
import { faCheck, faPlus } from '@fortawesome/pro-solid-svg-icons';
import filter from 'lodash/filter';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import TaskMilestoneIcon from '@/modules/projects/components/TaskMilestoneIcon.vue';
import { useNotify } from '@/plugins/notify/index.js';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
});

const store = useStore();
const notify = useNotify();

const open = ref(false);
const fetchedMissingMilestones = ref(false);
const filteredMilestones = ref([]);
const milestones = computed(() => store.getters['projects/getMilestones'].list);
const selectedMilestones = computed(() => filter(milestones.value, (milestone) => props.modelValue.includes(milestone?._id)));
const internalMilestoneIds = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
const missingMilestoneIds = computed(() => filter(props.modelValue, (milestoneId) => !milestones.value.find((milestone) => milestone._id === milestoneId)));
function handleClickOutside(event) {
  if (open.value === false) {
    return;
  }

  const { target } = event;
  const isOutsideClick = !target.closest('.set-milestone-pannel') && !target.closest('.btn-open-milestone-pannel');

  if (!isOutsideClick) {
    return;
  }

  open.value = false;
}

async function handleSearchNewMilestones(search) {
  try {
    await store.dispatch('projects/fetchMilestones', { search });
  } catch (err) {
    notify.error(err.message);
  }
}

async function handleFetchMissingMilestones() {
  try {
    const missingMilestonePromise = missingMilestoneIds.value.map((milestoneId) => store.dispatch('projects/fetchMilestone', { milestoneId }));
    await Promise.allSettled(missingMilestonePromise);
  } catch (err) {
    notify.error(err.message);
  }
}

watch(
  () => props.modelValue,
  () => {
    if (!fetchedMissingMilestones.value && missingMilestoneIds.value.length > 0) {
      fetchedMissingMilestones.value = true;
      handleFetchMissingMilestones();
    }
  },
);

onMounted(() => {
  document.querySelector('body').addEventListener('click', handleClickOutside, { passive: true });
});

onUnmounted(() => {
  document.querySelector('body').removeEventListener('click', handleClickOutside);
});
</script>
