<template>
  <div class="flex flex-col gap-2 p-4">
    <div class="font-medium uppercase">
      <FontAwesomeIcon :icon="faInfoCircle" />
      {{ $t('components.projects.statusTaskControl.isPending.title') }}
    </div>
    <div
      v-if="hasRole('admin:skyloud')"
      class="flex flex-col gap-2"
    >
      <p class="text-sm">
        {{ $t('components.projects.statusTaskControl.isPending.skyloudWaiting.text1') }}
        <br />
        {{ $t('components.projects.statusTaskControl.isPending.skyloudWaiting.text2') }}
        <span class="whitespace-nowrap">
          "
          <FontAwesomeIcon
            :icon="faCheckDouble"
            class="mr-1 text-success"
          />
          {{ $t('components.projects.statusTaskControl.isPending.skyloudWaiting.ended') }}"
        </span>
        .
        <br />
        <span>{{ $t('components.projects.statusTaskControl.isPending.skyloudWaiting.willBeArchived') }} : {{ formatDate(taskWillBeArchivedAt, 'DD MMMM YYYY - HH:mm') }}</span>
      </p>
      <SkyButton
        variant="flat"
        color="dark"
        size="small"
        animation="none"
        @click="handleRemoveAwaitingCustomerStatus"
      >
        {{ $t('components.projects.statusTaskControl.isPending.cancelButton') }}
      </SkyButton>
    </div>
    <p
      v-else-if="hasRole('admin')"
      class="text-sm"
    >
      {{ $t('components.projects.statusTaskControl.isPending.clientReply') }}
    </p>
    <p
      v-else
      class="text-sm"
    >
      {{ $t('components.projects.statusTaskControl.requestLocked') }}
    </p>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { formatDate } from '@/utils/format.js';
import dayjs from 'dayjs';
import { faInfoCircle, faCheckDouble } from '@fortawesome/pro-light-svg-icons';
import { useNotify } from '@/plugins/notify/index.js';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const notify = useNotify();
const { t } = useI18n();

const hasRole = store.getters['auth/hasRole'];

const taskWillBeArchivedAt = computed(() => dayjs(props.task.pendingAt).add(35, 'day').toDate());
const loading = ref(false);

async function handleRemoveAwaitingCustomerStatus() {
  loading.value = true;
  try {
    await store.dispatch('projects/updateTaskPendingStatus', { _id: props.task._id, isPending: false });
    notify.success(t('components.projects.editTaskModal.notifications.updateTaskStatus', { taskId: props.task.taskId }));
  } catch (err) {
    notify.error(err.message);
  }
  loading.value = false;
}
</script>
