import { ProbeEventStatus } from '@/modules/stats/availability/domain/probe-event/status/probe-event-status.js';

export class UnavailableStatus extends ProbeEventStatus {
  getTranslateTextKey() {
    return 'stats.availability.status.unavailable';
  }

  getBackgroundColor() {
    return 'bg-error';
  }

  getTextColor() {
    return 'text-error';
  }

  getOutlineColor() {
    return 'outline-error';
  }
}
