<template>
  <label class="block">
    <div
      v-if="label"
      class="block mb-2 text-sm font-medium text-primary"
    >
      {{ label }}
    </div>
    <div
      class="relative flex items-center h-10 pl-3 text-sm placeholder-gray-400 bg-white border rounded"
      :class="inputClasses"
    >
      <FontAwesomeIcon
        v-if="icon"
        class="absolute text-lg text-blue-200 transform -translate-y-1/2 pointer-events-none right-3 top-1/2"
        :icon="icon"
      />
      <input
        ref="input"
        v-model="internalValue"
        style="transition: all 0.15s ease 0s"
        class="flex-1 w-full pr-3 appearance-none focus:outline-none focus:shadow-outline"
        :type="type"
        :placeholder="placeholder"
        :readonly="readOnly"
      />
    </div>
    <span
      class="block mt-1 text-xs tracking-wide text-red-500"
      :class="{ hidden: !hasErrorMessages }"
    >
      {{ firstErrorMessage }}
    </span>
  </label>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import useValidatable from '@/modules/shared/utils/validatable.js';

export default {
  props: {
    modelValue: {
      type: [String, Date],
      default: undefined,
    },
    label: { type: String, default: () => undefined },
    icon: { type: Object, default: () => undefined },
    placeholder: { type: String, default: () => undefined },
    type: { type: String, default: () => 'date' },
    rules: { type: Array, default: () => [] },
    readOnly: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const input = ref(null);

    const { state, validate } = useValidatable({ props, emit });

    const inputClasses = computed(() => ({
      'border-red-500': state.hasErrorMessages,
      ' border-dark-blue-100': !state.hasErrorMessages,
    }));

    return {
      ...toRefs(state),
      input,
      inputClasses,
      validate,
    };
  },
};
</script>

<style scoped>
input::-webkit-inner-spin-button,
input::-webkit-calendar-picker-indicator {
  opacity: 0;
}
</style>
