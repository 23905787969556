<template>
  <span class="flex flex-row items-center gap-1 text-white bg-primary text-xs">
    <span>CTRL</span>
    /
    <img
      height="12"
      width="12"
      src="@/assets/icon_command.svg"
      alt="macos command icon"
    />
    + {{ toUpper(keybind) }}
  </span>
</template>

<script>
import { onBeforeUnmount, onMounted } from 'vue';
import toUpper from 'lodash/toUpper';

export default {
  props: {
    keybind: {
      type: String,
      required: true,
    },
  },
  emits: ['trigger'],
  setup(props, { emit }) {
    function handleAction(e) {
      const isPressingCtrlOrCmd = e.ctrlKey || e.metaKey;
      if (!isPressingCtrlOrCmd || e.key !== props.keybind) {
        return;
      }
      e.preventDefault();
      emit('trigger');
    }

    onMounted(() => {
      window.addEventListener('keydown', handleAction);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleAction);
    });

    return {
      toUpper,
    };
  },
};
</script>
