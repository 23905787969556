import accountsApi from '@/modules/shared/api/accounts.js';

export async function getApp() {
  const { data } = await accountsApi().get('/admin/app');

  return data;
}

export async function putAppConfigMails({ mailsConfig }) {
  const { data } = await accountsApi().put('/admin/app/config/mails', {
    ...mailsConfig,
  });

  return data;
}

export async function putAppConfigPaymentsStripe({ paymentsStripeConfig }) {
  const { data } = await accountsApi().put('/admin/app/config/payments/stripe', {
    ...paymentsStripeConfig,
  });

  return data;
}

export async function postSearchApps({ email }) {
  const { data } = await accountsApi().post('/apps/search', {
    email,
  });

  return data;
}

export async function patchCGVMetadata({ key, value }) {
  const { data } = await accountsApi().patch('/admin/apps/metadata/cgv', {
    key,
    value,
  });

  return data;
}
