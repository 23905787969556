<template>
  <div class="flex flex-col mt-4 gap-2">
    <SkyButton
      v-if="task.listId !== 'todo'"
      variant="flat"
      color="dark"
      size="small"
      animation="none"
      :loading="loading"
      @click="handleAskCustomerTaskValidation"
    >
      {{ $t('components.projects.statusTaskControl.workInProgress.askRequestValidation') }}
    </SkyButton>
    <SkyButton
      v-if="isLastMessageValid"
      variant="flat"
      color="light"
      size="small"
      animation="none"
      :loading="loading"
      class="w-full"
      @click="handleWaitingCustomerAnswer"
    >
      {{ $t('components.projects.statusTaskControl.workInProgress.waitForCustomer') }}
    </SkyButton>
    <SkyTooltip
      v-else-if="!confirmationAsked"
      variant="white-smoke"
    >
      <template #content>
        <span>{{ $t('components.projects.statusTaskControl.workInProgress.pendingRequestReminder') }}</span>
      </template>
      <SkyButton
        variant="flat"
        color="light"
        size="small"
        animation="none"
        :loading="loading"
        class="w-full"
        @click="confirmationAsked = true"
      >
        {{ $t('components.projects.statusTaskControl.workInProgress.waitForCustomer') }}
      </SkyButton>
    </SkyTooltip>
    <div
      v-if="confirmationAsked"
      class="flex flex-col gap-2"
    >
      <span class="text-sm">{{ $t('components.projects.statusTaskControl.workInProgress.confirmPendingRequestMessage') }}</span>
      <div class="flex flex-row items-center gap-2">
        <SkyButton
          variant="flat"
          color="dark"
          size="small"
          animation="none"
          :loading="loading"
          class="w-full"
          @click="handleWaitingCustomerAnswer"
        >
          {{ $t('components.projects.statusTaskControl.workInProgress.confirmPendingRequest') }}
        </SkyButton>
        <SkyButton
          variant="flat"
          color="light"
          size="small"
          animation="none"
          :loading="loading"
          class="w-full"
          @click="confirmationAsked = false"
        >
          {{ $t('components.projects.statusTaskControl.workInProgress.cancelConfirmPendingRequest') }}
        </SkyButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useNotify } from '@/plugins/notify/index.js';
import get from 'lodash/get';
import head from 'lodash/head';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const notify = useNotify();
const { t } = useI18n();

const getThreadMessages = store.getters['chats/getThreadMessages'];
const getUser = store.getters['users/getUser'];
const messages = computed(() => (props.task ? getThreadMessages({ threadId: props.task.threadId }) : []));
const lastMessage = computed(() => head(messages.value));
const lastMessageAuthor = ref(undefined);
const lastMessageAuthorRoles = computed(() => get(lastMessageAuthor.value, 'roles', []));
const isLastMessageValid = computed(() => get(lastMessage.value, 'kind') === 'user' && lastMessageAuthorRoles.value.includes('admin:skyloud'));

const loading = ref(false);
const confirmationAsked = ref(false);

async function handleAskCustomerTaskValidation() {
  loading.value = true;
  try {
    await store.dispatch('projects/updateTaskCompletionPendingStatus', { _id: props.task._id, isWaitingForCompletion: true });
  } catch (err) {
    notify.error(err.message);
  }
  loading.value = false;
}

async function handleWaitingCustomerAnswer() {
  loading.value = true;
  try {
    await store.dispatch('projects/updateTaskPendingStatus', { _id: props.task._id, isPending: true });
    notify.success(t('components.projects.editTaskModal.notifications.updateTaskStatus', { taskId: props.task.taskId }));
  } catch (err) {
    notify.error(err.message);
  }
  loading.value = false;
}

watch(
  () => lastMessage.value,
  async () => {
    if (lastMessage.value) {
      await store.dispatch('users/fetchUser', { userId: lastMessage.value.authorId });
      lastMessageAuthor.value = getUser({ userId: lastMessage.value.authorId });
    }
  },
  { deep: true, immediate: true },
);
</script>
