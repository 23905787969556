<template>
  <div class="flex flex-row-reverse gap-12 overflow-y-hidden overflow-x-auto snap-mandatory snap-x py-2">
    <AvailabilityHistoryMonth
      v-for="(monthHistory, index) in history.fillEventsForMonths(dateProvider, monthsToDisplay).reverse()"
      :key="index"
      :month-history="monthHistory"
      :probe="probe"
      :hide-months-name="hideMonthsName"
      class="snap-center"
    />
  </div>
</template>

<script setup>
import { ProbeEvents } from '@/modules/stats/availability/domain/probe-event/collection/probe-events.js';
import AvailabilityHistoryMonth from '@/modules/stats/availability/infrastructure/presenters/molecules/AvailabilityHistoryMonth.vue';
import { availabilityInjectors } from '@/modules/stats/availability/infrastructure/presenters/availability-injectors.js';
import { inject, provide } from 'vue';
import { Probe } from '../../../domain/probe/probe.js';

const props = defineProps({
  history: {
    type: ProbeEvents,
    required: true,
  },
  probe: {
    type: Probe,
    default: undefined,
  },
  hideMonthsName: {
    type: Boolean,
    default: false,
  },
  dayWidth: {
    type: Number,
    required: true,
  },
});

provide(availabilityInjectors.dayWidth, props.dayWidth);

/** @type {DateProvider} */
const dateProvider = inject(availabilityInjectors.dateProvider);

/** @type {Number} */
const monthsToDisplay = inject(availabilityInjectors.monthsToDisplay) || ProbeEvents.MAXIMUM_MONTHS;
</script>
