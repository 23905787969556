import paymentsApi from '@/modules/shared/api/payments.js';

export async function getProduct({ productId }) {
  const { data: product } = await paymentsApi().get(`/public/stripe/products/${productId}`);

  return product;
}

export async function getPrice({ priceId }) {
  const { data: price } = await paymentsApi().get(`/public/stripe/prices/${priceId}`);

  return price;
}

export async function getPaymentIntent({ paymentIntentId }) {
  const { data: paymentIntent } = await paymentsApi().get(`/admin/stripe/paymentIntents/${paymentIntentId}`);

  return paymentIntent;
}

export async function getProducts() {
  const { data } = await paymentsApi().get('/admin/stripe/products');
  const { hasMore = false, list: products = [] } = data;
  return { hasMore, products };
}

export async function getPrices(params) {
  const { data } = await paymentsApi().get('/admin/stripe/prices', { params });
  const { hasMore = false, list: prices = [] } = data;
  return { hasMore, prices };
}

export async function getTaxRates(params) {
  const { data } = await paymentsApi().get('/admin/stripe/taxRates', { params });
  const { hasMore = false, list: taxRates = [] } = data;
  return { hasMore, taxRates };
}

export async function getProductPrices({ productId }) {
  return getPrices({ productId });
}

export async function patchProductMetadata({ productId, metadata }) {
  await paymentsApi().patch(`/admin/stripe/products/${productId}`, {
    metadata,
  });
}

export async function getPayments() {
  const { data } = await paymentsApi().get('/admin/payments');
  const { hasMore = false, list: payments = [] } = data;
  return { hasMore, payments };
}

export async function postCreateSkyloudPaymentIntent() {
  const { data } = await paymentsApi().post('/admin/skyloud/payment-intent', {
    currency: 'eur',
    paymentMethodType: 'sepa_debit',
  });
  return data;
}

export async function getSkyloudPaymentMethods() {
  const { data } = await paymentsApi().get('/admin/skyloud/payment-methods');

  const { hasMore = false, list: paymentMethods = [] } = data;
  return { hasMore, paymentMethods };
}

export async function getSkyloudProfile() {
  const { data } = await paymentsApi().get('/admin/skyloud/profile');
  return data;
}

export async function postSkyloudDetachPaymentMethod({ paymentMethodId }) {
  const { data } = await paymentsApi().post('/admin/skyloud/payment-methods/detach', {
    paymentMethodId,
  });
  return data;
}

export async function getSkyloudPaymentMethodMandate({ paymentMethodId }) {
  const { data } = await paymentsApi().get(`/admin/skyloud/payment-method/${paymentMethodId}/mandate`);
  return data;
}
