<template>
  <div
    class="message"
    :class="{
      'message--is-author': currentUser._id === message.authorId,
      'message--loading-failed': loadingFailed,
      'message--is-private': isPrivate,
    }"
  >
    <div class="message__header">
      <span class="message__author">
        <User :user-id="message.authorId">
          <template #default="{ user }">
            <div class="flex flex-row items-center gap-2">
              <div class="relative">
                <span
                  v-if="!user || !user.picture"
                  class="relative flex items-center justify-center text-xs text-white rounded-full w-9 h-9 bg-primary"
                >
                  <FontAwesomeIcon :icon="faUser" />
                </span>
                <img
                  v-else
                  :src="user.picture"
                  alt=""
                  class="rounded-full w-9 h-9"
                />
                <img
                  v-if="user && isAdminSkyloud(user)"
                  src="@/assets/skyloud_team_icon.svg"
                  alt="Skyloud Logo"
                  class="absolute -right-1 -bottom-1"
                  style="width: 1.1rem"
                  title="Equipe Skyloud"
                />
              </div>
              <div class="flex flex-col justify-center">
                <span
                  v-if="user"
                  class="text-sm font-semibold"
                >
                  {{ user.firstName }}
                </span>
                <span
                  v-else
                  class="text-sm font-semibold"
                >
                  Deleted User
                </span>
                <span class="font-light text-gray-400">
                  {{ formatDate(message.createdAt, '[Le] DD MMMM YYYY [à] HH:mm') }}
                </span>
              </div>
            </div>
          </template>
        </User>
        <SkyButton
          v-if="isFile"
          size="tiny"
          variant="outline"
          :loading="isDownloading"
          @click="handleDownloadFile"
        >
          <FontAwesomeIcon
            :icon="faArrowDownToLine"
            class="text-lg"
          />
        </SkyButton>
      </span>
    </div>
    <div
      v-if="isFile"
      class="message__body"
    >
      <div v-if="fileRef">
        <a
          :href="previewUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="cursor-pointer"
        >
          <img
            v-if="isImage"
            :src="previewUrl"
            alt="image file"
          />
          <iframe
            v-else
            :disabled="isDownloading"
            :src="previewUrl"
            title="file preview"
            class="w-full"
          >
            Here is the file preview
          </iframe>
        </a>
      </div>
      <div v-else>
        {{ loadingFailed ? 'File not found' : 'Loading...' }}
      </div>
    </div>
    <div
      v-else
      class="message__body prose prose-sm prose-thread"
      :class="{
        'message__body--is-code': isCode,
      }"
      v-html="parsedText"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { parse } from 'marked';
import includes from 'lodash/includes';
import { formatDate } from '@/utils/format.js';
import User from '@/modules/users/components/User.vue';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
  isPrivate: {
    type: Boolean,
    default: false,
  },
});

const fileIdMatcher = /^\{storages:fileId:(?<fileId>[0-9a-f]{24})\}$/;

const store = useStore();

const currentUser = computed(() => store.getters['auth/currentUser']);
const fileRef = ref(undefined);
const previewUrl = ref(undefined);
const loadingFailed = ref(false);
const isDownloading = ref(false);
const isImage = ref(false);
const isCode = computed(() => props.message.text.startsWith('```') && props.message.text.endsWith('```') && props.message.text.indexOf('\n') > 0);
const isFile = computed(() => fileIdMatcher.test(props.message.text));
const parsedText = computed(() => parse(props.message.text));

function isAdminSkyloud({ roles }) {
  if (!roles) return false;

  return includes(roles, 'admin:skyloud', 0);
}

onMounted(async () => {
  isDownloading.value = true;
  try {
    if (!isFile.value) {
      return;
    }
    const matches = fileIdMatcher.exec(props.message.text);
    const { file, tempPreviewUrl } = await store.dispatch('storages/fetchFile', { fileId: matches.groups.fileId });
    fileRef.value = file;
    isImage.value = file.mimetype.includes('image');
    previewUrl.value = tempPreviewUrl;
  } catch (err) {
    loadingFailed.value = true;
  }
  isDownloading.value = false;
});

async function handleDownloadFile() {
  isDownloading.value = true;
  try {
    const fileBlob = await store.dispatch('storages/downloadFile', { fileId: fileRef.value._id });
    const blob = new Blob([fileBlob], { type: fileRef.value.mimetype });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileRef.value.originalname;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (err) {
    console.error(err);
  }
  isDownloading.value = false;
}
</script>

<style lang="scss" scoped>
.message {
  @apply flex flex-col gap-1 max-w-4/5 self-start py-2 px-3 rounded-md bg-white;
  border: 1px solid #d0dafd;

  .message__header {
    @apply flex items-center text-xs mb-3;
  }

  .message__author {
    @apply font-bold flex flex-row justify-between items-start min-[600px]:items-center w-full gap-4;
  }

  .message__body {
    @apply flex flex-col overflow-hidden max-w-none break-words;

    &.message__body--is-code {
      @apply p-0;
    }

    :deep(a) {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  &--is-author {
    @apply self-end;
    background-color: #f5f7ff;
  }

  &--is-private {
    @apply border-error border-opacity-40;
  }

  &--is-private.message--is-author {
    @apply bg-error bg-opacity-10;
  }

  &--loading-failed {
    @apply border-red-300 bg-red-50;
  }
}
</style>
