<template>
  <div
    ref="root"
    class="sky-gauge"
    :class="{
      'is-ready': isReady,
    }"
    :style="{
      '--total-length': circlesLength,
      '--dash-array': `${circlesLength * 0.8} 99999`,
      '--fill-offset': fillOffset,
      '--fill-color': fillColor,
      '--placeholder-fill-color': placeholderFillColor,
    }"
  >
    <div class="sky-gauge__value">
      <slot name="value">N/A</slot>
    </div>
    <div
      v-if="label"
      class="sky-gauge__label"
    >
      {{ label }}
    </div>
    <svg>
      <circle
        class="placeholder"
        cx="50%"
        cy="50%"
      />
      <circle
        class="fill"
        cx="50%"
        cy="50%"
      />
    </svg>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref, computed } from 'vue';
import isNil from 'lodash/isNil';
import round from 'lodash/round';

const { min, max } = Math;
const colorRed = 'rgb(197, 40, 40)';
const colorOrange = 'rgb(197, 174, 40)';
const colorGreen = 'rgb(52, 211, 153)';
const colorGray = 'rgb(229, 231, 235)';

export default {
  props: {
    fillPrct: {
      type: Number,
      default: () => undefined,
    },
    label: {
      type: String,
      default: () => undefined,
    },
  },
  setup(props) {
    const root = ref(null);

    function getRgbValuesfromRgbOrRgbaString({ color }) {
      const isRgba = color.indexOf('rgba') > -1;
      const firstParenthesisIndex = isRgba ? 5 : 4;
      const indexAfterBlueValue = isRgba ? color.length - 3 : color.length - 1;

      const colorValues = color
        .substring(firstParenthesisIndex, indexAfterBlueValue)
        .replaceAll(' ', '')
        .split(',')
        .slice(0, 3)
        .map((code) => parseFloat(code, 10));

      return {
        red: colorValues[0],
        green: colorValues[1],
        blue: colorValues[2],
      };
    }

    function getColorBetweenTwoColorsAtPrct({ fromRgbStringColor, toRgbStringColor, prct }) {
      if (prct >= 1) {
        return toRgbStringColor;
      }

      if (prct <= 0) {
        return fromRgbStringColor;
      }

      const fromColor = getRgbValuesfromRgbOrRgbaString({
        color: fromRgbStringColor,
      });
      const toColor = getRgbValuesfromRgbOrRgbaString({ color: toRgbStringColor });

      const redDiff = toColor.red - fromColor.red;
      const greenDiff = toColor.green - fromColor.green;
      const blueDiff = toColor.blue - fromColor.blue;

      const gradient = [
        min(255, max(0, round(fromColor.red + redDiff * prct))),
        min(255, max(0, round(fromColor.green + greenDiff * prct))),
        min(255, max(0, round(fromColor.blue + blueDiff * prct))),
      ];

      return `rgb(${gradient.join(', ')})`;
    }

    const state = reactive({
      circlesLength: computed(() => {
        const gauge = root.value;
        if (!gauge) {
          return undefined;
        }

        const firstCircle = gauge.querySelector('circle');
        const firstCircleRect = firstCircle.getBoundingClientRect();

        if (firstCircleRect.width === 0) {
          return undefined;
        }

        return round(firstCircle.getTotalLength());
      }),
      safePrct: computed(() => max(-1, min(props.fillPrct * 100, 100))),
      fillOffset: computed(() => {
        const gauge = root.value;
        if (!gauge || isNil(props.fillPrct)) {
          return undefined;
        }

        if (!state.circlesLength) {
          return undefined;
        }

        const circlesLength = state.circlesLength * 0.8;

        const fillPrct1Value = circlesLength / 100;
        const prct = state.safePrct;

        return circlesLength - fillPrct1Value * prct;
      }),
      fillColor: computed(() => {
        if (isNil(props.fillPrct)) {
          return colorGray;
        }

        const prct = state.safePrct;

        if (prct < 50) {
          return colorRed;
        }

        if (prct < 75) {
          return getColorBetweenTwoColorsAtPrct({
            fromRgbStringColor: colorRed,
            toRgbStringColor: colorOrange,
            prct: round(prct / 50 - 1, 4),
          });
        }

        return getColorBetweenTwoColorsAtPrct({
          fromRgbStringColor: colorOrange,
          toRgbStringColor: colorGreen,
          prct: round(prct / 50 - 1, 4),
        });
      }),
      placeholderFillColor: computed(() => {
        if (state.safePrct < 0) {
          return colorRed;
        }

        return colorGray;
      }),
      isReady: false,
    });

    onMounted(() => {
      setTimeout(() => {
        state.isReady = true;
      }, 1);
    });

    return {
      root,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
.sky-gauge {
  @apply relative flex items-center justify-center m-auto text-center bg-white rounded-full;
  height: 100px;
  width: 100px;

  &__value {
    @apply relative inline-block text-sm text-gray-200;
    transition: color ease-out 1s, font-weight ease 300ms;
  }

  &__label {
    @apply absolute bottom-1 text-sm font-semibold;
    transition: color ease-out 1s, font-weight ease 300ms;
  }

  > svg {
    @apply absolute top-0 left-0 h-full w-full;

    > circle {
      @apply stroke-current text-gray-200;
      r: 43%;
      fill: none;
      stroke-linecap: round;
      stroke-width: 5px;
      stroke-dasharray: var(--dash-array, 1000);

      transform-origin: center;
      transform: rotate(126.5deg);

      transition: stroke-width ease 300ms, color ease-out 1s;

      &.placeholder {
        color: var(--placeholder-fill-color, black);
      }

      &.fill {
        @apply opacity-0;
        stroke-dashoffset: var(--total-length, 1000);
      }
    }
  }

  // HOVER
  &:hover {
    .sky-gauge__value {
      @apply font-bold;
    }

    .sky-gauge__label {
      @apply font-bold;
    }

    &:hover > svg > circle {
      stroke-width: 7px;
    }
  }

  // ANIMATION
  &.is-ready[style*='--fill-offset'] {
    .sky-gauge__value {
      color: var(--fill-color, black);
    }

    svg > circle.fill {
      @apply opacity-100;
      color: var(--fill-color, black);
      stroke-dashoffset: var(--fill-offset, 1000);
      transition: stroke-width ease 300ms, stroke-dashoffset ease-out 1s, color ease-out 1s;
    }
  }
}
</style>
