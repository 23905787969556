<template>
  <slot
    v-if="user"
    :user="user"
  >
    <span :title="user.email">
      {{ user.firstName }}
    </span>
  </slot>
  <slot
    v-else
    :user="user"
  >
    <span>Deleted User</span>
  </slot>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  userId: {
    type: String,
    default: undefined,
  },
  user: {
    type: Object,
    default: undefined,
  },
});

const store = useStore();

const getUser = store.getters['users/getUser'];

const user = computed(() => (!props.user ? getUser({ userId: props.userId }) : props.user));

onMounted(async () => {
  try {
    if (props.user) return;
    await store.dispatch('users/fetchUser', { userId: props.userId });
  } catch (err) {
    console.error(err);
  }
});
</script>

<style></style>
