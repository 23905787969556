<template>
  <div
    :key="tooltipKey"
    class="relative"
  >
    <Teleport to="body">
      <div
        v-if="persist || elementIsInView"
        :class="{ hidden: !isHovered }"
      >
        <div
          ref="tooltipElementRef"
          class="absolute transform-gpu -translate-x-1/2 -translate-y-full transition-all z-50"
        >
          <div :class="{ 'mb-1': !hideCaret, 'mb-2': hideCaret }">
            <div
              class="rounded-md px-3 py-1 whitespace-nowrap text-xs"
              :class="{
                'bg-primary text-white': variant === 'primary',
                'bg-white-smoke border-2 border-[#D0DAFD]': variant === 'white-smoke',
              }"
            >
              <slot name="content" />
            </div>

            <SkyTooltipCaret
              v-if="!hideCaret"
              class="z-0"
              :variant="variant"
            />
          </div>
        </div>
      </div>
    </Teleport>

    <div ref="hostElementRef">
      <slot />
    </div>
  </div>
</template>

<script setup>
import SkyTooltipCaret from '@/components/ui/tooltips/SkyTooltipCaret.vue';
import { useTooltip } from '@/components/ui/tooltips/use-tooltip.js';
import { ref, watch } from 'vue';

const props = defineProps({
  variant: {
    type: String,
    required: false,
    default: 'primary',
    validator: (value) => ['primary', 'white-smoke'].includes(value),
  },
  persist: {
    type: Boolean,
    default: false,
  },
  hideCaret: {
    type: Boolean,
    default: false,
  },
});

const tooltipKey = ref(Math.random());
const { hostElementRef, tooltipElementRef, isHovered, elementIsInView } = useTooltip();

watch(
  () => elementIsInView.value,
  () => {
    if (!props.persist && elementIsInView.value) {
      setTimeout(() => {
        tooltipKey.value = Math.random();
      }, 200);
    }
  },
);
</script>
