<template>
  <div
    data-testid="availability-history-week"
    class="flex gap-4 w-full relative"
  >
    <div class="absolute left-0 top-0 inset-0 flex gap-4">
      <span
        v-for="(_, index) in new Array(WEEK_LENGTH).fill(1)"
        :key="index"
        class="rounded-md bg-transparent border-[#EBEEFF] border-2"
        :class="`w-${size} h-${size}`"
      ></span>
    </div>
    <span
      v-for="(dayHistory, index) in weekHistory"
      :key="index"
    >
      <AvailabilityHistoryReportTooltip
        v-if="!(dayHistory.status instanceof AvailableStatus)"
        :day-history="dayHistory"
      >
        <AvailabilityHistoryDay
          :day-history="dayHistory"
          :probe="probe"
        />
      </AvailabilityHistoryReportTooltip>
      <AvailabilityHistoryDay
        v-else
        :day-history="dayHistory"
        :probe="probe"
        class="relative"
      />
    </span>
  </div>
</template>

<script setup>
import AvailabilityHistoryDay from '@/modules/stats/availability/infrastructure/presenters/atoms/AvailabilityHistoryDay.vue';
import { ProbeEventsWeek } from '@/modules/stats/availability/domain/probe-event/collection/probe-events-week.js';
import AvailabilityHistoryReportTooltip from '@/modules/stats/availability/infrastructure/presenters/molecules/AvailabilityHistoryReportTooltip.vue';
import { inject } from 'vue';
import { availabilityInjectors } from '../availability-injectors.js';
import { AvailableStatus } from '../../../domain/probe-event/status/available-status.js';
import { Probe } from '../../../domain/probe/probe.js';

const WEEK_LENGTH = 7;
const size = inject(availabilityInjectors.dayWidth);

defineProps({
  weekHistory: {
    type: ProbeEventsWeek,
    required: true,
  },
  probe: {
    type: Probe,
    default: undefined,
  },
});
</script>
