<template>
  <div
    class="bg-white p-6 flex flex-col gap-2 items-center"
    v-bind="$attrs"
  >
    <div
      class="flex flex-row justify-between items-center w-full"
      :class="{
        [spacing]: spacing,
      }"
    >
      <div class="flex flex-col">
        <span class="font-semibold">{{ selectedMonthLabel }} {{ selectedMonth.getFullYear() }}</span>
        <span
          v-if="modelValue.start && modelValue.end"
          class="text-sm text-primary"
        >
          {{ formatDate(modelValue.start, 'DD/MM/YY') }} → {{ formatDate(modelValue.end, 'DD/MM/YY') }}
        </span>
        <span
          v-else-if="placeholder"
          class="text-sm text-primary"
        >
          {{ placeholder }}
        </span>
      </div>
      <div class="flex flex-row gap-2 text-xs">
        <span
          class="bg-primary bg-opacity-10 rounded-md px-2 py-1.5 hover:bg-opacity-30"
          :class="{
            'cursor-pointer': !isTargetMonthBeforeMin,
            'cursor-not-allowed': isTargetMonthBeforeMin,
          }"
          @click.stop="handleChangeCurrentMonth(-1)"
        >
          <FontAwesomeIcon :icon="faArrowLeft" />
        </span>
        <span
          class="bg-primary bg-opacity-10 rounded-md px-2 py-1.5 hover:bg-opacity-30"
          :class="{
            'cursor-pointer': !isTargetMonthAfterMax,
            'cursor-not-allowed': isTargetMonthAfterMax,
          }"
          @click.stop="handleChangeCurrentMonth(1)"
        >
          <FontAwesomeIcon :icon="faArrowRight" />
        </span>
        <span
          v-if="canReset"
          class="bg-primary bg-opacity-10 rounded-md px-2 py-1.5 cursor-pointer hover:bg-opacity-30"
          @click.stop="handleResetRange"
        >
          <FontAwesomeIcon :icon="faArrowRotateRight" />
        </span>
      </div>
    </div>
    <div class="relative w-full">
      <div
        v-if="!compact || (compact && open)"
        class="days-grid"
        :class="{
          'opacity-20': loading,
        }"
      >
        <div
          v-for="(day, index) in monthDays"
          :key="day.date"
          class="w-full flex justify-center items-center relative px-2 my-2"
        >
          <span
            v-if="isDateInRange(day.date) && !isMatchingEnd(day.date) && index % 7 !== 6"
            class="absolute bg-primary bg-opacity-50 w-full h-full left-1/2"
          ></span>
          <span
            class="text-xs rounded-full w-8 h-8 flex items-center justify-center font-medium relative z-10"
            :class="{
              'bg-[#FFF] border-[#EAEEFF] border hover:bg-[#eeebf5]': !day.isCurrentMonth && (!isMatchingStartOrEnd(day.date) || !isDateInRange(day.date)),
              'bg-[#EAEEFF] text-dark-gray hover:bg-[#ccd2e8]': !isMatchingStartOrEnd(day.date) || isDateInRange(day.date),
              'bg-primary text-white': isMatchingStartOrEnd(day.date),
              'outline outline-primary outline-2': isDateToday(day.date),
              'cursor-pointer': allowFuture || (!allowFuture && !isDateFuture(day.date)),
              'cursor-not-allowed': !allowFuture && isDateFuture(day.date),
            }"
            @click.stop="handleDefineRange(day)"
          >
            {{ new Date(day.date).getDate() }}
          </span>
        </div>
      </div>
      <div
        v-if="loading"
        class="absolute inset-0 z-30 flex justify-center items-center"
      >
        <SkyLoading />
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatDate } from '@/utils/format.js';
import { DateValueObject } from '@/utils/value-objects/date/date-value-object.js';
import { faArrowRotateRight, faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import { computed, ref } from 'vue';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

function getWeekday(date) {
  return dayjs(date).weekday();
}

const emit = defineEmits(['update:modelValue', 'reset']);
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({ start: undefined, end: undefined }),
  },
  spacing: {
    type: String,
    default: undefined,
  },
  min: {
    type: Date,
    default: undefined,
  },
  max: {
    type: Date,
    default: undefined,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
  allowFuture: {
    type: Boolean,
    default: true,
  },
  canReset: {
    type: Boolean,
    default: false,
  },
});

const selectedMonth = ref(new Date());
const selectedMonthLabel = computed(() => {
  const label = formatDate(selectedMonth.value, 'MMMM');
  return `${label[0].toUpperCase()}${label.slice(1)}`;
});
const daysInMonth = computed(() => dayjs(selectedMonth.value).daysInMonth());
const isRangeDefined = computed(() => !!props.modelValue.start && !!props.modelValue.end);
const nextMonth = computed(() => dayjs(selectedMonth.value).add(1, 'month'));
const prevMonth = computed(() => dayjs(selectedMonth.value).add(-1, 'month'));
const isTargetMonthBeforeMin = computed(() => props.min && dayjs(props.min).date(1).isAfter(prevMonth.value));
const isTargetMonthAfterMax = computed(() => props.max && dayjs(props.max).endOf('month').isBefore(nextMonth.value));

const currentMonthDays = computed(() => {
  return [...Array(daysInMonth.value)].map((day, index) => {
    return {
      date: dayjs(`${selectedMonth.value.getFullYear()}-${selectedMonth.value.getMonth() + 1}-${index + 1}`).format('YYYY-MM-DD'),
      isCurrentMonth: true,
    };
  });
});

const previousMonthDays = computed(() => {
  const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays.value[0].date);
  const previousMonth = dayjs(`${selectedMonth.value.getFullYear()}-${selectedMonth.value.getMonth()}-01`);
  const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday || 7;
  const previousMonthLastMondayDayOfMonth = dayjs(currentMonthDays.value[0].date).subtract(visibleNumberOfDaysFromPreviousMonth, 'day').date();

  return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day, index) => {
    return {
      date: dayjs(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`).format('YYYY-MM-DD'),
      isCurrentMonth: false,
    };
  });
});

const nextMonthDays = computed(() => {
  const lastDayOfTheMonthWeekday = getWeekday(`${selectedMonth.value.getFullYear()}-${selectedMonth.value.getMonth() + 1}-${currentMonthDays.value.length}`);
  const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday ? 6 - lastDayOfTheMonthWeekday : lastDayOfTheMonthWeekday;

  return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
    return {
      date: dayjs(`${nextMonth.value.year()}-${nextMonth.value.month() + 1}-${index + 1}`).format('YYYY-MM-DD'),
      isCurrentMonth: false,
    };
  });
});

const monthDays = computed(() => [...previousMonthDays.value, ...currentMonthDays.value, ...nextMonthDays.value]);

function isDateFuture(date) {
  return dayjs(date).isAfter(dayjs().format('YYYY-MM-DD'));
}

function isDateToday(date) {
  return dayjs(date).isSame(dayjs().format('YYYY-MM-DD'));
}

function isMatchingEnd(date) {
  return new Date(date)?.toDateString() === new Date(props.modelValue.end)?.toDateString();
}

function isMatchingStartOrEnd(date) {
  const isMatchingStart = new Date(date)?.toDateString() === new Date(props.modelValue.start)?.toDateString();
  return isMatchingStart || isMatchingEnd(date);
}

function isDateInRange(date) {
  const start = DateValueObject.fromNative(props.modelValue.start);
  const end = DateValueObject.fromNative(props.modelValue.end);
  const target = DateValueObject.fromNative(date);

  return isRangeDefined.value && (target.isEqual(start) || target.isBetweenTwoDates(start, end));
}

function handleDefineRange(day) {
  if (!props.allowFuture && isDateFuture(day.date)) return;

  const isChoosedEndBeforeStart = DateValueObject.fromNative(day.date).isBefore(DateValueObject.fromNative(props.modelValue.start));

  if (!props.modelValue.end && isChoosedEndBeforeStart) {
    const dayDate = dayjs(day.date).set('hour', 0).set('minute', 0).set('second', 0).toDate();
    const startDate = dayjs(props.modelValue.start).set('hour', 23).set('minute', 59).set('second', 59).toDate();
    emit('update:modelValue', { start: dayDate, end: startDate });
    return;
  }

  if (props.modelValue.start && !props.modelValue.end) {
    const dayDate = dayjs(day.date).set('hour', 23).set('minute', 59).set('second', 59).toDate();
    const startDate = dayjs(props.modelValue.start).set('hour', 0).set('minute', 0).set('second', 0).toDate();
    emit('update:modelValue', { start: startDate, end: dayDate });
    return;
  }

  const dayDate = dayjs(day.date).set('hour', 0).set('minute', 0).set('second', 0).toDate();
  emit('update:modelValue', { start: dayDate, end: undefined });
}

function handleChangeCurrentMonth(direction) {
  const targetMonth = dayjs(selectedMonth.value).add(direction, 'month');
  if ((direction === -1 && isTargetMonthBeforeMin.value) || (direction === 1 && isTargetMonthAfterMax.value)) return;

  selectedMonth.value = targetMonth.toDate();
}

function handleResetRange() {
  emit('update:modelValue', { start: undefined, end: undefined });
  emit('reset');
  selectedMonth.value = new Date();
}
</script>

<style lang="scss" scoped>
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
}
</style>
