<template>
  <div class="flex flex-col">
    <span
      v-for="index in endAt - startAt + 1"
      :key="index"
      class="hour-print"
      :style="{
        height: `${dayHourHeight}px`,
      }"
    >
      <span>{{ index + startAt - 1 }}</span>
      <SkyCalendarDayEvent
        v-for="event in getEventsFromHour(index + startAt - 1)"
        :key="event.startedAt"
        :event="event"
        :date="date"
        :show-notes="showNotes"
        :offset="getEventOffset(event)"
        class="cursor-pointer"
        @click="() => handleOpenLinkedTask(event)"
      />
      <span
        v-if="timeTriggerHours === index + startAt - 1"
        ref="timeTriggerRef"
        class="time-trigger"
        :style="{
          top: `${timeTriggerPosition}%`,
        }"
      ></span>
    </span>
  </div>
</template>

<script setup>
import { defaultSkyCalendarRange } from '@/modules/admin/admin.constants.js';
import last from 'lodash/last';
import { computed, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  startAt: {
    type: Number,
    default: defaultSkyCalendarRange.start,
  },
  endAt: {
    type: Number,
    default: defaultSkyCalendarRange.end,
  },
  showNotes: {
    type: Boolean,
    default: false,
  },
  date: {
    type: Date,
    default: new Date(),
  },
  events: {
    type: Array,
    default: () => [],
  },
});

const router = useRouter();

const dayHourHeight = 60;
const timeTriggerRef = ref(undefined);
const interval = ref(undefined);
const timeTriggerDate = ref(new Date());
const timeTriggerHours = computed(() => timeTriggerDate.value.getHours());
const timeTriggerPosition = computed(() => {
  const minutes = timeTriggerDate.value.getMinutes();
  return (minutes / 60) * 100;
});

const calendarDayMaxHours = ref(props.endAt);
provide('calendarDayMaxHours', calendarDayMaxHours);

function handleOpenLinkedTask(event) {
  const route = router.resolve(`/apps/${event.appId}?redirect=/projects/tasks/${event.slug}`);
  window.open(route.href, '_blank');
}

function getEventsFromHour(hour) {
  return props.events.filter((event) => {
    const isStartingSameDay = new Date(event.startedAt).getDate() === props.date.getDate();
    const eventHour = isStartingSameDay ? new Date(event.startedAt).getHours() : props.startAt;

    return eventHour === hour;
  });
}

function getEventOffset(event, offset = 0) {
  try {
    const nearEvents = props.events.filter((ev) => {
      return event.startedAt >= ev.startedAt && (!ev.stoppedAt || event.startedAt < ev.stoppedAt);
    });

    const currentEventIndex = nearEvents.findIndex((e) => event.slug === e.slug && event.startedAt === e.startedAt && e?.stoppedAt === event?.stoppedAt);
    const sameTimeEventIndex = nearEvents.findIndex((e) => event.slug !== e.slug && event.startedAt === e.startedAt && e?.stoppedAt === event?.stoppedAt);
    const hasEventInSameRange = currentEventIndex > sameTimeEventIndex && currentEventIndex >= 0 && sameTimeEventIndex >= 0;
    const validOffset = hasEventInSameRange ? offset + 2 : offset + 1;

    if (nearEvents.length - 1 > offset) {
      return getEventOffset(last(nearEvents), validOffset);
    }

    return offset;
  } catch (err) {
    console.debug(err.message);
    return offset;
  }
}

function handleTimeTriggerUpdate() {
  timeTriggerDate.value = new Date();
}

watch(
  () => props.endAt,
  () => {
    calendarDayMaxHours.value = props.endAt;
  },
  { deep: true },
);

onBeforeUnmount(() => {
  if (interval.value) {
    clearInterval(interval.value);
  }
});

onMounted(() => {
  interval.value = setInterval(handleTimeTriggerUpdate, 60_000);
});
</script>

<style lang="scss" scoped>
.hour-print {
  @apply relative text-[#A4B0E5];

  span {
    @apply absolute -top-2.5 -left-0.5;
  }

  &:before {
    @apply absolute content w-11/12 top-0 left-1/2 transform -translate-x-1/2 h-[1px] border border-dashed border-[#D0DAFD];
  }
}

.time-trigger {
  @apply w-full top-1/2 absolute transform -translate-y-1/2 h-[1px] bg-error block z-[3];

  &:after {
    @apply absolute content top-1/2 left-0 w-2 h-2 transform -translate-y-1/2 rounded-full bg-error;
  }
}
</style>
