import { ProbeEventStartedAt } from '@/modules/stats/availability/domain/probe-event/life-times/probe-event-started-at.js';
import { ProbeEventResolvedAt } from '@/modules/stats/availability/domain/probe-event/life-times/probe-event-resolved-at.js';
import { ProbeEventAcknowledgedAt } from './probe-event-acknowledged-at.js';

export class ProbeEventLifeTime {
  /**
   * @type {ProbeEventStartedAt}
   * @private
   */
  _startedAt;

  /**
   * @type {ProbeEventAcknowledgedAt | null}
   * @private
   */
  _acknowledgedAt;

  /**
   * @type {ProbeEventResolvedAt | null}
   * @private
   */
  _resolvedAt;

  /**
   * @param {ProbeEventStartedAt} startedAt
   * @param {ProbeEventAcknowledgedAt | null} acknowledgedAt
   * @param {ProbeEventResolvedAt | null} resolvedAt
   */
  constructor(startedAt, acknowledgedAt, resolvedAt) {
    this._startedAt = startedAt;
    this._acknowledgedAt = acknowledgedAt;
    this._resolvedAt = resolvedAt;
  }

  /**
   * @param {string} startedAt
   * @param {string|null} acknowledgedAt
   * @param {string|null} resolvedAt
   * @return {ProbeEventLifeTime}
   */
  static fromNative(startedAt, acknowledgedAt, resolvedAt) {
    return new ProbeEventLifeTime(
      ProbeEventStartedAt.fromNative(startedAt),
      acknowledgedAt === null ? null : ProbeEventAcknowledgedAt.fromNative(acknowledgedAt),
      resolvedAt === null ? null : ProbeEventResolvedAt.fromNative(resolvedAt),
    );
  }

  /**
   * @return {string}
   */
  get startedAtLocalString() {
    return this._startedAt.toLocaleString();
  }

  /**
   * @return {ProbeEventStartedAt}
   */
  get startedAt() {
    return this._startedAt;
  }

  /**
   * @return {ProbeEventAcknowledgedAt|null}
   */
  get acknowledgedAt() {
    return this._acknowledgedAt;
  }

  /**
   * @return {ProbeEventResolvedAt|null}
   */
  get resolvedAt() {
    return this._resolvedAt;
  }

  get ellapsedTime() {
    if (!this.isEnded()) {
      return 0;
    }

    return this._resolvedAt._value.getTime() - this._startedAt._value.getTime();
  }

  get beforeActionTime() {
    if (!this._acknowledgedAt) {
      return 0;
    }
    return this._acknowledgedAt._value.getTime() - this._startedAt._value.getTime();
  }

  get beforeRecoveryTime() {
    if (!this.isEnded()) {
      return 0;
    }

    if (!this._acknowledgedAt) {
      return this._resolvedAt._value.getTime() - this._startedAt._value.getTime();
    }

    return this._resolvedAt._value.getTime() - this._acknowledgedAt._value.getTime();
  }

  isStillOngoing() {
    return !this.isEnded();
  }

  isEnded() {
    return !!this._resolvedAt;
  }

  /**
   * @param {ProbeEventLifeTime} lifeTime
   * @return {0 | 1 | -1}
   */
  compareToStartedAt(lifeTime) {
    return this._startedAt.compareTo(lifeTime._startedAt);
  }

  /**
   * @param {ProbeEventLifeTime} lifeTime
   * @return {0 | 1 | -1}
   */
  compareToResolvedAt(lifeTime) {
    if (!this.isEnded() || !lifeTime.isEnded()) {
      return 0;
    }

    return this._resolvedAt.compareTo(lifeTime._resolvedAt);
  }

  /**
   * @param {DateValueObject} date
   * @returns {boolean}
   */
  isPartOfTheDay(date) {
    if (!this._resolvedAt) {
      return date.isAfterOrEqual(this._startedAt.withoutTime());
    }

    return date.isBetweenTwoDates(this._startedAt.withoutTime(), this._resolvedAt.withEndOfDayTime());
  }

  /**
   * @param {DateValueObject} date
   * @returns {boolean}
   */
  isPartOfTheISO8601Week(date) {
    if (!this._resolvedAt) {
      return this._startedAt.hasSameISO8601Week(date);
    }

    return this._startedAt.hasSameISO8601Week(date) || this._resolvedAt?.hasSameISO8601Week(date);
  }

  /**
   * @param {DateValueObject} date
   * @returns {boolean}
   */
  isPartOfTheMonth(date) {
    if (!this._resolvedAt) {
      return this._startedAt.hasSameMonth(date) || date.isAfterOrEqual(this._startedAt.withoutTime());
    }

    return this._startedAt.hasSameMonth(date) || this._resolvedAt?.hasSameMonth(date);
  }
}
