export class FindAllProbes {
  /**
   * @param {ProbesRepository} probesRepository
   * @private
   */
  _probesRepository;

  /**
   * @param {ProbesRepository} probesRepository
   */
  constructor(probesRepository) {
    this._probesRepository = probesRepository;
  }

  async execute() {
    return await this._probesRepository.findAll();
  }
}
