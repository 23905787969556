import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import some from 'lodash/some';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import {
  getTaxRates,
  getPrice,
  getProduct,
  getPaymentIntent,
  getProducts,
  getProductPrices,
  getPayments,
  patchProductMetadata,
  postCreateSkyloudPaymentIntent,
  getSkyloudPaymentMethods,
  getSkyloudProfile,
  postSkyloudDetachPaymentMethod,
  getSkyloudPaymentMethodMandate,
} from '@/modules/payments/payments.api.js';

const stateData = {
  prices: [],
  hasMorePrices: false,
  products: [],
  hasMoreProducts: false,
  taxRates: [],
  hasMoreTaxRates: false,
  paymentIntents: [],
  hasMorePayments: false,
  payments: [],
  skyloud: {
    profile: undefined,
    paymentMethods: [],
    hasMorePaymentMethods: false,
  },
};

const getters = {
  getPrice:
    (state) =>
    ({ priceId }) =>
      find(state.prices, { id: priceId }),
  getProduct:
    (state) =>
    ({ productId }) =>
      find(state.products, { id: productId }),
  getTaxRate:
    (state) =>
    ({ taxRateId }) =>
      find(state.taxRates, { id: taxRateId }),
  getPaymentIntent:
    (state) =>
    ({ paymentIntentId }) =>
      find(state.paymentIntents, { id: paymentIntentId }),
  getProducts: (state) => state.products,
  getPrices: (state) => state.prices,
  getTaxRates: (state) => state.taxRates,
  getPayments: (state) => state.payments,
  getProductPrices:
    (state) =>
    ({ productId }) =>
      filter(state.prices, { productId }),
  getSkyloudProfile: (state) => get(state, 'skyloud.profile'),
  getSkyloudPaymentMethods: (state) =>
    map(state.skyloud.paymentMethods, (paymentMethod) => {
      const defaultPayementMethod = get(state, 'skyloud.profile.invoiceSettings.defaultPaymentMethod');
      if (!defaultPayementMethod || paymentMethod.id !== defaultPayementMethod) {
        return paymentMethod;
      }
      return {
        ...paymentMethod,
        isDefault: true,
      };
    }),
};

const mutations = {
  resetState(state) {
    Object.assign(state, stateData);
  },
  saveProduct(state, { productId, product }) {
    state.products = [...state.products, { id: productId, ...product }];
  },
  savePrice(state, { priceId, price }) {
    state.prices = [...state.prices, { id: priceId, ...price }];
  },
  savePaymentIntent(state, { paymentIntentId, paymentIntent }) {
    state.paymentIntents = [...state.paymentIntents, { id: paymentIntentId, ...paymentIntent }];
  },
  saveProducts(state, { hasMore, products }) {
    state.hasMoreProducts = hasMore;
    state.products = [...filter(state.products, ({ id }) => !some(products, { id })), ...products];
  },
  savePrices(state, { hasMore, prices }) {
    state.hasMorePrices = hasMore;
    state.prices = [...filter(state.prices, ({ id }) => !some(prices, { id })), ...prices];
  },
  saveTaxRates(state, { hasMore, taxRates }) {
    state.hasMorePrices = hasMore;
    state.taxRates = [...filter(state.taxRates, ({ id }) => !some(taxRates, { id })), ...taxRates];
  },
  savePayments(state, { hasMore, payments }) {
    state.hasMorePayments = hasMore;
    state.payments = payments;
  },
  updateProductMetadata(state, { productId, metadata }) {
    const productIndex = findIndex(state.products, { id: productId });
    if (productIndex === -1) {
      return;
    }
    state.products[productIndex] = {
      ...state.products[productIndex],
      metadata,
    };
  },
  addPayment(state, { payment }) {
    state.payments = [payment, ...state.payments];
  },
  removePayment(state, { paymentId }) {
    state.payments = reject(state.payments, { _id: paymentId });
  },
  saveSkyloudPaymentMethods(state, { paymentMethods, hasMore }) {
    state.skyloud.paymentMethods = paymentMethods;
    state.skyloud.hasMorePaymentMethods = hasMore;
  },
  saveSkyloudProfile(state, { profile }) {
    state.skyloud.profile = profile;
  },
  removeSkyloudPaymentMethod(state, { paymentMethodId }) {
    state.skyloud.paymentMethods = reject(state.skyloud.paymentMethods, { id: paymentMethodId });
  },
};

const actions = {
  async fetchProduct({ commit, state }, { productId }) {
    if (some(state.products, { id: productId })) {
      return;
    }
    const product = await getProduct({ productId });
    commit('saveProduct', { productId, product });
  },
  async fetchPrice({ commit, state }, { priceId }) {
    if (some(state.prices, { id: priceId })) {
      return;
    }
    const price = await getPrice({ priceId });
    commit('savePrice', { priceId, price });
  },
  async fetchPaymentIntent({ commit, state }, { paymentIntentId }) {
    if (some(state.paymentsIntents, { id: paymentIntentId })) {
      return;
    }
    const paymentIntent = await getPaymentIntent({ paymentIntentId });
    commit('savePaymentIntent', { paymentIntentId, paymentIntent });
  },
  async fetchSkyloudPaymentMethodMandate(_, { paymentMethodId }) {
    const mandate = await getSkyloudPaymentMethodMandate({ paymentMethodId });
    return mandate;
  },
  async fetchProducts({ commit }) {
    const { hasMore, products } = await getProducts();
    commit('saveProducts', { hasMore, products });
  },
  async fetchProductPrices({ commit, state }, { productId }) {
    if (some(state.prices, { productId })) {
      return;
    }
    const { hasMore, prices } = await getProductPrices({ productId });
    commit('savePrices', { hasMore, prices });
  },
  async fetchTaxRates({ commit, state }) {
    if (!isEmpty(state.taxRates)) {
      return;
    }
    const { hasMore, taxRates } = await getTaxRates();
    commit('saveTaxRates', { hasMore, taxRates });
  },
  async fetchPayments({ commit, state }) {
    if (!isEmpty(state.payments)) {
      return;
    }
    const { hasMore, payments } = await getPayments();
    commit('savePayments', { hasMore, payments });
  },
  async updateProductMetadata({ commit }, { productId, metadata }) {
    await patchProductMetadata({ productId, metadata });
    commit('updateProductMetadata', { productId, metadata });
  },
  async createSkyloudInitialPaymentIntentSecret() {
    const { clientSecret } = await postCreateSkyloudPaymentIntent();
    return clientSecret;
  },
  async fetchSkyloudProfile({ commit, state }) {
    if (state.skyloud.profile) {
      return;
    }
    const profile = await getSkyloudProfile();

    commit('saveSkyloudProfile', { profile });
  },
  async fetchSkyloudPaymentMethods({ commit }) {
    const { hasMore, paymentMethods } = await getSkyloudPaymentMethods();
    commit('saveSkyloudPaymentMethods', { hasMore, paymentMethods });
  },
  async detachSkyloudPaymentMethod({ commit }, { paymentMethodId }) {
    commit('removeSkyloudPaymentMethod', { paymentMethodId });
    await postSkyloudDetachPaymentMethod({ paymentMethodId });
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
