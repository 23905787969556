export class InconsistentProbeEvent extends Error {
  static missingId() {
    return new this('Missing required event id');
  }

  static missingProbeId() {
    return new this('Missing required probe id');
  }

  static missingLifeTime() {
    return new this('Missing required event life time');
  }

  static missingName() {
    return new this('Missing required probe name');
  }

  static missingType() {
    return new this('Missing required probe type');
  }
}
