<template>
  <div class="flex flex-col space-y-2 skylist text-primary">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.skylist a {
  &:hover {
    @apply bg-gray-50;
  }
  &.router-link-exact-active {
    @apply bg-dark-blue-50;
  }
}
</style>
