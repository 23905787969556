<template>
  <div class="flex gap-4 py-4 items-center list-none">
    <AvailabilitySummaryHeaderWidget
      :title="probe.name?.toString()"
      :status="filledProbeEvents.status"
      :last-available-at="filledProbeEvents.lastAvailableAt"
    />
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { ProbeEvents } from '@/modules/stats/availability/domain/probe-event/collection/probe-events.js';
import { availabilityInjectors } from '@/modules/stats/availability/infrastructure/presenters/availability-injectors.js';
import { Probe } from '@/modules/stats/availability/domain/probe/probe.js';
import AvailabilitySummaryHeaderWidget from '@/modules/stats/availability/infrastructure/presenters/organisms/widget/AvailabilitySummaryHeaderWidget.vue';

/**
 * @type {{ probeEvents: ProbeEvents }}
 */
const props = defineProps({
  probeEvents: {
    type: ProbeEvents,
    required: true,
  },
  probe: {
    type: Probe,
    required: true,
  },
});
/** @type {DateProvider} */
const dateProvider = inject(availabilityInjectors.dateProvider);
const filledProbeEvents = computed(() => props.probeEvents.fillEventsForMonths(dateProvider));
</script>
