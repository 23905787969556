import accountsApi from '@/modules/shared/api/accounts.js';
import projectsApi from '@/modules/shared/api/projects.js';
import cloudsApi from '@/modules/shared/api/clouds.js';
import * as utils from '@/utils/index.js';
import pickBy from 'lodash/pickBy';
import qs from 'qs';
import { TASKS_FETCH_LIMIT } from '../projects/projects.constants.js';

export const adminAccountsAxios = accountsApi();
export const adminProjectsAxios = projectsApi();
export const adminCloudsAxios = cloudsApi();

export async function accountsGetOauthUrl() {
  const { data } = await adminAccountsAxios.get('/oauth2/url');
  const { authUrl } = data;
  return authUrl;
}

export async function accountsPostOauthCodeCreateToken({ code }) {
  const { data } = await adminAccountsAxios.post('/oauth2/token', { code });
  return data;
}

export async function accountsGetAdminUser({ appId }) {
  const { data } = await adminAccountsAxios.get(`/skyloud/apps/${appId}/user`);
  return data;
}

export async function accountsGetAdminUserApps({ search = undefined, limit = undefined } = {}) {
  const { data } = await adminAccountsAxios.get('/skyloud/user/apps', { params: { search, limit } });
  const { list: apps, hasMore } = data;
  return { apps, hasMore };
}

export async function accountsGetUser({ appId, userId }) {
  const { data } = await adminAccountsAxios.get(`/skyloud/apps/${appId}/users/${userId}`);
  return data;
}

export async function accountsPostRegisterAdminUserToApp({ appId }) {
  return adminAccountsAxios.post(`/skyloud/user/apps/${appId}/register`);
}

export async function accountsPostCreateAppUserToken({ appId }) {
  const { data } = await adminAccountsAxios.post(`/skyloud/user/apps/${appId}/token`);

  return data;
}

export async function accountsGetAppById({ appId }) {
  const { data } = await adminAccountsAxios.get(`/skyloud/apps/${appId}`);

  return data;
}

export async function adminFetchMilestones({ skip, limit = 15, search = undefined, startAt = undefined, endAt = undefined }) {
  const { data } = await adminProjectsAxios.get('/skyloud/milestones', { params: { skip, limit, search, startAt, endAt } });

  return data;
}

export async function accountsPatchAppAdminMetadata({ appId, ...adminMetadata }) {
  await adminAccountsAxios.patch(`/skyloud/apps/${appId}/metadata`, { ...adminMetadata });
}

export async function accountsPostDisableApp({ appId }) {
  await adminAccountsAxios.post(`/skyloud/apps/${appId}/disable`);
}

export async function accountsPostDispatchEvent({ appId, eventName, eventPayload }) {
  await adminAccountsAxios.post(`/skyloud/apps/${appId}/dispatch`, { eventName, eventPayload });
}

export async function accountsPatchUpdateApp({ appId, logo, primaryColor, owner, subscriptions, metadata, privileged }) {
  await adminAccountsAxios.patch(`/skyloud/apps/${appId}`, { logo, primaryColor, owner, subscriptions, metadata, privileged });
}

export async function projectsGetDeletedTasks() {
  const { data } = await adminProjectsAxios.get('/skyloud/tasks/deleted');

  return data;
}

export async function accountsPatchAppFeature({ appId, featureId, enabled }) {
  const { data } = await adminAccountsAxios.patch(`/skyloud/apps/${appId}/feature`, { featureId, enabled });

  return data;
}

export async function accountsGetAllIds() {
  const { data } = await adminAccountsAxios.get('/skyloud/user/ids');

  return data;
}

export async function accountsGetSkyloudUsers() {
  const { data } = await adminAccountsAxios.get('/skyloud/users/skyloud');
  return data;
}

export async function projectsGetLabelsByAppId({ appId }) {
  const { data } = await adminProjectsAxios.get(`/skyloud/labels/${appId}`);

  return data;
}

export async function projectsGetMilestones({ skip, limit = 15, search = undefined }) {
  const { data } = await adminProjectsAxios.get(`/skyloud/milestones`, { params: { skip, limit, search } });

  return data;
}

export async function projectsPatchUpdateAppLabel({ labelId, name, type, backgroundColor, rules, dependencies }) {
  await adminProjectsAxios.patch(`/skyloud/labels/${labelId}`, {
    name,
    backgroundColor,
    type,
    rules,
    dependencies,
  });
}

export async function projectsPostAppLabel({ appId, name, type, backgroundColor, rules, disabledAt, dependencies, dependsOn }) {
  const { data } = await adminProjectsAxios.post(`/skyloud/labels/${appId}/`, {
    name,
    backgroundColor,
    type,
    rules,
    disabledAt,
    dependencies,
    dependsOn,
  });

  return data;
}

export async function projectsDeleteAppLabel({ labelId }) {
  await adminProjectsAxios.delete(`/skyloud/labels/${labelId}`);
}

export async function projectsDisableAppLabel({ labelId }) {
  const { data } = await adminProjectsAxios.patch(`/skyloud/labels/${labelId}/disable`);

  return data;
}

export async function projectsGetAllAssignedTasks({ appIds, skip = 0, limit = TASKS_FETCH_LIMIT, search, filters, sort, signal } = {}) {
  const params = pickBy({
    skip,
    limit,
    search,
    filters,
    sort,
    appIds,
  });
  const { data } = await adminProjectsAxios.post('/skyloud/tasks', { ...params }, { signal });
  const { hasMore = false, list: tasks = [] } = data;
  return { hasMore, tasks };
}

export async function projectsGetTasks({ name, filters }) {
  const queryParams = pickBy({ name, filters });
  const queryString = qs.stringify(queryParams, { arrayFormat: 'brackets' });
  const { data } = await adminProjectsAxios.get(`/skyloud/backlog/tasks?${queryString}`);
  return data;
}

export async function projectsFindTasksByIds({ appIds, tasksIds }) {
  const params = pickBy({
    filters: { _id: tasksIds },
    appIds,
  });
  const { data } = await adminProjectsAxios.post('/skyloud/tasks/find', { ...params });
  const { hasMore = false, list: tasks = [] } = data;
  return { hasMore, tasks };
}

export async function projectsGetMonthCurrentWorklogs({ date, userIds }) {
  const { data } = await adminProjectsAxios.get('/skyloud/tasks/worklogs', { params: { date, userIds } });
  return data;
}

export async function adminGetUsers({ limit, skip, search, appId }) {
  const query = utils.queryString({ limit, skip, search });
  const { data } = await adminAccountsAxios.get(`/skyloud/users/${appId}?${query}`);
  return {
    list: data.list,
    total: data.total,
  };
}

export async function accountsUsersFilterSearch({ search }) {
  const query = utils.queryString({ search });
  const { data } = await adminAccountsAxios.get(`/skyloud/users/filter/search?${query}`);
  return data;
}

export async function projectsGetAllFilters() {
  const { data } = await adminProjectsAxios.get('/skyloud/labels');
  return data;
}

export async function projectsPostCreateReport({ parts, date, userIds, observation }) {
  const { data } = await adminProjectsAxios.post('/skyloud/worklogs/report', { parts, date, userIds, observation });
  return data;
}

export async function projectsPatchUpdateReport({ parts, observation, reportId }) {
  const { data } = await adminProjectsAxios.patch(`/skyloud/worklogs/report/${reportId}`, { parts, observation });
  return data;
}

export async function projectsGetMonthWorklogsReports({ date, userIds }) {
  const { data } = await adminProjectsAxios.get('/skyloud/tasks/worklogs/reports', { params: { date, userIds } });
  return data;
}

export async function adminGetBacklogPresets() {
  const { data } = await adminProjectsAxios.get('/skyloud/backlog/presets');
  return data;
}

export async function adminPostBacklogPreset({ name, filters, stats, columns }) {
  const { data } = await adminProjectsAxios.post('/skyloud/backlog/presets', { name, filters, stats, columns });
  return data;
}

export async function adminPatchBacklogPreset({ presetId, name, filters, stats, columns }) {
  const { data } = await adminProjectsAxios.patch(`/skyloud/backlog/presets/${presetId}`, { name, filters, stats, columns });
  return data;
}

export async function adminDeleteBacklogPreset({ presetId }) {
  await adminProjectsAxios.delete(`/skyloud/backlog/presets/${presetId}`);
}

export async function adminGetBacklogsStats({ appIds, filters, stats }) {
  const queryParams = pickBy({
    filters,
    appIds,
    stats,
  });
  const queryString = qs.stringify(queryParams, { arrayFormat: 'brackets' });
  const { data } = await adminProjectsAxios.get(`/skyloud/backlog/stats?${queryString}`);

  return data;
}

export async function adminProjectsGetAssignedTasksStats({ filters }) {
  const queryParams = pickBy({
    filters,
  });

  const queryString = qs.stringify(queryParams, { arrayFormat: 'brackets' });
  const { data } = await adminProjectsAxios.get(`/skyloud/tasks/stats?${queryString}`);

  return data;
}

export async function adminPatchTaskMetadata({ taskId, metadata }) {
  const { data } = await adminProjectsAxios.patch(`/skyloud/tasks/${taskId}/metadata`, { metadata });
  return data;
}

export async function adminPostCreateMilestone({ sprint }) {
  const { data } = await adminProjectsAxios.post('/skyloud/milestones', { sprint });
  return data;
}

export async function projectsGetTask({ taskId }) {
  const { data } = await adminProjectsAxios.get(`/skyloud/tasks/${taskId}`);

  return data;
}

export async function adminGetActiveIncidents() {
  const { data } = await adminCloudsAxios.get(`/skyloud/incidents`);
  const { list: incidents, hasMore } = data;
  return { incidents, hasMore };
}

export async function adminGetMonitor({ monitorId }) {
  const { data } = await adminCloudsAxios.get(`/skyloud/monitors/${monitorId}`);

  return data;
}
