import map from 'lodash/map';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import dayjs from 'dayjs';
import { SPRINT_DURATION_V2, SPRINT_V2_START_DATE } from './admin.constants.js';

export function insertAt(arr, index, element) {
  return [...arr.slice(0, index), element, ...arr.slice(index)];
}

export function getCurrentSprintNumber() {
  const today = dayjs();

  const ellapsedTimeSinceSprintV2Started = today.diff(SPRINT_V2_START_DATE, 'millisecond');
  const nbOfSprintsV2 = Math.ceil(ellapsedTimeSinceSprintV2Started / SPRINT_DURATION_V2);

  const nbOfSprintsV1BeforeV2Started = 30;
  return nbOfSprintsV1BeforeV2Started + nbOfSprintsV2;
}

export function getStartDateOfSprint(sprint) {
  const currentSprint = getCurrentSprintNumber();
  const searchedSprint = sprint || currentSprint;

  const offset = searchedSprint - 30;

  return dayjs(SPRINT_V2_START_DATE).add((offset - 1) * SPRINT_DURATION_V2, 'milliseconds');
}

export function getEndDateOfSprint(sprint) {
  const searchedSprint = sprint || getCurrentSprintNumber();
  return getStartDateOfSprint(searchedSprint).add(SPRINT_DURATION_V2, 'milliseconds').subtract(1, 'day').set('hour', 23).set('minute', 59).set('second', 59);
}

export function hasRegisteredWorklogs({ workLogs, userIds = [] }) {
  const sprintStartDate = getStartDateOfSprint().toDate();

  return some(workLogs, (worklog) => {
    const isInCurrentSprint = dayjs(worklog.stoppedAt).isSameOrAfter(sprintStartDate);
    return includes(userIds, worklog.userId) && isInCurrentSprint;
  });
}

export function replaceMarkdownCheckboxes(markdownText, newTodosDescription) {
  const sentences = markdownText.trim();
  const withoutTodos = sentences.replaceAll(/( *)(- \[[x|X|\S| ]\]) (.+)\n?/g, '').trim('');

  if (includes(sentences, '## TODO')) {
    return withoutTodos.replace('## TODO', `## TODO\n\n${newTodosDescription}`);
  }

  return `${withoutTodos}\n\n${newTodosDescription}`;
}
export function parseMarkdownCheckboxes(markdownText) {
  const sentences = markdownText.trim().split('\n');

  const parseLines = (lines, currentIndent) => {
    let shouldBreak = false;
    return lines
      .map((line, index) => {
        const match = line.match(/^( *)(- \[[x|X|\S| ]\]) (.+)$/);

        if (!match) return undefined;

        const [, whitespaces, , text] = match;
        const isChecked = includes(line.toLowerCase(), '[x]');
        const indent = whitespaces.length;
        const item = { text, state: isChecked, children: [] };

        if (shouldBreak || indent < currentIndent) {
          shouldBreak = true;
          return undefined;
        }

        if (indent === currentIndent) {
          item.children = parseLines(lines.slice(index + 1), currentIndent + 2);
          return item;
        }

        return undefined;
      })
      .filter(Boolean);
  };

  return parseLines(sentences, 0);
}

export function countMarkdownCheckboxes(markdownString) {
  const regexUnchecked = /(?: ){0,4}-(?:\s| )*\[(?:\s| )?\].+/g;
  const regexChecked = /(?: ){0,4}-(?:\s| )*\[(?:x|X)\].+/g;

  const uncheckedMatches = `${markdownString}`.match(regexUnchecked);
  const checkedMatches = `${markdownString}`.match(regexChecked);

  const uncheckedNb = (uncheckedMatches && uncheckedMatches.length) || 0;
  const checkedNb = (checkedMatches && checkedMatches.length) || 0;
  const totalCheckNb = uncheckedNb + checkedNb;

  return {
    uncheckedNb,
    checkedNb,
    totalCheckNb,
  };
}

export function getTaskProgress({ description }) {
  const { totalCheckNb, checkedNb } = countMarkdownCheckboxes(description);

  if (totalCheckNb === 0) return 0;

  return (checkedNb / totalCheckNb) * 100;
}

export function workLogsToEvents(workLogs) {
  const workLogsEvents = reduce(
    workLogs,
    (acc, task) => {
      const events = map(task.workLogs, (worklog) => ({
        slug: task.taskId,
        taskId: task._id,
        appId: task.appId,
        title: task.name,
        userId: worklog.userId,
        note: worklog.note,
        startedAt: worklog.startedAt,
        stoppedAt: worklog.stoppedAt,
        pausedAt: worklog.pausedAt,
        pauseTimeInMs: worklog.pauseTimeInMs,
      }));

      return [...acc, ...events];
    },
    [],
  );

  const sortedEvents = workLogsEvents.slice().sort((a, b) => {
    if (new Date(a?.startedAt).getTime() === new Date(b?.startedAt).getTime()) {
      return new Date(a?.stoppedAt).getTime() - new Date(b?.stoppedAt).getTime();
    }
    return new Date(a?.startedAt).getTime() - new Date(b?.startedAt).getTime();
  });

  return sortedEvents;
}

export function hasActiveWorklogs({ workLogs, userIds }) {
  return !!some(workLogs, (workLog) => !workLog.stoppedAt && userIds.includes(workLog.userId));
}
