import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import flow from 'lodash/flow';
import fpMap from 'lodash/fp/map';
import reduce from 'lodash/reduce';
import fpJoin from 'lodash/fp/join';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import dropWhile from 'lodash/dropWhile';
import fpDropWhile from 'lodash/fp/dropWhile';
import dropRightWhile from 'lodash/dropRightWhile';
import fpDropRightWhile from 'lodash/fp/dropRightWhile';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export function formatDate(date, format = 'DD MMMM YYYY') {
  if (!date) return '';
  return dayjs(date).format(format);
}

export function formatAmount(amountInCents) {
  const amount = amountInCents / 100;
  return amount.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  });
}

export function formatShortDate(date) {
  if (!date) return '';
  return dayjs(date).format('DD MMM');
}

const ONE_SECOND_IN_MS = 1000;
const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
export const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;
export const ONE_BUSINESS_DAY_IN_MS = ONE_HOUR_IN_MS * 7;
export const ONE_SKYLOUD_DAY_IN_MS = ONE_HOUR_IN_MS * 8;

export const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;

const timeSpentOptions = (isSkyloud) => [
  { time: isSkyloud ? ONE_SKYLOUD_DAY_IN_MS : ONE_BUSINESS_DAY_IN_MS, unit: 'j' },
  { time: ONE_HOUR_IN_MS, unit: 'h' },
  { time: ONE_MINUTE_IN_MS, unit: 'm' },
  { time: ONE_SECOND_IN_MS, unit: 's' },
];

const realTimeSpentOptions = [
  { time: ONE_DAY_IN_MS, unit: 'j' },
  { time: ONE_HOUR_IN_MS, unit: 'h' },
  { time: ONE_MINUTE_IN_MS, unit: 'm' },
  { time: ONE_SECOND_IN_MS, unit: 's' },
];
function chainTimeUnits(acc, nextUnitInMs) {
  const timeUnit = nextUnitInMs.unit === 's' ? Math.round(acc.timeRest / nextUnitInMs.time) : Math.floor(acc.timeRest / nextUnitInMs.time);

  return {
    timeRest: acc.timeRest - timeUnit * nextUnitInMs.time,
    timeBlockUnits: [...acc.timeBlockUnits, { time: timeUnit, unit: nextUnitInMs.unit }],
  };
}

function dropEmptyTime({ time }) {
  return time === 0;
}

function combineTimeUnit({ time, unit }) {
  return `${time}${unit}`;
}

export function formatRealDurationFromMs(timeInMs, minPrecisionUnit = 'm', maxPrecisionUnit = 'h') {
  const timeSpentConfig = dropRightWhile(realTimeSpentOptions, ({ unit }) => unit !== minPrecisionUnit);
  const timeUnits = dropWhile(timeSpentConfig, ({ unit }) => unit !== maxPrecisionUnit);

  const timeUnitsAggregator = reduce(timeUnits, chainTimeUnits, {
    timeRest: timeInMs,
    timeBlockUnits: [],
  });

  const timeHumanized = flow([fpDropRightWhile(dropEmptyTime), fpDropWhile(dropEmptyTime), fpMap(combineTimeUnit), fpJoin(' ')])(timeUnitsAggregator.timeBlockUnits);

  if (isEmpty(timeHumanized)) return '0s';

  return timeHumanized;
}

export function formatDurationFromMs(timeInMs, minPrecisionUnit = 'm', maxPrecisionUnit = 'h', isSkyloud = false) {
  if (!isNumber(timeInMs) || timeInMs < 0) {
    return '0s';
  }

  const timeSpentConfig = dropRightWhile(timeSpentOptions(isSkyloud), ({ unit }) => unit !== minPrecisionUnit);
  const timeUnits = dropWhile(timeSpentConfig, ({ unit }) => unit !== maxPrecisionUnit);

  const timeUnitsAggregator = reduce(timeUnits, chainTimeUnits, {
    timeRest: timeInMs,
    timeBlockUnits: [],
  });

  const timeHumanized = flow([fpDropRightWhile(dropEmptyTime), fpDropWhile(dropEmptyTime), fpMap(combineTimeUnit), fpJoin(' ')])(timeUnitsAggregator.timeBlockUnits);

  if (isEmpty(timeHumanized)) return '0s';

  return timeHumanized;
}
export function sumDurationToMilliseconds(days = 0, hours = 0, minutes = 0) {
  return days * (1000 * 60 * 60 * 8) + hours * (1000 * 60 * 60) + minutes * (1000 * 60);
}

export function durationPartsFromMs(timeInMs, minPrecisionUnit = 'm', maxPrecisionUnit = 'h', isSkyloud = true) {
  if (!isNumber(timeInMs) || timeInMs < 0) {
    return undefined;
  }

  const timeSpentConfig = dropRightWhile(timeSpentOptions(isSkyloud), ({ unit }) => unit !== minPrecisionUnit);
  const timeUnits = dropWhile(timeSpentConfig, ({ unit }) => unit !== maxPrecisionUnit);

  const timeUnitsAggregator = reduce(timeUnits, chainTimeUnits, {
    timeRest: timeInMs,
    timeBlockUnits: [],
  });

  const timeHumanized = reduce(timeUnitsAggregator.timeBlockUnits, (acc, timeBlock) => ({ ...acc, [timeBlock.unit]: timeBlock.time }), {});

  if (isEmpty(timeHumanized)) return undefined;

  return timeHumanized;
}

export function formatBytes(bytes, k = 1024, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function roundEllapsedTime(ellapsedTime, stepInMs) {
  if (ellapsedTime % stepInMs === 0) return ellapsedTime;
  return ellapsedTime + (stepInMs - (ellapsedTime % stepInMs));
}

export function timeFromNow(date, withoutSuffix = false) {
  return dayjs(date).fromNow(withoutSuffix);
}
