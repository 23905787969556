import { ProbeEventsDayState } from '@/modules/stats/availability/domain/probe-event/collection/day-states/probe-events-day-state.js';
import { UnavailableStatus } from '@/modules/stats/availability/domain/probe-event/status/unavailable-status.js';
import { AvailableStatus } from '@/modules/stats/availability/domain/probe-event/status/available-status.js';
import { MaintenanceStatus } from '@/modules/stats/availability/domain/probe-event/status/maintenance-status.js';

/**
 * @implements {ProbeEventsDayState}
 */
export class ProbeEventsDayStatePast extends ProbeEventsDayState {
  getDayStatus(eventsOfTheDay) {
    if (eventsOfTheDay.length === 0) {
      return new AvailableStatus();
    }

    const hasOnlyMaintenanceEvents = eventsOfTheDay.reduce((acc, event) => (event.type === 'MAINTENANCE' ? acc : false), true);
    if (hasOnlyMaintenanceEvents) {
      return new MaintenanceStatus();
    }

    return new UnavailableStatus();
  }
}
