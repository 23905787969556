<template>
  <div
    class="flex flex-col gap-4"
    :class="{
      'mt-8': !isAdminSkyloudUser,
    }"
  >
    <div
      class="thread-writing-area"
      :class="{ 'pending-thread': isTaskAwaitingCustomerAnswer && isIntersectingDisabledBanner }"
    >
      <Transition>
        <div
          v-if="isTaskAwaitingCustomerAnswer && !isAdminSkyloudUser"
          ref="threadPendingMessage"
          class="thread-pending-msg"
          :class="{ 'is-intersecting': isIntersectingDisabledBanner }"
        >
          <span>{{ $t('components.chats.thread.pendingStatus') }}</span>
        </div>
      </Transition>
      <form
        class="relative flex w-full gap-1 py-1 pl-2 pr-1 bg-white border rounded-md border-dark-blue-100"
        :class="{ 'border-error border-opacity-20': isPrivate }"
        @submit.prevent="handleSubmit"
      >
        <div class="relative flex items-center flex-1 w-full min-w-0 py-1">
          <span
            v-show="!get(form, 'text')"
            class="absolute left-0 font-light text-gray-400"
          >
            Aa
          </span>
          <div
            ref="messageInputRef"
            contenteditable="true"
            class="flex-1 h-full break-words whitespace-pre-wrap outline-none select-text max-w-none overflow-hidden"
            @input="handleTextInput"
            @keydown="handleKeyDownActions"
          />
        </div>
        <label
          class="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white px-3.5 py-1 text-gray-500 transform transition-all disabled:opacity-40 outline-none focus:outline-none self-end"
          for="tread-upload"
          :disabled="isUploadingFiles || sendingMessage"
          :class="{
            'hover:bg-green-400 hover:scale-105': form.text && form.text !== '',
          }"
        >
          <input
            id="tread-upload"
            type="file"
            name="upload-thread"
            class="hidden"
            @change="handleThreadFilesUpload"
          />
          <FontAwesomeIcon :icon="faPaperclip" />
        </label>
        <button
          type="submit"
          class="rounded-md hover:bg-opacity-80 px-3.5 py-1 text-white transform transition-all disabled:opacity-40 outline-none focus:outline-none self-end"
          :disabled="!form.text || form.text === '' || sendingMessage"
          :class="{
            'hover:bg-green-400 hover:scale-105': form.text && form.text !== '',
            'bg-primary': !isPrivate,
            'bg-error': isPrivate,
          }"
        >
          <FontAwesomeIcon :icon="faPaperPlane" />
        </button>
      </form>
      <div
        v-if="String(form.text).length > 1500"
        class="text-xs"
        :class="{
          'text-yellow-600': String(form.text).length >= 2000 && String(form.text).length <= 2500,
          'text-red-600': String(form.text).length > 2500,
        }"
      >
        {{ $t('components.chats.thread.charactersUsed') }} : {{ String(form.text).length }} / 2500
      </div>
    </div>
    <div
      v-if="messages.length > 0"
      ref="listMessagesRef"
      class="flex flex-col gap-5"
    >
      <div
        v-for="(group, groupIndex) of groupedMessagesByDate"
        :key="groupIndex"
        class="flex flex-col items-start gap-3"
      >
        <div
          v-for="(message, index) of group"
          :key="message._id"
          class="flex flex-col items-start w-full"
        >
          <span
            v-if="index === 0"
            class="message-separator"
          >
            {{ formatDate(message.createdAt) }}
          </span>
          <ThreadSystemMessage
            v-if="message.kind === 'system'"
            :message="message"
          />
          <ThreadMessage
            v-else
            :message="message"
            :is-private="isPrivate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, nextTick, onMounted, onUnmounted, reactive, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import prismjs from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import get from 'lodash/get';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import { formatDate } from '@/utils/format.js';
import { useNotify } from '@/plugins/notify/index.js';
import { faPaperPlane, faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import ThreadMessage from '@/modules/chats/components/ThreadMessage.vue';
import ThreadSystemMessage from '@/modules/chats/components/ThreadSystemMessage.vue';
import { TASK_PENDING_STATUS } from '@/modules/projects/projects.constants.js';

export default {
  components: {
    ThreadMessage,
    ThreadSystemMessage,
  },
  props: {
    taskId: {
      type: String,
      default: () => undefined,
    },
    threadId: {
      type: String,
      default: () => undefined,
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['active'],
  setup(props, { emit }) {
    const store = useStore();
    const notify = useNotify();

    const messageInputRef = ref(null);
    const listMessagesRef = ref(null);
    const threadPendingMessage = ref(null);

    const getThreadMessages = store.getters['chats/getThreadMessages'];
    const getTask = store.getters['projects/getTask'];
    const hasRole = store.getters['auth/hasRole'];

    const state = reactive({
      form: {
        text: undefined,
      },
      task: computed(() => getTask({ taskId: props.taskId }) || {}),
      isAdminSkyloudUser: computed(() => hasRole('admin:skyloud')),
      isWaitingEstimationValidation: computed(
        () => get(state.task, 'estimatedTime') > 0 && !get(state.task, 'estimatedTimeAcceptedAt') && !get(state.task, 'estimatedTimeRefusedAt'),
      ),
      isTaskAwaitingCustomerAnswer: computed(
        () => !!get(state.task, 'pendingAt') && state.task.pendingStatus === TASK_PENDING_STATUS.CUSTOMER && !get(state, 'isWaitingEstimationValidation'),
      ),
      messages: computed(() => getThreadMessages({ threadId: props.threadId })),
      groupedMessagesByDate: computed(() =>
        reduce(
          state.messages,
          (acc, message) => {
            const dateKey = formatDate(message.createdAt);
            const currentGroup = get(acc, dateKey, []);
            return {
              ...acc,
              [dateKey]: [...currentGroup, message],
            };
          },
          {},
        ),
      ),
      sendingMessage: false,
      isUploadingFiles: false,
      isIntersectingDisabledBanner: false,
      intersectionObserver: undefined,
    });

    function updatePrismRender() {
      if (!listMessagesRef.value || state.messages.length === 0) {
        return;
      }
      nextTick(() => {
        try {
          prismjs.highlightAllUnder(listMessagesRef.value);
        } catch (err) {
          console.debug('Unable to highlight all under');
        }
      });
    }

    function handleEditorClipboardPaste(event) {
      event.preventDefault();

      const text = (event.originalEvent || event).clipboardData.getData('text/plain');

      document.execCommand('insertHTML', false, text);
    }

    function handleObserverIntersectionEntries(entries) {
      forEach(entries, (entry) => {
        if (entry.isIntersecting && entry.target.classList.contains('thread-pending-msg')) {
          state.isIntersectingDisabledBanner = true;
        }
      });
    }

    function initIntersectionObserverForThreadPendingMessage() {
      try {
        state.isIntersectingDisabledBanner = false;
        if (state.intersectionObserver) {
          state.intersectionObserver.observe(threadPendingMessage.value);
          return;
        }

        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 1.0,
        };

        state.intersectionObserver = new IntersectionObserver(handleObserverIntersectionEntries, options);
        if (threadPendingMessage.value) {
          state.intersectionObserver.observe(threadPendingMessage.value);
        }
      } catch (err) {
        state.isIntersectingDisabledBanner = state.isTaskAwaitingCustomerAnswer;
      }
    }

    function destroyIntersectionObserver() {
      if (!state.intersectionObserver) {
        return;
      }
      state.intersectionObserver.disconnect();
      state.intersectionObserver = undefined;
    }

    onMounted(async () => {
      try {
        await store.dispatch('chats/fetchThreadMessages', {
          threadId: props.threadId,
        });
        updatePrismRender();

        nextTick(() => {
          messageInputRef.value?.addEventListener('paste', handleEditorClipboardPaste);
        });

        if (state.isTaskAwaitingCustomerAnswer) {
          nextTick(initIntersectionObserverForThreadPendingMessage);
        }
      } catch (err) {
        notify.error(err.message);
      }
    });

    onUnmounted(destroyIntersectionObserver);

    async function handleSubmit() {
      state.sendingMessage = true;
      try {
        const { text } = state.form;
        if (isEmpty(text)) {
          state.sendingMessage = false;
          return;
        }

        if (state.isTaskAwaitingCustomerAnswer && !state.isAdminSkyloudUser) {
          await store.dispatch('projects/updateTaskPendingStatus', { _id: state.task._id, isPending: false });
        }

        await store.dispatch('chats/sendMessage', {
          threadId: props.threadId,
          taskId: state.task.taskId,
          text,
          isPrivateThread: props.isPrivate,
        });
        state.form.text = '';
        messageInputRef.value.innerText = '';
      } catch (err) {
        notify.error(err.message);
      }
      state.sendingMessage = false;
    }

    async function handleThreadFilesUpload(event) {
      state.isUploadingFiles = true;
      try {
        const uploadedFiles = await store.dispatch('storages/uploadInternalFiles', { files: event.target.files });
        uploadedFiles.map((file) => {
          return store.dispatch('chats/sendMessage', {
            threadId: props.threadId,
            taskId: state.task.taskId,
            text: `{storages:fileId:${file._id}}`,
            isPrivateThread: props.isPrivate,
          });
        });
      } catch (err) {
        notify.error(err.message);
      }
      state.isUploadingFiles = false;
    }

    function handleTextInput(event) {
      state.form.text = String(event.target.innerText).trim();
    }

    function handleKeyDownActions({ code, metaKey, ctrlKey }) {
      if (code !== 'Enter') {
        return;
      }

      if (!ctrlKey && !metaKey) {
        return;
      }

      if (state.sendingMessage === true) {
        return;
      }

      handleSubmit();
    }

    watch(() => state.messages, updatePrismRender);

    watch(
      () => state.form.text,
      () => emit('active', state.form.text && state.form.text !== ''),
    );

    watch(
      () => pick(state.task, ['pendingAt']),
      () => {
        try {
          if (state.isAdminSkyloudUser) {
            return;
          }

          if (!state.task.pendingAt) {
            state.isIntersectingDisabledBanner = false;
            state.intersectionObserver?.disconnect();
            return;
          }

          if (state.task.pendingAt && state.intersectionObserver && threadPendingMessage.value) {
            nextTick(() => {
              try {
                state.intersectionObserver.observe(threadPendingMessage.value);
              } catch (err) {
                state.isIntersectingDisabledBanner = state.isTaskAwaitingCustomerAnswer;
              }
            });
            return;
          }

          nextTick(() => initIntersectionObserverForThreadPendingMessage());
        } catch (err) {
          state.isIntersectingDisabledBanner = state.isTaskAwaitingCustomerAnswer;
        }
      },
    );

    return {
      handleSubmit,
      handleTextInput,
      handleKeyDownActions,
      handleThreadFilesUpload,
      formatDate,
      messageInputRef,
      listMessagesRef,
      threadPendingMessage,
      faPaperPlane,
      faPaperclip,
      ...toRefs(state),
      get,
    };
  },
};
</script>

<style lang="scss" scoped>
.thread-writing-area {
  @apply relative flex flex-col items-end gap-1 p-0.5 rounded-md transition-colors duration-700;

  &.pending-thread {
    @apply bg-primary duration-100;
  }

  .thread-pending-msg {
    @apply absolute text-white bg-primary left-0 right-0 p-2 text-sm rounded-tl-md rounded-tr-md transform translate-y-0 transition-all duration-500 opacity-0;

    &.is-intersecting {
      @apply opacity-100;
      --tw-translate-y: -95%;
    }

    &.v-leave-to {
      @apply translate-y-0 opacity-0;
    }
  }
}

.message-separator {
  @apply w-full text-center text-xs my-3 text-gray-500 relative;

  &::after,
  &::before {
    @apply absolute bg-gray-500 w-1/3 top-1/2 transform -translate-y-1/2 opacity-10;
    height: 2px;
    content: '';
  }

  &::before {
    @apply left-0;
  }

  &::after {
    @apply right-0;
  }
}
</style>
