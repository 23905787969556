<template>
  <div>
    <div
      v-if="currentUser.roles.includes('admin:skyloud')"
      class="flex flex-col gap-4 p-4"
    >
      <div class="font-medium uppercase">
        <FontAwesomeIcon :icon="faBolt" />
        {{ $t('components.projects.statusTaskControl.customerCompletedValidation.skyloud.title') }}
      </div>
      <p class="text-sm">
        {{ $t('components.projects.statusTaskControl.customerCompletedValidation.skyloud.description.waitingForClient') }}
        <strong>{{ $t('components.projects.statusTaskControl.customerCompletedValidation.skyloud.description.destination') }}</strong>
        {{ $t('components.projects.statusTaskControl.customerCompletedValidation.skyloud.description.condition') }}
      </p>
      <SkyButton
        variant="flat"
        color="dark"
        size="small"
        animation="none"
        @click="handleCancelCustomerTaskValidation"
      >
        {{ $t('components.projects.statusTaskControl.customerCompletedValidation.skyloud.cancelButton') }}
      </SkyButton>
    </div>
    <div
      v-else
      class="flex flex-col gap-4 p-4"
    >
      <div class="font-medium uppercase">
        <FontAwesomeIcon :icon="faBolt" />
        {{ $t('components.projects.statusTaskControl.customerCompletedValidation.client.title') }}
      </div>
      <p class="text-sm">{{ $t('components.projects.statusTaskControl.customerCompletedValidation.client.description.waitingForValidation', { taskSlug: task.taskId }) }}</p>
      <p class="text-sm">{{ $t('components.projects.statusTaskControl.customerCompletedValidation.client.description.condition') }}</p>
      <div class="flex flex-col gap-2">
        <SkyButton
          variant="flat"
          color="dark"
          size="small"
          @click="handleTaskCompletion(true)"
        >
          {{ $t('components.projects.statusTaskControl.customerCompletedValidation.client.confirmButton') }}
        </SkyButton>
        <SkyButton
          variant="flat"
          color="light"
          size="small"
          @click="handleTaskCompletion(false)"
        >
          {{ $t('components.projects.statusTaskControl.customerCompletedValidation.client.refuseButton') }}
        </SkyButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { useNotify } from '@/plugins/notify/index.js';

const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const notify = useNotify();

const currentUser = computed(() => store.getters['auth/currentUser']);

async function handleCancelCustomerTaskValidation() {
  try {
    await store.dispatch('projects/updateTaskCompletionPendingStatus', { _id: props.task._id, isWaitingForCompletion: false });
  } catch (err) {
    notify.error(err.message);
  }
}

async function handleTaskCompletion(completed) {
  try {
    await store.dispatch('projects/updateTaskCompletion', { _id: props.task._id, completed });
  } catch (err) {
    notify.error(err.message);
  }
}
</script>
