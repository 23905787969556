import { InconsistentProbeEvent } from '@/modules/stats/availability/domain/probe-event/exceptions/inconsistent-probe-event.js';
import { ProbeId } from '@/modules/stats/availability/domain/probe/probe-id.js';
import { ProbeName } from '@/modules/stats/availability/domain/probe/probe-name.js';
import { NullProbeName } from '@/modules/stats/availability/domain/probe/null-probe-name.js';
import { required } from '@/utils/validate/required.js';
import { NullProbeId } from '@/modules/stats/availability/domain/probe/null-probe-id.js';
import { UnavailableStatus } from '@/modules/stats/availability/domain/probe-event/status/unavailable-status.js';
import { AvailableStatus } from '@/modules/stats/availability/domain/probe-event/status/available-status.js';
import { ProbeEventLifeTime } from '@/modules/stats/availability/domain/probe-event/life-times/probe-event-life-time.js';
import { ProbeEventId } from '@/modules/stats/availability/domain/probe-event/ids/probe-event-id.js';

export class ProbeEvent {
  /**
   * @type {ProbeEventId}
   * @private
   */
  _id;

  /**
   * @type {ProbeId|NullProbeId}
   * @private
   */
  _probeId;

  /**
   * @type {ProbeEventLifeTime}
   * @private
   */
  _lifeTime;

  /**
   * @type {ProbeName|NullProbeName}
   * @private
   */
  _name;

  /**
   * @type {String}
   * @private
   */
  _type;

  /**
   * @type {String|Undefined}
   * @private
   */
  _cause;

  /**
   * @param {ProbeEventId} id
   * @param {ProbeEventLifeTime} lifeTime
   * @param {ProbeId|NullProbeId} probeId
   * @param {String|Undefined} cause
   * @param {ProbeName|NullProbeName} name
   * @param {String} type
   */
  constructor(id, probeId, type, cause, name, lifeTime) {
    this._id = id;
    this._name = name;
    this._type = type;
    this._cause = cause;
    this._probeId = probeId;
    this._lifeTime = lifeTime;

    required(id instanceof ProbeEventId, InconsistentProbeEvent.missingId());
    required(lifeTime instanceof ProbeEventLifeTime, InconsistentProbeEvent.missingLifeTime());
    required(probeId instanceof ProbeId || probeId instanceof NullProbeId, InconsistentProbeEvent.missingProbeId());
    required(name instanceof ProbeName || name instanceof NullProbeName, InconsistentProbeEvent.missingName());
    required(typeof type === 'string', InconsistentProbeEvent.missingType());
  }

  /**
   * @return {ProbeEventLifeTime}
   */
  get lifeTime() {
    return this._lifeTime;
  }

  /**
   * @return {ProbeEventId}
   */
  get id() {
    return this._id._value;
  }

  /**
   * @return {String}
   */
  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  /**
   * @return {String}
   */
  get cause() {
    return this._cause;
  }

  /**
   * @return {ProbeEventProbeId}
   */
  get probeId() {
    return this._probeId._value;
  }

  /**
   * @returns {ProbeEventStatus}
   */
  get status() {
    return this._lifeTime.isEnded() ? new AvailableStatus() : new UnavailableStatus();
  }

  get name() {
    return this._name._value;
  }

  /**
   * @param {ProbeEvent} event
   * @returns {boolean}
   */
  isSame(event) {
    return this._id.isSame(event._id);
  }

  /**
   * @param {ProbeId} probeId
   * @returns {boolean}
   */
  isEventOfProbe(probeId) {
    return this._probeId.isSame(probeId);
  }

  hasSameProbe(event) {
    return event.isEventOfProbe(this._probeId);
  }
}
