<template>
  <div
    ref="root"
    class="tasks-list"
    :data-list-id="listId"
  >
    <div
      v-if="$slots.header"
      class="relative px-1 py-2 font-medium text-dark-purple text-md"
    >
      <slot name="header" />
    </div>
    <div
      ref="listContainer"
      class="list-container"
    >
      <slot />
      <SkyLoading v-if="watchScroll" />
      <!-- <span class="spacer" /> -->
    </div>
  </div>
</template>

<script>
import { nextTick, onUnmounted, reactive, ref, watchEffect } from 'vue';
import min from 'lodash/min';
import max from 'lodash/max';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import nth from 'lodash/nth';
import ceil from 'lodash/ceil';
import floor from 'lodash/floor';
import get from 'lodash/get';

export default {
  props: {
    listId: {
      type: String,
      default: () => undefined,
    },
    watchScroll: {
      type: Boolean,
      default: () => undefined,
    },
  },
  emits: ['onScrollBottom'],
  setup(props, { slots, emit }) {
    const root = ref('root');
    const listContainer = ref('listContainer');

    const state = reactive({
      intersectionObserver: undefined,
    });

    function handleIntersect(entries) {
      const triggeringCards = filter(entries, (entry) => entry.isIntersecting);

      if (isEmpty(triggeringCards)) {
        return;
      }

      state.intersectionObserver.disconnect();
      emit('onScrollBottom');
    }

    function updateCheckpointToCard({ checkPointCardIndexFromBottom }) {
      const indexFromBottom = props.watchScroll ? checkPointCardIndexFromBottom - 1 : checkPointCardIndexFromBottom;

      const cardTrigger = nth(listContainer.value.children, indexFromBottom);

      state.intersectionObserver.disconnect();
      state.intersectionObserver.observe(cardTrigger);
    }

    function getTriggerIndexFromBottom({ listContainerEl }) {
      if (!listContainerEl) {
        return 0;
      }

      const nbOfCards = listContainerEl.querySelectorAll('.task-card').length;
      const listHeight = listContainerEl.clientHeight;
      const cardheight = listContainerEl.children[0].clientHeight;

      const nbOfVisibleCardsAtSameTime = floor(listHeight / (cardheight + 8));

      const nbOfCardsNotVisibleInOneBlock = nbOfCards % nbOfVisibleCardsAtSameTime;

      const remeaningCardMean = ceil(nbOfCardsNotVisibleInOneBlock / 2);

      const triggerIndexFromBottom = remeaningCardMean < 5 ? max([1, remeaningCardMean]) : min([5, remeaningCardMean]);

      return -triggerIndexFromBottom;
    }

    watchEffect(async () => {
      if (!props.watchScroll) {
        return;
      }

      if (!(listContainer.value instanceof Element)) {
        await nextTick();
      }

      const defaultSlot = slots.default();
      const listContainerEl = listContainer.value;

      const newNbOfTasks = get(defaultSlot, '0.children.length');
      const nbOfMountedTasks = listContainerEl.querySelectorAll('.task-card').length;

      if (nbOfMountedTasks !== newNbOfTasks) {
        await nextTick();
      }

      if (!props.watchScroll) {
        return;
      } // Avoid calls when the component cards are refreshed but does not need to fetch anymore

      const isScrollable = listContainerEl.scrollHeight > listContainerEl.offsetHeight;

      if (!isScrollable) {
        return;
      }

      const checkPointCardIndexFromBottom = getTriggerIndexFromBottom({ listContainerEl });

      if (!state.intersectionObserver) {
        state.intersectionObserver = new IntersectionObserver(handleIntersect, {
          root: root.value,
          threshold: 1.0,
        });
      }

      updateCheckpointToCard({ checkPointCardIndexFromBottom });
    });

    onUnmounted(() => {
      if (!state.intersectionObserver) {
        return;
      }
      state.intersectionObserver.disconnect();
    });

    return {
      root,
      listContainer,
    };
  },
};
</script>

<style lang="scss">
.tasks-list {
  @apply flex flex-col h-full rounded-lg;

  .list-container {
    @apply flex flex-col flex-1 gap-2 p-1 overflow-y-auto max-h-[800px] lg:max-h-none;

    .spacer {
      @apply flex w-full pointer-events-none opacity-0 select-none;
      min-height: 7px;
    }
  }

  &.mouse-over {
    @apply bg-dark-blue-50;
    outline: 1px solid rgb(0, 55, 255);

    .task-card {
      @apply hover:bg-white;
    }
  }
}
</style>
