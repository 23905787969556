<template>
  <SkyModal
    v-bind="$attrs"
    transparent
    @close="handleClose"
  >
    <div
      class="flex flex-col gap-4 lg:flex-row lg:items-start"
      data-modal-close-on-click-sepcific-el
    >
      <div class="flex flex-col flex-1 gap-8 px-8 py-8 bg-white rounded-lg shadow pointer-events-auto">
        <div class="flex items-start justify-between gap-8">
          <SkyTitle>{{ $t('components.projects.newTaskModal.title') }}</SkyTitle>
        </div>
        <TaskForm
          edit-only
          @valid="handleSubmit"
          @active="handleActive"
        >
          <template #footer="{ form, rules }">
            <div class="flex flex-col w-full gap-4">
              <AdminAppSelect
                v-if="apps.length !== 0"
                v-model="appId"
                label="Selected app"
                :options="apps"
                :rules="rules.appId"
              />
              <div class="flex flex-row justify-between items-end w-full gap-6">
                <UserSelect
                  v-if="isAdminSkyloud"
                  v-model="form.userId"
                  label="Propriétaire"
                  :rules="rules.userId"
                />
                <SkyButton
                  :loading="loading"
                  type="submit"
                  class="whitespace-nowrap"
                >
                  <FontAwesomeIcon :icon="faFloppyDisk" />
                  {{ $t('components.projects.newTaskModal.addButton') }}
                </SkyButton>
              </div>
            </div>
          </template>
        </TaskForm>
      </div>
      <div class="flex flex-col gap-4 pointer-events-auto lg:w-1/4">
        <div class="flex flex-col gap-2 p-4 rounded-md bg-gradient-radial bg-gradient-right-bottom from-primary to-secondary text-white-smoke">
          <div class="font-medium uppercase">
            <FontAwesomeIcon :icon="faLifeRing" />
            {{ $t('components.projects.newTaskModal.support.title') }}
          </div>
          <p class="text-sm">{{ $t('components.projects.newTaskModal.support.subtitle') }}</p>
        </div>
        <div class="flex flex-col gap-2 rounded-md shadow-inner">
          <TaskCard
            v-for="task of latestsTasks"
            :key="task._id"
            :task="task"
            @click="$emit('close', { taskId: task.taskId })"
          />
        </div>
      </div>
    </div>
  </SkyModal>
</template>

<script setup>
import { computed, nextTick, ref } from 'vue';
import { useStore } from 'vuex';
import { useNotify } from '@/plugins/notify/index.js';
import TaskCard from '@/modules/projects/components/TaskCard.vue';
import TaskForm from '@/modules/projects/components/TaskForm.vue';
import UserSelect from '@/modules/users/components/UserSelect.vue';
import { faLifeRing, faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
import { useI18n } from 'vue-i18n';
import omit from 'lodash/omit';
import AdminAppSelect from '@/modules/admin/components/AdminAppSelect.vue';

const emit = defineEmits(['close', 'before-create']);
const props = defineProps({
  apps: {
    type: Array,
    default: () => [],
  },
});

const store = useStore();
const notify = useNotify();
const { t } = useI18n();

const hasRole = store.getters['auth/hasRole'];

const latestsTasks = computed(() => store.getters['projects/getLatestsTasks']);
const isAdminSkyloud = computed(() => hasRole('admin:skyloud'));
const appId = ref();
const loading = ref(false);
const isFormActive = ref(false);

async function handleCreateTask(form) {
  const task = await store.dispatch('projects/addTask', form);
  notify.success(t('components.projects.newTaskModal.success', { taskId: task.taskId }));
  nextTick(() => emit('close', { taskId: task.taskId }));
}

async function handleSubmit(form) {
  loading.value = true;
  try {
    const formToSend = isAdminSkyloud.value ? form : omit(form, ['userId']);

    if (props.apps.length > 0 && !appId.value) {
      notify.error('You need to choose a valid app where to create the request');
      loading.value = false;
      return;
    }

    if (props.apps.length > 0) {
      emit('before-create', {
        appId: appId.value,
        callback: async () => {
          try {
            await handleCreateTask(formToSend);
          } catch (err) {
            notify.error(err.message);
          }
          loading.value = false;
        },
      });
      return;
    }
    await handleCreateTask(formToSend);
  } catch (err) {
    notify.error(err.message);
  }
  loading.value = false;
}

function handleClose(options = { force: false }) {
  if (isFormActive.value && !options.force) {
    return;
  }
  emit('close');
}

function handleActive(value) {
  isFormActive.value = value;
}
</script>
