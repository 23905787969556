<template>
  <div
    v-if="open"
    class="sky-modal"
    @mousedown="$emit('close')"
  >
    <div
      class="absolute left-0 right-0 p-4 mx-auto mt-4 lg:mt-24"
      :class="width ? width : 'max-w-5xl'"
      data-modal-close-on-click-sepcific-el
      @mousedown.stop
    >
      <button
        v-if="!hideCloseButton"
        type="button"
        class="absolute top-0 right-0 z-40 inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-white rounded-full shadow outline-none cursor-pointer close-btn focus:outline-none hover:text-gray-800"
        :class="{
          'pointer-events-auto': transparent,
        }"
        @click="$emit('close', { force: true })"
      >
        <svg
          class="w-6 h-6 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
        </svg>
      </button>

      <div
        class="modal-layer"
        :class="{
          'rounded-lg shadow bg-white': !transparent,
          [padding ? padding : 'p-4 lg:p-8']: !transparent,
        }"
        v-bind="$attrs"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted } from 'vue';
import get from 'lodash/get.js';
import keys from 'lodash/keys.js';
import includes from 'lodash/includes.js';

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: undefined,
    },
    padding: {
      type: String,
      default: undefined,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    function handleEscapeKeydown(event) {
      if (!props.open || event.code !== 'Escape') {
        return;
      }
      event.preventDefault();
      emit('close');
    }

    function handleClickOnModalLayerContainers(event) {
      const targetDataSet = get(event, 'target.dataset', {});
      const hasCloseAttr = includes(keys(targetDataSet), 'modalCloseOnClickSepcificEl');
      const hasModalLayerClass = event.target.classList.contains('modal-layer');

      if (!hasCloseAttr && !hasModalLayerClass) {
        return;
      }

      emit('close');
    }

    onMounted(() => {
      document.body.addEventListener('keydown', handleEscapeKeydown);
      document.body.addEventListener('click', handleClickOnModalLayerContainers);
    });

    onBeforeUnmount(() => {
      document.body.removeEventListener('keydown', handleEscapeKeydown);
      document.body.removeEventListener('click', handleClickOnModalLayerContainers);
    });
  },
};
</script>

<style lang="scss" scoped>
.sky-modal {
  @apply fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full overflow-y-auto;
  animation-name: animateBackground;
  animation-duration: 500ms;
  animation-delay: 10ms;
  animation-fill-mode: forwards;

  .modal-layer {
    @apply block w-full opacity-0;
    animation-name: displayModal;
    animation-duration: 350ms;
    animation-delay: 100ms;
    animation-fill-mode: forwards;
  }

  .close-btn {
    @apply opacity-0;
    animation-name: displayModal;
    animation-duration: 350ms;
    animation-delay: 100ms;
    animation-fill-mode: forwards;
  }

  @keyframes displayModal {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes animateBackground {
    0% {
      @apply bg-transparent bg-opacity-0 backdrop-filter backdrop-blur-none;
    }
    100% {
      @apply bg-gray-300 bg-opacity-70 backdrop-filter backdrop-blur-sm;
    }
  }
}
</style>
