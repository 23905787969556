<template>
  <div
    :key="randomKey"
    class="relative"
    @click="openEditMenu = !openEditMenu"
  >
    <Teleport to="body">
      <div
        v-if="elementIsInView"
        :class="{ hidden: !editable || !openEditMenu }"
      >
        <div
          ref="tooltipElementRef"
          class="priority-icon-editor bg-white border border-primary border-opacity-25 text-xs p-2 rounded flex flex-col gap-1 absolute transform-gpu -translate-x-1/2 -translate-y-full transition-all z-50"
        >
          <div
            v-for="prio in priorities"
            :key="prio.label"
            class="flex flex-row items-center gap-2"
          >
            <SkyCheckbox
              :checked="internalPriorityValue === prio.code"
              @change="() => handleSelectPriority(prio)"
            />
            <div
              class="priority-tag"
              :class="{
                [`priority-tag--${prio.code}`]: true,
              }"
            >
              {{ prio.label }}
            </div>
          </div>
          <SkyButton
            :loading="loading"
            size="tiny"
            animation="none"
            class="mt-2 w-full"
            @click="handleUpdate"
          >
            Valider
          </SkyButton>
        </div>
      </div>
    </Teleport>
    <div
      ref="hostElementRef"
      class="btn-priority-icon-editor"
    >
      <span v-if="loading">
        <SkyLoading size="tiny" />
      </span>
      <div
        v-else
        class="priority-tag"
        :class="{
          [`priority-tag--${internalPriority}`]: true,
          'cursor-pointer': editable,
        }"
      >
        {{ priorityLabel }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import get from 'lodash/get';
import { useStore } from 'vuex';
import { useNotify } from '@/plugins/notify/index.js';
import { useTooltip } from '@/components/ui/tooltips/use-tooltip.js';
import { taskPriorities, taskPrioritiesLabels } from '../projects.constants.js';

const props = defineProps({
  priority: {
    type: Number,
    default: () => taskPriorities.MEDIUM,
  },
  appId: {
    type: String,
    default: undefined,
  },
  taskId: {
    type: String,
    default: undefined,
  },
  editable: {
    type: Boolean,
    default: false,
  },
});

const { locale } = useI18n();
const store = useStore();
const notify = useNotify();

const randomKey = ref(Math.random());
const { hostElementRef, tooltipElementRef, elementIsInView } = useTooltip();

const openEditMenu = ref(false);
const loading = ref(false);
const showUpdateSelect = ref(false);
const internalPriorityValue = ref(props.priority);
const internalPriority = ref(props.priority);
const priorities = computed(() => taskPrioritiesLabels[locale.value]);

const priorityLabel = computed(() => {
  const localePriorities = get(taskPrioritiesLabels, locale.value);
  const matchingPriority = localePriorities.find(({ code }) => code === internalPriority.value);

  return get(matchingPriority, 'label');
});

async function handleUpdate() {
  if (!props.editable || !props.appId || !props.taskId) return;
  try {
    loading.value = true;
    await store.dispatch('admin/connectUserToApp', { appId: props.appId });
    await store.dispatch('projects/updateTask', { _id: props.taskId, priority: internalPriorityValue.value });
    showUpdateSelect.value = false;
    internalPriority.value = internalPriorityValue.value;
    store.commit('admin/updateBacklogTask', { taskId: props.taskId, payload: { priority: internalPriorityValue.value } });
  } catch (err) {
    notify.error(err.message);
  }
  loading.value = false;
  openEditMenu.value = false;
}

function handleSelectPriority(prio) {
  internalPriorityValue.value = prio.code;
}

function handleClickOutside(event) {
  if (openEditMenu.value === false) {
    return;
  }

  const { target } = event;
  const isOutsideClick = !target.closest('.priority-icon-editor') && !target.closest('.btn-priority-icon-editor');

  if (!isOutsideClick) {
    return;
  }

  openEditMenu.value = false;
}

onMounted(() => {
  document.querySelector('body').addEventListener('click', handleClickOutside, { passive: true });
});

onUnmounted(() => {
  document.querySelector('body').removeEventListener('click', handleClickOutside);
});

watch(
  () => props.priority,
  () => {
    internalPriorityValue.value = props.priority;
    internalPriority.value = props.priority;
  },
);

watch(
  () => elementIsInView.value,
  () => {
    if (elementIsInView.value) {
      setTimeout(() => {
        randomKey.value = Math.random();
      }, 200);
    }
  },
);
</script>

<style lang="scss" scoped>
.priority-tag {
  @apply rounded px-2 py-1 font-medium;

  &--0 {
    @apply bg-success text-success bg-opacity-20;
  }

  &--1 {
    @apply bg-[#7F95FF] text-[#7F95FF] bg-opacity-20;
  }

  &--2 {
    @apply bg-[#FFD731] text-[#EEC20C] bg-opacity-20;
  }

  &--3 {
    @apply bg-[#F79348] text-[#F79348] bg-opacity-20;
  }

  &--4 {
    @apply bg-[#F44545] text-[#F44545] bg-opacity-20;
  }
}
</style>
