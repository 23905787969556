<template>
  <div class="relative flex flex-col gap-6">
    <TypeLabelsSelect
      v-model="internalLabelIds"
      :name="$t('components.projects.labelsSelect.categories.name')"
      type="category"
      :multi="false"
    />
    <TypeLabelsSelect
      v-model="internalLabelIds"
      :name="$t('components.projects.labelsSelect.themes.name')"
      type="theme"
      use-dependencies
    />
    <TypeLabelsSelect
      v-model="internalLabelIds"
      :name="$t('components.projects.labelsSelect.tags.name')"
      type="tag"
      use-dependencies
    />
  </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import TypeLabelsSelect from '@/modules/projects/components/TypeLabelsSelect.vue';

export default {
  components: {
    TypeLabelsSelect,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const state = reactive({
      internalLabelIds: computed({
        get() {
          return props.modelValue;
        },
        set(value) {
          emit('update:modelValue', value);
        },
      }),
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style></style>
