<template>
  <SkyCard
    v-if="loading"
    class="px-0 py-4 w-full h-96 shadow-none"
  >
    <SkyLoading />
  </SkyCard>
  <div v-else>
    <AvailabilityWidget />
  </div>
</template>

<script setup>
import SkyLoading from '@/components/ui/SkyLoading.vue';
import SkyCard from '@/components/ui/SkyCard.vue';
import AvailabilityWidget from '@/modules/stats/availability/infrastructure/presenters/organisms/widget/AvailabilityWidget.vue';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useNotify } from '@/plugins/notify/index.js';
import dayjs from 'dayjs';

const store = useStore();
const loading = ref(false);
const notify = useNotify();

onMounted(async () => {
  try {
    loading.value = true;
    const from = dayjs().date(1).set('hour', 0).set('minute', 0).set('second', 0).toDate();
    const to = dayjs().set('hour', 23).set('minute', 59).set('second', 59).toDate();
    await store.dispatch('stats/fetchProbesEventsHistory', { from, to });
    await store.dispatch('stats/fetchProbes');
    await store.dispatch('stats/fetchReports');
    loading.value = false;
  } catch (err) {
    notify.error(err.message);
    loading.value = false;
  }
});
</script>
