<template>
  <svg
    class="loader"
    :class="classes"
    :style="{
      width: '100%',
      height: `calc(${radius} * 2 + 10px)`,
    }"
  >
    <circle
      class="loader-placeholder"
      cx="50%"
      cy="50%"
      :r="radius"
    ></circle>
    <circle
      class="loader-fill"
      cx="50%"
      cy="50%"
      :r="radius"
    ></circle>
  </svg>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'normal',
    enum: ['extratiny', 'tiny', 'small', 'normal'],
  },
  variant: {
    type: String,
    default: 'primary',
    enum: ['white', 'primary'],
  },
});

const radiusProperties = {
  extratiny: '6px',
  tiny: '10px',
  small: '20px',
  normal: '25px',
};

const radius = computed(() => radiusProperties[props.size]);

const classes = computed(() => ({
  'sky-loader--extratiny': props.size === 'extratiny',
  'sky-loader--tiny': props.size === 'tiny',
  'sky-loader--small': props.size === 'small',
  'sky-loader--normal': props.size === 'normal',
  'sky-loader--variant-white': props.variant === 'white',
  'sky-loader--variant-primary': props.variant === 'primary',
}));
</script>

<style lang="scss" scoped>
.loader {
  &.sky-loader--extratiny {
    .loader-fill {
      stroke-width: 3px;
      stroke-dashoffset: 340;
    }

    .loader-placeholder {
      stroke-width: 3px;
    }
  }

  &.sky-loader--tiny {
    .loader-fill {
      stroke-width: 5px;
      stroke-dashoffset: 330;
    }

    .loader-placeholder {
      stroke-width: 5px;
    }
  }

  &.sky-loader--small {
    .loader-fill {
      stroke-width: 8px;
      stroke-dashoffset: 310;
    }

    .loader-placeholder {
      stroke-width: 8px;
    }
  }

  &.sky-loader--normal .loader-fill {
    stroke-width: 9px;
    stroke-dashoffset: 305;
  }

  .loader-placeholder {
    stroke-width: 9px;
  }

  &.sky-loader--variant-white {
    .loader-fill {
      fill: none;
      stroke: #fff;
      stroke-dasharray: 350;
      stroke-linecap: round;
      transform-origin: 50%;
    }

    .loader-placeholder {
      fill: none;
      stroke: #fff;
      stroke-opacity: 0.2;
      stroke-dasharray: 350;
      stroke-dashoffset: 0;
      stroke-linecap: round;
    }
  }

  &.sky-loader--variant-primary {
    .loader-fill {
      fill: none;
      stroke: #7f96ff;
      stroke-dasharray: 350;
      stroke-linecap: round;
      transform-origin: 50%;
    }

    .loader-placeholder {
      fill: none;
      stroke: #7f96ff;
      stroke-opacity: 0.2;
      stroke-dasharray: 350;
      stroke-dashoffset: 0;
      stroke-linecap: round;
    }
  }
}

.loader-fill {
  animation: load 1s infinite linear;
}

@keyframes load {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
</style>
