<template>
  <div
    data-testid="availability-consolidated-monitors"
    class="flex flex-col justify-center gap-4"
  >
    <div class="flex flex-row items-start justify-between font-semibold text-sm mt-2">
      <div class="flex flex-col">
        <h2>{{ $t('stats.availability.consolidatedMonitors') }}</h2>
        <span class="text-xs font-normal italic">{{ $t('components.stats.currentMonthGTD') }}</span>
      </div>

      <span
        :class="{
          'text-success': monitorsDisponibilities >= 75,
          'text-warning': monitorsDisponibilities >= 50 && monitorsDisponibilities < 75,
          'text-error': monitorsDisponibilities < 50,
        }"
      >
        {{ monitorsDisponibilities.toFixed(2) }}%
      </span>
    </div>

    <AvailabilityHistory
      v-if="!loading"
      class="max-w-fit mx-auto px-1"
      :history="history.toProbeEvents()"
      :probe="oldestProbe"
      :hide-months-name="false"
      :day-width="7"
    />
  </div>
</template>

<script setup>
import AvailabilityHistory from '@/modules/stats/availability/infrastructure/presenters/organisms/AvailabilityHistory.vue';
import { ConsolidatedProbesHistory } from '@/modules/stats/availability/domain/consolidated-probes-history.js';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import { useNotify } from '@/plugins/notify/index.js';
import dayjs from 'dayjs';

defineProps({
  history: {
    type: ConsolidatedProbesHistory,
    required: true,
  },
});

const store = useStore();
const notify = useNotify();
const loading = ref(false);

const monitorsDisponibilities = ref(0);
const probes = computed(() => store.getters['stats/probes']);
const oldestProbe = computed(() => head(sortBy(probes.value?._items, ['createdAt'])));

async function setMonitorsDisponibilities() {
  try {
    const disponibilities = await store.dispatch('stats/fetchMonitorsDisponibilities', {
      from: dayjs().date(1).set('hour', 0).set('minute', 0).set('second', 0).toDate(),
      to: dayjs().endOf('month').set('hour', 23).set('minute', 59).set('second', 59).toDate(),
    });

    monitorsDisponibilities.value = disponibilities.value;
  } catch (err) {
    notify.error(err.message);
  }
}

onMounted(async () => {
  try {
    loading.value = true;
    await setMonitorsDisponibilities();
    loading.value = false;
  } catch (err) {
    notify.error(err.message);
  }
});
</script>
