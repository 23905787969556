<template>
  <div
    class="relative flex items-center justify-center px-2 py-1 rounded hover:opacity-75"
    :class="{
      'bg-gradient-to-br from-secondary to-primary': !copied,
      'bg-green-400': copied,
    }"
    @click="copyToClipboard"
  >
    <a
      :href="`mailto:support@skyloud.fr?subject=${subject}&body=${body}`"
      class="absolute inset-0 z-10 text-transparent"
    >
      {{ $t('components.ui.skyDebug.sendDebugEmail') }}
    </a>
    <FontAwesomeIcon
      :icon="copied ? faCheck : faBug"
      class="text-white"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { faCheck, faBug } from '@fortawesome/pro-light-svg-icons';

export default {
  props: {
    subject: { type: String, default: () => '' },
    body: { type: String, default: () => '' },
  },
  setup() {
    const state = reactive({
      copied: false,
    });

    function copyToClipboard() {
      this.copied = true;
      setTimeout(() => {
        state.copied = false;
      }, 2000);
    }

    return {
      ...toRefs(state),
      copyToClipboard,
      faCheck,
      faBug,
    };
  },
};
</script>

<style></style>
