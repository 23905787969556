import statsApi from '@/modules/shared/api/stats.js';
import { ApiProbesRepository } from '@/modules/stats/availability/infrastructure/repositories/probes/api-probes-repository.js';
import { FindAllProbes } from '@/modules/stats/availability/domain/usecases/find-all-probes.js';
import get from 'lodash/get';
import { ApiProbeEventsRepository } from './availability/infrastructure/repositories/probe-events/api-probe-events-repository.js';
import { FindConsolidatedProbesEvents } from './availability/domain/usecases/find-consolidated-probes-events.js';
import { formatKPIUrl } from './stats.functions.js';

export async function getGTI(payload) {
  const requestUrl = formatKPIUrl('/admin/kpi/gti', payload);
  const { data } = await statsApi().get(requestUrl);

  return data;
}

export async function getGTR(payload) {
  const requestUrl = formatKPIUrl('/admin/kpi/gtr', payload);
  const { data } = await statsApi().get(requestUrl);

  return data;
}

export async function getGTD(payload) {
  const requestUrl = formatKPIUrl('/admin/kpi/gtd', payload);
  const { data } = await statsApi().get(requestUrl);

  return data;
}

export async function getReports(period) {
  const from = get(period, 'from');
  const to = get(period, 'to');

  if (!from || !to) {
    const { data } = await statsApi().get('/admin/reports');
    return data;
  }

  const { data } = await statsApi().get(`/admin/reports?from=${from}&to=${to}`);
  return data;
}

export async function getReport({ reportId }) {
  const { data } = await statsApi().get(`/admin/reports/${reportId}`);

  return data;
}

export async function patchPublishReport({ reportId }) {
  const { data } = await statsApi().patch(`/admin/reports/${reportId}/publish`);

  return data;
}

export async function patchAskReportCompletion({ reportId }) {
  const { data } = await statsApi().patch(`/admin/reports/${reportId}/request`);

  return data;
}

export async function patchReport({ reportId, causeDescription, resolveDescription, incidents }) {
  await statsApi().patch(`/admin/reports/${reportId}`, { causeDescription, resolveDescription, incidents });
}

export async function getProbes() {
  const probesRepository = new ApiProbesRepository(statsApi());
  const findAllProbes = new FindAllProbes(probesRepository);
  const probes = await findAllProbes.execute();

  return probes;
}

export async function getProbesEventsHistory(period) {
  const probeEventsRepository = new ApiProbeEventsRepository(statsApi());
  const findProbesEventsHistory = new FindConsolidatedProbesEvents(probeEventsRepository);
  const history = await findProbesEventsHistory.execute(period);

  return history;
}

export async function getMonitorsDisponibilities({ monitorsIds, from, to }) {
  const { data } = await statsApi().get('/admin/probes/disponibilities', { params: { monitorsIds, from, to } });

  return data;
}
