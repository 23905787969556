import { required } from '@/utils/validate/required.js';
import { ProbeId } from '@/modules/stats/availability/domain/probe/probe-id.js';
import { InconsistentProbe } from '@/modules/stats/availability/domain/probe/exceptions/inconsistent-probe.js';
import { ProbeName } from '@/modules/stats/availability/domain/probe/probe-name.js';
import { DateValueObject } from '@/utils/value-objects/date/date-value-object.js';

export class Probe {
  /**
   * @type {ProbeId}
   * @private
   */
  _id;

  /**
   * @type {ProbeName}
   * @private
   */
  _name;

  /**
   * @type {DateValueObject}
   * @private
   */
  _createdAt;

  /**
   * @param {ProbeId} id
   * @param {ProbeName} name
   * @param {DateValueObject} createdAt
   */
  constructor(id, name, createdAt) {
    this._id = id;
    this._name = name;
    this._createdAt = createdAt;

    required(id instanceof ProbeId, InconsistentProbe.missingId());
    required(name instanceof ProbeName, InconsistentProbe.missingName());
    required(createdAt instanceof DateValueObject, InconsistentProbe.missingCreatedAt());
  }

  get id() {
    return this._id._value;
  }

  get name() {
    return this._name;
  }

  get createdAt() {
    return this._createdAt._value;
  }

  /**
   * @param {ProbeEvent} event
   * @returns {boolean}
   */
  hasSameProbe(event) {
    return event.isEventOfProbe(this._id);
  }

  /**
   * @param {DateValueObject} date
   * @returns {boolean}
   */
  isActiveAt(date) {
    return this._createdAt.isBefore(date) || this._createdAt.isSameDay(date);
  }
}
