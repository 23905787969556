<template>
  <div class="flex flex-row items-center gap-4 w-auto">
    <div
      v-for="tab in tabs"
      :key="tab[path]"
      class="flex flex-row justify-center items-center gap-2 rounded-md bg-opacity-10 py-4 text-sm cursor-pointer transition-all w-14 duration-300 ease-out"
      :class="{
        'w-full px-2': selectedTab === tab[path],
        [`${getColors(tab)}`]: true,
      }"
      @click="selectedTab = tab[path]"
    >
      <FontAwesomeIcon
        :icon="tab.icon"
        class="text-xs"
      />
      <span
        v-if="selectedTab === tab[path]"
        class="leading-0 font-medium"
      >
        {{ tab.label }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const emit = defineEmits(['update:model-value']);
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  path: {
    type: String,
    default: 'slug',
  },
  tabs: {
    type: Array,
    default: () => [],
  },
});

const selectedTab = ref(props.modelValue || props.tabs[0][props.path]);

function getColors(tab) {
  const color = tab?.color || (selectedTab.value === tab[props.path] ? 'text-white' : 'text-primary');
  const backgroundColor = tab?.backgroundColor || (selectedTab.value === tab[props.path] ? 'bg-primary bg-opacity-100' : 'bg-primary');
  return `${color} ${backgroundColor}`;
}

watch(
  () => selectedTab.value,
  () => emit('update:model-value', selectedTab.value),
);
</script>
