import { i18n } from '@/plugins/i18n/index.js';
import { Probes } from '@/modules/stats/availability/domain/probe/probes.js';
import {
  getGTD,
  getGTI,
  getGTR,
  getReports,
  getReport,
  getProbes,
  patchReport,
  getProbesEventsHistory,
  patchPublishReport,
  patchAskReportCompletion,
  getMonitorsDisponibilities,
} from './stats.api.js';
import { ConsolidatedProbesHistory } from './availability/domain/consolidated-probes-history.js';

const stateData = {
  gti: undefined,
  gtr: undefined,
  gtd: undefined,
  reports: [],
  probes: new Probes(),
  probesEventsHistory: new ConsolidatedProbesHistory(),
  selectedPeriod: { start: undefined, end: undefined },
};

const getters = {
  getGTI: (state) => state.gti,
  getGTR: (state) => state.gtr,
  getGTD: (state) => state.gtd,
  reports: (state) => state.reports,
  probes: (state) => state.probes,
  probesEventsHistory: (state) => state.probesEventsHistory,
  selectedPeriod: (state) => state.selectedPeriod,
};

const mutations = {
  saveGti(state, { gti }) {
    state.gti = gti;
  },
  saveGtr(state, { gtr }) {
    state.gtr = gtr;
  },
  saveGtd(state, { gtd }) {
    state.gtd = gtd;
  },
  setReports(state, { reports }) {
    state.reports = reports;
  },
  setProbes(state, { probes }) {
    state.probes = probes;
  },
  setProbesEventsHistory(state, { history }) {
    state.probesEventsHistory = history;
  },
  setSelectedPeriod(state, period) {
    state.selectedPeriod = period;
  },
};

const actions = {
  async fetchReports({ commit }, period) {
    try {
      const reports = await getReports(period);
      commit('setReports', { reports });
    } catch (err) {
      throw new Error(i18n.global.t('stats.store.fetchReports.requestError'));
    }
  },
  async fetchReport(_, { reportId }) {
    try {
      return await getReport({ reportId });
    } catch (err) {
      throw new Error(i18n.global.t('stats.store.fetchReports.requestError'));
    }
  },
  async updateReport(_, { reportId, causeDescription, resolveDescription, incidents: completeIncidents }) {
    try {
      const incidents = completeIncidents.reduce((acc, incident) => [...acc, { id: incident.id, probeId: incident.probeId, type: incident.type }], []);

      await patchReport({ reportId, causeDescription, resolveDescription, incidents });
    } catch (err) {
      throw new Error(i18n.global.t('stats.store.fetchReports.requestError'));
    }
  },
  async publishReport({ commit, state }, { reportId }) {
    const updatedReport = await patchPublishReport({ reportId });
    const { reports } = state;

    commit('setReports', {
      reports: [...reports.filter((r) => r._id !== reportId), updatedReport],
    });

    return updatedReport;
  },
  async askReportCompletion({ commit, state }, { reportId }) {
    const updatedReport = await patchAskReportCompletion({ reportId });
    const { reports } = state;

    commit('setReports', {
      reports: [...reports.filter((r) => r._id !== reportId), updatedReport],
    });

    return updatedReport;
  },
  async fetchProbes({ commit }) {
    try {
      const probes = await getProbes();
      commit('setProbes', { probes });
    } catch (err) {
      throw new Error(i18n.global.t('stats.store.fetchProbes.requestError'));
    }
  },
  async fetchProbesEventsHistory({ commit }, period) {
    try {
      const history = await getProbesEventsHistory(period);
      commit('setProbesEventsHistory', { history });
    } catch (err) {
      throw new Error(i18n.global.t('stats.store.fetchProbesEvents.requestError'));
    }
  },
  async fetchGTI({ commit }, payload) {
    try {
      const gti = await getGTI(payload);
      commit('saveGti', { gti });
    } catch (err) {
      throw new Error(i18n.global.t('stats.store.fetchGTI.requestError'));
    }
  },
  async fetchGTR({ commit }, payload) {
    try {
      const gtr = await getGTR(payload);
      commit('saveGtr', { gtr });
    } catch (err) {
      throw new Error(i18n.global.t('stats.store.fetchGTR.requestError'));
    }
  },
  async fetchGTD({ commit }, payload) {
    try {
      const gtd = await getGTD(payload);
      commit('saveGtd', { gtd });
    } catch (err) {
      throw new Error(i18n.global.t('stats.store.fetchGTD.requestError'));
    }
  },
  async fetchMonitorsDisponibilities(_, { monitorsIds, from, to }) {
    try {
      const monitorsDisponibilities = await getMonitorsDisponibilities({ monitorsIds, from, to });
      return monitorsDisponibilities;
    } catch (err) {
      throw new Error(i18n.global.t('stats.store.fetchMonitorsDisponibilities.requestError'));
    }
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
