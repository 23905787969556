<script>
import get from 'lodash/get.js';
import { computed, reactive, h } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPencil, faTimes, faCheck } from '@fortawesome/pro-light-svg-icons';

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    modelValue: {
      type: Number,
      default: () => 0,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { slots, emit }) {
    const state = reactive({
      countTabs: computed(() => slots.default().length - 1),
    });

    const isLastStep = slots.default().length - 1 === props.modelValue;

    return () =>
      h('div', { class: 'pt-5' }, [
        h(
          'div',
          {
            class: 'relative flex w-full justify-between px-4 mb-8',
          },
          [
            h(
              'div',
              {
                class: 'px-4 bg-dark-blue-200 absolute top-1/2 transform -translate-y-1/2 left-6 right-6',
                style: { height: '3px' },
              },
              [
                h('div', {
                  class: 'bg-gradient-to-br from-secondary to-primary h-full',
                  style: {
                    width: `${Math.round((props.modelValue / state.countTabs) * 100)}%`,
                  },
                }),
              ],
            ),
            ...slots.default().map(({ props: slotProps }, index) =>
              h(
                'button',
                {
                  key: index,
                  type: 'button',
                  onClick: () => {
                    emit('update:modelValue', index);
                  },
                  disabled: props.modelValue < index || props.disabled,
                  class: {
                    'relative rounded-full w-8 h-8 z-10 text-white text-sm flex items-center justify-center outline-none focus:outline-none': true,
                    'bg-dark-blue-200': props.modelValue < index,
                    'bg-gradient-to-br from-secondary to-primary': props.modelValue >= index,
                  },
                },
                [
                  h(FontAwesomeIcon, {
                    icon: get(
                      {
                        [`${props.modelValue > index || isLastStep}`]: faCheck,
                        [`${props.modelValue === index && !isLastStep}`]: faPencil,
                        [`${props.modelValue < index && !isLastStep}`]: faTimes,
                      },
                      'true',
                    ),
                  }),
                  h(
                    'div',
                    {
                      class: {
                        'absolute bottom-full left-1/2 h-4 -translate-x-1/2 my-1 text-xs transform whitespace-nowrap': true,
                        'text-dark-blue-200': props.modelValue < index,
                        'text-primary': props.modelValue >= index,
                      },
                    },
                    slotProps.name,
                  ),
                ],
              ),
            ),
          ],
        ),
        slots.default().find((slot, index) => index === props.modelValue),
      ]);
  },
};
</script>
