import get from 'lodash/get';
import trim from 'lodash/trim';
import some from 'lodash/some';
import find from 'lodash/find';
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import { i18n } from '@/plugins/i18n/index.js';
import { getUsers, getUser, postCreateUser, patchUserProfile, patchUser, deleteUser } from '@/modules/users/users.api.js';
import { adminGetUsers } from '@/modules/admin/admin.api.js';
import findIndex from 'lodash/findIndex.js';
import filter from 'lodash/filter.js';

const stateData = {
  searchText: undefined,
  list: [],
  usersCache: {},
  pagination: {
    limit: 50,
    skip: 0,
    total: 0,
  },
  promises: {},
};

const getters = {
  getUser:
    (state) =>
    ({ userId }) => {
      const user = get(state.usersCache, userId);
      if (user) {
        return user;
      }
      return find(state.list, { _id: userId });
    },
  getUsers: (state) => state.list,
  hasNext: (state) => state.pagination.total > state.pagination.skip + state.pagination.limit,
  hasPrevious: (state) => state.pagination.skip > 0,
  getSearchText: (state) => state.searchText,
};

const mutations = {
  resetState(state) {
    Object.assign(state, stateData);
  },
  setUsers(state, { users }) {
    state.list = users;
  },
  addUser(state, { userToAdd }) {
    const existingUser = find(state.list, (user) => user._id === userToAdd._id);
    if (existingUser) return;
    state.list = [...state.list, userToAdd];
  },
  saveUser(state, { userId, user }) {
    delete state.promises[userId];
    state.usersCache[userId] = user;
    state.list = [{ userId, ...user }, ...state.list];
  },
  updateUser(state, { userId, user }) {
    const userIndex = findIndex(state.list, (existingUser) => existingUser._id === userId);
    if (userIndex === -1) {
      return;
    }
    state.list[userIndex] = user;
  },
  setPaginationTotal(state, { total }) {
    state.pagination.total = total;
  },
  setPagination(state, { limit, skip }) {
    state.pagination = {
      limit,
      skip,
    };
  },
  nextPagination(state) {
    const { limit, skip } = state.pagination;
    state.pagination = {
      ...state.pagination,
      skip: skip + limit,
    };
  },
  previousPagination(state) {
    const { limit, skip } = state.pagination;
    state.pagination = {
      ...state.pagination,
      skip: Math.max(0, skip - limit),
    };
  },
  setSearchText(state, { searchText }) {
    state.searchText = isEmpty(searchText) ? undefined : trim(searchText);
    state.pagination = {
      ...stateData.pagination,
    };
  },
  savePromise(state, { promiseId }) {
    state.promises[promiseId] = true;
  },
};

const actions = {
  async searchUsers({ commit }, { search }) {
    try {
      const { list: users, total } = await getUsers({
        search,
      });
      commit('setUsers', { users });
      commit('setPaginationTotal', { total });

      return users;
    } catch (err) {
      throw new Error(i18n.global.t('users.store.searchUsers.requestError'));
    }
  },
  async adminSearchUsers({ commit }, { search, appId }) {
    try {
      const { list: users, total } = await adminGetUsers({
        appId,
        search,
      });
      commit('setUsers', { users });
      commit('setPaginationTotal', { total });

      return users;
    } catch (err) {
      throw new Error(i18n.global.t('users.store.searchUsers.requestError'));
    }
  },
  async fetchUsers({ commit, state }) {
    try {
      const { list: users, total } = await getUsers({
        ...state.pagination,
        search: state.searchText,
      });
      commit('setUsers', { users });
      commit('setPaginationTotal', { total });
    } catch (err) {
      throw new Error(i18n.global.t('users.store.fetchUsers.requestError'));
    }
  },
  async fetchUser({ commit, state }, { userId }) {
    try {
      if (hasIn(state.usersCache, userId) || some(state.list, { _id: userId }) || state.promises[userId]) {
        return;
      }
      commit('savePromise', { promiseId: userId });
      const user = await getUser({ userId });

      commit('saveUser', { userId, user });
    } catch (err) {
      throw new Error(i18n.global.t('users.store.fetchUser.requestError'));
    }
  },
  async deleteUser({ commit, state }, { userId }) {
    try {
      await deleteUser({ userId });
      const updatedUsers = filter(state.list, (user) => `${user._id}` !== `${userId}`);
      commit('setUsers', { users: updatedUsers });
    } catch (err) {
      throw new Error(i18n.global.t('users.store.deleteUser.requestError'));
    }
  },
  async postCreateUser({ commit }, { email, firstName, lastName, job, metadata, address, phone, roles }) {
    try {
      const newUser = await postCreateUser({
        email,
        firstName,
        lastName,
        job,
        metadata,
        address,
        phone,
        roles,
      });
      commit('saveUser', { userId: newUser._id, user: newUser });
    } catch (err) {
      throw new Error(i18n.global.t('users.store.postCreateUser.requestError'));
    }
  },
  async patchUser({ commit }, { userId, email, firstName, lastName, job, metadata, address, phone, roles }) {
    try {
      const updatedUser = await patchUser({
        userId,
        email,
        firstName,
        lastName,
        job,
        metadata,
        address,
        phone,
        roles,
      });
      commit('updateUser', { userId, user: updatedUser });
    } catch (err) {
      throw new Error("Impossible de modifier l'utilisateur");
    }
  },
  async updateProfile({ commit }, { firstName, lastName, address, phone, locale }) {
    try {
      await patchUserProfile({
        firstName,
        lastName,
        address,
        phone,
        locale,
      });

      commit(
        'auth/setProfile',
        {
          firstName,
          lastName,
          address,
          phone,
          locale,
        },
        { root: true },
      );
    } catch (err) {
      throw new Error(i18n.global.t('users.store.updateProfile.requestError'));
    }
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
