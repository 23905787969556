import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/es/lib/isEmail';

export const getIsRequiredRule = (errorMessage) => (inputValue) => {
  if (typeof inputValue === 'number') {
    return true;
  }
  return !isEmpty(inputValue) || errorMessage || 'Le champ est nécessaire';
};

export const getIsAfter = (getDate, errorMessage) => (inputValue) => new Date(getDate()) <= new Date(inputValue) || errorMessage || `La date doit être supérieure à ${getDate()}`;

export const getIsEmailRule = (errorMessage) => (inputValue) => (!isEmpty(inputValue) && isEmail(inputValue)) || errorMessage || 'Le champ doit être une adresse email valide';

export const getIsLengthRule =
  (minLength = 8, errorMessage = undefined) =>
  (inputValue) =>
    (!isEmpty(inputValue) && size(inputValue) >= minLength) || errorMessage || `Le champ doit faire au moins ${minLength} caractères`;

export const getIsTimeDiffInHoursLessThanRule =
  (getDate, maxHours = 18, errorMessage = undefined) =>
  (inputValue) => {
    const diffInMs = Math.abs(new Date(getDate()) - new Date(inputValue));
    return diffInMs / 3600000 <= maxHours || errorMessage || `L'écart est trop important. ${maxHours}h max.`;
  };

export const getIsHexadecimalColor = (errorMessage) => (inputValue) => {
  if (/^#[A-Fa-f0-9]{6}$/.test(inputValue)) {
    return true;
  }
  return errorMessage || 'Le champ doit être une couleur hexadecimal valide';
};

export const getIsRegexMatchRule = (regex, errorMessage) => (inputValue) => {
  if (isEmpty(inputValue) || regex.test(inputValue)) {
    return true;
  }

  return errorMessage || "Le format n'est pas respecté";
};

export const getIsModulo = (modulo, errorMessage) => (inputValue) => {
  if (inputValue % modulo === 0) {
    return true;
  }

  return errorMessage || `Le champ doit être une valeur divisible par ${modulo}`;
};
