import { ImmutableCollection } from '@/utils/collections/immutable-collection.js';
import { Probe } from '@/modules/stats/availability/domain/probe/probe.js';

/**
 * @extends {ImmutableCollection<Probe>}
 */
export class Probes extends ImmutableCollection {
  innerTypeOf() {
    return Probe;
  }

  /**
   * @param {String[]} ids
   * @returns {Probes}
   */
  getFromIds(ids) {
    return Probes.fromProbes(this.filter((probe) => ids.includes(probe.id)));
  }

  /**
   * @param {Probe[]} probes
   * @returns {Probes}
   */
  static fromProbes(probes) {
    return new Probes(...probes);
  }
}
