<template>
  <div
    class="border border-[#F2F2F2] bg-white rounded-lg p-4 gap-4 flex flex-col overflow-hidden transition-all duration-500"
    :class="{ 'pb-0': folding }"
    style="min-width: 300px"
  >
    <div class="flex flex-row items-center justify-start gap-4">
      <img
        class="max-w-3/5 max-h-10"
        :src="appLogo"
        :alt="`Logo de ${appName}`"
      />
      <p class="flex flex-col flex-1 min-w-[78px] font-medium text-dark-gray">{{ $t('components.apps.appTaskPrice.myPrices') }}</p>
      <FontAwesomeIcon
        v-if="isFoldAllowed"
        :icon="faChevronRight"
        class="transition-all transform cursor-pointer text-dark-gray"
        :class="{
          'rotate-180': folding,
          'rotate-90': !folding,
        }"
        @click="folding = !folding"
      />
    </div>
    <div
      class="flex flex-col items-center justify-start w-3/4 gap-4 mx-auto text-sm transition-all duration-500 transform text-dark-gray"
      style="transform-origin: top center"
      :class="{ 'max-h-0': folding, 'max-h-60': !folding }"
    >
      <p
        class="flex items-center justify-center w-full gap-5"
        :class="{
          'flex-col': `${dailyPrice}`.length > 10 || `${hourlyPrice}`.length > 10,
          'mt-4': `${dailyPrice}`.length > 10 || `${hourlyPrice}`.length > 10,
        }"
      >
        <span class="w-full font-medium text-left whitespace-nowrap">{{ $t('components.apps.appTaskPrice.prices.daily') }} :</span>
        <span class="px-2 py-1 font-semibold bg-gray-100 rounded whitespace-nowrap">{{ dailyPrice }}€ {{ $t('components.apps.appTaskPrice.exclTaxes') }}</span>
      </p>
      <p
        class="flex items-center justify-center w-full gap-5"
        :class="{
          'flex-col': `${dailyPrice}`.length > 10 || `${hourlyPrice}`.length > 10,
          'mt-2': `${dailyPrice}`.length > 10 || `${hourlyPrice}`.length > 10,
        }"
      >
        <span class="w-full font-medium text-left whitespace-nowrap">{{ $t('components.apps.appTaskPrice.prices.hourly') }} :</span>
        <span class="px-2 py-1 font-semibold bg-gray-100 rounded whitespace-nowrap">{{ hourlyPrice }}€ {{ $t('components.apps.appTaskPrice.exclTaxes') }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { reactive, computed, toRefs, onMounted } from 'vue';
import { useStore } from 'vuex';
import get from 'lodash/get';
import find from 'lodash/find';
import toLower from 'lodash/toLower';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

export default {
  props: {
    isFolded: {
      type: Boolean,
      default: false,
    },
    isFoldAllowed: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();

    const state = reactive({
      app: computed(() => store.state.apps.app),
      missionLabel: computed(() => find(store.state.projects.labels, (label) => toLower(label.name) === 'mission')),
      hourlyPrice: computed(() => get(state.missionLabel, 'rules.@pricingTaskUpdate.unitHourlyAmountInCents', 0) / 100),
      dailyPrice: computed(() => get(state.missionLabel, 'rules.@pricingTaskUpdate.unitDailyAmountInCents', 0) / 100),
      appLogo: computed(() => get(state.app, 'logo', '/img/default-logo.svg')),
      appName: computed(() => get(state.app, 'name', '')),
      folding: false,
    });

    onMounted(() => {
      state.folding = props.isFoldAllowed ? props.isFolded : false;
    });

    return {
      ...toRefs(state),
      faChevronRight,
    };
  },
};
</script>
