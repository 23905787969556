<template>
  <SkyForm
    class="flex flex-col flex-1 gap-6"
    v-bind="$attrs"
    @valid="handleSubmit"
  >
    <SkyInputText
      v-model="form.name"
      :rules="rules.name"
      :placeholder="$t('components.projects.taskForm.inputs.name.placeholder')"
      :label="$t('components.projects.taskForm.inputs.name.label')"
      auto-focus
      :read-only="readOnly"
      :autofocus="editOnly && !form.name"
    />
    <div>
      <span class="block mb-2 text-sm font-medium text-primary">
        {{ $t('components.projects.taskForm.inputs.description.label') }}
        <span class="text-error">*</span>
      </span>
      <div
        v-if="!editorEnabled"
        ref="markdownRef"
        class="px-3 py-2 prose-sm prose break-words transition-colors border rounded hover:bg-gray-50 border-dark-blue-100 hover:border-primary max-w-none"
        :class="{
          'cursor-pointer': !readOnly,
        }"
        @click="handleOpenEditor"
        v-html="parse(form.description || '')"
      />
      <div
        v-else
        class="flex flex-col gap-2"
      >
        <SkyTextarea
          ref="textareaRef"
          v-model="form.description"
          :rules="rules.description"
          :read-only="readOnly"
          class="flex-1"
          :placeholder="$t('components.projects.taskForm.inputs.description.placeholder')"
          rows="10"
          :autofocus="!!form.name"
        />
        <span class="text-xs text-gray-400">
          {{ $t('components.projects.taskForm.markdown') }}
          <a
            href="https://www.markdownguide.org/basic-syntax/"
            target="_blank"
            rel="noopener noreferrer"
            class="text-primary hover:underline"
          >
            {{ $t('components.projects.taskForm.documentation') }}
          </a>
        </span>
      </div>
    </div>
    <div
      v-if="!readOnly"
      class="flex justify-end"
    >
      <slot
        name="footer"
        :form="form"
        :rules="rules"
      />
    </div>
  </SkyForm>
</template>

<script>
import { computed, nextTick, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { parse } from 'marked';
import pick from 'lodash/pick';
import prismjs from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import { getIsRequiredRule } from '@/modules/shared/utils/formValidationRules.js';
import { useStore } from 'vuex';
import get from 'lodash/get';

export default {
  props: {
    task: {
      type: Object,
      default: () => undefined,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
    editOnly: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['valid', 'active'],

  setup(props, { emit }) {
    const store = useStore();
    const markdownRef = ref(null);

    const currentUser = computed(() => store.getters['auth/currentUser']);

    const state = reactive({
      form: {
        name: undefined,
        userId: get(currentUser, 'value._id'),
        description: undefined,
        isPending: undefined,
      },
      rules: {
        name: [getIsRequiredRule()],
        userId: [getIsRequiredRule()],
        appId: [getIsRequiredRule()],
        description: [getIsRequiredRule()],
      },
      loading: false,
      editorEnabled: props.editOnly,
    });

    function updatePrismRender() {
      if (!markdownRef.value || state.editorEnabled) {
        return;
      }

      nextTick(() => {
        try {
          prismjs.highlightAllUnder(markdownRef.value);
        } catch (err) {
          console.debug('Unable to highlight all under');
        }
      });
    }

    function handleSubmit() {
      emit('valid', state.form);
      state.editorEnabled = props.editOnly;
      updatePrismRender();
      emit('active', false);
    }

    function handleOpenEditor() {
      if (props.readOnly) {
        return;
      }
      state.editorEnabled = true;
    }

    function handleTaskActive() {
      const isEditingNameField = !!state.form.name && props.task?.name !== state.form.name;
      const isEditingDescriptionField = !!state.form.description && props.task?.description !== state.form.description;
      emit('active', isEditingNameField || isEditingDescriptionField);
    }

    function handleNewTaskActive() {
      const isEditingNameField = !!state.form.name;
      const isEditingDescriptionField = !!state.form.description;
      emit('active', isEditingNameField || isEditingDescriptionField);
    }

    watch(
      () => pick(props.task, ['name', 'description', 'pendingAt', 'userId']),
      (value) => {
        if (!value) return;
        state.form = {
          name: value.name,
          userId: value.userId,
          description: value.description,
          isPending: !!value.pendingAt,
        };
        updatePrismRender();
      },
    );

    watch(
      () => state.form,
      () => {
        if (props.task) {
          handleTaskActive();
          return;
        }
        handleNewTaskActive();
      },
      { deep: true },
    );

    onMounted(() => {
      if (!props.task) return;
      state.form = {
        name: props.task.name,
        userId: props.task.userId,
        description: props.task.description,
        isPending: !!props.task.pendingAt,
      };
      if (!markdownRef.value) return;
      updatePrismRender();
    });

    return {
      handleSubmit,
      handleOpenEditor,
      parse,
      markdownRef,
      ...toRefs(state),
    };
  },
};
</script>
