<template>
  <button
    class="relative text-white rounded btn-confirm focus:outline-none"
    :class="classes"
    type="button"
    :disabled="disabled"
    @click.stop="confirmAction"
  >
    <div class="absolute w-6 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
      <SkyLoading
        v-show="loading"
        size="tiny"
        variant="white"
      />
    </div>
    <div :class="{ invisible: loading }">
      <slot v-if="!state.waiting && !state.asked" />
      <span v-else>{{ label }}</span>
      <div
        v-if="state.waiting"
        class="btn-confirm__progress"
        :style="{
          animationDuration: `${cooldown}ms`,
        }"
      />
      <div
        v-if="state.reseting"
        class="btn-confirm__progress"
        :style="{
          animationDuration: `${cooldown * 2}ms`,
        }"
      />
    </div>
  </button>
</template>

<script>
import { computed, onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  props: {
    confirmText: { type: String, default: undefined },
    waitingText: { type: String, default: undefined },
    variant: {
      type: String,
      default: () => 'error',
      validator: (value) => ['error', 'warning', 'outline'].includes(value),
    },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    cooldown: { type: [Number, String], default: 1500 },
    size: { type: String, default: () => 'medium' },
  },

  emits: ['confirm'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const state = reactive({
      asked: false,
      reseting: false,
      waiting: false,
      confirmActionTimeout: undefined,
      confirmText: t('components.ui.skyButtonConfirm.confirmText'),
      waitingText: t('components.ui.skyButtonConfirm.waitingText'),
    });

    const classes = computed(() => ({
      'pointer-events-none opacity-60': state.waiting,
      'bg-gray-900 hover:bg-gray-800': !props.variant,
      'opacity-60 disabled': props.loading,
      // VARIANTS
      'bg-red-600 hover:bg-red-500': props.variant === 'error',
      'bg-warning hover:bg-warning': props.variant === 'warning',
      'sky-btn--variant-outline': props.variant === 'outline',
      // SIZES
      'sky-btn--small': props.size === 'small',
      'sky-btn--medium': props.size === 'medium',
      // STATE
      'sky-btn--disabled': props.disabled || props.loading,
    }));

    const label = computed(() => {
      if (state.waiting) return state.waitingText;
      return state.confirmText;
    });

    function resetAction() {
      state.reseting = true;
      setTimeout(() => {
        state.asked = false;
        state.waiting = false;
        state.reseting = false;
      }, props.cooldown * 2);
    }

    function confirmAction() {
      if (state.waiting || props.loading) return;
      if (!state.asked) {
        state.waiting = true;
        state.confirmActionTimeout = setTimeout(() => {
          state.asked = true;
          state.waiting = false;
          resetAction();
        }, props.cooldown);
        return;
      }
      clearTimeout(state.confirmActionTimeout);
      state.asked = false;
      state.waiting = false;
      state.reseting = false;
      emit('confirm');
    }

    onMounted(() => {
      if (props.confirmText) {
        state.confirmText = props.confirmText;
      }
      if (props.waitingText) {
        state.waitingText = props.waitingText;
      }
    });

    return {
      state,
      label,
      classes,
      confirmAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-confirm {
  &__progress {
    height: 2px;
    @apply bg-white absolute bottom-0 left-0;
    animation: cooldown 1500ms linear forwards;
  }

  /* VARIANTS */
  &.sky-btn--variant-outline {
    @apply bg-transparent border border-primary text-primary;

    & .btn-confirm__progress {
      @apply bg-primary;
    }
  }

  /* SIZES */
  &.sky-btn--small {
    @apply py-2 px-4 text-xs;
  }

  &.sky-btn--medium {
    @apply px-6 py-3 text-sm;
  }

  /* STATES */
  &.sky-btn--disabled {
    @apply opacity-60 transform-none cursor-not-allowed;
  }
}

@keyframes cooldown {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}
</style>
