import axios from 'axios';
import get from 'lodash/get';
import omit from 'lodash/omit';
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import * as Sentry from '@sentry/browser';
import { i18n } from '@/plugins/i18n/index.js';
import { readAccessTokenData } from '@/utils/jwt.js';
import { login, recovery, fetchUser, createApp, resetPassword, getAccessTokens, createAccessToken, deleteAccessToken } from '@/modules/auth/auth.api.js';

const defaultState = {
  accessToken: undefined,
  user: {},
  accessTokens: [],
};

function getWorkspaceAccessTokens() {
  try {
    return JSON.parse(localStorage.getItem('workspace_tokens') || '{}');
  } catch (err) {
    return {};
  }
}

function saveWorkspaceAccessToken({ appId, accessToken }) {
  const workspaceTokens = {
    ...getWorkspaceAccessTokens(),
    [appId]: accessToken,
  };
  localStorage.setItem('workspace_tokens', JSON.stringify(workspaceTokens));
}

function removeWorkspaceAccessToken({ appId }) {
  const workspaceTokens = getWorkspaceAccessTokens();
  localStorage.setItem('workspace_tokens', JSON.stringify(omit(workspaceTokens, appId)));
}

function getAppAccessToken({ appId }) {
  const workspaceTokens = getWorkspaceAccessTokens();
  return get(workspaceTokens, appId);
}

function getInitialState() {
  const accessToken = localStorage.getItem('token');
  if (!accessToken) return defaultState;
  try {
    const data = readAccessTokenData({ accessToken });
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common['x-app-id'] = data.appId;

    return { ...defaultState, accessToken, user: data };
  } catch (error) {
    return defaultState;
  }
}

const stateData = getInitialState();

const getters = {
  isAuthenticated: ({ accessToken, user }) => !!accessToken && !!user,
  currentUser: ({ user }) => user,
  token: ({ accessToken }) => accessToken,
  isProfileComplete: ({ user }) => !isEmpty(user.firstName) && !isEmpty(user.lastName),
  getAccessTokens: (state) => state.accessTokens,
  hasRole: (state) => (role) => {
    const roles = get(state.user, 'roles', []);
    return roles.includes(role);
  },
  isAdminSkyloud: ({ user }) => (!user ? false : includes(user.roles, 'admin:skyloud')),
};

const mutations = {
  setUser(state, { user }) {
    state.user = user;
  },
  setProfile(state, { firstName, lastName, address, phone, locale }) {
    state.user = {
      ...state.user,
      firstName,
      lastName,
      address,
      phone,
      locale,
    };
  },
  setLocalToken(state, { accessToken }) {
    try {
      const data = readAccessTokenData({ accessToken });

      axios.defaults.headers.common['x-app-id'] = data.appId;
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      localStorage.setItem('token', accessToken);
      state.accessToken = accessToken;

      saveWorkspaceAccessToken({
        appId: data.appId,
        accessToken,
      });
    } catch (err) {
      console.error(err);
      localStorage.removeItem('workspace_tokens');
      localStorage.removeItem('token');
    }
  },
  removeLocalToken(state) {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['x-app-id'];
    delete axios.defaults.headers.common.Authorization;
    state.user = {};
    state.accessToken = undefined;
  },
  setAccessTokens(state, { accessTokens }) {
    state.accessTokens = accessTokens;
  },
  addAccessToken(state, { accessToken }) {
    state.accessTokens = [...state.accessTokens, accessToken];
  },
  removeAccessToken(state, payload) {
    const accessTokenId = get(payload, 'accessTokenId');
    state.accessTokens = reject(state.accessTokens, { _id: accessTokenId });
  },
};

const actions = {
  async login({ commit }, { appId, email, password }) {
    try {
      axios.defaults.headers.common['x-app-id'] = appId;
      const { token: accessToken } = await login({ appId, email, password });
      const data = readAccessTokenData({ accessToken });

      if (!includes(data.roles, 'admin')) {
        throw new Error(i18n.global.t('auth.store.login.notEnoughRights'));
      }
      commit('setLocalToken', { accessToken });
    } catch (err) {
      delete axios.defaults.headers.common['x-app-id'];
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('auth.store.login.requestError'));
      }
      throw err;
    }
  },
  logout({ commit }) {
    Sentry.setUser(null);
    commit('apps/resetState', undefined, { root: true });
    commit('users/resetState', undefined, { root: true });
    commit('payments/resetState', undefined, { root: true });
    commit('bookings/resetState', undefined, { root: true });
    commit('converters/resetState', undefined, { root: true });
    commit('projects/resetState', undefined, { root: true });
    commit('chats/resetState', undefined, { root: true });
    commit('removeLocalToken');
  },
  removeWorkspaceToken({ getters: localGetters }) {
    try {
      const data = readAccessTokenData({ accessToken: localGetters.token });
      removeWorkspaceAccessToken({ appId: data.appId });
    } catch (err) {
      console.error(err);
    }
  },
  async switchApp({ commit, dispatch }, { appId }) {
    await dispatch('logout');
    const accessToken = getAppAccessToken({ appId });

    if (!accessToken) {
      throw new Error(i18n.global.t('auth.store.switchApp.accessTokenExpired'));
    }

    commit('setLocalToken', { accessToken });
  },
  async recovery(store, { appId, email }) {
    try {
      axios.defaults.headers.common['x-app-id'] = appId;
      await recovery({ email });
    } catch (err) {
      delete axios.defaults.headers.common['x-app-id'];
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('auth.store.recovery.requestError'));
      }
      throw err;
    }
  },
  async resetPassword(store, { password }) {
    try {
      await resetPassword({ password });
    } catch {
      throw new Error(i18n.global.t('auth.store.resetPassword.requestError'));
    }
  },
  async fetchUser({ commit, dispatch }) {
    try {
      const { user } = await fetchUser();
      const userId = get(user, '_id');
      // eslint-disable-next-line no-underscore-dangle
      Sentry.setUser({
        id: user._id,
        email: user.email,
      });
      commit('setUser', {
        user: {
          userId,
          ...user,
        },
      });

      // const userRoles = get(user, 'roles', []);

      // if (includes(userRoles, 'admin:skyloud')) {
      //   commit('projects/setMembersFilters', { membersFilters: [userId] }, { root: true });
      // }
      await dispatch('apps/searchAssociatedEmailApps', { email: user.email }, { root: true });
    } catch (error) {
      throw new Error(i18n.global.t('auth.store.fetchUser.requestError'));
    }
  },
  async createApp(store, { slug, name, description, options, url, owner, address, tax }) {
    try {
      return await createApp({
        slug,
        name,
        description,
        options,
        url,
        owner,
        address,
        tax,
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('auth.store.createApp.requestError'));
      }
      throw err;
    }
  },
  async fetchAccessTokens({ commit }) {
    try {
      const accessTokens = await getAccessTokens();
      commit('setAccessTokens', { accessTokens });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('auth.store.fetchAccessTokens.requestError'));
      }
      throw err;
    }
  },
  async addAccessToken({ commit }, { name }) {
    try {
      const accessToken = await createAccessToken({ name });
      commit('addAccessToken', {
        accessToken: omit(accessToken, 'token'),
      });

      return accessToken;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('auth.store.addAccessToken.requestError'));
      }
      throw err;
    }
  },
  async removeAccessToken({ commit }, { accessTokenId }) {
    try {
      await deleteAccessToken({ accessTokenId });
      commit('removeAccessToken', { accessTokenId });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error(i18n.global.t('auth.store.removeAccessToken.requestError'));
      }
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
