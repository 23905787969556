<template>
  <router-link class="sky-list-item">
    <slot />
  </router-link>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.sky-list-item {
  @apply px-6 py-4 text-sm font-light rounded text-primary transition-colors;

  &:hover {
    @apply bg-dark-blue-50;
  }

  &.router-link-exact-active {
    @apply bg-gradient-to-br from-secondary to-primary text-white-smoke;
  }
}
</style>
