/**
 * @interface
 */
export class ProbeEventsDayState {
  /**
   * @param {ImmutableCollection<ProbeEvent>} eventsOfTheDay
   * @returns {ProbeEventStatus}
   */
  // eslint-disable-next-line no-unused-vars
  getDayStatus(eventsOfTheDay) {
    throw new Error('Not implemented');
  }
}
