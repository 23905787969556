import get from 'lodash/get';
import dayjs from 'dayjs';

export function getEventColorFromBookingStatus({ status }) {
  if (status === 'confirmed') {
    return 'green';
  }
  if (status === 'awaiting_payment') {
    return 'dashed_green';
  }
  if (status === 'pending') {
    return 'blue';
  }
  if (status === 'canceled') {
    return 'black';
  }
  if (status === 'closed') {
    return 'orange';
  }
  if (status === 'failed') {
    return 'red';
  }
  return 'primary';
}

export function createHtmlGiftVoucher({ booking, room, selectedOptions, price }) {
  const html = `
  <div>
    <div style="height: 100%;width:100%;background-image:url(https://static.skyloud.net/projects/nuit-nature/gift_voucher_background_v2.jpeg);background-position:center center;background-size:cover;">
        <div style="position:absolute;top:17.6cm;left:4cm;">${booking.giftReclaimCode || booking._id}</div>
          <div style="position:absolute;top:17.7cm;left:17.5cm;">${dayjs(booking.confirmedAt).add(1, 'year').format('DD/MM/YYYY')}</div>
          <strong style="position:absolute;top:20cm;left:9.1cm;">
            ${get(price, 'nickname', 'Erreur')} - Pour ${booking.adults} adulte(s)${booking.children > 0 ? ` et ${booking.children} enfant(s)` : ''}
          </strong>
          <small style="position:absolute;top:20.5cm;left:9.1cm;color:#ffffff;">${booking._id}</small>
          <div style="position:absolute;top:21cm;width:20cm;left:1.8cm;">
            <h2>${booking.quantity} nuit${booking.quantity > 1 ? 's' : ''} • ${room.name}</h2>
            <p>${room.description}</p>
            <strong>Prestations incluses :</strong>
              <ul>
                ${selectedOptions
                  .map(
                    ({ product, quantity }) => `<li>
                    <div><strong>
                      ${get(product, 'name', 'Produit inconnu')} - Quantité : ${quantity}
                    </strong></div>${
                      get(product, 'description') === null
                        ? ''
                        : `<p>
                      ${get(product, 'description')}
                    </p>`
                    }
                  </li>`,
                  )
                  .join('')}
              </ul>
            </div>
          </div>
          <div style="height: 100%;width:100%;background-image:url(https://static.skyloud.net/projects/nuit-nature/gift_voucher_background_message.jpeg);background-position:center center;background-size:cover;"></div>
        </div>`;
  return { html };
}
