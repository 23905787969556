export const REPORT_REQUEST_LIMIT = 900_000;

export const incidentTypes = {
  TO_DEFINE: {
    disabled: true,
    textColor: 'text-error',
    backgroundColor: 'bg-error bg-opacity-20',
    label: 'A définir',
  },
  INTERNAL: {
    textColor: 'text-[#DEAA1D]',
    backgroundColor: 'bg-[#FFEFC3]',
    label: 'Infrastructure',
  },
  EXTERNAL: {
    textColor: 'text-[#575757]',
    backgroundColor: 'bg-[#DBDBDB]',
    label: 'Cloud Provider',
  },
  CUSTOMER: {
    textColor: 'text-[#43AA65]',
    backgroundColor: 'bg-[#D8FFE1]',
    label: 'Applicatif',
  },
  MAINTENANCE: {
    textColor: 'text-primary',
    backgroundColor: 'bg-[#EBEEFF]',
    label: 'Maintenance',
  },
};

export const reportMessageTemplates = [
  {
    name: "Scale up/down de l'infra",
    messages: {
      causeDescription: "Scaling automatique de l'infrastructure",
      resolveDescription: 'Aucune - comportement désiré',
    },
  },
  {
    name: 'Dépassement des ressources allouées',
    messages: {
      causeDescription: 'Redémarrage des instances suite au franchissement des limites établies',
      resolveDescription: 'Aucune - comportement attendu. Il est néanmoins possible de revoir les limites si cela est souhaité',
    },
  },
  {
    name: 'Mise a jour applicatif',
    messages: {
      causeDescription: 'Indisponibilité liée à une mise à jour ou une action client',
      resolveDescription: 'Aucune - comportement désiré',
    },
  },
];
